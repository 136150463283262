import React, { Component } from 'react';
import Util from '../../../../Util';
import Textarea from 'react-textarea-autosize';
// Redux
import { connect } from 'react-redux';
import { saveMyActivityTaskFieldsAction } from '../../../../actions/activityActions';

//Components
import Tooltip from '../../Tooltip/Tooltip';
import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import RadioButton from '../../RadioButton/RadioButton';
import DateField from '../../DateTimeField/DateField/DateField';
import ContextMenu from '../../ContextMenu/ContextMenu';
import Dropdown from '../../Dropdown/Dropdown';

class MyActivitiesTaskPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myActivityTask: this.props.myActivityTask,

      isAutoSaveEnabled: !this.props.myActivityTask.completedAt,
      isEditing: !this.props.myActivityTask.completedAt,

      fieldUpdateLookup: {}
    };

    this.autoSaveTimeout = null;

    this.completeTask = this.completeTask.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.saveFields = this.saveFields.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
  }
  componentWillReceiveProps(props) {
    if (props.myActivityTask) {
      this.setState({
        myActivityTask: props.myActivityTask,
        isAutoSaveEnabled: !props.myActivityTask.completedAt,
        isEditing: !props.myActivityTask.completedAt
      });
    }
  }
  componentWillUnmount() {
    if (this.state.isAutoSaveEnabled) {
      clearTimeout(this.autoSaveTimeout);
      this.saveFields();
    }
  }
  onFieldChange(myActivityTaskFieldId, newValue) {
    let fieldUpdateData = this.state.fieldUpdateLookup[
      myActivityTaskFieldId
    ] || { value: null };

    fieldUpdateData.value = newValue;

    this.setFieldUpdateData(myActivityTaskFieldId, fieldUpdateData);
  }
  onSingleOptionFieldChange(myActivityTaskFieldId, selectedOptionId) {
    let fieldUpdateData = this.state.fieldUpdateLookup[
      myActivityTaskFieldId
    ] || { setOptionId: null };

    fieldUpdateData.setOptionId = selectedOptionId;

    this.setFieldUpdateData(myActivityTaskFieldId, fieldUpdateData);
  }
  onMultipleOptionFieldChange(myActivityTaskFieldId, toggleSelectedOptionId) {
    let fieldUpdateData = this.state.fieldUpdateLookup[
      myActivityTaskFieldId
    ] || { toggleOptionIds: [] };

    fieldUpdateData.toggleOptionIds = fieldUpdateData.toggleOptionIds.includes(
      toggleSelectedOptionId
    )
      ? [
          ...fieldUpdateData.toggleOptionIds.filter(
            toggledOptionId => toggledOptionId !== toggleSelectedOptionId
          )
        ]
      : [...fieldUpdateData.toggleOptionIds, toggleSelectedOptionId];

    this.setFieldUpdateData(myActivityTaskFieldId, fieldUpdateData);
  }
  setFieldUpdateData(myActivityTaskFieldId, fieldUpdateData) {
    this.setState({
      fieldUpdateLookup: {
        ...this.state.fieldUpdateLookup,
        [myActivityTaskFieldId]: { ...fieldUpdateData }
      }
    });

    if (this.state.isAutoSaveEnabled) {
      clearTimeout(this.autoSaveTimeout);
      this.autoSaveTimeout = setTimeout(this.saveFields, 2000);
    }
  }
  saveFields() {
    if (Object.keys(this.state.fieldUpdateLookup).length > 0) {
      let updatedMyActivityTaskFields = this.state.myActivityTask.myActivityTaskFields.map(
        myActivityTaskField => {
          //If the field ID does not have associated update data, return the object as usual.
          if (
            !this.state.fieldUpdateLookup.hasOwnProperty(
              myActivityTaskField.myActivityTaskFieldId
            )
          )
            return myActivityTaskField;

          //But if it DOES match, return the object with the altered value

          let fieldUpdateData = this.state.fieldUpdateLookup[
            myActivityTaskField.myActivityTaskFieldId
          ];

          if (fieldUpdateData.hasOwnProperty('value')) {
            // A simple text or string field
            return {
              ...myActivityTaskField,
              value: fieldUpdateData.value
            };
          } else if (fieldUpdateData.hasOwnProperty('setOptionId')) {
            // A single option selectable field like a dropdown or radio buttons
            return {
              ...myActivityTaskField,
              myActivityTaskFieldOptions: myActivityTaskField.myActivityTaskFieldOptions.map(
                myActivityTaskFieldOption => {
                  return myActivityTaskFieldOption.myActivityTaskFieldOptionId ===
                    fieldUpdateData.setOptionId
                    ? { ...myActivityTaskFieldOption, isSelected: true }
                    : { ...myActivityTaskFieldOption, isSelected: false };
                }
              )
            };
          } else if (fieldUpdateData.hasOwnProperty('toggleOptionIds')) {
            // A multiple option selectable field like a checkbox group
            return {
              ...myActivityTaskField,
              myActivityTaskFieldOptions: myActivityTaskField.myActivityTaskFieldOptions.map(
                myActivityTaskFieldOption => {
                  let shouldToggle = fieldUpdateData.toggleOptionIds.includes(
                    myActivityTaskFieldOption.myActivityTaskFieldOptionId
                  );

                  //If the option ID does not match, return the object as usual.
                  //If it DOES match, return the object with isSelected the opposite (toggled) value - (!isSelected)
                  return !shouldToggle
                    ? myActivityTaskFieldOption
                    : {
                        ...myActivityTaskFieldOption,
                        isSelected: !myActivityTaskFieldOption.isSelected
                      };
                }
              )
            };
          }

          return null;
        }
      );

      //Perform the save action, which will save serverside and update the myActvitiy in redux state if it is currently in progress
      this.props.saveMyActivityTaskFieldsAction(
        this.state.myActivityTask.myActivityId,
        this.state.myActivityTask.myActivityTaskId,
        updatedMyActivityTaskFields
      );

      //set the update data
      this.setState({
        myActivityTask: {
          ...this.state.myActivityTask,
          myActivityTaskFields: updatedMyActivityTaskFields
        },

        fieldUpdateLookup: {}
      });
    }
  }
  toggleEditing() {
    this.setState({
      isEditing: !this.state.isEditing,
      fieldUpdateLookup: {}
    });
  }
  completeTask() {
    if (this.state.isAutoSaveEnabled) {
      clearTimeout(this.autoSaveTimeout);
      this.saveFields();
    }

    if (this.props.onCompleteTask) this.props.onCompleteTask();
  }
  render() {
    let getField = (myActivityTaskField, htmlFieldId) => {
      let field = null;

      let getFieldOptionGroupName = () => `${htmlFieldId}_option`;

      switch (myActivityTaskField.type) {
        case Util.enum.FieldType.Text:
          field = (
            <div className="field-container" id={htmlFieldId}>
              <input
                type="text"
                placeholder={
                  myActivityTaskField.placeholder || 'Enter your response'
                }
                defaultValue={myActivityTaskField.value || ''}
                onChange={e =>
                  this.onFieldChange(
                    myActivityTaskField.myActivityTaskFieldId,
                    e.target.value
                  )
                }
              />
            </div>
          );
          break;

        case Util.enum.FieldType.Textarea:
          field = (
            <div className="field-container" id={htmlFieldId}>
              <Textarea
                placeholder={
                  myActivityTaskField.placeholder || 'Enter your response'
                }
                defaultValue={myActivityTaskField.value || ''}
                onChange={e =>
                  this.onFieldChange(
                    myActivityTaskField.myActivityTaskFieldId,
                    e.target.value
                  )
                }
              />
            </div>
          );
          break;

        case Util.enum.FieldType.Dropdown:
          let dropdownSelectedOption = myActivityTaskField.myActivityTaskFieldOptions.find(
            option => option.isSelected
          );
          field = (
            <div className="field-container" id={htmlFieldId}>
              <Dropdown
                defaultValue={
                  dropdownSelectedOption
                    ? dropdownSelectedOption.myActivityTaskFieldOptionId
                    : ''
                }
                options={myActivityTaskField.myActivityTaskFieldOptions}
                displayProp={'label'}
                valueProp={'myActivityTaskFieldOptionId'}
                onChange={selectedValue =>
                  this.onSingleOptionFieldChange(
                    myActivityTaskField.myActivityTaskFieldId,
                    selectedValue
                  )
                }
                isEmptyAllowed={true}
                placeholder={
                  myActivityTaskField.placeholder || 'Select your response'
                }
              />
            </div>
          );
          break;

        case Util.enum.FieldType.CheckboxGroup:
          field = (
            <div className="field-container" id={htmlFieldId}>
              {myActivityTaskField.myActivityTaskFieldOptions.map(
                myActivityTaskFieldOption => (
                  <Checkbox
                    key={myActivityTaskFieldOption.myActivityTaskFieldOptionId}
                    defaultChecked={myActivityTaskFieldOption.isSelected}
                    onChange={() =>
                      this.onMultipleOptionFieldChange(
                        myActivityTaskField.myActivityTaskFieldId,
                        myActivityTaskFieldOption.myActivityTaskFieldOptionId
                      )
                    }
                    label={myActivityTaskFieldOption.label}
                  />
                )
              )}
            </div>
          );
          break;

        case Util.enum.FieldType.RadioButtons:
          field = (
            <div className="field-container" id={htmlFieldId}>
              {myActivityTaskField.myActivityTaskFieldOptions.map(
                myActivityTaskFieldOption => (
                  <RadioButton
                    key={myActivityTaskFieldOption.myActivityTaskFieldOptionId}
                    name={getFieldOptionGroupName()}
                    defaultChecked={myActivityTaskFieldOption.isSelected}
                    onChange={() =>
                      this.onSingleOptionFieldChange(
                        myActivityTaskField.myActivityTaskFieldId,
                        myActivityTaskFieldOption.myActivityTaskFieldOptionId
                      )
                    }
                    label={myActivityTaskFieldOption.label}
                  />
                )
              )}
            </div>
          );
          break;

        case Util.enum.FieldType.DateField:
          field = (
            <div className="field-container" id={htmlFieldId}>
              <DateField
                defaultValue={myActivityTaskField.value}
                min={myActivityTaskField.min}
                max={myActivityTaskField.max}
                onChange={newDate =>
                  this.onFieldChange(
                    myActivityTaskField.myActivityTaskFieldId,
                    newDate
                  )
                }
              />
            </div>
          );
          break;

        default:
          break;
      }

      return field;
    };

    let getLockedField = (myActivityTaskField, htmlFieldId) => {
      let lockedFieldContent = null;

      switch (myActivityTaskField.type) {
        case Util.enum.FieldType.Text:
        case Util.enum.FieldType.Textarea:
          lockedFieldContent = myActivityTaskField.value || '';
          break;

        case Util.enum.FieldType.DateField:
          lockedFieldContent = Util.format.date.weekdayMonthDayYear(
            myActivityTaskField.value
          );
          break;

        case Util.enum.FieldType.CheckboxGroup:
          let selectedOptions = myActivityTaskField.myActivityTaskFieldOptions.filter(
            myActivityTaskFieldOption => myActivityTaskFieldOption.isSelected
          );
          lockedFieldContent = Util.array.any(selectedOptions) ? (
            <ul className="checklist">
              {selectedOptions.map(selectedOption => (
                <li key={selectedOption.myActivityTaskFieldOptionId}>
                  {selectedOption.label}
                </li>
              ))}
            </ul>
          ) : null;
          break;

        case Util.enum.FieldType.RadioButtons:
        case Util.enum.FieldType.Dropdown:
          let selectedOption = myActivityTaskField.myActivityTaskFieldOptions.find(
            myActivityTaskFieldOption => myActivityTaskFieldOption.isSelected
          );
          lockedFieldContent = selectedOption ? selectedOption.label : '';
          break;

        default:
          break;
      }

      return (
        <div className="field-container locked" id={htmlFieldId}>
          {lockedFieldContent || (
            <span className="no-response">No response provided.</span>
          )}
        </div>
      );
    };

    let getFieldLabel = (myActivityTaskField, htmlFieldId) => {
      return (
        <label className="blue" htmlFor={htmlFieldId}>
          {myActivityTaskField.label}
          {myActivityTaskField.htmlHelp ? (
            <Tooltip
              tipTrigger={<span className="field-help-trigger bold">?</span>}
              tipContent={
                <div
                  className="field-help"
                  dangerouslySetInnerHTML={{
                    __html: myActivityTaskField.htmlHelp
                  }}
                ></div>
              }
              position="top"
            />
          ) : null}
        </label>
      );
    };

    return (
      <div className="my-activities-task-panel">
        <div className="task-title">
          <div className="title-upper">
            <h2 className="task-ordinal">
              Task {this.state.myActivityTask.ordinal}
            </h2>
            {!this.state.isEditing &&
            Util.array.any(this.state.myActivityTask.myActivityTaskFields) ? (
              <ContextMenu
                items={[
                  {
                    icon: Util.icon.edit,
                    label: 'Edit Task',
                    onClick: this.toggleEditing
                  }
                ]}
              />
            ) : null}
          </div>
          <h2>{this.state.myActivityTask.name}</h2>
        </div>
        {this.state.myActivityTask.htmlContent ? (
          <section
            dangerouslySetInnerHTML={{
              __html: this.state.myActivityTask.htmlContent
            }}
          />
        ) : null}
        {Util.array.any(this.state.myActivityTask.myActivityTaskFields) ? (
          <div className="task-fields">
            {this.state.myActivityTask.myActivityTaskFields.map(
              (myActivityTaskField, idx) => {
                let htmlFieldId =
                  'field_' + myActivityTaskField.myActivityTaskFieldId;

                return (
                  <div className="task-field" key={idx}>
                    {getFieldLabel(myActivityTaskField, htmlFieldId)}
                    {this.state.isEditing
                      ? getField(myActivityTaskField, htmlFieldId)
                      : getLockedField(myActivityTaskField, htmlFieldId)}
                  </div>
                );
              }
            )}
          </div>
        ) : null}
        <div className="task-footer">
          {!this.state.myActivityTask.completedAt ? (
            <Button
              label="I have completed this task"
              onClick={this.completeTask}
              size="lg"
              className="task-complete-button"
              icon={Util.icon.tick}
            />
          ) : null}
          {!this.state.isAutoSaveEnabled && this.state.isEditing ? (
            <Button
              label="Cancel"
              onClick={this.toggleEditing}
              isSecondary={true}
            />
          ) : null}
          {!this.state.isAutoSaveEnabled && this.state.isEditing ? (
            <Button
              label="Save"
              onClick={() => {
                this.saveFields();
                this.toggleEditing();
              }}
              isSecondary={false}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { saveMyActivityTaskFieldsAction }
)(MyActivitiesTaskPanel);
