import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

// Data source: journey or journeyId?
export default class JourneyBadge extends Component {
  render() {
    let journey =
      this.props.journey ||
      Util.context.referenceData.getJourneyById(this.props.journeyId);
    if (!journey) return 'N/A';

    let skill = null;
    let bgColour = Util.css.colour.ledaLightBlue;
    let borderColour = Util.css.colour.ledaBlue;

    if (Util.array.any(journey.skills)) {
      skill = journey.skills[0];
      let skillGroup = Util.context.referenceData.getSkillGroupById(
        skill.skillGroupId
      );
      borderColour = skillGroup.primaryColour;
      bgColour = skillGroup.complementaryColour;
    }

    return (
      <div
        className={`journey-badge ${this.props.size || 'md'} ${this.props
          .className || ''}`}
        style={{
          backgroundColor: `#${bgColour}`,
          borderColor: `#${borderColour}`
        }}
      >
        <div className="journey-badge-images">
          {skill ? (
            <img
              key={skill.skillId}
              className="journey-image"
              src={Util.storage.skillCircularIcon(skill.urlId)}
              alt={skill.name}
            />
          ) : null}
        </div>
        <ReactSVG
          className="journey-svg-icon"
          src={Util.icon.journey}
          style={{ backgroundColor: `#${borderColour}` }}
        />
      </div>
    );
  }
}
