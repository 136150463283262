import React, { Component } from 'react';
import validator from 'validator';
import withForm from '../withForm';

import Button from '../../Button/Button';

class ForgotPasswordForm extends Component {
  render() {
    return (
      <form className="form-forgot" onSubmit={this.props.onSubmit}>
        <p>Please enter the email address associated with your account.</p>
        {this.props.getField('email')}
        <div className="button-container">
          <Button label="Submit" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(ForgotPasswordForm, {
  fields: {
    email: {
      label: 'Email',
      getError: val => {
        if (!validator.isEmail(val)) return 'Enter a valid email address';
      }
    }
  }
});
