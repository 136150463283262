import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

//Components
import SkillCardsView from '../../../../UI/CardsView/SkillCardsView/SkillCardsView';

export default class AppSkillsPage extends Component {
  render() {
    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Skills'
      }
    ];

    return (
      <div className="leda-page-app-skills">
        <AppExploreLayout
          pageTitle="Skills"
          pageSubtitle="Learn about the key skills great leaders rely on every day."
          pageIcon={Util.icon.skill}
          breadcrumbs={breadcrumbs}
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="capabilities-section">
                {Util.context.referenceData
                  .getCapabilities()
                  .map(capability => (
                    <div
                      key={capability.capabilityId}
                      className="capability-group"
                    >
                      <div className="capability-heading">
                        <h1>{capability.name}</h1>
                        <p>{capability.overview}</p>
                      </div>
                      {capability.skillGroups.map(skillGroup => {
                        return (
                          <div
                            key={skillGroup.skillGroupId}
                            className="capability-skill-group"
                          >
                            <h3 className="skill-group-name grey-3">
                              {skillGroup.name}
                            </h3>
                            {Util.array.any(skillGroup.skills) ? (
                              <SkillCardsView skills={skillGroup.skills} />
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
