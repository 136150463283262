//Polyfills for legacy browser support - lets IE display things SLIGHTLY better
import 'react-app-polyfill/ie9';
import 'core-js/fn/array';
import 'core-js/fn/string';

import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdminActivityEditorPage from './Pages/Admin/AdminActivityEditorPage/AdminActivityEditorPage';
import AdminActivityListPage from './Pages/Admin/AdminActivityListPage/AdminActivityListPage';
import AdminCommunicationListPage from './Pages/Admin/AdminCommunicationListPage/AdminCommunicationListPage';
import AdminContentEditorPage from './Pages/Admin/AdminContentEditorPage/AdminContentEditorPage';
import AdminContentListPage from './Pages/Admin/AdminContentListPage/AdminContentListPage';
// Foundations
import AdminFoundation from './Foundations/Admin/AdminFoundation';
// 3. Admin only
import AdminHomePage from './Pages/Admin/AdminHomePage/AdminHomePage';
import AdminJourneyListPage from './Pages/Admin/AdminJourneyListPage/AdminJourneyListPage';
import AdminOrganisationCreatorPage from './Pages/Admin/AdminOrganisationCreatorPage/AdminOrganisationCreatorPage';
import AdminOrganisationEditorPage from './Pages/Admin/AdminOrganisationEditorPage/AdminOrganisationEditorPage';
import AdminTeamEditorPage from './Pages/Admin/AdminTeamEditorPage/AdminTeamEditorPage';
import AdminTeamListPage from './Pages/Admin/AdminTeamListPage/AdminTeamListPage';
import AdminTeamPresetEditor from './Pages/Admin/AdminTeamPresetEditor/AdminTeamPresetEditor';
import AdminTeamPresetsPage from './Pages/Admin/AdminTeamPresetsPage/AdminTeamPresetsPage';
import AdminTestPage from './Pages/Admin/AdminTestPage/AdminTestPage';
import AdminUserListPage from './Pages/Admin/AdminUserListPage/AdminUserListPage';
import AppActivitiesPage from './Pages/App/Explore/AppActivitiesPage/AppActivitiesPage';
import AppAssessmentsPage from './Pages/App/Explore/AppAssessmentsPage/AppAssessmentsPage';
// 2. Authenticated only
import AppCompletedActivitiesPage from './Pages/App/Activities/AppCompletedActivitiesPage/AppCompletedActivitiesPage';
import AppCoursePage from './Pages/App/Explore/AppCoursePage/AppCoursePage';
import AppExplorePage from './Pages/App/Explore/AppExplorePage/AppExplorePage';
import AppFeedbackPage from './Pages/App/Feedback/AppFeedbackPage/AppFeedbackPage';
import AppFoundation from './Foundations/App/AppFoundation';
import AppGamesPage from './Pages/App/Explore/AppGamesPage/AppGamesPage';
import AppHelpPage from './Pages/App/Help/AppHelpPage/AppHelpPage';
import AppJourneyPage from './Pages/App/Journey/AppJourneyPage/AppJourneyPage';
import AppMyActivitiesPage from './Pages/App/Activities/AppMyActivitiesPage/AppMyActivitiesPage';
import AppPostPage from './Pages/App/Misc/AppPostPage/AppPostPage';
import AppProfilePage from './Pages/App/Misc/AppProfilePage/AppProfilePage';
import AppSettingProfilePage from './Pages/App/Settings/AppSettingsProfilePage/AppSettingsProfilePage';
import AppSettingsNotificationPage from './Pages/App/Settings/AppSettingsNotificationPage/AppSettingsNotificationPage';
import AppSkillPage from './Pages/App/Explore/AppSkillPage/AppSkillPage';
import AppSkillsPage from './Pages/App/Explore/AppSkillsPage/AppSkillsPage';
import AppStoryPage from './Pages/App/Explore/AppStoryPage/AppStoryPage';
import AppTeamInsightsPage from './Pages/App/Teams/AppTeamInsightsPage/AppTeamInsightsPage';
import AppTeamJourneysPage from './Pages/App/Teams/AppTeamJourneysPage/AppTeamJourneysPage';
import AppTeamMembersPage from './Pages/App/Teams/AppTeamMembersPage/AppTeamMembersPage';
import AppTeamPage from './Pages/App/Teams/AppTeamPage/AppTeamPage';
import AppTeamPostsPage from './Pages/App/Teams/AppTeamPostsPage/AppTeamPostsPage';
import AuthFoundation from './Foundations/Auth/AuthFoundation';
// 1. Guest only
import AuthLoginPage from './Pages/Auth/AuthLoginPage/AuthLoginPage';
import AuthPasswordCreatePage from './Pages/Auth/AuthPasswordCreatePage/AuthPasswordCreatePage';
import AuthPasswordForgotPage from './Pages/Auth/AuthPasswordForgotPage/AuthPasswordForgotPage';
import AuthPasswordResetPage from './Pages/Auth/AuthPasswordResetPage/AuthPasswordResetPage';
import AuthVerifyAccountPage from './Pages/Auth/AuthVerifyAccountPage/AuthVerifyAccountPage';
// Components
import Modal from '../components/UI/Modal/Modal';
import Site404Page from './Pages/Site/Site404Page/Site404Page';
import SiteArticlesBrowsePage from './Pages/Site/Articles/SiteArticlesBrowsePage/SiteArticlesBrowsePage';
import SiteArticlesContentPage from './Pages/Site/Articles/SiteArticlesContentPage/SiteArticlesContentPage';
import SiteContactPage from './Pages/Site/SiteContactPage/SiteContactPage';
import SiteFoundation from './Foundations/Site/SiteFoundation';
// 4. All
import SiteHomePage from './Pages/Site/SiteHomePage/SiteHomePage';
import SiteHowPage from './Pages/Site/SiteHowPage/SiteHowPage';
import SiteInvalidBrowserPage from './Pages/Site/SiteInvalidBrowserPage/SiteInvalidBrowserPage';
import SiteLicenseExpiredPage from './Pages/Site/SiteLicenseExpiredPage/SiteLicenseExpiredPage';
import SitePlatformPage from './Pages/Site/SitePlatformPage/SitePlatformPage';
import SitePricingPage from './Pages/Site/SitePricingPage/SitePricingPage';
import SitePrivacyPolicyPage from './Pages/Site/SitePrivacyPolicyPage/SitePrivacyPolicyPage';
import SiteTermsConditionsPage from './Pages/Site/SiteTermsConditionsPage/SiteTermsConditionsPage';
import SiteUnderConstructionPage from './Pages/Site/SiteUnderConstructionPage/SiteUnderConstructionPage';
import SiteUnsubscribePage from './Pages/Site/SiteUnsubscribePage/SiteUnsubscribePage';
import SiteUserFeedbackPage from './Pages/Site/SiteUserFeedbackPage/SiteUserFeedbackPage';
import Util from '../Util';
import { authenticateAction } from '../actions/userActions';
import { closeAllModalsAction } from '../actions/modalActions'; //Probably shouldn't open any modals here as they'd be there on construction page etc
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class AppContainer extends Component {
  componentDidMount() {
    //Register the history tracking event, and other window (global) events
    this.props.history.listen((location, action) => this.onPageChange());
    Util.event.registerWindowEvents();

    //Authenticate the user
    this.props.authenticateAction();
    // Fire page as we've (presumably) just loaded up the page
    this.onPageChange();
  }
  componentDidUpdate() {
    Util.route.setPageTitle(this.props.userNotifications);
  }
  onPageChange() {
    //Fires on initial page load(mounted- as seen above) and with each subsequent route change
    Util.analytics.page();
    Util.route.setPageTitle(this.props.userNotifications);
    this.props.closeAllModalsAction();
    Util.scroll.to(0);

    const currentUser = Util.context.user.getCurrent();

    if (currentUser) {
      // window.Intercom('boot', {
      //   app_id: 'tsufmi78',
      //   name: currentUser.fullName,
      //   email: currentUser.email,
      //   created_at: currentUser.createdAt
      // });
    } else {
      // window.Intercom('boot', {
      //   app_id: 'tsufmi78'
      // });
    }

    //Jumping to a the id of an element specificed in the hash (could use a cleanup)
    if (window.location.hash) {
      let jumpToElId = window.location.hash.slice(1);
      let attempts = 0;
      let tryScroll = () => {
        attempts++;
        let jumpToEl = document.getElementById(jumpToElId);
        //eslint-disable-next-line
        jumpToEl
          ? Util.scroll.to(jumpToEl.offsetTop)
          : attempts < 20
          ? setTimeout(tryScroll, Util.context._isReady ? 20 : 1000)
          : null;
      };
      tryScroll();
    }
  }
  render() {
    //If context isn't ready, show loadmask (authenticate still happening)
    if (!Util.context._isReady)
      return (
        <div className="app-container">
          <div className="loader"></div>
        </div>
      );

    const isAuthenticated = () => !!Util.context.user.getCurrent();

    //This intentionally doesn't redirect because we want to maintain the URL for copypasta.
    //This is intentionally outside the return below because we don't want to render other elements like the header/footer

    //If there is a thenTo redirection parameter in the url, go there now. The parameter will be removed on reditect.
    let thenTo = Util.route.getParameterByName('thenTo');
    if (isAuthenticated() && thenTo) return <Redirect to={thenTo} />;

    return (
      <div className="app-container">
        <Switch>
          <Route
            path="/app"
            render={() => {
              if (Util.context.env.getIsUnderConstruction())
                return <Redirect to={Util.route.site.underConstruction()} />;
              if (!isAuthenticated())
                return <Redirect to={Util.route.auth.login()} />;

              /* /app - accessible to logged in users */
              return (
                <AppFoundation>
                  <Switch>
                    {/* Redirect */}
                    <Route
                      exact
                      path="/app"
                      render={() => (
                        <Redirect
                          to={{
                            pathname: Util.route.app.home(),
                            search: this.props.location.search
                          }}
                        />
                      )}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/app/home"
                      render={() => (
                        <Redirect
                          to={{
                            pathname: Util.route.app.home(),
                            search: this.props.location.search
                          }}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app/profile"
                      render={({ match }) => (
                        <AppProfilePage
                          userId={Util.context.user.getCurrentUserId()}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app/profile/:userId"
                      render={({ match }) => (
                        <AppProfilePage userId={match.params.userId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/post/:postId"
                      render={({ match }) => (
                        <AppPostPage postId={match.params.postId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/journey/:journeyId"
                      render={({ match }) => (
                        <AppJourneyPage journeyId={match.params.journeyId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/journey/:journeyId/step/:stepId"
                      render={({ match }) => (
                        <AppJourneyPage
                          journeyId={match.params.journeyId}
                          stepId={match.params.stepId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app/activities"
                      render={() => <AppMyActivitiesPage />}
                    />
                    <Route
                      exact
                      path="/app/activities/completed-activities"
                      render={() => <AppCompletedActivitiesPage />}
                    />
                    <Route
                      exact
                      path="/app/feedback"
                      render={() => <AppFeedbackPage />}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/app/settings"
                      render={() => (
                        <Redirect to={Util.route.app.settingsProfile()} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/settings/profile"
                      render={() => <AppSettingProfilePage />}
                    />
                    <Route
                      exact
                      path="/app/settings/notification"
                      render={() => <AppSettingsNotificationPage />}
                    />
                    <Route
                      exact
                      path="/app/help"
                      render={() => <AppHelpPage />}
                    />
                    <Route
                      exact
                      path="/app/explore"
                      render={() => <AppExplorePage />}
                    />
                    <Route
                      exact
                      path="/app/explore/activities"
                      render={() => <AppActivitiesPage />}
                    />
                    <Route
                      exact
                      path="/app/explore/games"
                      render={() => <AppGamesPage />}
                    />
                    <Route
                      exact
                      path="/app/explore/assessments"
                      render={() => <AppAssessmentsPage />}
                    />
                    <Route
                      exact
                      path="/app/explore/skill"
                      render={() => <AppSkillsPage />}
                    />
                    <Route
                      exact
                      path="/app/explore/skill/:skillId"
                      render={({ match }) => (
                        <AppSkillPage skillId={match.params.skillId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/explore/story"
                      render={({ match }) => <AppCoursePage />}
                    />
                    <Route
                      exact
                      path="/app/explore/story/:storyId"
                      render={({ match }) => (
                        <AppStoryPage storyId={match.params.storyId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/explore/story/:storyId/chapter/:chapterId"
                      render={({ match }) => (
                        <AppStoryPage
                          storyId={match.params.storyId}
                          chapterId={match.params.chapterId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app/team"
                      render={() => <AppTeamPage />}
                    />
                    <Route
                      exact
                      path="/app/team/:teamId"
                      render={({ match }) => (
                        <AppTeamPage teamId={match.params.teamId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/team/:teamId/journeys"
                      render={({ match }) => (
                        <AppTeamJourneysPage teamId={match.params.teamId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/team/:teamId/members"
                      render={({ match }) => (
                        <AppTeamMembersPage teamId={match.params.teamId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/team/:teamId/posts"
                      render={({ match }) => (
                        <AppTeamPostsPage teamId={match.params.teamId} />
                      )}
                    />
                    <Route
                      exact
                      path="/app/team/:teamId/insights"
                      render={({ match }) => (
                        <AppTeamInsightsPage teamId={match.params.teamId} />
                      )}
                    />
                    {/* If none of the above routes are matched, return a 404 */}
                    {/* Redirect */}{' '}
                    <Route
                      render={() => (
                        <Redirect to={Util.route.site.error404()} />
                      )}
                    />
                  </Switch>
                  {/* Modals */}
                  {this.props.modals.map(modal => (
                    <Modal key={modal.id} modal={modal} />
                  ))}
                </AppFoundation>
              );
            }}
          />
          {/* /auth - accessible to guests only */}
          <Route
            path="/auth"
            render={() => {
              if (Util.context.env.getIsUnderConstruction())
                return <Redirect to={Util.route.site.underConstruction()} />;
              if (isAuthenticated())
                return <Redirect to={Util.route.app.home()} />;

              return (
                <AuthFoundation>
                  <Switch>
                    <Route
                      exact
                      path="/auth/login"
                      render={({ match }) => <AuthLoginPage />}
                    />
                    <Route
                      exact
                      path="/auth/password-forgot"
                      render={() => <AuthPasswordForgotPage />}
                    />
                    <Route
                      exact
                      path="/auth/password-reset/:token"
                      render={({ match }) => (
                        <AuthPasswordResetPage token={match.params.token} />
                      )}
                    />
                    <Route
                      exact
                      path="/auth/password-create/:token"
                      render={({ match }) => (
                        <AuthPasswordCreatePage token={match.params.token} />
                      )}
                    />
                    <Route
                      exact
                      path="/auth/verify-account/:token"
                      render={({ match }) => (
                        <AuthVerifyAccountPage token={match.params.token} />
                      )}
                    />
                    {/* If none of the above routes are matched, return a 404 */}
                    {/* Redirect */}{' '}
                    <Route
                      render={() => (
                        <Redirect to={Util.route.site.error404()} />
                      )}
                    />
                  </Switch>
                </AuthFoundation>
              );
            }}
          />
          {/* /admin - admins only */}
          <Route
            path="/admin"
            render={() => {
              if (Util.context.env.getIsUnderConstruction())
                return <Redirect to={Util.route.site.underConstruction()} />;
              if (!Util.context.user.getIsLedaAdmin())
                return <Redirect to={Util.route.site.home()} />;

              return (
                <AdminFoundation>
                  <Switch>
                    <Route
                      exact
                      path="/admin"
                      render={() => <AdminHomePage />}
                    />
                    <Route
                      exact
                      path="/admin/test"
                      render={() => <AdminTestPage />}
                    />
                    <Route
                      exact
                      path="/admin/user-list"
                      render={() => <AdminUserListPage />}
                    />
                    <Route
                      exact
                      path="/admin/organisation-creator"
                      render={props => (
                        <AdminOrganisationCreatorPage {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/organisation-editor/:organisationId"
                      render={({ match }) => (
                        <AdminOrganisationEditorPage
                          organisationId={match.params.organisationId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/team-list"
                      render={props => <AdminTeamListPage {...props} />}
                    />
                    <Route
                      path="/admin/team-editor/:teamId"
                      render={({ match }) => (
                        <AdminTeamEditorPage teamId={match.params.teamId} />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/team-presets"
                      render={() => <AdminTeamPresetsPage />}
                    />
                    <Route
                      exact
                      path="/admin/communication-list/:recipient"
                      render={({ match }) => (
                        <AdminCommunicationListPage
                          recipient={match.params.recipient}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/content-editor"
                      render={() => <AdminContentEditorPage />}
                    />
                    <Route
                      exact
                      path="/admin/content-editor/:contentId"
                      render={({ match }) => (
                        <AdminContentEditorPage
                          contentId={match.params.contentId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/content-list"
                      render={() => <AdminContentListPage />}
                    />
                    <Route
                      exact
                      path="/admin/activity-editor"
                      render={() => <AdminActivityEditorPage />}
                    />
                    <Route
                      exact
                      path="/admin/activity-editor/:activityId"
                      render={({ match }) => (
                        <AdminActivityEditorPage
                          activityId={match.params.activityId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/activity-list"
                      render={() => <AdminActivityListPage />}
                    />
                    <Route
                      exact
                      path="/admin/journey-list"
                      render={() => <AdminJourneyListPage />}
                    />
                    <Route
                      path="/admin/team-presets/:presetId"
                      render={props => <AdminTeamPresetEditor {...props} />}
                    />
                    {/* If none of the above routes are matched, return a 404 */}
                    {/* Redirect */}{' '}
                    <Route
                      render={() => (
                        <Redirect to={Util.route.site.error404()} />
                      )}
                    />
                  </Switch>
                  {/* Modals */}
                  {this.props.modals.map(modal => (
                    <Modal key={modal.id} modal={modal} />
                  ))}
                </AdminFoundation>
              );
            }}
          />
          {/* /site - accessible to anybody (includes exact path "/" - site home) */}
          <Route
            path="/"
            render={() => {
              return (
                <SiteFoundation>
                  <Switch>
                    <Route exact path="/" render={() => <SiteHomePage />} />
                    <Route
                      exact
                      path="/privacy-policy"
                      render={() => <SitePrivacyPolicyPage />}
                    />
                    <Route
                      exact
                      path="/terms-conditions"
                      render={() => <SiteTermsConditionsPage />}
                    />
                    <Route
                      exact
                      path="/user-feedback/:token"
                      render={({ match }) => (
                        <SiteUserFeedbackPage token={match.params.token} />
                      )}
                    />
                    <Route
                      exact
                      path="/unsubscribe"
                      render={() => <SiteUnsubscribePage />}
                    />
                    <Route
                      exact
                      path="/license-expired"
                      render={() => <SiteLicenseExpiredPage />}
                    />
                    <Route
                      exact
                      path="/pricing"
                      render={() => <SitePricingPage />}
                    />
                    <Route exact path="/how" render={() => <SiteHowPage />} />
                    <Route
                      exact
                      path="/contact"
                      render={() => (
                        <SiteContactPage
                          key={'contact us'}
                          variation={'contact us'}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/try-for-free"
                      render={() => (
                        <SiteContactPage
                          key={'try for free'}
                          variation={'try for free'}
                        />
                      )}
                    />
                    {/* <Route exact path="/courses" render={() => <SiteCoursesPage />} /> */}
                    <Route
                      exact
                      path="/platform"
                      render={() => <SitePlatformPage />}
                    />
                    <Route
                      exact
                      path="/articles"
                      render={({ match }) => <SiteArticlesBrowsePage />}
                    />
                    <Route
                      exact
                      path="/articles/tag/:tagId"
                      render={({ match }) => (
                        <SiteArticlesBrowsePage tagId={match.params.tagId} />
                      )}
                    />
                    <Route
                      exact
                      path="/articles/:contentId"
                      render={({ match }) => (
                        <SiteArticlesContentPage
                          contentId={match.params.contentId}
                        />
                      )}
                    />
                    <Route exact path="/404" render={() => <Site404Page />} />
                    <Route
                      exact
                      path="/under-construction"
                      render={() => <SiteUnderConstructionPage />}
                    />
                    {/* Other Redirects (that don't begin with /app or /auth etc) - These should only be changed very cautiously  */}
                    {/* Redirect */}
                    <Route
                      exact
                      path="/site/signup"
                      render={() => (
                        <Redirect
                          to={{
                            pathname: Util.route.site.contact(),
                            search: this.props.location.search
                          }}
                        />
                      )}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/site/register"
                      render={() => (
                        <Redirect
                          to={{
                            pathname: Util.route.site.contact(),
                            search: this.props.location.search
                          }}
                        />
                      )}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/site/privacy-policy"
                      render={() => (
                        <Redirect to={Util.route.site.privacyPolicy()} />
                      )}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/site/terms-conditions"
                      render={() => (
                        <Redirect to={Util.route.site.termsConditions()} />
                      )}
                    />
                    {/* Redirect */}
                    <Route
                      exact
                      path="/site/unsubscribe"
                      render={({ match }) => (
                        <Redirect to={Util.route.site.unsubscribe()} />
                      )}
                    />
                    {/* If none of the above routes are matched, return a 404 */}
                    {/* Redirect */}{' '}
                    <Route
                      render={() => (
                        <Redirect to={Util.route.site.error404()} />
                      )}
                    />
                  </Switch>
                </SiteFoundation>
              );
            }}
          />
          {/* Universal error redirect - just in case */}
          {/* Redirect */}}
          <Route
            render={({ match }) => <Redirect to={Util.route.site.error404()} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modals: state.modalState.modals,
  userNotifications: state.notificationState.userNotifications
});

export default withRouter(
  connect(mapStateToProps, { authenticateAction, closeAllModalsAction })(
    AppContainer
  )
);
