import React, { Component } from 'react';

import AppActivitiesLayout from '../../../../Layouts/App/AppActivitiesLayout/AppActivitiesLayout';

// Components
import ActivityCardsView from '../../../../UI/CardsView/ActivityCardsView/ActivityCardsView';

export default class AppCompletedActivitiesPage extends Component {
  render() {
    return (
      <div className="leda-page-app-completed-activities">
        <AppActivitiesLayout
          pageTitle="Completed Activities"
          pageSubtitle="Reflect on the activities you've completed in the past."
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <ActivityCardsView dataUrl="/api/activity/getCompletedActivities" />
            </div>
          </div>
        </AppActivitiesLayout>
      </div>
    );
  }
}
