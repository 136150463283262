import React, { Component } from 'react';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';
import ContactForm from '../../../UI/Forms/ContactForm/ContactForm';
import CompanyLogos from '../../../UI/CompanyLogos/CompanyLogos';

import img_devices from '../../../../img/site/devices.png';

export default class SiteContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false,
      variation: this.props.variation
    };

    this.setIsSubmitted = this.setIsSubmitted.bind(this);
  }
  componentDidMount() {
    let email = Util.route.getParameterByName('email');
    if (email) Util.analytics.track('EnteredEmailForContact', { email });

    // Util.ads.google.track(Util.ads.google.ConversionEvent.DemoContactPageView);
    // Util.ads.facebook.track(Util.ads.facebook.Event.ViewContent, { content_ids: 'demo-contact-page', });
    // Util.ads.linkedIn.track(Util.ads.linkedIn.ConversionEvent.DemoContactPageView);
  }

  setIsSubmitted(isSubmitted) {
    this.setState({
      isSubmitted
    });
  }

  // shouldComponentUpdate(nextProps){
  // 	console.log(nextProps)
  // 	return nextProps.variation !== this.state.variation;
  // }

  // componentDidUpdate(props) {
  // 	this.setState({variation: props.variation})
  // 	this.setIsSubmitted(true);
  // }

  render() {
    return (
      <div className="leda-page-site-contact">
        <SiteLayout>
          <div className="contact-strip">
            <div className="curve-bg"></div>
            <div className="beams"></div>
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="contact-container">
                  <div className="contact-detail">
                    <div className="contact-detail-heading">
                      <h2
                        className={
                          'gilroy white ' +
                          (this.state.isSubmitted ? 'is-submitted' : '')
                        }
                      >
                        {this.state.variation === 'contact us'
                          ? 'Contact Us'
                          : 'Try Leda for free'}
                      </h2>
                    </div>
                    <img alt="" src={img_devices} />
                  </div>
                  <div className="contact-form">
                    <div className="contact-form-inner">
                      {this.state.isSubmitted ? (
                        <p className="text-center">
                          Thank you, we'll be in touch shortly.
                        </p>
                      ) : (
                        <ContactForm
                          variation={this.props.variation}
                          onSubmit={(form, formData) => {
                            this.setIsSubmitted(true);

                            if (this.props.variation === 'contact us') {
                              Util.api.post('/api/public/submitContactUs', {
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                                email: formData.email,
                                organisation: formData.organisation,
                                jobTitle: formData.jobTitle,
                                message: formData.message
                              });

                              //Analytics
                              Util.analytics.track('ContactUsSubmitted', {
                                formData
                              });
                            } else {
                              Util.api.post('/api/public/submitTryForFree', {
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                                email: formData.email,
                                organisation: formData.organisation,
                                jobTitle: formData.jobTitle,
                                phoneNumber: formData.phoneNumber
                              });

                              //Analytics
                              Util.analytics.track('TryForFreeSubmitted', {
                                formData
                              });
                            }

                            if (formData.consentToNews) {
                              Util.analytics.track('NewsletterSignUp', {
                                email: formData.email
                              });
                            }

                            //Ads
                            Util.ads.facebook.track(
                              Util.ads.facebook.Event.Contact
                            );
                            Util.ads.google.track(
                              Util.ads.google.ConversionEvent
                                .DemoContactSubmitted
                            );
                            Util.ads.linkedIn.track(
                              Util.ads.linkedIn.ConversionEvent
                                .DemoContactSubmitted
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leda-wrapper">
            <div className="leda-container-md">
              <div className="leda-row">
                <h1 className="bold text-center">
                  Great companies trust Leda.
                </h1>
                <CompanyLogos />
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
