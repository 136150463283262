import React, { Component } from 'react';

import AppLayout from '../AppLayout';

import JourneyBadge from '../../../UI/JourneyBadge/JourneyBadge';

export default class AppJourneyLayout extends Component {
  render() {
    return (
      <AppLayout
        pageIconElement={
          <JourneyBadge size="sm" journey={this.props.journey} />
        }
        pageTitle={this.props.journey.name}
        {...this.props}
      >
        {this.props.children}
      </AppLayout>
    );
  }
}
