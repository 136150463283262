import React, { Component } from 'react';
import validator from 'validator';
import Util from '../../../../../Util';
import withForm from '../../withForm';

import Button from '../../../Button/Button';

class AdminTeamEditorForm extends Component {
  quickJoin = () => {
    const teamId = this.props.teamId;
    Util.api.post(
      '/api/admin/quickJoinOrganisation',
      {
        organisationId: this.props.organisationId,
        teamId
      },
      {
        success: () => {
          //Need to redirect fully so auth happens again
          if (teamId) {
            window.open(Util.route.app.team(teamId));
          } else {
            window.open(Util.route.app.home());
          }
        }
      }
    );
  };

  render() {
    return (
      <form className="admin-form-team-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('name')}
        {this.props.getField('description')}
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
          <Button
            label="Preview Team"
            isSecondary={true}
            icon={Util.icon.view}
            onClick={this.quickJoin}
          />
        </div>
      </form>
    );
  }
}

export default withForm(AdminTeamEditorForm, {
  fields: {
    name: {
      label: 'Team name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a team name';
      }
    },
    description: {
      label: 'Team description',
      type: Util.enum.FieldType.Textarea,
      isOptional: true,
      getError: val => {
        if (!validator.isLength(val, { max: 2000 }))
          return 'Team description too long (max 2,000 characters)';
      }
    }
  }
});
