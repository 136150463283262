import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Util from '../../../../Util';

import 'react-datepicker/dist/react-datepicker.css';

// we ALWAYS want to handle times as 24HR "HH:mm:ss" inside this component
export default class TimeField extends Component {
  constructor(props) {
    super(props);

    let initialValue = this.props.value || this.props.defaultValue;

    this.state = {
      value: this.getParsedValue(initialValue)
    };

    this.getParsedValue = this.getParsedValue.bind(this);
    this.getValueAsDate = this.getValueAsDate.bind(this);
    this.changeTime = this.changeTime.bind(this);
  }
  componentWillReceiveProps(props) {
    if (props.value) {
      this.setState({
        value: this.getParsedValue(props.value)
      });
    }
  }
  getParsedValue(value) {
    //in case a date was passed in
    return typeof value === 'string'
      ? value === 'now'
        ? Util.format.date.inputTime()
        : !value
        ? null //empty string
        : value
      : value
      ? Util.format.date.inputTime(value)
      : null;
  }
  getValueAsDate(value) {
    if (!value) return null;
    if (value === 'now') return new Date();
    return moment(value, 'HH:mm:ss').toDate();
  }
  changeTime(time) {
    let validatedValue = time;

    //turn 09:00 into 09:00:00
    if (time && time.length === 5) {
      validatedValue = time + ':00';
    }

    //Minmax validation
    let valueAsDate = this.getValueAsDate(validatedValue);
    let minAsDate = this.getValueAsDate(this.props.min);
    let maxAsDate = this.getValueAsDate(this.props.max);

    if (minAsDate && valueAsDate && minAsDate > valueAsDate)
      validatedValue = this.getParsedValue(minAsDate);
    if (maxAsDate && valueAsDate && maxAsDate < valueAsDate)
      validatedValue = this.getParsedValue(maxAsDate);

    this.setState({
      value: this.getParsedValue(validatedValue)
    });

    if (this.props.onChange) this.props.onChange(validatedValue);
  }
  render() {
    return Util.userAgent.isDesktop() ? (
      <DatePicker
        selected={this.getValueAsDate(this.state.value)}
        onChange={val => this.changeTime(Util.format.date.inputTime(val))}
        minTime={
          this.getValueAsDate(this.props.min) || Util.moment.startOfDay()
        } //both max and min required if using one
        maxTime={this.getValueAsDate(this.props.max) || Util.moment.endOfDay()}
        showTimeSelect
        showTimeSelectOnly
        dateFormat="h:mm aa"
      />
    ) : (
      <input
        type="time"
        onChange={e => this.changeTime(e.target.value)}
        value={this.state.value || ''}
        min={this.getParsedValue(this.props.min)} //ignored by safari
        max={this.getParsedValue(this.props.max)} //ignored by safari
      />
    );
  }
}
