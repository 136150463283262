import React, { Component } from 'react';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import AdminOrganisationEditorForm from '../../../UI/Forms/Admin/AdminOrganisationEditorForm/AdminOrganisationEditorForm';
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb';
import Button from '../../../UI/Button/Button';
import Grid from '../../../UI/Grid/Grid';
import { Redirect } from 'react-router-dom';
import Util from '../../../../Util';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';

class AdminOrganisationEditorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !!this.props.organisationId,

      organisation: {
        organisationId: null,
        isPrivateUsers: false,
        name: ((props.location || {}).state || {}).newOrgName || ''
      }
    };
  }
  componentDidMount() {
    if (this.props.organisationId) {
      Util.api.post(
        '/api/admin/getOrganisationById',
        {
          organisationId: this.props.organisationId
        },
        {
          success: organisation => {
            this.setState({
              organisation
            });
          },
          complete: () => this.setState({ isLoading: false })
        }
      );
    }
  }

  quickJoin = () => {
    Util.api.post(
      '/api/admin/quickJoinOrganisation',
      {
        organisationId: this.props.organisationId
      },
      {
        success: () => {
          //Need to redirect fully so auth happens again
          window.open(Util.route.app.home());
        }
      }
    );
  };

  createTeamClicked = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Create Team',
      icon: Util.icon.team,
      data: {
        value: '',
        label: 'Team name',
        onSubmit: teamName => {
          Util.api.post(
            '/api/admin/createTeam',
            {
              teamName,
              organisationId: this.props.organisationId
            },
            {
              success: team => {
                this.setState({
                  redirectTo: Util.route.admin.teamEditor(team.teamId)
                });
              }
            }
          );
        }
      }
    });
  };

  rowClicked = (teamId, nodeName) => {
    if (nodeName === 'TD') {
      this.setState({
        redirectTo: Util.route.admin.teamEditor(teamId)
      });
    }
  };

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div className="leda-page-admin-organisation-editor">
        <AdminLayout bottomSpacerHeight={100}>
          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <div>
              <BreadCrumb />
              <h1>Edit Organisation</h1>

              <AdminOrganisationEditorForm
                button={true}
                quickJoin={this.quickJoin}
                formData={this.state.organisation}
                organisationId={this.state.organisation.organisationId}
                onSubmit={(form, formData) => {
                  form.setLoading(true);

                  Util.api.post(
                    '/api/admin/saveOrganisation',
                    {
                      organisationId: this.state.organisation.organisationId,
                      ...formData
                    },
                    {
                      success: () => {
                        form.setLoading(false);
                      }
                    }
                  );
                }}
              />
            </div>
          )}
        </AdminLayout>

        <div className="leda-layout-admin bg-grey">
          <div className="leda-container-lg">
            <h1 className="free-heading">Team in this org</h1>
            <div className="leda-row">
              <Button
                className="quick-join"
                size="md"
                label="Create New Team"
                onClick={this.createTeamClicked}
              />
              <Grid
                data={this.state.organisation.teams}
                rowClicked={({ teamId }, nodeName) =>
                  this.rowClicked(teamId, nodeName)
                }
                className="bg-white"
                columns={[
                  {
                    name: 'Team Id',
                    key: 'teamId'
                  },
                  {
                    name: 'Created At',
                    key: 'createdAt',
                    render: val => Util.format.date.shortDateTime(val)
                  },
                  {
                    name: 'Name',
                    key: 'name',
                    width: '50%'
                  },
                  {
                    name: 'Team Members',
                    key: 'teamUsers',
                    render: (val, record) => {
                      return record.teamUsers.length;
                    }
                  },
                  {
                    key: 'teamId',
                    isSortDisabled: true,
                    render: teamId => {
                      return (
                        <div className="button-container">
                          <Button
                            title="View team members"
                            size="sm"
                            isIcon={true}
                            icon={Util.icon.view}
                            onClick={() =>
                              this.quickJoin(this.state.organisation, teamId)
                            }
                          />
                          <Button
                            title="Edit team"
                            isIcon={true}
                            to={Util.route.admin.teamEditor(teamId)}
                            size="sm"
                            icon={Util.icon.edit}
                          />
                        </div>
                      );
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
        <div style={{ height: '200px' }}></div>
      </div>
    );
  }
}
export default connect(null, { openModalAction })(AdminOrganisationEditorPage);
