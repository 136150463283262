import React, { Component } from 'react';

import JourneyBadge from '../JourneyBadge/JourneyBadge';
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar/ProgressBar';
import Util from '../../../Util';
import { connect } from 'react-redux';

class TeamJourneysPanel extends Component {
  render() {
    let now = new Date();

    let teamJourneys = this.props.team.teamJourneys.sort((tj1, tj2) =>
      Util.sort.by('scheduledAt', tj1, tj2, Util.sort.asDate)
    );

    let incompleteTeamJourneys = teamJourneys.filter(teamJourney => {
      let journey = Util.context.referenceData.getJourneyById(
        teamJourney.journeyId
      );

      if (journey) {
        let userJourney = this.props.userJourneys.find(
          userJourney => userJourney.journeyId === journey.journeyId
        );

        let teamJourneyInFuture =
          teamJourney.scheduledAt && new Date(teamJourney.scheduledAt) > now;

        return (
          !journey.isLocked &&
          (!teamJourney.scheduledAt || !teamJourneyInFuture) &&
          (!userJourney || !userJourney.completedAt)
        );
      }
      return null;
    });

    let nextScheduledTeamJourney = Util.array.none(incompleteTeamJourneys)
      ? teamJourneys.find(
          teamJourney =>
            teamJourney.scheduledAt && new Date(teamJourney.scheduledAt) > now
        )
      : null;
    let nextScheduledJourney = nextScheduledTeamJourney
      ? Util.context.referenceData.getJourneyById(
          nextScheduledTeamJourney.journeyId
        )
      : null;

    return (
      <div className="team-journeys-panel">
        {Util.array.any(incompleteTeamJourneys) ? (
          incompleteTeamJourneys.map(teamJourney => {
            let journey = Util.context.referenceData.getJourneyById(
              teamJourney.journeyId
            );
            let userJourney = this.props.userJourneys.find(
              userJourney => userJourney.journeyId === journey.journeyId
            );

            if (
              journey.isLocked ||
              (userJourney && userJourney.completedAt) ||
              journey.journeyId === 1000
            )
              return null;

            return (
              <Link
                key={teamJourney.teamJourneyId}
                className="team-journey-item"
                to={Util.route.app.journey(journey.urlId)}
              >
                <JourneyBadge size="sm" journey={journey} />
                <div className="journey-info">
                  <h4 className="bold">{journey.name}</h4>
                  {userJourney ? (
                    <ProgressBar
                      isPercentVisible={true}
                      numerator={
                        userJourney.steps.filter(
                          step =>
                            Util.context.referenceData.getJourneyStepStatus(
                              step,
                              userJourney,
                              this.props.myActivities,
                              this.props.userProgressActivity
                            ) === 'completed'
                        ).length
                      }
                      denominator={userJourney.steps.length}
                    />
                  ) : (
                    <ProgressBar
                      isPercentVisible={true}
                      numerator={0}
                      denominator={1}
                    />
                  )}
                </div>
              </Link>
            );
          })
        ) : (
          <div className="empty-text">
            <p>This team has no Journeys for you to complete right now.</p>
            {nextScheduledJourney ? (
              <p>
                <br />
                Your next Journey,{' '}
                <Link to={Util.route.app.teamJourneys(this.props.team.teamId)}>
                  {nextScheduledJourney.name}
                </Link>
                , will commence on{' '}
                <Link to={Util.route.app.teamJourneys(this.props.team.teamId)}>
                  {Util.format.date.weekdayMonthDayYear(
                    nextScheduledJourney.scheduledAt
                  )}
                </Link>
                .
              </p>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys,
  myActivities: state.activityState.myActivities,
  userProgressActivity: state.userState.userProgress.activity
});

export default connect(mapStateToProps, {})(TeamJourneysPanel);
