import React, { Component } from 'react';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import AdminOrganisationEditorForm from '../../../UI/Forms/Admin/AdminOrganisationEditorForm/AdminOrganisationEditorForm';
import { Redirect } from 'react-router-dom';
import Util from '../../../../Util';

export default class AdminOrganisationCreatorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: null,
      organisation: {
        name: props.location.state.newOrgName || ''
      }
    };
  }
  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div className="leda-page-admin-organisation-editor">
        <AdminLayout pageTitle="Create Organisation" overflowVisible={true}>
          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <AdminOrganisationEditorForm
              formData={this.state.organisation}
              onSubmit={(form, formData) => {
                form.setLoading(true);

                Util.api.post(
                  '/api/admin/saveOrganisation',
                  {
                    ...formData
                  },
                  {
                    success: res => {
                      this.setState({
                        redirectTo: Util.route.admin.organisationEditor(
                          res.organisationId
                        )
                      });
                    }
                  }
                );
              }}
            />
          )}
        </AdminLayout>
      </div>
    );
  }
}
