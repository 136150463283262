import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Util from '../../../../Util';
//Layout
import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb';
import AdminTeamEditorForm from '../../../UI/Forms/Admin/AdminTeamEditorForm/AdminTeamEditorForm';
import AdminTeamEditorInsights from '../AdminTeamEditorInsights/AdminTeamEditorInsights';
import AdminTeamEditorMembers from '../AdminTeamEditorMembers/AdminTeamEditorMembers';
import AdminTeamEditorSettings from '../AdminTeamEditorSettings/AdminTeamEditorSettings';

export default class AdminTeamEditorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      team: null,
      selectedTab: this.getInitialTab()
    };
  }
  componentDidMount() {
    this.fetchData(this.props.teamId);
  }

  getInitialTab = () => {
    const currentUrl = window.location.href.split('/');
    if (currentUrl.includes('settings')) {
      return 'settings';
    } else if (currentUrl.includes('stats')) {
      return 'stats';
    } else {
      return 'members';
    }
  };
  fetchData = () => {
    Util.api.post(
      '/api/admin/getTeamById',
      {
        teamId: this.props.teamId,
        includeUsers: true
      },
      {
        success: team => {
          team.teamJourneys = team.teamJourneys.map(teamJourney => {
            teamJourney.scheduledAt = new Date(
              teamJourney.scheduledAt
            ).toISOString();

            teamJourney.unlockedAt = new Date(
              teamJourney.unlockedAt
            ).toISOString();

            return teamJourney;
          });

          this.setState({
            team: team, //don't remove this, otherwise the value are not correct (but I have no explanation why it works)
            isLoading: false
          });
        }
      }
    );
  };

  render() {
    if (this.state.isLoading) return <div className="loader"></div>;
    const teamId = this.state.team.teamId;
    return (
      <div className="leda-page-app-team-settings">
        <BreadCrumb />
        <AdminLayout
          pageTitle={`Team Settings (${this.state.team.organisationName})`}
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <AdminTeamEditorForm
                key={JSON.stringify(this.state.team)}
                formData={{ ...this.state.team }}
                organisationId={this.state.team.organisation.organisationId}
                teamId={this.state.team.teamId}
                onSubmit={(form, formData) => {
                  form.setLoading(true);

                  Util.id.tempId.clearAllTempIds(formData);

                  Util.api.post(
                    '/api/admin/updateTeam',
                    { team: formData },
                    {
                      success: team => {
                        alert('Team saved.');
                        form.setFormData(team);
                        this.setState({
                          team: team
                        });
                      },
                      failure: errorMsg => form.setOverallError(errorMsg),
                      complete: () => form.setLoading(false)
                    }
                  );

                  Util.analytics.track('SavedTeam', { ...formData });
                }}
              />
            </div>
          </div>
        </AdminLayout>
        <AdminLayout>
          <div style={{ minHeight: '500px', paddingTop: '75px' }}>
            <div className="select-panel-container">
              <div
                className={
                  'select-panel ' +
                  (this.state.selectedTab === 'members' ? 'selected' : '')
                }
              >
                <Link
                  className="select-title"
                  to={`/admin/team-editor/${teamId}`}
                  onClick={() => this.setState({ selectedTab: 'members' })}
                >
                  MEMBERS
                </Link>
              </div>
              <div
                className={
                  'select-panel ' +
                  (this.state.selectedTab === 'settings' ? 'selected' : '')
                }
              >
                <Link
                  className="select-title"
                  to={`/admin/team-editor/${teamId}/settings`}
                  onClick={() => this.setState({ selectedTab: 'settings' })}
                >
                  SETTINGS
                </Link>
              </div>
              <div
                className={
                  'select-panel ' +
                  (this.state.selectedTab === 'stats' ? 'selected' : '')
                }
              >
                <Link
                  className="select-title"
                  to={`/admin/team-editor/${teamId}/stats`}
                  onClick={() => this.setState({ selectedTab: 'stats' })}
                >
                  STATS
                </Link>
              </div>
            </div>

            <div>
              <Switch>
                <Route
                  exact
                  path={`/admin/team-editor/${teamId}`}
                  render={() => (
                    <AdminTeamEditorMembers
                      team={{ ...this.state.team }}
                      organisation={this.state.team.organisation}
                    />
                  )}
                />
                <Route
                  path={`/admin/team-editor/${teamId}/settings`}
                  render={() => (
                    <AdminTeamEditorSettings
                      team={{ ...this.state.team }}
                      changeTeamDescription={description => {
                        const team = { ...this.state.team };
                        team.description = description;
                        this.setState({ team });
                      }}
                    />
                  )}
                />
                <Route
                  path={`/admin/team-editor/${teamId}/stats`}
                  render={() => (
                    <AdminTeamEditorInsights teamId={this.state.team.teamId} />
                  )}
                />
              </Switch>
            </div>
          </div>
        </AdminLayout>
      </div>
    );
  }
}
