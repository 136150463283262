import React, { Component } from 'react';

// Components
import ActivityCardsView from '../CardsView/ActivityCardsView/ActivityCardsView';

//data source: this.props.activities
export default class RelatedActivityStrip extends Component {
  render() {
    return (
      <div className="related-activity-strip">
        <div className="related-activity-title">
          <h2>Related Activities</h2>
          <h4 className="related-activity-count">
            {this.props.activities.length}
          </h4>
        </div>
        <ActivityCardsView activities={this.props.activities} />
      </div>
    );
  }
}
