import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppLayout from '../../../../Layouts/App/AppLayout';
import ProfilePhoto from '../../../../UI/ProfilePhoto/ProfilePhoto';
import Button from '../../../../UI/Button/Button';
import JourneyRibbon from '../../../../UI/JourneyRibbon/JourneyRibbon';

//this.props.userId or this.props.user
export default class AppProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      isLoading: !this.props.user
    };
  }
  componentDidMount() {
    if (this.props.userId) {
      Util.api.post(
        '/api/organisation/getUserProfileDataById',
        {
          userId: this.props.userId
        },
        {
          success: profileData => {
            this.setState({
              user: profileData.user,
              completedJourneyIds: profileData.completedJourneyIds
            });
          },
          complete: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      );
    }
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (!this.state.user) {
      content = <div className="empty-text">User not found.</div>;
    } else {
      content = (
        <div className="profile-panel">
          <ProfilePhoto size="lg" user={this.state.user} />
          <div className="profile-details">
            <div>
              <h2>{this.state.user.fullName}</h2>
              <p>
                {this.state.user.jobTitle}{' '}
                {this.state.user.isLedaAdmin ? (
                  <span>
                    at <span className="blue bold">Leda</span>
                  </span>
                ) : null}
              </p>
            </div>
            {this.state.user.bio ? (
              <div className="bio">{this.state.user.bio}</div>
            ) : null}
            <p>
              <a href={`mailto:${this.state.user.email}`} className="bold">
                {this.state.user.email}
              </a>
            </p>
            {this.state.user.phone ? <p>{this.state.user.phone}</p> : null}
            {Util.array.any(this.state.completedJourneyIds) ? (
              <div className="badges">
                <h3>Journey Badges</h3>
                <div className="badge-container">
                  {this.state.completedJourneyIds.map(completedJourneyId => (
                    <JourneyRibbon
                      key={completedJourneyId}
                      journeyId={completedJourneyId}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          {Util.context.user.getCurrentUserId() === this.state.user.userId ? (
            <Button
              to={Util.route.app.settingsProfile()}
              size="sm"
              isSecondary={true}
              icon={Util.icon.edit}
            />
          ) : null}
        </div>
      );
    }

    return (
      <div className="leda-page-app-profile">
        <AppLayout
          pageTitle="User Profile"
          pageIcon={Util.icon.user}
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">{content}</div>
          </div>
        </AppLayout>
      </div>
    );
  }
}
