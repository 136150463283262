import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import Util from '../../../Util';

import LedaLogo from '../LedaLogo/LedaLogo';
import Truncate from '../Truncate/Truncate';

export default class AppNavSidebar extends Component {
  constructor(props) {
    super(props);
  }
  onItemClick = item => {
    let isActive = Util.route.getCurrent().startsWith(item.to);
    if (
      !Util.css.breakpoint.oversm() &&
      (Util.array.none(item.items) || isActive)
    )
      this.props.toggleNav();

    Util.analytics.track('AppNavSidebarClicked', {
      linkName: item.label
    });
  };
  render() {
    let activitiesItem = {
      label: 'Activities',
      icon: Util.icon.activity,
      to: Util.route.app.myActivities(),
      items: [
        {
          label: 'Completed Activities',
          to: Util.route.app.completedActivities(),
          icon: Util.icon.tick
        }
      ]
    };

    let feedbackItem = {
      label: 'Feedback',
      icon: Util.icon.feedback,
      to: Util.route.app.feedback(),
      isDisabled: !Util.context.feature.getIsFeedbackEnabled(),
      isHidden: !Util.context.feature.getIsFeedbackVisible(),
      items: [
        {
          label: 'Feedback',
          to: Util.route.app.feedback(),
          isRoot: true
        }
      ]
    };

    let exploreItem = {
      label: 'Explore',
      icon: Util.icon.explore,
      to: Util.route.app.explore(),
      items: [
        {
          label: 'Skills',
          to: Util.route.app.skills(),
          icon: Util.icon.skill
        },
        {
          label: 'Stories',
          to: Util.route.app.stories(),
          icon: Util.icon.story
        },
        {
          label: 'Games',
          to: Util.route.app.games(),
          icon: Util.icon.game
        },
        {
          label: 'Assessments',
          to: Util.route.app.assessments(),
          icon: Util.icon.assessment
        }
      ]
    };

    //Customise the sidebar based off the type of user
    let sidebarItems = [];

    if (!Util.context.user.getOrganisationId()) {
      sidebarItems = [exploreItem, activitiesItem];
    } else {
      let teamsItem = {
        label: Util.context.user.getOrganisationName(),
        icon: Util.icon.home,
        to: Util.route.app.teams(),
        items: Util.context.user
          .getTeams()
          .filter(({ isDisabled }) => !isDisabled)
          .map(team => {
            let subItems = [];

            if (Util.array.any(team.teamJourneys)) {
              subItems = [
                ...subItems,
                {
                  label: 'Journeys',
                  to: Util.route.app.teamJourneys(team.teamId),
                  icon: Util.icon.journey
                }
              ];
            }

            subItems = [
              ...subItems,
              {
                label: 'Members',
                to: Util.route.app.teamMembers(team.teamId),
                icon: Util.icon.users
              }
            ];

            if (Util.context.user.getIsOrganisationAdmin()) {
              subItems = [
                ...subItems,
                {
                  label: 'Insights',
                  to: Util.route.app.teamInsights(team.teamId),
                  icon: Util.icon.graph
                }
              ];
            }

            if (Util.context.user.canAdminTeam(team.teamId)) {
              subItems = [
                ...subItems,
                {
                  label: 'Posts',
                  to: Util.route.app.teamPosts(team.teamId),
                  icon: Util.icon.post
                }
              ];
            }

            return {
              label: team.name,
              to: Util.route.app.team(team.teamId),
              icon: Util.icon.team,
              items: subItems
            };
          })
      };
      sidebarItems = [teamsItem, activitiesItem, feedbackItem, exploreItem];
    }

    let nestingCount = 0;
    let indentIncrement = 10;

    let getItemElement = (item, idx) => {
      nestingCount++;

      let isActive = Util.route.getCurrent().startsWith(item.to);
      let element = (
        <div key={idx}>
          <Link
            to={item.to}
            className={`nav-button ${isActive ? 'active' : ''} ${
              item.isDisabled ? 'disabled' : ''
            }`}
            onClick={() => this.onItemClick(item)}
          >
            <ReactSVG
              className="nav-button-icon"
              style={
                nestingCount > 2
                  ? { marginLeft: (nestingCount - 2) * indentIncrement + 'px' }
                  : {}
              }
              src={item.icon}
            />
            <Truncate
              component="p"
              className="nav-button-label"
              maxLine={2}
              text={item.label}
            />
            {/* {Util.array.any(item.items)
						? <ReactSVG className={`arrow-icon ${isActive ? 'open' : ''}`} src={Util.icon.arrowHead.up} />
						: null
					} */}
          </Link>
          {isActive && item.items ? (
            <div className="nav-button-children">
              {item.items.map(getItemElement)}
            </div>
          ) : null}
        </div>
      );

      nestingCount--;
      return element;
    };

    return (
      <Div100vh className="app-nav-sidebar">
        <div className="nav-button nav-title">
          <LedaLogo to={Util.route.app.home()} />
          <div className="flex-spacer"></div>
          <button
            className="toggle-icon link-button"
            onClick={this.props.toggleNav}
          >
            <ReactSVG
              className="nav-button-icon"
              src={Util.icon.arrowHead.left}
            />
            <ReactSVG className="nav-button-icon" src={Util.icon.menu} />
          </button>
        </div>
        {sidebarItems.filter(btn => !btn.isHidden).map(getItemElement)}
      </Div100vh>
    );
  }
}
