import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

// Images
import img_leda_logo from '../../../img/leda_logo.svg';

export default class LedaLogo extends Component {
  render() {
    let logoCls = `leda-logo ${Util.context.env.getIsDev() ? 'dev-mode' : ''} ${
      Util.context.env.getIsStag() ? 'stag-mode' : ''
    }`;

    return (
      <div className={logoCls}>
        <Link
          className="header-center"
          onClick={() => Util.analytics.track('LedaLogoClicked')}
          to={this.props.to || Util.route.site.home()}
        >
          <ReactSVG className="logo-icon" src={img_leda_logo} />
          <span className="env-context">
            {Util.context.env.getIsDev() ? 'DEV' : ''}
            {Util.context.env.getIsStag() ? 'STAG' : ''}
          </span>
        </Link>
      </div>
    );
  }
}
