import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import { connect } from 'react-redux';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import Button from '../../../UI/Button/Button';
import Grid from '../../../UI/Grid/Grid';

class AdminTeamPresetsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presets: [],
      redirectTo: null
    };
  }
  componentDidMount() {
    Util.api.post(
      '/api/admin/getAllPresets',
      {},
      {
        success: presets => {
          this.setState({ presets });
        }
      }
    );
  }

  dupplicatePreset = presetId => {
    const newPreset = this.state.presets[presetId];
    Util.api.post(
      '/api/admin/createPreset',
      {
        name: newPreset.name,
        description: newPreset.description
      },
      {
        success: preset => {
          this.setState({
            presets: Object.assign(this.state.presets, {
              [preset.presetId]: preset
            })
          });
        }
      }
    );
  };

  createPreset = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Create Presets',
      data: {
        value: '',
        label: 'Presets Name',
        onSubmit: value => {
          Util.api.post(
            '/api/admin/createPreset',
            {
              name: value
            },
            {
              success: preset => {
                this.setState({
                  presets: Object.assign(this.state.presets, {
                    [preset.presetId]: preset
                  }),
                  redirectTo: Util.route.admin.teamPresetEditor(preset.presetId)
                });
              }
            }
          );
        }
      }
    });
  };

  rowClicked = (presetId, nodeName) => {
    if (nodeName === 'TD') {
      this.setState({
        redirectTo: Util.route.admin.teamPresetEditor(presetId)
      });
    }
  };

  render() {
    const redirectTo = this.state.redirectTo;

    if (redirectTo) {
      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: {
              presetId: this.state.presets[redirectTo.match(/\d+/)[0]].presetId
            }
          }}
        />
      );
    }
    return (
      <div className="leda-page-admin-team-presets">
        <AdminLayout pageTitle="Team Presets">
          <Button label="Create new" size="md" onClick={this.createPreset} />
          <Grid
            data={Object.values(this.state.presets)}
            rowClicked={({ presetId }, nodeName) =>
              this.rowClicked(presetId, nodeName)
            }
            columns={[
              {
                name: 'Team preset name',
                key: 'name'
              },
              {
                key: 'presetId',
                isSortDisabled: true,
                render: presetId => {
                  return (
                    <div className="button-container">
                      <Button
                        title="Dupplicate Preset"
                        size="sm"
                        isIcon={true}
                        icon={Util.icon.copy}
                        onClick={() => this.dupplicatePreset(presetId)}
                      />
                      <Button
                        title="Edit Preset"
                        isIcon={true}
                        onClick={() =>
                          this.setState({
                            redirectTo: Util.route.admin.teamPresetEditor(
                              presetId
                            )
                          })
                        }
                        size="sm"
                        icon={Util.icon.edit}
                      />
                    </div>
                  );
                }
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(AdminTeamPresetsPage);
