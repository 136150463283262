import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import Util from '../../../../../Util';

class StoryChapterSelectPanel extends Component {
  render() {
    let isCompleted = !!this.props.userProgressVideo[
      this.props.chapter.videoId
    ];

    return (
      <div
        className={`chapter-select-panel ${isCompleted ? 'completed' : ''} ${
          this.props.isSelected ? 'selected' : ''
        }`}
        onClick={this.props.onClick || null}
      >
        <p className="chapter-select-title-xs">{this.props.chapter.ordinal}</p>
        <p className="chapter-select-title-sm">
          Chapter {this.props.chapter.ordinal}
        </p>
        <p className="chapter-select-title-md">
          Chapter {this.props.chapter.ordinal}
        </p>
        {isCompleted ? (
          <ReactSVG className="icon-tick" src={Util.icon.tick} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(StoryChapterSelectPanel);
