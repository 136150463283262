import {
  MOD_MODAL_OPEN,
  MOD_MODAL_CLOSE,
  MOD_MODAL_CLOSE_ALL
} from '../actions/types';

import Util from '../Util';

export const openModalAction = modalConfig => dispatch => {
  let modalId = Util.id.tempId.getTempId();

  // Apply default modal properties, then overwrite the applicable ones with the passed in config
  let defaultConfig = {
    id: modalId,
    type: Util.enum.ModalType.Alert, // Determines the JSX that will be used to render the modal
    showCloseButton: true,

    //events
    onClose: null,

    // Modal data for rendering and behaviour, holds all the data that is specific to the type of modal
    // For example, a form modal might have a data record, and an 'onSubmit' function in data
    data: {}
  };

  let config = { ...defaultConfig, ...modalConfig };

  dispatch({
    type: MOD_MODAL_OPEN,
    config
  });

  Util.analytics.track('ModalOpened', {
    modalId,
    type: Util.enum.toString(Util.enum.ModalType, config.type)
  });

  return modalId;
};

export const closeModalAction = modalId => dispatch => {
  dispatch({
    type: MOD_MODAL_CLOSE,
    modalId
  });

  Util.analytics.track('ModalClosed', {
    modalId
  });
};

export const closeAllModalsAction = () => dispatch => {
  dispatch({
    type: MOD_MODAL_CLOSE_ALL
  });
};
