import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authenticateAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

import AuthLayout from '../../../Layouts/Auth/AuthLayout';

// Components
import ResetPasswordForm from '../../../UI/Forms/ResetPasswordForm/ResetPasswordForm';

class AuthPasswordResetPage extends Component {
  render() {
    return (
      <div className="leda-page-auth-password-reset">
        <AuthLayout>
          <h1>Change password</h1>
          <p>Please enter a new password.</p>
          <ResetPasswordForm
            isAccountSetup={false}
            onSubmit={(form, formData) => {
              form.setLoading(true);

              Util.api.post(
                '/api/auth/resetPassword',
                {
                  password: formData.password,
                  token: this.props.token
                },
                {
                  success: result => {
                    if (result.success) {
                      Util.analytics.track('PasswordReset', {
                        isAccountSetup: false
                      });
                      this.props.authenticateAction(result.token);
                    }
                  },
                  failure: errorMsg => {
                    form.setOverallError(errorMsg);
                    form.setLoading(false);
                  }
                }
              );
            }}
          />
        </AuthLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { authenticateAction }
)(AuthPasswordResetPage);
