import React, { Component } from 'react';
import Slider from 'react-slick';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

// Slick CSS import
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

function CarouselArrow(props) {
  const { className, style, onClick, path } = props;
  return (
    <ReactSVG
      className={`${className} arrow-icon`}
      src={path}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

export default class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBreakpoint: null
    };

    this.sliderRef = React.createRef();

    this.onReInit = this.onReInit.bind(this);
  }
  getSlider() {
    //Used to access the slider outside the component
    return this.sliderRef.current;
  }
  onReInit(initialSlide) {
    let slider = this.sliderRef.current;

    if (slider.state.breakpoint !== this.state.currentBreakpoint) {
      slider.slickGoTo(initialSlide);

      this.setState({
        currentBreakpoint: slider.state.breakpoint
      });
    }
  }
  render() {
    // https://react-slick.neostack.com/docs/api/
    let defaultSettings = {
      initialSlide: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      dots: false,
      infinite: false,
      responsive: [],
      speed: 300,
      arrows: true,
      nextArrow: <CarouselArrow src={Util.icon.arrowHead.right} />,
      prevArrow: <CarouselArrow src={Util.icon.arrowHead.left} />
    };

    let settings = { ...defaultSettings, ...this.props.settings };

    let cleanSettings = settings => {
      //Bug: add a reinit event that will push the initial slide into view when initialised (eg. at responsive breakpoints)
      settings.onReInit = () => this.onReInit(settings.initialSlide);

      //Bug: infinite sliders with less children than the slidestoshow property will break
      if (this.props.children.length < settings.slidesToShow)
        settings.infinite = false;
    };

    //For each responsive setting
    cleanSettings(settings);
    settings.responsive.forEach(resp => cleanSettings(resp.settings));

    return (
      <Slider
        className={this.props.className || ''}
        ref={this.sliderRef}
        {...settings}
      >
        {this.props.children}
      </Slider>
    );
  }
}
