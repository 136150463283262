import React, { Component } from 'react';
import { connect } from 'react-redux';
import { completedAssessmentAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

// Components
import ProgressBar from '../../ProgressBar/ProgressBar';
import SupplementaryPanel from '../../SupplementaryPanel/SupplementaryPanel';
import ActivityCardsView from '../../CardsView/ActivityCardsView/ActivityCardsView';
import Button from '../../Button/Button';

class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assessment: this.props.assessment,

      // Active state (what is happening in the survey right now)
      activeQuestion: null,
      activeResults: null,

      // Tracked state (monitors progress through the game)
      questionHistory: {
        //eg. 22: 8 (question 22 was answered with option 8)
      },
      skillPoints: {
        //eg. 45: 2 (skill ID 45 with 2 points)
      }
    };

    this.restart = this.restart.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.previousQuestion = this.previousQuestion.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
  }
  restart() {
    // Reset tracking state
    this.setState({
      skillPoints: {},
      questionHistory: {},
      activeQuestion: null,
      activeResults: null
    });
  }
  answerQuestion(option) {
    // If this option has associated optionSkills to assign points for
    if (Util.array.any(option.optionSkills)) {
      // Copy the existing skillPoints object
      let skillPointsCopy = { ...this.state.skillPoints };

      option.optionSkills.forEach(optionSkill => {
        skillPointsCopy[optionSkill.skillId]
          ? (skillPointsCopy[optionSkill.skillId] += optionSkill.value)
          : (skillPointsCopy[optionSkill.skillId] = optionSkill.value);
      });

      this.setState({
        skillPoints: skillPointsCopy
      });
    }

    // Set the questionHistory answer for this (questionId: optionId)
    let questionHistoryCopy = { ...this.state.questionHistory };
    questionHistoryCopy[this.state.activeQuestion.questionId] = option.optionId;

    this.setState({
      questionHistory: questionHistoryCopy
    });

    this.trackSurveyEvent('SurveyAnswer', {
      questionId: this.state.activeQuestion.questionId,
      questionText: this.state.activeQuestion.htmlContent,
      optionId: option.optionId,
      optionText: option.htmlContent
    });

    this.nextQuestion();
  }
  previousQuestion() {
    let activeQuestionIdx = this.state.assessment.questions.indexOf(
      this.state.activeQuestion
    );
    let prevQuestion = this.state.assessment.questions[activeQuestionIdx - 1];

    if (prevQuestion) {
      this.changeQuestion(prevQuestion);
    }
  }
  nextQuestion() {
    let activeQuestionIdx = this.state.assessment.questions.indexOf(
      this.state.activeQuestion
    );
    let nextQuestion = this.state.assessment.questions[activeQuestionIdx + 1];

    if (nextQuestion) {
      this.changeQuestion(nextQuestion);
    } else {
      // We just passed the last question. End the survey.
      this.trackSurveyEvent('SurveyComplete', {
        skillPoints: Object.keys(this.state.skillPoints).map(key => {
          let skillId = parseInt(key, 10);
          let relatedSkill = Util.array.firstMatch(
            this.state.assessment.relatedSkills,
            skillId,
            'skillId'
          );

          return {
            skillId: skillId,
            skillName: relatedSkill.name,
            skillMaxPoints: relatedSkill.maxPoints,
            points: this.state.skillPoints[skillId]
          };
        })
      });

      this.props.completedAssessmentAction(this.state.assessment.assessmentId);

      this.setState({
        activeResults: this.getResults(),
        activeQuestion: null
      });
    }
  }
  changeQuestion(question) {
    this.setState({
      activeQuestion: question
    });
  }
  getResults() {
    let results = {
      activities: [],
      feedbacks: []
    };

    this.state.assessment.relatedSkills.forEach(relatedSkill => {
      let resultForSkill = this.state.skillPoints[relatedSkill.skillId] || 0;

      //1. Feedback results
      // Find all matching feedbacks for this result
      let matchedFeedbacks = this.state.assessment.surveyFeedbacks.filter(
        feedback => {
          if (feedback.skillId !== relatedSkill.skillId) return false;
          return Util.operator.evaluate(
            feedback.operator,
            resultForSkill,
            feedback.value
          );
        }
      );

      results.feedbacks = results.feedbacks.concat(
        Util.operator
          .filterToMaxAndMins(matchedFeedbacks)
          .map(fb => fb.htmlContent)
      );

      //2. Activities
      // Find all matching activities for this result
      let matchedActivities = this.state.assessment.surveyActivities.filter(
        activity => {
          if (activity.skillId !== relatedSkill.skillId) return false;
          return Util.operator.evaluate(
            activity.operator,
            resultForSkill,
            activity.value
          );
        }
      );

      results.activities = results.activities.concat(
        Util.operator
          .filterToMaxAndMins(matchedActivities)
          .map(act => act.activity)
      );
    });

    return results;
  }
  trackSurveyEvent(event, extraProps) {
    extraProps = extraProps || {};

    let allProps = {
      ...extraProps,
      surveyId: this.state.assessment.surveyId,
      surveyUrlId: this.state.assessment.urlId,
      surveyName: this.state.assessment.name
    };

    Util.analytics.track(event, allProps);
  }
  render() {
    let surveyContainerContent = null;

    if (this.state.activeResults) {
      // Results Screen
      surveyContainerContent = (
        <div className="survey-results">
          <div className="result-details">
            <div className="result-title">
              <h1 className="gilroy">Results</h1>
            </div>
            <div className="result-info">
              <div className="result-feedbacks">
                {this.state.activeResults.feedbacks.map((feedback, idx) => (
                  <div
                    key={idx}
                    className="feedback-item"
                    dangerouslySetInnerHTML={{ __html: feedback }}
                  ></div>
                ))}
                {Util.array.any(this.state.activeResults.activities) ? (
                  <SupplementaryPanel
                    title="Recommended Activities"
                    subtitle={'Based on your results'}
                    icon={Util.icon.activity}
                  >
                    <ActivityCardsView
                      isNeverLargeMode={true}
                      activities={this.state.activeResults.activities}
                    />
                  </SupplementaryPanel>
                ) : null}
              </div>
            </div>
          </div>
          <Button label="Restart survey" onClick={this.restart} />
        </div>
      );
    } else if (!this.state.activeQuestion) {
      // Start Screen
      surveyContainerContent = (
        <div className="survey-start">
          <h1 className="gilroy">{this.state.assessment.name}</h1>
          <section
            className="gilroy"
            dangerouslySetInnerHTML={{
              __html: this.state.assessment.instruction
            }}
          ></section>
          <section className="gilroy">{this.state.assessment.overview}</section>
          <Button
            label="Start survey"
            onClick={() =>
              this.changeQuestion(
                Util.array.first(this.state.assessment.questions)
              )
            }
            size="lg"
          />
        </div>
      );
    } else {
      // Actual survey stuff
      surveyContainerContent = (
        <div className="survey-scene">
          <h1 className="counter gilroy">
            {this.state.assessment.questions.indexOf(
              this.state.activeQuestion
            ) +
              1 +
              '/' +
              this.state.assessment.questions.length}
          </h1>
          <p
            className="question gilroy"
            dangerouslySetInnerHTML={{
              __html: this.state.activeQuestion.htmlContent
            }}
          />
          <div className="options">
            {this.state.activeQuestion.options.map(option => {
              return (
                <div
                  key={option.optionId}
                  className={`option ${
                    this.state.questionHistory[
                      this.state.activeQuestion.questionId
                    ] === option.optionId
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => {
                    this.answerQuestion(option);
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: option.htmlContent }}
                  ></div>
                </div>
              );
            })}
          </div>
          <div className="navigation">
            {this.state.assessment.questions.indexOf(
              this.state.activeQuestion
            ) < 1 ? null : (
              <Button
                label="Back"
                isSubmit={true}
                onClick={this.previousQuestion}
              />
            )}
            <div className="flex-spacer"></div>
            {!this.state.questionHistory[
              this.state.activeQuestion.questionId
            ] ? null : (
              <Button
                label="Next"
                isSubmit={true}
                onClick={this.nextQuestion}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="survey">
        <div className="survey-header">
          {this.state.assessment ? this.state.assessment.name : 'Loading...'}
        </div>
        <div className="survey-container">{surveyContainerContent}</div>
        <div className="survey-footer">
          {!this.state.assessment ? null : (
            <ProgressBar
              numerator={Object.keys(this.state.questionHistory).length}
              denominator={this.state.assessment.questions.length}
            />
          )}
          <div
            className="survey-citation"
            dangerouslySetInnerHTML={{
              __html: this.state.assessment
                ? this.state.assessment.citation
                : null
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { completedAssessmentAction }
)(Survey);
