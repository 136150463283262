import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../../Util';

import SiteNewsletterStrip from '../../../UI/SiteNewsletterStrip/SiteNewsletterStrip';

export default class SiteArticlesLayout extends Component {
  render() {
    let tagLinks = [
      {
        name: 'Leadership skills',
        urlId: 'leadership-skills'
      },
      {
        name: 'Emotional Intelligence',
        urlId: 'emotional-intelligence'
      },
      {
        name: 'Teams and Culture',
        urlId: 'teams-and-culture'
      },
      {
        name: 'Business',
        urlId: 'business'
      },
      {
        name: 'Training',
        urlId: 'training'
      }
    ];

    return (
      <div className="leda-layout-site-articles">
        {this.props.pageTitle ? (
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="page-title">
                <h2 className="gilroy">{this.props.pageTitle}</h2>
                <div className="tag-link-nav">
                  {tagLinks.map((tagLink, idx) => (
                    <Link
                      key={idx}
                      to={Util.route.site.articlesTag(tagLink.urlId)}
                    >
                      <h3
                        className={
                          Util.route.isCurrently(
                            Util.route.site.articlesTag(tagLink.urlId)
                          )
                            ? 'blue'
                            : ''
                        }
                      >
                        {tagLink.name}
                      </h3>
                    </Link>
                  ))}
                  <Link to={Util.route.site.articles()}>
                    <h3
                      className={
                        Util.route.isCurrently(Util.route.site.articles())
                          ? 'blue'
                          : ''
                      }
                    >
                      Others
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`page-container ${
            this.props.wrapperCls ? `leda-wrapper ${this.props.wrapperCls}` : ''
          }`}
        >
          {this.props.children}
        </div>
        <SiteNewsletterStrip className="dark-blue" />
      </div>
    );
  }
}
