import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from '../../../Util';
import ReactSVG from 'react-svg';

import Accordion from '../Accordion/Accordion';
import Button from '../Button/Button';
import JourneyBadge from '../JourneyBadge/JourneyBadge';
import ProgressBar from '../ProgressBar/ProgressBar';
import JourneyStepListNav from '../JourneyStepListNav/JourneyStepListNav';

//this.props.journeyId
class JourneyAccordion extends Component {
  render() {
    let journey = Util.context.referenceData.getJourneyById(
      this.props.journeyId
    );

    if (this.props.journeyId !== 1000) {
      let userJourney = this.props.userJourneys.find(
        userJourney => userJourney.journeyId === journey.journeyId
      );

      let isLocked = this.props.isLocked || journey.isLocked;

      let actionContainer = (
        <div className="action-container">
          {isLocked ? (
            <h3>
              <ReactSVG className="lock-icon" src={Util.icon.lock} />
              Locked
            </h3>
          ) : userJourney ? (
            !!userJourney.completedAt ? (
              <Button
                isSecondary={true}
                size="md"
                label="View"
                to={Util.route.app.journey(journey.urlId)}
              />
            ) : (
              <Button
                size="md"
                label="Continue"
                to={Util.route.app.journey(journey.urlId)}
              />
            )
          ) : (
            <Button
              size="md"
              label="Start"
              to={Util.route.app.journey(journey.urlId)}
            />
          )}
        </div>
      );

      let head = (
        <div className="journey-accordion-head">
          <JourneyBadge
            className={isLocked ? 'disabled' : ''}
            journey={journey}
          />
          <div className="journey-title">
            <h2 className="journey-name">{journey.name}</h2>
            <div className="progress-info">
              {userJourney && !!userJourney.completedAt ? (
                <h3 className="green">
                  <ReactSVG className="tick-icon" src={Util.icon.tick} />
                  Completed
                </h3>
              ) : userJourney ? (
                <ProgressBar
                  isPercentVisible={true}
                  numerator={
                    userJourney.steps.filter(
                      step =>
                        Util.context.referenceData.getJourneyStepStatus(
                          step,
                          userJourney,
                          this.props.myActivities,
                          this.props.userProgressActivity
                        ) === 'completed'
                    ).length
                  }
                  denominator={userJourney.steps.length}
                />
              ) : !isLocked ? (
                <ProgressBar
                  isPercentVisible={true}
                  numerator={0}
                  denominator={1}
                />
              ) : null}
            </div>
          </div>
          {actionContainer}
        </div>
      );
      return (
        <Accordion
          className={`journey-accordion ${this.props.className || ''} ${
            userJourney && !!userJourney.completedAt ? 'completed' : ''
          }`}
          head={head}
        >
          <div className="journey-info">
            {userJourney ? (
              <div className="journey-steps">
                <h4 className="journey-info-title grey-1 bold">
                  Journey steps:
                </h4>
                <JourneyStepListNav userJourney={userJourney} />
              </div>
            ) : null}
            <div className="journey-details">
              <h4 className="journey-info-title grey-1 bold">
                Journey description:
              </h4>
              <section
                dangerouslySetInnerHTML={{ __html: journey.htmlSummary }}
              />
              {actionContainer}
            </div>
          </div>
        </Accordion>
      );
    } else {
      // render breaks
      return (
        <div className="break">
          <p>{this.props.breakLabel}</p>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys,
  myActivities: state.activityState.myActivities,
  userProgressActivity: state.userState.userProgress.activity
});

export default connect(mapStateToProps, {})(JourneyAccordion);
