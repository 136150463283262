import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

export default class ContextMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false
    };

    this.setMenuVisible = this.setMenuVisible.bind(this);
    this.onBodyClick = this.onBodyClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }
  componentDidMount() {
    document.body.addEventListener('click', this.onBodyClick);
  }
  componentWillUnmount() {
    document.body.removeEventListener('click', this.onBodyClick);
  }
  setMenuVisible(isVisible) {
    this.setState({
      isMenuVisible: isVisible
    });
  }
  onItemClick(item) {
    this.setState({
      isMenuVisible: false
    });

    if (item.onClick) item.onClick();
  }
  onBodyClick(e) {
    let menuItemsEl = e.target.classList.contains('context-menu-items')
      ? e.target
      : e.target.closest('.context-menu-items');

    if (!menuItemsEl && this.state.isMenuVisible) {
      this.setState({
        isMenuVisible: false
      });
    }
  }
  render() {
    let align = this.props.align || 'right';

    return (
      <div className={`context-menu ${this.props.className || ''}`}>
        <div
          className={`context-menu-button ${
            this.state.isMenuVisible ? 'open' : ''
          }`}
          onClick={() => this.setMenuVisible(!this.state.isMenuVisible)}
        >
          {this.props.button || (
            <ReactSVG
              className="default-button-icon"
              src={this.props.icon || Util.icon.contextMenu}
            />
          )}
        </div>
        {this.state.isMenuVisible ? (
          <div className={`context-menu-items align-${align}`}>
            {this.props.items.map((item, idx) => {
              return item.to ? (
                <Link
                  className={`context-menu-item ${
                    Util.route.isCurrently(item.to) ? 'active' : ''
                  }`}
                  key={idx}
                  to={item.to}
                  onClick={() => this.onItemClick(item)}
                >
                  {item.icon ? (
                    <ReactSVG className="item-icon" src={item.icon} />
                  ) : null}
                  <p>{item.label}</p>
                </Link>
              ) : (
                <div
                  className="context-menu-item"
                  key={idx}
                  onClick={() => this.onItemClick(item)}
                >
                  {item.icon ? (
                    <ReactSVG className="item-icon" src={item.icon} />
                  ) : null}
                  <p>{item.label}</p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
