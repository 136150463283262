import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

//this.props.text
//this.props.maxLine
//this.props.component  (eg. h2, p, div)
//this.props.revealLabel (eg. 'See more')
export default class Truncate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRevealed: false,
      maxLine: this.props.maxLine
    };

    this.onReveal = this.onReveal.bind(this);
  }
  onReveal() {
    if (!this.state.isRevealed) {
      this.setState({
        isRevealed: true,
        maxLine: 999999999999 //i dont have a better plan for this yet
      });
    }
  }
  render() {
    let config = {
      className: this.props.className,
      component: this.props.component || 'div',
      maxLine: this.state.maxLine,
      onClick: this.onReveal
    };

    if (this.props.html) {
      return (
        <HTMLEllipsis
          unsafeHTML={this.props.html}
          ellipsisHTML={
            this.props.revealLabel
              ? `<span class="reveal-label">... <button class="link-button">${this.props.revealLabel}</button></span>`
              : `...`
          }
          {...config}
        />
      );
    } else {
      return (
        <ResponsiveEllipsis
          text={this.props.text}
          ellipsis={
            this.props.revealLabel ? (
              <span className="reveal-label">
                ... <button className="link-button">{this.props.revealLabel}</button>
              </span>
            ) : (
              '...'
            )
          }
          {...config}
        />
      );
    }
  }
}
