import React, { Component } from 'react';

//components
import CardsView from '../../CardsView/CardsView';
import GameCard from '../../Card/GameCard/GameCard';
import AssessmentCard from '../../Card/AssessmentCard/AssessmentCard';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import ArticlePanel from '../../ArticlePanel/ArticlePanel';

export default class JourneyStepItemView extends Component {
  render() {
    let stepItem = this.props.stepItem;
    let specialContent = null;

    switch (true) {
      case !!stepItem.assessment:
        specialContent = (
          <CardsView>
            <AssessmentCard assessment={stepItem.assessment} />
          </CardsView>
        );
        break;
      case !!stepItem.content:
        specialContent = (
          <ArticlePanel className="gilroy" content={stepItem.content} />
        );
        break;
      case !!stepItem.game:
        specialContent = (
          <CardsView>
            <GameCard game={stepItem.game} />
          </CardsView>
        );
        break;
      case !!stepItem.video:
        specialContent = <VideoPlayer video={stepItem.video} />;
        break;
      default:
        break;
    }

    return (
      <div className="step-item">
        <h1 className="step-item-name gilroy">{stepItem.name}</h1>
        {stepItem.htmlContent ? (
          <section
            className="gilroy"
            dangerouslySetInnerHTML={{ __html: stepItem.htmlContent }}
          />
        ) : null}
        {specialContent ? <div>{specialContent}</div> : null}
      </div>
    );
  }
}
