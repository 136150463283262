import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

export default class Button extends Component {
  render() {
    let config = {
      label: this.props.label,
      to: this.props.to,
      icon: this.props.icon,
      iconAlign: this.props.iconAlign || 'left',
      size: this.props.size || 'md', //xl, lg, md, sm, xs
      isSecondary: this.props.isSecondary,
      isBlack: this.props.isBlack,
      isAdmin: this.props.isAdmin,
      isIcon: this.props.isIcon,
      isSubmit: this.props.isSubmit,
      isDisabled: this.props.isDisabled,
      isActive: this.props.isActive,
      title: this.props.title,
      href: this.props.href,
      target:
        this.props.target ||
        (Util.route.isInternal(this.props.href) ? '_self' : '_blank'),
      onClick: () => {
        Util.analytics.track('ButtonClicked', { label: this.props.label });
        if (this.props.onClick) this.props.onClick();
      }
    };

    let buttonContent = (
      <div className={`button-inner`}>
        {config.icon && config.iconAlign === 'left' ? (
          <ReactSVG className="button-icon left" src={config.icon} />
        ) : null}
        {config.label ? (
          <span className="button-label">{config.label}</span>
        ) : null}
        {config.icon && config.iconAlign === 'right' ? (
          <ReactSVG className="button-icon right" src={config.icon} />
        ) : null}
      </div>
    );

    const { isSecondary, isBlack, isAdmin, isIcon, isDisabled } = config
    let buttonClass = `button ${this.props.className || ''} button-${
      config.size
      } ${isSecondary ? 'button-secondary' : ''} ${isSecondary || isAdmin || isBlack || isIcon ? '' : 'button-primary'}${
      isDisabled ? 'disabled' : ''
      } ${isAdmin ? 'button-admin' : ''} ${isIcon ? 'button-icon' : ''} 
    ${isBlack ? 'button-black' : ''} 
    ${this.props.isActive ? 'active' : ''}`;

    if (config.to) {
      return (
        <Link
          className={buttonClass}
          title={config.title}
          to={config.to}
          onClick={config.onClick}
        >
          {buttonContent}
        </Link>
      );
    } else if (config.href) {
      return (
        <a
          className={buttonClass}
          title={config.title}
          href={config.href}
          target={config.target}
          onClick={config.onClick}
        >
          {buttonContent}
        </a>
      );
    } else {
      return (
        <button
          className={buttonClass}
          title={config.title}
          type={config.isSubmit ? 'submit' : 'button'}
          onClick={config.onClick}
        >
          {buttonContent}
        </button>
      );
    }
  }
}
