import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import CardsView from '../CardsView';
import StoryCard from '../../Card/StoryCard/StoryCard';

//data source: this.props.stories
export default class StoryCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.stories,
      stories: this.props.stories || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: stories => {
        this.setState({
          stories
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (Util.array.none(this.state.stories)) {
      content = <div className="empty-text">No stories to display.</div>;
    }

    return (
      <CardsView
        className="story-cards-view"
        isNeverLargeMode={this.props.isNeverLargeMode}
      >
        {content}
        {this.state.stories.map((story, j) => (
          <StoryCard
            key={j}
            story={story}
            isNeverLargeMode={this.props.isNeverLargeMode}
          />
        ))}
      </CardsView>
    );
  }
}
