import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import Util from '../../../../Util';
import withForm from '../withForm';

import Button from '../../Button/Button';

class ResetPasswordForm extends Component {
  render() {
    return (
      <form className="form-reset" onSubmit={this.props.onSubmit}>
        {this.props.getField('password')}
        {this.props.getField('confirmPassword')}
        {this.props.isAccountSetup ? (
          <h5 className="disclaimer grey-3">
            By clicking "Sign up", you agree to Leda's{' '}
            <Link to={Util.route.site.termsConditions()}>
              Terms and Conditions
            </Link>{' '}
            and that you have read our{' '}
            <Link to={Util.route.site.privacyPolicy()}>Privacy Policy</Link>.
          </h5>
        ) : null}
        <div className="button-container">
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
          <Button label="Submit" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(ResetPasswordForm, {
  getOverallError: formData => {
    if (!validator.equals(formData.password, formData.confirmPassword))
      return 'Passwords do not match';
  },
  fields: {
    password: {
      label: 'Password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Enter a password (between 8 - 64 characters)';
      }
    },
    confirmPassword: {
      label: 'Confirm password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Re-enter your password (between 8 - 64 characters)';
      }
    }
  }
});
