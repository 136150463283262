import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import Carousel from '../Carousel/Carousel';

export default class TabPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIdx: this.props.selectedTabIdx || 0
    };
  }
  componentWillReceiveProps(props) {
    if (
      props.selectedTabIdx &&
      props.selectedTabIdx !== this.state.selectedTabIdx
    ) {
      this.setState({
        selectedTabIdx: props.selectedTabIdx
      });
    }
  }
  changeTab(idx) {
    let selectedTab = this.props.tabs[idx];

    if (selectedTab.onClick) selectedTab.onClick();

    this.setState({
      selectedTabIdx: idx
    });

    Util.analytics.track('TabClicked', {
      label: selectedTab.label
    });
  }
  render() {
    if (Util.array.none(this.props.tabs)) return null;

    let selectedTab = this.props.tabs[this.state.selectedTabIdx];

    let settings = {
      variableWidth: true,
      initialSlide: this.props.selectedTabIdx,
      swipeToSlide: true,
      arrows: false,
      responsive: [
        {
          breakpoint: Util.css.breakpoint.sm, //Below sm
          settings: {
            arrows: true
          }
        }
      ]
    };

    return (
      <div className="tabpanel-container">
        <Carousel className="carousel-tabpanel" settings={settings}>
          {this.props.tabs.map((tab, idx) => (
            <div
              key={idx}
              style={{ width: !!tab.tabWidth ? `${tab.tabWidth}px` : 'auto' }}
              className={`tab ${
                this.state.selectedTabIdx === idx ? 'selected-tab ' : ''
              }`}
              onClick={() => this.changeTab(idx)}
            >
              {tab.label}
            </div>
          ))}
        </Carousel>
        {selectedTab && selectedTab.content ? (
          <div className="selected-tab-content">{selectedTab.content}</div>
        ) : null}
      </div>
    );
  }
}
