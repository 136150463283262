import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import StoryCardsView from '../CardsView/StoryCardsView/StoryCardsView';

export default class CourseView extends Component {
  render() {
    return (
      <div className="course-view">
        {Util.context.referenceData.getModules().map((mod, i) => (
          <div key={mod.moduleId} className="module-story-cards-container">
            <h1 className="module-name">{mod.name}</h1>
            <StoryCardsView stories={mod.stories} />
          </div>
        ))}
      </div>
    );
  }
}
