import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

export default class Checkbox extends Component {
  render() {
    let config = {
      label: this.props.label,
      isDisabled: this.props.isDisabled,

      //One of these will be ignored based on a condition below
      checked: this.props.checked,
      defaultChecked: this.props.defaultChecked || false,

      onChange: e => {
        Util.analytics.track('CheckboxChange', {
          label: this.props.label,
          isChecked: e.target.checked
        });
        if (this.props.onChange) this.props.onChange(e);
      }
    };

    let checkboxClass = `checkbox ${config.isDisabled ? 'disabled' : ''} ${this
      .props.className || ''}`;

    let checkboxConfig = {
      type: 'checkbox',
      onChange: config.onChange
    };

    //Is the checkbox controlled or uncontrolled?
    if ('checked' in this.props) {
      checkboxConfig.checked = config.checked;
    } else {
      checkboxConfig.defaultChecked = config.defaultChecked;
    }

    return (
      <label className={checkboxClass}>
        {config.label}
        <input {...checkboxConfig} />
        <span className="checkbox-mark">
          <ReactSVG className="tick" src={Util.icon.tick} />
        </span>
      </label>
    );
  }
}
