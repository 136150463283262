import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

import Accordion from '../Accordion/Accordion';
import SkillLinkItem from '../SkillLinkItem/SkillLinkItem';

//this.props.selectedSkillId
export default class SkillLinkMenu extends Component {
  render() {
    return (
      <div className="skill-link-menu">
        {Util.context.referenceData.getSkillGroups().map(skillGroup => {
          return (
            <Accordion
              key={skillGroup.skillGroupId}
              isOpen={this.props.openSkillGroupId === skillGroup.skillGroupId}
              head={
                <div
                  className="skill-group-head"
                  style={{ backgroundColor: '#' + skillGroup.primaryColour }}
                >
                  <span>{skillGroup.name}</span>
                  <ReactSVG
                    className="arrow-icon"
                    src={Util.icon.arrowHead.up}
                  />
                </div>
              }
              isArrowHidden={true}
            >
              {skillGroup.skills.map(skill => (
                <SkillLinkItem key={skill.skillId} skill={skill} />
              ))}
            </Accordion>
          );
        })}
      </div>
    );
  }
}
