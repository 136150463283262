import React, { Component } from 'react';
import Util from '../../../Util';

export default class Accordion extends Component {
  constructor(props) {
    super(props);

    this.accordionRef = React.createRef();

    this.onClick = this.onClick.bind(this);
  }
  componentDidMount() {
    if (this.props.isAutoOpen) Util.accordion.open(this.accordionRef.current);
  }
  componentDidUpdate() {
    //The content might have changed. If this is true, the height might of changed!
    // Lets toggle another accordion open to make sure it all fits.
    let accordion = this.accordionRef.current;
    if (Util.accordion.isOpen(accordion)) Util.accordion.open(accordion);
  }
  onClick(e) {
    Util.accordion.toggle(e.target);

    if (this.props.onClick) this.props.onClick();
  }
  open() {
    //This is only made for external/parent components to trigger open/close easily. It is not used within this component
    let accordion = this.accordionRef.current;
    Util.accordion.open(accordion);
  }
  close() {
    //This is only made for external/parent components to trigger open/close easily. It is not used within this component
    let accordion = this.accordionRef.current;
    if (Util.accordion.isOpen(accordion)) Util.accordion.close(accordion);
  }
  render() {
    return (
      <div
        ref={this.accordionRef}
        className={`accordion ${this.props.className || ''} ${
          this.props.isArrowHidden ? 'hide-arrow' : ''
        } ${this.props.isOpen ? 'open' : ''}`}
      >
        <div className="accordion-head" onClick={this.onClick}>
          {typeof this.props.head === 'string' ? (
            <h4>{this.props.head}</h4>
          ) : (
            this.props.head
          )}
        </div>
        <div
          className="accordion-body"
          style={{ maxHeight: this.props.isOpen ? 'none' : null }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
