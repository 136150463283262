import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Store from './Store';
import history from './history';

import AppContainer from './components/AppContainer';

class App extends Component {
  render() {
    return (
      <Provider store={Store}>
        <Router history={history}>
          <AppContainer />
        </Router>
      </Provider>
    );
  }
}

export default App;
