import React, { Component } from 'react';
import Util from '../../../Util';

import JourneyBadge from '../JourneyBadge/JourneyBadge';

// Data source: journey or journeyId?
export default class JourneyRibbon extends Component {
  render() {
    let journey =
      this.props.journey ||
      Util.context.referenceData.getJourneyById(this.props.journeyId);
    if (!journey) return 'N/A';

    return (
      <div
        className={`journey-ribbon ${this.props.size || 'md'} ${this.props
          .className || ''}`}
        title={journey.name}
        style={this.props.style || {}}
      >
        <JourneyBadge journey={journey} size={this.props.size || 'md'} />
        <div className="ribbon-tail">
          <div className="tail-left"></div>
          <div className="tail-right"></div>
        </div>
      </div>
    );
  }
}
