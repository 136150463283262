import React, { Component } from 'react';
import TextArea from 'react-textarea-autosize';
import Util from '../../../Util';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';

import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import FileUpload from '../FileUpload/FileUpload';
import AudienceSelect from '../AudienceSelect/AudienceSelect';
import FileList from '../FileList/FileList';
import Checkbox from '../Checkbox/Checkbox';
import PostImages from '../PostImages/PostImages';

//this.props.post OR this.props.postId to edit existing post
class PostEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !!this.props.postId,
      isUploadingFiles: false,

      //New post info
      post: this.props.post || this.getNewPostObject()
    };

    this.onScheudleDropdownClick = this.onScheudleDropdownClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onFilesUploaded = this.onFilesUploaded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onAudienceChange = this.onAudienceChange.bind(this);
    this.onIsLedaOfficialChange = this.onIsLedaOfficialChange.bind(this);
  }
  componentDidMount() {
    if (this.props.postId) {
      this.setState({
        isLoading: true
      });

      Util.api.post(
        '/api/post/getPostById',
        { postId: this.props.postId },
        {
          success: post => {
            this.setState({
              post
            });
          },
          complete: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      );
    }
  }
  getNewPostObject() {
    return {
      text: '',
      postFiles: [],
      scheduledAt: null,
      isLedaOfficial: Util.context.user.getIsLedaAdmin(),
      isGlobal: this.props.defaultAudienceIsGlobal,
      organisationId: this.props.defaultAudienceOrganisationId,
      teamId: this.props.defaultAudienceTeamId
    };
  }
  onTextChange(e) {
    this.setState({
      post: {
        ...this.state.post,
        text: e.target.value
      }
    });
  }
  onIsLedaOfficialChange(e) {
    this.setState({
      post: {
        ...this.state.post,
        isLedaOfficial: e.target.checked
      }
    });
  }
  onFilesUploaded(files) {
    this.setState({
      post: {
        ...this.state.post,
        postFiles: [
          ...this.state.post.postFiles,
          ...files.map(file => {
            return {
              postFileId: null,
              file
            };
          })
        ]
      }
    });
  }
  removeFile(fileId) {
    this.setState({
      post: {
        ...this.state.post,
        postFiles: this.state.post.postFiles.filter(
          postFile => postFile.file.fileId !== fileId
        )
      }
    });
  }
  onAudienceChange(audience) {
    this.setState({
      post: {
        ...this.state.post,
        ...audience
      }
    });
  }
  onScheudleDropdownClick(e) {
    e.target.blur();

    this.props.openModalAction({
      type: Util.enum.ModalType.ScheduleAtModal,
      title: 'Schedule Post',
      icon: Util.icon.post,
      data: {
        dateTime: this.state.post.scheduledAt,
        onSubmit: value => {
          let now = new Date();
          if (value) value = value.setSeconds(0, 0); //Clean the seconds/milliseconds

          this.setState({
            post: {
              ...this.state.post,
              scheduledAt: value
            }
          });
        }
      }
    });
  }
  onSubmit() {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/post/savePost',
      {
        post: this.state.post
      },
      {
        success: savedPost => {
          this.setState({
            post: this.getNewPostObject()
          });

          if (this.props.onPostSaved) this.props.onPostSaved(savedPost);
        },
        complete: () => {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }
  render() {
    let content = null;
    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else {
      let isFileLimit = this.state.post.postFiles.length >= 10;
      let dropdownSingleOptionId = Util.id.tempId.getTempId();

      let imagePostFiles = this.state.post.postFiles.filter(postFile =>
        postFile.file.type.startsWith('image')
      );
      let otherPostFiles = this.state.post.postFiles.filter(
        postFile => !imagePostFiles.includes(postFile)
      );

      content = (
        <div className="post-editor-inner">
          <div className="post-editor-title">
            <h4 className="bold">
              {this.state.post.postId ? 'Edit post' : 'Create new post'}
            </h4>
          </div>
          <div className="post-editor-data">
            <TextArea
              placeholder="What would you like to say?"
              value={this.state.post.text}
              onChange={this.onTextChange}
            />
            {isFileLimit ? null : (
              <div className="post-editor-attach">
                <FileUpload
                  onUploadSuccess={this.onFilesUploaded}
                  uploadUrl={'/api/file/uploadImages'}
                  allowedExtensions={['.png', '.jpg', '.jpeg']}
                >
                  <Button
                    size="sm"
                    isSecondary={true}
                    label="Photo"
                    icon={Util.icon.camera}
                  />
                </FileUpload>
                <FileUpload
                  onUploadSuccess={this.onFilesUploaded}
                  uploadUrl={'/api/file/uploadDocuments'}
                  allowedExtensions={['.pdf']}
                >
                  <Button
                    size="sm"
                    isSecondary={true}
                    label="Document"
                    icon={Util.icon.attachment}
                  />
                </FileUpload>
              </div>
            )}
          </div>
          {Util.array.any(otherPostFiles) ? (
            <FileList
              files={otherPostFiles.map(postFile => postFile.file)}
              onRemoveFile={this.removeFile}
            />
          ) : null}
          {Util.array.any(imagePostFiles) ? (
            <PostImages
              postFiles={imagePostFiles}
              onRemoveImage={this.removeFile}
            />
          ) : null}
          <div className="post-editor-actions">
            {this.state.post.postedAt ? null : (
              <div className="field-container">
                {!this.props.defaultAudienceOrganisationId && (
                  <label>
                    Audience
                    <AudienceSelect
                      onAudienceChange={this.onAudienceChange}
                      audienceOrganisationId={this.state.post.organisationId}
                      audienceTeamId={this.state.post.teamId}
                      audienceIsGlobal={this.state.post.isGlobal}
                    />
                  </label>
                )}
              </div>
            )}
            {this.state.post.postedAt ? null : (
              <div className="field-container">
                <label>Publish</label>
                <Dropdown
                  onClick={this.onScheudleDropdownClick}
                  valueProp="id"
                  displayProp="label"
                  value={dropdownSingleOptionId}
                  options={[
                    {
                      id: dropdownSingleOptionId,
                      label: this.state.post.scheduledAt
                        ? Util.format.date.shortDateTime(
                            this.state.post.scheduledAt
                          )
                        : 'Now'
                    }
                  ]}
                />
              </div>
            )}
            {Util.context.user.getIsLedaAdmin() ? (
              <Checkbox
                isDisabled={this.state.post.isGlobal}
                checked={
                  this.state.post.isGlobal || this.state.post.isLedaOfficial
                }
                onChange={this.onIsLedaOfficialChange}
                label="Official Leda Post"
              />
            ) : null}
            <div className="flex-spacer"></div>
            <Button
              label={
                this.state.post.postId
                  ? 'Save'
                  : this.state.post.scheduledAt
                  ? 'Schedule'
                  : 'Post'
              }
              size="sm"
              onClick={this.onSubmit}
              isDisabled={
                (!this.state.post.text &&
                  Util.array.none(this.state.post.postFiles)) ||
                this.state.isUploadingFiles
              }
            />
          </div>
        </div>
      );
    }

    return <div className="post-editor">{content}</div>;
  }
}

export default connect(null, { openModalAction })(PostEditor);
