import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

export default class VideoOverlay extends Component {
  render() {
    return (
      <div
        className={`video-overlay ${this.props.className || ''} ${
          this.props.isVisible ? 'is-visible' : ''
        }`}
      >
        <div className="video-overlay-inner">
          {!this.props.showCloseButton ? null : (
            <ReactSVG
              className="close-button"
              src={Util.icon.close}
              onClick={this.props.onClose}
            />
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}
