import React, { Component } from 'react';
import moment from 'moment';

import DateField from './DateField/DateField';
import TimeField from './TimeField/TimeField';

export default class DateTimeField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:
        typeof this.props.value === 'string'
          ? !this.props.value
            ? null //empty string
            : new Date(this.props.value)
          : this.props.value
    };

    this.setDate = this.setDate.bind(this);
    this.setTime = this.setTime.bind(this);
    this.setToNow = this.setToNow.bind(this);
  }
  setToNow() {
    this.changeDateTime(new Date());
  }
  setDate(date, dateAsDateTime) {
    let momentValue = moment(dateAsDateTime);
    let momentDateTime = moment(this.state.value || new Date());

    momentDateTime.set({
      date: momentValue.get('date'),
      month: momentValue.get('month'),
      year: momentValue.get('year')
    });

    this.changeDateTime(momentDateTime.toDate());
  }
  setTime(time, timeAsDateTime) {
    let momentDateTime = moment(this.state.value || new Date());
    let splitTime = time.split(':');

    momentDateTime.set({
      hour: parseInt(splitTime[0], 10),
      minute: parseInt(splitTime[1], 10),
      second: parseInt(splitTime[2], 10)
    });

    this.changeDateTime(momentDateTime.toDate());
  }
  changeDateTime(combinedDateTime) {
    let validatedValue = combinedDateTime;

    //Minmax validation
    let minDateTime = this.props.min === 'now' ? new Date() : this.props.min;
    let maxDateTime = this.props.max === 'now' ? new Date() : this.props.max;

    if (minDateTime && validatedValue && minDateTime > validatedValue)
      validatedValue = minDateTime;
    if (maxDateTime && validatedValue && maxDateTime < validatedValue)
      validatedValue = maxDateTime;

    this.setState({
      value: validatedValue
    });

    // While the value state of this component uses a local date,
    // we ALWAYS want to handle dates as UTC outside this component/in the db
    if (this.props.onChange) this.props.onChange(validatedValue);
  }
  render() {
    return (
      <div className="date-time-field custom-input">
        <div className="date-time-field-inputs">
          <div className="field-container">
            <label>Date</label>
            <DateField
              min={this.props.min}
              max={this.props.max}
              value={this.state.value}
              onChange={this.setDate}
            />
          </div>
          <div className="field-container">
            <label>Time</label>
            {/* This intentionally does not set min/max, this is vlaidated in changeDateTime */}
            <TimeField value={this.state.value} onChange={this.setTime} />
          </div>
        </div>
        <div className="date-time-field-quicklinks">
          {/* TODO: this link shouldnt exist if NOW isn't a possible option with min/max */}
          <h4>
            <button className="link-button" onClick={this.setToNow}>Set to now</button>
          </h4>
        </div>
      </div>
    );
  }
}
