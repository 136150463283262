/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import { openModalAction } from '../../../actions/modalActions';
import Util from '../../../Util';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import FileList from '../FileList/FileList';
import FileUpload from '../FileUpload/FileUpload';
import PostImages from '../PostImages/PostImages';

//this.props.post OR this.props.postId to edit existing post
class PresetPostEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post || this.getNewPostObject(),
      postLater: {
        duration: this.props.post?.scheduledIn || 0,
        unit: 'days',
        at: this.props.post?.time || '00:00:00'
      },
      postToday: {
        minutes: 0,
        hours: 0
      },
      setToNowActive: false
    };
  }

  diffDays = (date1 = new Date(), date2 = new Date()) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if ((date1.getDay() === date2.getDay()) & (diffDays < 1)) {
      // return 0 if it's on the same day
      return 0;
    } else {
      return Math.ceil(diffDays);
    }
  };

  clearPostToday = () => {
    this.setState({
      postToday: {
        minutes: 0,
        hours: 0
      }
    });
  };

  clearPostLater = () => {
    this.setState({
      postLater: {
        duration: '',
        unit: 'days',
        at: ''
      }
    });
  };

  handleSubmit = () => {
    const postLater = this.state.postLater;
    const postToday = this.state.postToday;

    if (postLater.duration || postLater.at) {
      let duration = postLater.duration;

      if (postLater.unit === 'weeks') {
        duration = duration * 7;
      } else if (postLater.unit === 'months') {
        duration = duration * 30;
      }

      const post = { ...this.state.post };
      post.scheduledIn = Number.parseInt(duration);
      post.time = postLater.at + ':00';

      this.props.onSubmit(post);
    } else if (postToday.minutes || postToday.hours) {
      // const presetPostDate = moment()
      //   .add(postToday.hours, 'hours')
      //   .add(postToday.minutes, 'minutes')
      //   .toDate();
    } else {
      const post = { ...this.state.post };
      post.time = '00:00:00';
      post.scheduledIn = 0;

      this.props.onSubmit(post);
    }
  };

  getNewPostObject = () => {
    return {
      text: '',
      scheduledIn: 0,
      isLedaOfficial: false,
      time: '00:00:00',
      postFiles: []
    };
  };
  onTextChange = e => {
    this.setState({
      post: {
        ...this.state.post,
        text: e.target.value
      }
    });
  };
  onIsLedaOfficialChange = e => {
    this.setState({
      post: {
        ...this.state.post,
        isLedaOfficial: e.target.checked
      }
    });
  };
  onFilesUploaded = files => {
    this.setState({
      post: {
        ...this.state.post,
        postFiles: [
          ...this.state.post.postFiles,
          ...files.map(file => {
            return {
              postFileId: null,
              file
            };
          })
        ]
      }
    });
  };
  removeFile = fileId => {
    this.setState({
      post: {
        ...this.state.post,
        postFiles: this.state.post.postFiles.filter(
          postFile => postFile.file.fileId !== fileId
        )
      }
    });
  };

  onScheduleDropdownClick = e => {
    e.target.blur();

    this.props.openModalAction({
      type: Util.enum.ModalType.ScheduledInModal,
      title: 'Scheduled In',
      icon: Util.icon.post,
      data: {
        scheduledIn: this.state.post.scheduledIn || 0,
        onSubmit: ({ scheduledIn, time }) => {
          this.setState({
            post: {
              ...this.state.post,
              scheduledIn,
              time
            }
          });
        }
      }
    });
  };

  setToNow = () => {
    const post = { ...this.state.post };

    post.scheduledIn = 0;
    post.time = '00:00:00';

    this.setState({
      post
    });
  };

  render() {
    let isFileLimit = this.state.post.postFiles.length >= 10;

    let imagePostFiles = this.state.post.postFiles.filter(postFile =>
      postFile.file.type.startsWith('image')
    );
    let otherPostFiles = this.state.post.postFiles.filter(
      postFile => !imagePostFiles.includes(postFile)
    );

    return (
      <div className="post-editor" style={{ minWidth: '700px' }}>
        <div className="post-editor-inner">
          <div className="post-editor-title">
            <h4 className="bold">Create New Preset Post</h4>
          </div>

          <div className="post-editor-data">
            <TextArea
              placeholder="What would you like to say?"
              maxRows={20}
              value={this.state.post.text}
              onChange={this.onTextChange}
            />

            {isFileLimit ? null : (
              <div className="post-editor-attach">
                <FileUpload
                  onUploadSuccess={this.onFilesUploaded}
                  uploadUrl={'/api/file/uploadImages'}
                  allowedExtensions={['.png', '.jpg', '.jpeg']}
                >
                  <Button
                    size="sm"
                    isSecondary={true}
                    label="Photo"
                    icon={Util.icon.camera}
                  />
                </FileUpload>
                <FileUpload
                  onUploadSuccess={this.onFilesUploaded}
                  uploadUrl={'/api/file/uploadDocuments'}
                  allowedExtensions={['.pdf']}
                >
                  <Button
                    size="sm"
                    isSecondary={true}
                    label="Document"
                    icon={Util.icon.attachment}
                  />
                </FileUpload>
              </div>
            )}
          </div>

          {Util.array.any(otherPostFiles) ? (
            <FileList
              files={otherPostFiles.map(postFile => postFile.file)}
              onRemoveFile={this.removeFile}
            />
          ) : null}
          {Util.array.any(imagePostFiles) ? (
            <PostImages
              postFiles={imagePostFiles}
              onRemoveImage={this.removeFile}
            />
          ) : null}

          <div className="post-editor-actions">
            <div className="field-container">
              <h4 className="bold">Scheduled to be posted in</h4>
              <div
                className="schedule-at-select"
                style={{ display: 'flex', marginBottom: '10px' }}
              >
                <input
                  type="number"
                  style={{ width: '25px', marginRight: '5px' }}
                  min="0"
                  value={this.state.postLater.duration}
                  onChange={e =>
                    this.setState({
                      postLater: {
                        ...this.state.postLater,
                        duration: e.target.value
                      }
                    })
                  }
                />
                <Dropdown
                  valueProp="value"
                  displayProp="value"
                  value={this.state.postLater.unit}
                  options={[
                    {
                      value: 'days'
                    },
                    {
                      value: 'weeks'
                    },
                    {
                      value: 'months'
                    }
                  ]}
                  onChange={unit =>
                    this.setState({
                      postLater: { ...this.state.postLater, unit }
                    })
                  }
                />
                <p style={{ lineHeight: '30px', margin: '0 5px' }}>at</p>
                <input
                  type="time"
                  onClick={() => {
                    this.clearPostToday();
                    this.setState({ setToNowActive: false });
                  }}
                  onChange={e =>
                    this.setState({
                      postLater: { ...this.state.postLater, at: e.target.value }
                    })
                  }
                  value={this.state.postLater.at}
                />
                <div
                  style={{
                    height: '30px',
                    borderLeft: '1px solid black',
                    width: '1px',
                    margin: '0 5px'
                  }}
                >
                  &nbsp;
                </div>
                <p style={{ lineHeight: '30px' }}>Or post in HH &nbsp;</p>
                <input
                  disabled
                  style={{ width: '30px' }}
                  type="number"
                  min="0"
                  onClick={() => {
                    this.clearPostLater();
                    this.setState({ setToNowActive: false });
                  }}
                  onChange={e =>
                    this.setState({
                      postToday: {
                        ...this.state.postToday,
                        hours: e.target.value
                      }
                    })
                  }
                  value={this.state.postToday.hours}
                />
                <p style={{ lineHeight: '30px' }}>&nbsp;MM&nbsp;</p>
                <input
                  disabled
                  style={{ width: '30px' }}
                  type="number"
                  min="0"
                  onClick={() => {
                    this.clearPostLater();
                    this.setState({ setToNowActive: false });
                  }}
                  onChange={e =>
                    this.setState({
                      postToday: {
                        ...this.state.postToday,
                        minutes: e.target.value
                      }
                    })
                  }
                  value={this.state.postToday.minutes}
                />
                <div
                  style={{
                    height: '30px',
                    borderLeft: '1px solid black',
                    width: '1px',
                    margin: '0 5px'
                  }}
                >
                  &nbsp;
                </div>
                <button
                  className="link-button"
                  onClick={() => {
                    this.clearPostLater();
                    this.clearPostToday();
                    this.setState({ setToNowActive: true });
                  }}
                >
                  Set to now
                </button>
              </div>
            </div>

            <div className="field-container">
              <Checkbox
                isDisabled={false}
                checked={this.state.post.isLedaOfficial}
                onChange={this.onIsLedaOfficialChange}
                label="Official Leda Post"
              />
            </div>
            <div className="flex-spacer" style={{ width: '100px' }}></div>
            <Button
              label="Save"
              size="md"
              onClick={this.handleSubmit}
              isDisabled={
                (!this.state.post.text &&
                  Util.array.none(this.state.post.postFiles)) ||
                this.state.isUploadingFiles
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(PresetPostEditor);
