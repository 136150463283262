import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import Util from './../../../../Util';

//Components
import Card from '../Card';
import ProgressBar from '../../ProgressBar/ProgressBar';
import StoryIcon from '../../StoryIcon/StoryIcon';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.story
class StoryCard extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    Util.analytics.track('StoryCardClicked', {
      storyId: this.props.story.storyId,
      storyUrlId: this.props.story.urlId,
      storyName: this.props.story.name
    });
  }
  render() {
    let userCompletedChapters = this.props.story.chapters.filter(
      ch => !!this.props.userProgressVideo[ch.videoId]
    );
    let isStoryCompleted =
      userCompletedChapters.length > 0 &&
      this.props.story.chapters.length === userCompletedChapters.length;
    let firstUnwatchedChapter = this.props.story.chapters
      .sort((i1, i2) => Util.sort.by('ordinal', i1, i2, Util.sort.asNumber))
      .find(ch => !userCompletedChapters.includes(ch));

    let linkTo = firstUnwatchedChapter
      ? Util.route.app.storyChapter(
          this.props.story.urlId,
          firstUnwatchedChapter.urlId
        )
      : Util.route.app.story(this.props.story.urlId);

    return (
      <Card
        className="story-card"
        isLocked={this.props.story.isLocked}
        isNeverLargeMode={this.props.isNeverLargeMode}
        to={linkTo}
        onClick={this.onClick}
      >
        <div className="card-body">
          <StoryIcon story={this.props.story} />
          <div className="story-name">
            <h1 className="mini-hidden">{this.props.story.name}</h1>
            <p className="mini-only">{this.props.story.name}</p>
          </div>
          <Truncate
            className="story-overview mini-hidden"
            component="p"
            maxLine={2}
            text={this.props.story.overview}
          />
        </div>
        <div className={`card-footer ${isStoryCompleted ? 'completed' : ''}`}>
          <div className="progress-info">
            <h5 className="progress-label bold">
              {userCompletedChapters.length +
                '/' +
                this.props.story.chapters.length +
                ' watched'}
            </h5>
            <ProgressBar
              numerator={userCompletedChapters.length}
              denominator={this.props.story.chapters.length}
            />
          </div>
          <div className="footer-strip completed-strip">
            <p>Completed</p>
            <ReactSVG className="strip-icon" src={Util.icon.tick} />
          </div>
          <div className="footer-strip go-strip">
            <p>Go to Story</p>
            <ReactSVG className="strip-icon" src={Util.icon.arrow.right} />
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(StoryCard);
