import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import DialogueGame from './DialogueGame/DialogueGame';

//data source: this.props.gameId
export default class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      game: null
    };
  }
  componentDidMount() {
    this.fetchContent(this.props.gameId);
  }
  componentWillReceiveProps(props) {
    if (props.gameId && props.gameId !== this.props.gameId)
      this.fetchContent(props.gameId);
  }
  fetchContent(gameId) {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/game/getGameById',
      {
        gameId
      },
      {
        success: game => {
          this.setState({
            game
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (!this.state.game) {
      content = <div className="empty-text">No game to display.</div>;
    } else {
      switch (this.state.game.type) {
        case Util.enum.GameType.Dialogue:
          content = (
            <DialogueGame game={this.state.game} onQuit={this.props.onQuit} />
          );
          break;
        default:
          content = <div>Invalid game.</div>;
          break;
      }
    }

    return <div className="game">{content}</div>;
  }
}
