import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';
import { Link } from 'react-router-dom';

import FeedbackThumbsButton from '../FeedbackThumbsBar/FeedbackThumbsButton/FeedbackThumbsButton';

export default class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbackSubmitted: false
    };

    this.onFeedbackSubmit = this.onFeedbackSubmit.bind(this);
  }
  onFeedbackSubmit() {
    this.setState({
      feedbackSubmitted: true
    });
  }
  render() {
    return (
      <div
        className={`card ${this.props.className || ''} ${
          this.props.isNeverLargeMode ? 'never-big-card' : ''
        } ${this.props.isLocked ? 'locked' : ''} ${
          this.props.isSelected ? 'selected' : ''
        }`}
      >
        {this.props.isLocked ? (
          <ReactSVG className="lock-icon" src={Util.icon.lock} />
        ) : null}
        {this.props.isLocked && this.props.isLockedInterestEnabled ? (
          <div className="interest-panel">
            <p>
              {this.props.interestLabel ||
                `We're building content that matters to you. Show your interest by upvoting.`}
            </p>
            {this.state.feedbackSubmitted ? (
              <p className="submitted-msg">Thank you!</p>
            ) : (
              <FeedbackThumbsButton
                rating={1}
                feedbackType={Util.enum.FeedbackType.LockedContentInterest}
                message={this.props.interestMessage}
                onClick={this.onFeedbackSubmit}
              />
            )}
          </div>
        ) : null}
        <div
          className={`card-inner ${this.props.isLocked ? 'disabled' : ''}`}
          style={this.props.style}
          onClick={this.props.isLocked ? null : this.props.onClick}
        >
          {this.props.to && !this.props.isLocked ? (
            <Link className="link-no-style link-cover" to={this.props.to} />
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}
