import React, { Component } from 'react';
import { connect } from 'react-redux';
import { completedContentAction } from '../../../actions/userActions';
import Util from '../../../Util';

import Button from '../Button/Button';

// Data source: this.props.content (content obj) OR this.props.contentId (fetch) OR htmlContent (raw html)
class ArticlePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content,
      htmlContent: this.props.content
        ? this.props.content.htmlContent
        : this.props.htmlContent,

      isLoading:
        !this.props.content && !this.props.htmlContent && this.props.contentId,
      isFailed: false
    };

    this.onArticlePanelClick = this.onArticlePanelClick.bind(this);
  }
  componentDidMount() {
    //If we have a contentId but not content, try to fetch the content
    if (this.props.contentId && !this.props.content) {
      this.setState({
        isLoading: true
      });

      Util.api.post(
        '/api/content/getContentById',
        {
          contentId: this.props.contentId
        },
        {
          success: content => {
            this.setState({
              content,
              htmlContent: content.htmlContent
            });

            this.props.completedContentAction(content.contentId);
          },
          failure: () => {
            this.setState({
              isFailed: true
            });
          },
          complete: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      );
    }
  }
  onArticlePanelClick(e) {
    // Accordion interaction
    Util.accordion.toggle(e.target);
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (this.state.isFailed) {
      content = <p>Sorry, something went wrong.</p>;
    }

    return (
      <div className="article-panel" onClick={this.onArticlePanelClick}>
        {content}
        {this.state.content &&
        Util.context.user.getIsLedaAdmin() &&
        !this.props.isEditDisabled ? (
          <div className="content-admin-bar">
            <Button
              label="Edit"
              isAdmin={true}
              size={'sm'}
              to={Util.route.admin.contentEditor(this.state.content.urlId)}
            />
          </div>
        ) : null}
        <article
          className={`${this.props.className || ''}`}
          dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { completedContentAction }
)(ArticlePanel);
