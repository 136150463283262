import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticateAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

import AuthLayout from '../../../Layouts/Auth/AuthLayout';

// Components
import ForgotPasswordForm from '../../../UI/Forms/ForgotPasswordForm/ForgotPasswordForm';

class AuthPasswordForgotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false
    };

    this.onSubmitted = this.onSubmitted.bind(this);
  }
  onSubmitted() {}
  render() {
    return (
      <div className="leda-page-auth-password-forgot">
        <AuthLayout>
          <h1>Forgotten password</h1>
          {this.state.isSubmitted ? (
            <div className="form-message">
              <p>Please check your email for further instructions.</p>
            </div>
          ) : (
            <ForgotPasswordForm
              onSubmit={(form, formData) => {
                form.setLoading(true);

                Util.api.post(
                  '/api/auth/triggerForgotPassword',
                  {
                    email: formData.email
                  },
                  {
                    success: result => {
                      Util.analytics.track('PasswordForgot', {
                        email: formData.email
                      });

                      this.setState({
                        isSubmitted: true
                      });
                    },
                    failure: errorMsg => form.setOverallError(errorMsg),
                    complete: () => form.setLoading(false)
                  }
                );
              }}
            />
          )}
          <div className="link-container">
            <Link to={Util.route.auth.login()}>Back to login</Link>
          </div>
        </AuthLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { authenticateAction }
)(AuthPasswordForgotPage);
