import React, { Component } from 'react';

import TagLink from '../TagLink/TagLink';

//this.props.tags
export default class TagList extends Component {
  render() {
    return (
      <div className="tag-list">
        {this.props.tags.map((tag, idx) => (
          <TagLink
            key={idx}
            to={this.props.to}
            getTo={this.props.getTo}
            tag={tag}
          />
        ))}
      </div>
    );
  }
}
