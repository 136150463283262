import React, { Component } from 'react';
import AppFeedbackLayout from '../../../../Layouts/App/AppFeedbackLayout/AppFeedbackLayout';

// Components
import UserFeedbackSummary from '../../../../UI/UserFeedbackSummary/UserFeedbackSummary';
import UserFeedbackRequestPanel from '../../../../UI/UserFeedbackRequestPanel/UserFeedbackRequestPanel';
import Accordion from '../../../../UI/Accordion/Accordion';
import TabPanel from '../../../../UI/TabPanel/TabPanel';

export default class AppFeedbackPage extends Component {
  render() {
    return (
      <div className="leda-page-app-feedback">
        <AppFeedbackLayout pageTitle="Feedback">
          <div className="leda-container-md">
            <div className="leda-row">
              <p>
                Feedback is a great motivator. So why not invite your team,
                colleagues, and peers to give you feedback on your leadership
                skills?
              </p>
              <TabPanel
                tabs={[
                  {
                    label: 'Request feedback',
                    content: <UserFeedbackRequestPanel />
                  },
                  {
                    label: 'Feedback results',
                    content: (
                      <div className="leda-container-sm">
                        <div className="leda-row">
                          <UserFeedbackSummary />
                        </div>
                      </div>
                    )
                  }
                ]}
              />
              <Accordion head={<h4>Learn about feedback</h4>}>
                <h1>How feedback works</h1>
                <div>
                  <ol>
                    <li>
                      <b>Send invitations.</b>
                      <p>
                        Type colleagues' emails into Leda, or copy the link and
                        send it yourself.
                      </p>
                    </li>
                    <li>
                      <b>Get feedback.</b>
                      <p>
                        Review all the feedback as it arrives. It's anonymous,
                        so it's likely to be honest.
                      </p>
                    </li>
                    <li>
                      <b>Act on it.</b>
                      <p>
                        We recommend skills for you to practice based on the
                        feedback.
                      </p>
                    </li>
                  </ol>
                </div>
                <h1>What will you get feedback on?</h1>
                <div>
                  <p>
                    We ask the people you invite to consider the key leadership
                    skills:
                  </p>
                  <ul>
                    <li>Emotional self-awareness</li>
                    <li>Emotional self-control</li>
                    <li>Growth mindset</li>
                    <li>Motivating others</li>
                    <li>Communication and collaboration</li>
                    <li>Empathy</li>
                  </ul>
                  <p>
                    They choose 2 that you do well, and 2 that you could improve
                    on. They may also share their thoughts on your leadership
                    skills and style in their own words.
                  </p>
                </div>
                <h1>Who should you invite?</h1>
                <div>
                  <p>
                    You might consider sending invitations to people who you
                    lead, and peers who work with you, too. A coach, buddy, or
                    trusted adviser in a more senior role than yours may also
                    provide valuable feedback.
                  </p>
                </div>
                <h1>Why does it matter?</h1>
                <div>
                  <p>
                    The people you work with can give an objective view of your
                    leadership skills. That information can help you to focus on
                    learning the skills you can improve. Feedback is great
                    motivator, and it’s important in building a growth mindset.
                  </p>
                </div>
              </Accordion>

              <div className="feedback-help"></div>
            </div>
          </div>
        </AppFeedbackLayout>
      </div>
    );
  }
}
