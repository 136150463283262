import React, { Component } from 'react';
import Util from '../../../../Util';

import Button from '../../../UI/Button/Button';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

import img_PT_Activities from './PT_Activities.png';
import img_PT_Badges from './PT_Badges.png';
import img_PT_Courses from './PT_Courses.png';
import img_PT_Explore from './PT_Explore.png';
import img_PT_Insights from './PT_Insights.png';
import img_PT_Journeys from './PT_Journeys.png';
import img_PT_Mentor from './PT_Mentor.png';
import img_PT_Progress from './PT_Progress.png';
import img_PT_Team from './PT_Team.png';
import img_PT_Timeline from './PT_Timeline.png';

export default class SitePlatformPage extends Component {
  render() {
    const redirection = !!Util.context.user.getCurrent()
      ? Util.route.site.pricing()
      : Util.route.site.contact(); // Go to contact page if user is not authentificated, go to pricing page otherwise
    return (
      <div className="leda-page-site-platform">
        <SiteLayout
          pageTitle="Platform Tour"
          pageSubtitle="Leda takes a fresh approach to teaching leadership skills."
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">
                    Join a Course, or build your own
                  </h4>
                  <p>
                    Join a Leda Course designed to develop specific skills over
                    6 to 12 months. Or, build your own Course to suit your
                    team's needs.
                  </p>
                  {/* <Button isSecondary={true} label="Learn about courses and link" to={Util.route.site.contact()} /> */}
                </div>
                <div className="img-wrapper to-hide">
                  <img src={img_PT_Courses} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">View your Course Plan</h4>
                  <p>
                    Your Course Plan shows start dates for each Journey, and
                    lets you access each one. It also shows your progress, so
                    you'll never get lost.
                  </p>
                </div>
                <div className="img-wrapper">
                  <img src={img_PT_Timeline} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Start a Journey</h4>
                  <p>
                    Click into a Journey to get started. Each step in a Journey
                    includes videos and readings. There are also
                    self-assessments and games! You'll need about 10 minutes to
                    complete a step.
                  </p>
                  <Button
                    isSecondary={true}
                    label="Learn about our journeys"
                    to={Util.route.site.how() + '#journeys-strip'}
                  />
                </div>
                <div className="img-wrapper to-hide">
                  <img src={img_PT_Journeys} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Practice in the real world</h4>
                  <p>
                    Each step in a Journey includes activities: short, specific
                    practices that help you embed what you've learned into every
                    work day.
                  </p>
                </div>
                <div className="img-wrapper">
                  <img src={img_PT_Activities} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">
                    Complete a Journey, earn a badge and unlock the summary
                  </h4>
                  <p>
                    As you complete each Journey, you'll earn a skill badge for
                    your Profile. You'll also unlock a downloadable summary of
                    what you learned.
                  </p>
                </div>
                <div className="img-wrapper to-hide">
                  <img src={img_PT_Badges} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Stay on track</h4>
                  <p>
                    Weekly emails keep you up to date on your progress, and
                    include tips and Leda news too!
                  </p>
                </div>
                <div className="img-wrapper">
                  <img src={img_PT_Progress} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Share with your team</h4>
                  <p>
                    Your team page on Leda lets you discuss what you learn with
                    colleagues, and share how you're using your new skills at
                    work.
                  </p>
                </div>
                <div className="img-wrapper to-hide">
                  <img src={img_PT_Team} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Explore for yourself</h4>
                  <p>
                    Explore the full range of leadership skills through our
                    video stories, games, assessments, and more.
                  </p>
                </div>
                <div className="img-wrapper">
                  <img src={img_PT_Explore} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Track team progress</h4>
                  <p>
                    Team leaders get at-a-glance insights into progress - and
                    sticking points - for everyone in their team.
                  </p>
                </div>
                <div className="img-wrapper to-hide">
                  <img src={img_PT_Insights} alt="" />
                </div>
              </div>
              <div className="platform-row">
                <div className="platform-row-info">
                  <h4 className="bold gilroy">Get full mentor supports</h4>
                  <p>
                    Registered mentors in your organisation receive full
                    summaries of each Journey, including learning outcomes and
                    talking points, to better support your learners.
                  </p>
                </div>
                <div className="img-wrapper">
                  <img src={img_PT_Mentor} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="get-leda-strip">
            <div className="leda-container-lg">
              <div className="leda-row">
                <div className="get-leda-strip-inner">
                  <h3 className="gilroy bold">
                    Grow high-performing teams and leaders
                  </h3>
                  <Button size="xl" label="Get Leda" to={redirection} />
                </div>
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
