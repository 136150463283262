import React, { Component } from 'react';
import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

import Grid from '../../../UI/Grid/Grid';
import JourneyBadge from '../../../UI/JourneyBadge/JourneyBadge';

export default class AdminJourneyListPage extends Component {
  state = {
    journeys: []
  };

  componentDidMount() {
    this.getAllJourneys();
  }

  getAllJourneys = () => {
    Util.api.post(
      '/api/admin/getAllJourneys',
      {},
      {
        success: journeys => {
          this.setState({
            journeys: journeys.filter(({ journeyId }) => journeyId !== 1000)
          });
        },
        failure: () => {
          alert('Error while fetching the journeys');
        }
      }
    );
  };

  render() {
    return (
      <div className="leda-page-admin-activity-list">
        <AdminLayout pageTitle="Journey List">
          <Grid
            data={this.state.journeys}
            searchKeys={['journeyId', 'name', 'overview']}
            columns={[
              {
                name: 'JRN_JourneyId',
                key: 'journeyId',
                sortFn: Util.sort.asNumber
              },
              {
                key: 'journeyId',
                isSortDisabled: true,
                render: (val, record) => <JourneyBadge journey={record} />
              },
              {
                name: 'Created At',
                key: 'createdAt',
                sortFn: Util.sort.asDate,
                render: val => Util.format.date.shortDateTime(val)
              },
              {
                name: 'Name',
                key: 'name'
              },
              {
                name: 'Overview',
                key: 'overview'
              },
              {
                name: 'HtmlSummary',
                key: 'htmlSummary',
                isSortDisabled: true,
                render: val => (
                  <section dangerouslySetInnerHTML={{ __html: val }} />
                )
              },
              {
                name: 'Is Locked',
                key: 'isLocked',
                sortFn: Util.sort.asBoolean
              },
              {
                name: 'Is Featured',
                key: 'isFeatured',
                sortFn: Util.sort.asBoolean
              },
              {
                name: 'Is Global',
                key: 'isGlobal',
                sortFn: Util.sort.asBoolean
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}
