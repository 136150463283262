import React, { Component } from 'react';
import Util from '../../../../../Util';
import withForm from '../../withForm';

import Button from '../../../Button/Button';
import Grid from '../../../Grid/Grid';
import Dropdown from '../../../Dropdown/Dropdown';

class AdminContentEditorForm extends Component {
  constructor(props) {
    super(props);

    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }
  addTag(tagId, tag) {
    let existingTags = this.props.formData.tags || [];

    //If dropdown empty or tag is already in the array, don't add it again
    if (!tag || Util.array.firstIdMatch(existingTags, tag.tagId, 'tagId'))
      return;

    this.props.updateFormData('tags', [...existingTags, tag]);
  }
  removeTag(tagId) {
    this.props.updateFormData(
      'tags',
      this.props.formData.tags.filter(tag => tag.tagId !== tagId)
    );
  }
  render() {
    return (
      <form className="form-content-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('name')}
        {!this.props.formData.contentId ? this.props.getField('urlId') : null}
        <div className="form-row">
          {this.props.getField('isPublic')}
          {this.props.formData.urlId && this.props.formData.isPublic ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={Util.route.site.articlesContent(this.props.formData.urlId)}
            >{`${window.location.hostname}/articles/${this.props.formData.urlId}`}</a>
          ) : null}
        </div>
        {this.props.getField('isFeatured')}
        {this.props.getField('authorLabel')}
        {this.props.getField('overview')}
        {this.props.getField('featureImageBlobId')}
        {this.props.getField('featureImageCaption')}
        {this.props.getField('htmlContent')}
        <Grid
          title="Tags for this content"
          data={this.props.formData.tags}
          toolbarItems={[
            <Dropdown
              optionsUrl={'/api/admin/getAllTags'}
              valueProp={'tagId'}
              displayProp={'name'}
              isEmptyAllowed={true}
              isEmptyOnChange={true}
              placeholder="Select a tag to add"
              onChange={this.addTag}
            />
          ]}
          columns={[
            {
              name: 'Name',
              key: 'name',
              width: '100%'
            },
            {
              key: 'tagId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <div className="button-container">
                    <Button
                      icon={Util.icon.bin}
                      size="sm"
                      isSecondary={true}
                      onClick={() => this.removeTag(val)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
          <Button
            label="Cancel"
            isSecondary={true}
            to={Util.route.admin.contentList()}
          />
        </div>
      </form>
    );
  }
}

export default withForm(AdminContentEditorForm, {
  fields: {
    name: {
      label: 'Name'
    },
    isPublic: {
      label: 'IsPublic',
      type: Util.enum.FieldType.Checkbox
    },
    isFeatured: {
      label: 'IsFeatured',
      type: Util.enum.FieldType.Checkbox
    },
    authorLabel: {
      label: 'Author Label',
      isOptional: true
    },
    overview: {
      label: 'Overview',
      type: Util.enum.FieldType.Textarea
    },
    urlId: {
      label: 'URL ID',
      getError: val => {
        if (!Util.id.urlId.isUrlIdFormat(val))
          return 'URL ID can only container lowercase letters and hyphens (looks-like-this)';
      }
    },
    featureImageBlobId: {
      label: 'Feature Image',
      type: Util.enum.FieldType.FileUpload,
      allowedExtensions: ['.png', '.jpg', '.jpeg'],
      uploadUrl: '/api/file/uploadImages',
      fieldFileProperty: 'blobId',
      isImage: true,
      isOptional: true
    },
    featureImageCaption: {
      label: 'Feature Image Caption',
      type: Util.enum.FieldType.Text,
      isOptional: true
    },
    htmlContent: {
      label: 'Content',
      type: Util.enum.FieldType.HtmlEditor
    }
  }
});
