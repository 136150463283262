import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';
import Util from '../../../Util';

//this.props.files
class FileList extends Component {
  render() {
    return (
      <div className="file-list">
        {this.props.files.map(file => {
          return (
            <div key={file.fileId} className="file-item">
              <a
                className="file-item-inner"
                href={Util.storage.blob(file.blobId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG
                  className="file-item-link-icon"
                  src={Util.icon.attachment}
                />
                {file.fileName}
              </a>
              {this.props.onRemoveFile ? (
                <ReactSVG
                  className="remove-button"
                  src={Util.icon.close}
                  onClick={() => this.props.onRemoveFile(file.fileId)}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction }
)(FileList);
