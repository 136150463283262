import React, { Component } from 'react';
import Util from '../../../../Util';

import AppLayout from '../AppLayout';

export default class AppFeedbackLayout extends Component {
  render() {
    return (
      <AppLayout pageIcon={Util.icon.feedback} {...this.props}>
        {this.props.children}
      </AppLayout>
    );
  }
}
