import React, { Component } from 'react';

import SiteArticlesLayout from '../../../../Layouts/Site/SiteArticlesLayout/SiteArticlesLayout';

// Components
import ArticlesBrowseView from '../../../../UI/ArticlesBrowseView/ArticlesBrowseView';

//data source: this.props.tagId
export default class SiteArticlesBrowsePage extends Component {
  render() {
    return (
      <div className="leda-page-site-articles-browse">
        <SiteArticlesLayout pageTitle="Articles" wrapperCls="grey-6">
          <div className="leda-container-md">
            <div className="leda-row">
              <ArticlesBrowseView
                key={this.props.tagId}
                tagId={this.props.tagId}
              />
            </div>
          </div>
        </SiteArticlesLayout>
      </div>
    );
  }
}
