import {
  APP_USER_AUTHENTICATED,
  APP_USER_LOGGED_OUT,
  ACT_ADDED_MY_ACTIVITY,
  ACT_COMPLETED_MY_ACTIVITY,
  ACT_COMPLETED_MY_ACTIVITY_TASK,
  ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS,
  ACT_DELETED_MY_ACTIVITY
} from '../actions/types';

const initialState = {
  myActivities: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_USER_AUTHENTICATED:
      return {
        ...state,
        myActivities: action.myActivities
      };

    case APP_USER_LOGGED_OUT:
      return initialState; // Reset the entire state to the initialState

    case ACT_ADDED_MY_ACTIVITY:
      return {
        ...state,
        // Add the new myActivity to myActivities
        myActivities: [action.myActivity, ...state.myActivities]
      };

    case ACT_DELETED_MY_ACTIVITY:
      return {
        ...state,
        // Remove the deleted myactivity
        myActivities: [
          ...state.myActivities.filter(
            myAct => myAct.myActivityId !== action.myActivityId
          )
        ]
      };

    case ACT_COMPLETED_MY_ACTIVITY:
      return {
        ...state,
        myActivities: state.myActivities.map(myAct => {
          return myAct.myActivityId !== action.myActivityId
            ? myAct
            : {
                ...myAct,
                completedAt: new Date()
              };
        })
      };

    case ACT_COMPLETED_MY_ACTIVITY_TASK:
      return {
        ...state,
        myActivities: state.myActivities.map(myAct => {
          return myAct.myActivityId !== action.myActivityId
            ? myAct
            : {
                ...myAct,
                myActivityTasks: myAct.myActivityTasks.map(myActivityTask => {
                  return myActivityTask.myActivityTaskId !==
                    action.myActivityTaskId
                    ? myActivityTask
                    : {
                        ...myActivityTask,
                        completedAt: new Date()
                      };
                })
              };
        })
      };

    case ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS:
      return {
        ...state,
        myActivities: state.myActivities.map(myAct => {
          return myAct.myActivityId !== action.myActivityId
            ? myAct
            : {
                ...myAct,
                myActivityTasks: myAct.myActivityTasks.map(myActivityTask => {
                  return myActivityTask.myActivityTaskId !==
                    action.myActivityTaskId
                    ? myActivityTask
                    : {
                        ...myActivityTask,
                        myActivityTaskFields: action.myActivityTaskFields
                      };
                })
              };
        })
      };

    default:
      return state;
  }
}
