import React, { Component } from 'react';
import Util from '../../../../Util';

//components
import Accordion from '../../Accordion/Accordion';
import JourneyStepItemView from '../JourneyStepItemView/JourneyStepItemView';
import CardsView from '../../CardsView/CardsView';
import ActivityCard from '../../Card/ActivityCard/ActivityCard';
import JourneyStepStatus from '../../JourneyStepStatus/JourneyStepStatus';

export default class JourneyStepView extends Component {
  constructor(props) {
    super(props);

    this.onStepItemClick = this.onStepItemClick.bind(this);
    this.getStepItemRef = this.getStepItemRef.bind(this);
  }
  getStepItemRef(stepItemId) {
    return `step-item-${stepItemId}`;
  }
  onStepItemClick(stepItemId) {
    let panel = this.refs[this.getStepItemRef(stepItemId)];

    //Open the accordion if it isn't open
    let parentElement = panel.parentElement;
    if (panel.parentElement.classList.contains('accordion-head')) {
      if (!Util.accordion.isOpen(parentElement)) {
        Util.accordion.toggle(parentElement);
        setTimeout(() => Util.scroll.toElement(parentElement), 400);
      } else {
        Util.scroll.toElement(parentElement);
      }
    } else {
      //The activity panel is not an accordion
      Util.scroll.toElement(panel);
    }

    Util.analytics.track('JourneyStepItemClicked', {
      stepItemId: stepItemId
    });
  }
  render() {
    let step = this.props.step;

    let mainStepItems = step.stepItems.filter(stepItem => !stepItem.isSupport);
    let supportStepItems = step.stepItems.filter(
      stepItem => stepItem.isSupport
    );

    return (
      <div className="step-panel">
        <div className="leda-container-sm">
          <div className="leda-row">
            <div className="step-item step-intro">
              <h1 className="step-item-name gilroy">{step.name}</h1>
              <div className="step-overview">
                <section className="gilroy">
                  <p>{step.overview}</p>
                </section>
              </div>
              <div className="step-item-menu">
                {mainStepItems.map(stepItem => (
                  <div className="step-item-menu-row" key={stepItem.stepItemId}>
                    <button 
                      className="link-button"
                      onClick={() => this.onStepItemClick(stepItem.stepItemId)}
                    >
                      <span className="bold">{stepItem.actionName}</span> -{' '}
                      {stepItem.name}
                    </button>
                  </div>
                ))}
                {Util.array.any(supportStepItems) ? (
                  <div className="step-item-menu-row">
                    <button className="link-button" onClick={() => this.onStepItemClick('explore')}>
                      <span className="bold">Explore</span> - Additional content
                      (optional)
                    </button>
                  </div>
                ) : null}
                <div className="step-item-menu-row">
                  <button className="link-button" onClick={() => this.onStepItemClick('practice')}>
                    <span className="bold">Practice</span> - Complete an
                    activity to finish this step
                  </button>
                </div>
              </div>
            </div>
            {mainStepItems.map(stepItem => (
              <Accordion
                key={stepItem.stepItemId}
                head={
                  <h4
                    ref={this.getStepItemRef(stepItem.stepItemId)}
                  >{`${stepItem.actionName}: ${stepItem.name}`}</h4>
                }
                isOpen={true}
              >
                <JourneyStepItemView
                  key={stepItem.stepItemId}
                  stepItem={stepItem}
                />
              </Accordion>
            ))}
            {Util.array.any(supportStepItems) ? (
              <Accordion
                isOpen={false}
                head={
                  <h4 ref={this.getStepItemRef('explore')}>
                    Explore: Additional content (optional)
                  </h4>
                }
              >
                {supportStepItems.map(stepItem => (
                  <JourneyStepItemView
                    key={stepItem.stepItemId}
                    stepItem={stepItem}
                  />
                ))}
              </Accordion>
            ) : null}
          </div>
        </div>
        <div
          className="step-item step-activities"
          ref={this.getStepItemRef('practice')}
        >
          <div className="leda-container-sm">
            <div className="leda-row">
              <h1 className="step-item-name gilroy">Activity</h1>
              <div className="step-status">
                <JourneyStepStatus step={step} isLabelHidden={true} />
                <JourneyStepStatus
                  className="status-message"
                  step={step}
                  isIconHidden={true}
                  readyToStartText={
                    <p>
                      To finish this step, practice{' '}
                      {Util.format.pluralise(
                        step.activities,
                        'this activity',
                        'these activities'
                      )}
                      .
                    </p>
                  }
                  inProgressText={
                    <p>
                      To finish this step, complete{' '}
                      {Util.format.pluralise(step.activities, 'this', 'an')}{' '}
                      activity.
                    </p>
                  }
                  finishedText={
                    <p>Congratulations! You can move to the next step.</p>
                  }
                />
              </div>
              <CardsView>
                {step.activities.map(activity => (
                  <ActivityCard
                    key={activity.activityId}
                    activity={activity}
                    journeyStepId={step.stepId}
                  />
                ))}
              </CardsView>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
