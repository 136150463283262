import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppLayout from '../../../../Layouts/App/AppLayout';
import Post from '../../../../UI/Post/Post';
import Button from '../../../../UI/Button/Button';

//this.props.postId or this.props.post
export default class AppPostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: this.props.post,
      isLoading: !this.props.post
    };
  }
  componentWillReceiveProps(props) {
    if (
      props.postId &&
      (!this.state.post ||
        (this.state.post &&
          parseInt(props.postId, 10) !== this.state.post.postId))
    )
      this.fetchData(parseInt(props.postId, 10));
  }
  componentDidMount() {
    if (!this.props.post) this.fetchData(parseInt(this.props.postId, 10));
  }
  fetchData(postId) {
    this.setState({
      isLoading: true
    });

    if (postId) {
      Util.api.post(
        '/api/post/getPostById',
        {
          postId: postId
        },
        {
          success: post => {
            this.setState({
              post
            });
          },
          complete: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      );
    }
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = (
        <div className="post-container">
          <div className="loader"></div>
        </div>
      );
    } else {
      content = (
        <div className="post-container">
          {!this.state.post ? (
            <p>This post is no longer availble.</p>
          ) : (
            <Post isCommentsVisible={true} post={this.state.post} />
          )}
          <Button
            to={Util.route.app.team()}
            label={`Back to ${Util.context.user.getOrganisationName()} home`}
          />
        </div>
      );
    }

    return (
      <div className="leda-page-app-post">
        <AppLayout>
          <div className="leda-container-sm">
            <div className="leda-row">{content}</div>
          </div>
        </AppLayout>
      </div>
    );
  }
}
