import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../../../Util';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon
} from 'react-share';

import SiteArticlesLayout from '../../../../Layouts/Site/SiteArticlesLayout/SiteArticlesLayout';

// Components
import ArticlePanel from '../../../../UI/ArticlePanel/ArticlePanel';
import ContentCardsView from '../../../../UI/CardsView/ContentCardsView/ContentCardsView';
import TagList from '../../../../UI/TagList/TagList';
import Button from '../../../../UI/Button/Button';

//data source: this.props.contentId
export default class SiteArticlesContentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      content: null,
      relatedContents: []
    };
  }
  componentDidMount() {
    this.fetchContent(this.props.contentId);
  }
  componentWillReceiveProps(props) {
    if (props.contentId && props.contentId !== this.props.contentId)
      this.fetchContent(props.contentId);
  }
  fetchContent(contentId) {
    this.setState({
      isLoading: true,

      content: null,
      relatedContents: []
    });

    Util.api.post(
      '/api/public/getPublicContentByIdWithRelated',
      {
        contentId
      },
      {
        success: result => {
          this.setState({
            content: result.content,
            relatedContents: result.relatedContents
          });

          //Set open graph data for sharing
          if (result.content)
            Util.document.setMeta(
              result.content.name,
              result.content.overview,
              Util.storage.blob(result.content.featureImageBlobId)
            );
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (!this.state.content) {
      content = <div className="empty-text">No content to display.</div>;
    } else {
      content = (
        <div className="content-container">
          <section className="gilroy">
            <h1>{this.state.content.name}</h1>
            <div className="content-subtitle">
              {this.state.content.authorLabel ? (
                <h5>{this.state.content.authorLabel}</h5>
              ) : null}
              <h5>
                {Util.format.date.dayMonthYear(this.state.content.createdAt)}
              </h5>
              <h5>
                <ReactSVG className="clock-icon" src={Util.icon.clock} />
                {Util.format.readingTime(this.state.content.htmlContent)}
              </h5>
            </div>
            {this.state.content.featureImageBlobId ? (
              <img
                alt={this.state.content.featureImageCaption}
                className="feature-image"
                src={Util.storage.blob(this.state.content.featureImageBlobId)}
              />
            ) : null}
            <h5 className="grey-3">{this.state.content.featureImageCaption}</h5>
          </section>
          <ArticlePanel
            className="gilroy"
            content={this.state.content}
            isEditable={true}
          />
          {Util.array.any(this.state.content.tags) ? (
            <div className="tags">
              <ReactSVG className="tag-icon" src={Util.icon.tag} />
              <TagList
                tags={this.state.content.tags}
                getTo={tag => Util.route.site.articlesTag(tag.urlId)}
              />
            </div>
          ) : null}
          <div className="share-footer">
            <div className="button-container">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <LinkedinShareButton url={window.location.href}>
                <LinkedinIcon size={40} />
              </LinkedinShareButton>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={40} />
              </TwitterShareButton>
            </div>
          </div>
          <div className="call-to-action">
            <div>
              <h4 className="gilroy white">
                Nothing beats good training. Get the best.
              </h4>
            </div>
            <Button label="Get Leda" to={Util.route.site.contact()} />
          </div>
        </div>
      );
    }

    return (
      <div className="leda-page-site-articles-content">
        <SiteArticlesLayout>
          <div className="share-sidebar">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={50} />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={50} />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={50} />
            </TwitterShareButton>
          </div>
          <div className="leda-container-sm">
            <div className="leda-row">{content}</div>
          </div>
          {Util.array.any(this.state.relatedContents) ? (
            <div className="leda-wrapper grey-6">
              <div className="leda-container-md">
                <div className="leda-row">
                  <div className="related-articles">
                    <h4 className="gilroy">Related articles</h4>
                    <ContentCardsView contents={this.state.relatedContents} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </SiteArticlesLayout>
      </div>
    );
  }
}
