import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

// Components
import SkillCardsView from '../../../../UI/CardsView/SkillCardsView/SkillCardsView';
import StoryCardsView from '../../../../UI/CardsView/StoryCardsView/StoryCardsView';
// import ActivityCardsView from '../../../../UI/CardsView/ActivityCardsView/ActivityCardsView';
// import AssessmentCardsView from '../../../../UI/CardsView/AssessmentCardsView/AssessmentCardsView';
// import GameCardsView from '../../../../UI/CardsView/GameCardsView/GameCardsView';
import Button from '../../../../UI/Button/Button';

export default class AppExplorePage extends Component {
  constructor(props) {
    super(props);

    // Select some random skills
    let getRandomFive = array => {
      let arrayCopy = [...array];
      return arrayCopy.length <= 5
        ? arrayCopy
        : Util.array.shuffle(arrayCopy).splice(0, 5);
    };

    //When this stuff was in render() it would change every re-render (everytime notifications came back etc)
    this.state = {
      skills: getRandomFive(
        Util.context.referenceData.getSkills().filter(skill => !skill.isLocked)
      ),
      stories: getRandomFive(Util.context.referenceData.getStories()),
      games: getRandomFive(Util.context.referenceData.getGames())
    };
  }
  render() {
    let getExplorePanel = (title, subtitle, icon, content, viewMoreTo) => {
      return (
        <div className="explore-panel">
          <div className="panel-title">
            <ReactSVG className="panel-icon" src={icon} />
            <h1>{title}</h1>
          </div>
          <p className="panel-subtitle">{subtitle}</p>
          {content}
          <Button
            size="sm"
            className="view-more-button"
            to={viewMoreTo}
            label={`Explore ${title}`}
            isSecondary={true}
          />
        </div>
      );
    };

    return (
      <div className="leda-page-app-explore">
        <AppExploreLayout
          pageTitle="Explore"
          pageSubtitle="Explore Leda content at your own pace, or revisit your favourite games and stories."
        >
          <div className="leda-container-md">
            <div className="leda-row">
              {getExplorePanel(
                'Skills',
                'Learn about the key skills great leaders rely on every day.',
                Util.icon.skill,
                <SkillCardsView skills={this.state.skills} />,
                Util.route.app.skills()
              )}
              {getExplorePanel(
                'Stories',
                "It's just another day in the office. Meet real-life characters and learn how they — and you! — can build better leadership practices.",
                Util.icon.story,
                <StoryCardsView stories={this.state.stories} />,
                Util.route.app.stories()
              )}
              {/* {getExplorePanel("Activities", "Activities help you put specific leadership behaviours into practice, daily. Mark each one as complete when you're done.",Util.icon.activity, <ActivityCardsView dataUrl={'/api/activity/getFeaturedActivities'} dataParams={{ limit: 5 }} />, Util.route.app.activities())} */}
              {/* {getExplorePanel("Assessments", "Test your skills with our self-assessments, and pinpoint your potential to become a stronger leader.",Util.icon.assessment, <AssessmentCardsView dataUrl={'/api/assessment/getFeaturedAssessments'} dataParams={{ limit: 2 }} />, Util.route.app.assessments())} */}
              {/* {getExplorePanel("Games", "Put your Leda lessons into practice with some games before you unleash them on your colleagues.", Util.icon.game, <GameCardsView games={this.state.games} />, Util.route.app.games())} */}
            </div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
