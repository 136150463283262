import React, { Component } from 'react';

import ArticlePanel from '../ArticlePanel/ArticlePanel';
import { Editor } from '@tinymce/tinymce-react';
import TabPanel from '../TabPanel/TabPanel';
import TextArea from 'react-textarea-autosize';

//data: this.props.value
export default class HtmlEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }
  handleEditorChange(value) {
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(value);
  }
  getValue() {
    return this.state.value;
  }
  render() {
    return (
      <div className="html-editor">
        <TabPanel
          tabs={[
            {
              label: 'Editor',
              content: (
                <Editor
                  apiKey="6160z4ynhq0ml6imjmvnmuzxb1or1gcxj46ci121djstr3l8"
                  value={this.state.value}
                  onEditorChange={this.handleEditorChange}
                  init={{
                    menubar: false,
                    plugins: 'image lists textcolor table link hr',
                    toolbar:
                      'undo redo | removeformat | formatselect | alignleft aligncenter alignright | bold italic underline forecolor | bullist numlist link blockquote hr | table | image | accordion',
                    height: '500px',
                    setup: editor => {
                      editor.addButton('accordion', {
                        text: 'Accordion',
                        icon: false,
                        onclick: function() {
                          editor.insertContent(`&nbsp;
											<div class="accordion">
												<div class="accordion-head">
												Accordion Head
												</div>
												<div class="accordion-body">
													Accordion Body
												</div>
											</div>&nbsp;`);
                        }
                      });
                    },
                    textcolor_map: [
                      '4D4D4D',
                      'Leda Black',

                      '666767',
                      'Leda Grey 1',
                      '828282',
                      'Leda Grey 2',
                      'B2B3B3',
                      'Leda Grey 3',
                      'CACDCD',
                      'Leda Grey 4',
                      'EFEFF1',
                      'Leda Grey 5',
                      'F8F8FA',
                      'Leda Grey 6',

                      'E2E7F7',
                      'Leda Blue-Grey 1',
                      'F1F3FA',
                      'Leda Blue-Grey 2',
                      'F9FAFE',
                      'Leda Blue-Grey 3',

                      '61C0E0',
                      'Leda Blue',
                      '30A1C9',
                      'Leda Dark Blue'
                    ]
                  }}
                />
              )
            },
            {
              label: 'Preview',
              content: (
                <ArticlePanel
                  className="gilroy"
                  isEditDisabled={true}
                  content={{ htmlContent: this.state.value }}
                />
              )
            },
            {
              label: 'Raw HTML',
              onClick: this.onPreviewClick,
              content: (
                <TextArea
                  value={this.state.value}
                  onChange={e => this.handleEditorChange(e.target.value)}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}
