import {
  APP_USER_AUTHENTICATED,
  NTF_REFRESHED_NOTIFICATIONS,
  NTF_SEEN_NOTIFICATIONS,
  NTF_ACTIONED_NOTIFICATION
} from '../actions/types';
import Util from '../Util';

const initialState = {
  userNotifications: []
};

export default function(state = initialState, action) {
  let newState = state;

  switch (action.type) {
    case APP_USER_AUTHENTICATED:
      newState = {
        ...state,
        userNotifications: action.userNotifications
      };
      break;

    case NTF_REFRESHED_NOTIFICATIONS:
      let existingUserNotificationIds = state.userNotifications.map(
        userNotification => userNotification.userNotificationId
      );

      newState = {
        ...state,
        userNotifications: [
          ...state.userNotifications,
          ...action.userNotifications.filter(
            userNotification =>
              !existingUserNotificationIds.includes(
                userNotification.userNotificationId
              )
          )
        ]
      };
      break;

    case NTF_SEEN_NOTIFICATIONS:
      newState = {
        ...state,
        userNotifications: state.userNotifications.map(userNotification => {
          return action.userNotificationIds.includes(
            userNotification.userNotificationId
          ) && !userNotification.seenAt
            ? {
                ...userNotification,
                seenAt: new Date()
              }
            : userNotification;
        })
      };
      break;

    case NTF_ACTIONED_NOTIFICATION:
      newState = {
        ...state,
        userNotifications: state.userNotifications.map(userNotification => {
          return action.userNotificationId ===
            userNotification.userNotificationId && !userNotification.actionedAt
            ? {
                ...userNotification,
                actionedAt: new Date()
              }
            : userNotification;
        })
      };
      break;

    default:
      break;
  }

  Util.route.setPageTitle(state.userNotifications);
  return newState;
}
