import React, { Component } from 'react';
import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

import AdminActivityEditorForm from '../../../UI/Forms/Admin/AdminActivityEditorForm/AdminActivityEditorForm';

export default class AdminActivityEditorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !!this.props.activityId,
      activity: null
    };
  }
  componentDidMount() {
    if (this.props.activityId) this.fetchData(this.props.activityId);
  }
  fetchData(activityId) {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/activity/getActivityById',
      {
        activityId
      },
      {
        success: activity => {
          this.setState({
            isLoading: false,
            activity
          });
        }
      }
    );
  }
  render() {
    return (
      <div className="leda-page-admin-activity-editor">
        <AdminLayout
          className="Activity Editor"
          pageTitle={`${!this.state.activity ? 'Create' : 'Edit'} activity`}
        >
          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <AdminActivityEditorForm
              formData={this.state.activity}
              onSubmit={(form, formData) => {
                form.setLoading(true);

                Util.id.tempId.clearAllTempIds(formData);

                Util.api.post(
                  '/api/admin/saveActivity',
                  {
                    activity: formData
                  },
                  {
                    success: activity => {
                      alert('Activity saved.');
                      form.setFormData(activity);
                      this.setState({
                        activity
                      });
                    },
                    failure: errorMsg => form.setOverallError(errorMsg),
                    complete: () => form.setLoading(false)
                  }
                );
              }}
            />
          )}
        </AdminLayout>
      </div>
    );
  }
}
