import {
  APP_USER_AUTHENTICATED,
  JRN_STARTED_JOURNEY,
  JRN_COMPLETED_JOURNEY
} from '../actions/types';

const initialState = {
  userJourneys: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_USER_AUTHENTICATED:
      return {
        ...state,
        userJourneys: action.userJourneys
      };

    case JRN_STARTED_JOURNEY:
      return {
        ...state,
        // Add the new userJourney to userJourneys
        userJourneys: [action.userJourney, ...state.userJourneys]
      };

    case JRN_COMPLETED_JOURNEY:
      return {
        ...state,
        userJourneys: state.userJourneys.map(userJourney => {
          return userJourney.userJourneyId !== action.userJourneyId
            ? userJourney
            : { ...userJourney, completedAt: new Date() };
        })
      };

    default:
      return state;
  }
}
