import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import AdminTeamPresetEditorForm from '../../../UI/Forms/Admin/AdminTeamPresetEditorForm/AdminTeamPresetEditorForm';
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb';

import AdminTeamPresetJourneys from '../AdminTeamPresetJourneys/AdminTeamPresetJourneys';
import AdminTeamPresetPosts from '../AdminTeamPresetPosts/AdminTeamPresetPosts';

export default class AdminTeamPresetEditor extends Component {
  constructor(props) {
    super(props);

    const presetId = props.location.state
      ? props.location.state.presetId
      : window.location.href.split('/')[5];

    this.state = {
      selectedTab: 'journeys',
      presetId
    };
  }

  componentDidMount() {
    Util.api.post(
      '/api/admin/getPresetById',
      {
        presetId: this.state.presetId
      },
      {
        success: preset => {
          this.setState({ preset });
        }
      }
    );
  }

  render() {
    const presetId = (this.state.preset || {}).presetId;
    return (
      <div className="leda-page-app-team-settings">
        <BreadCrumb />
        <AdminLayout pageTitle="Preset Settings">
          <div className="leda-container-md">
            <div className="leda-row">
              <AdminTeamPresetEditorForm
                key={this.state.preset}
                formData={this.state.preset}
                onSubmit={(form, formData) => {
                  form.setLoading(true);

                  Util.id.tempId.clearAllTempIds(formData);

                  Util.api.post(
                    '/api/admin/updatePreset',
                    {
                      preset: {
                        name: formData.name,
                        description: formData.description,
                        presetId: this.state.preset.presetId
                      }
                    },
                    {
                      success: team => {
                        alert('Preset saved.');
                        form.setFormData(team);
                        this.setState({
                          team: team
                        });
                      },
                      failure: errorMsg => form.setOverallError(errorMsg),
                      complete: () => form.setLoading(false)
                    }
                  );
                }}
              />
            </div>
          </div>
        </AdminLayout>
        <AdminLayout>
          <div className="select-panel-container">
            <div
              className={
                'select-panel ' +
                (this.state.selectedTab === 'journeys' ? 'selected' : '')
              }
            >
              <Link
                className="select-title"
                to={`/admin/team-presets/${presetId}`}
                onClick={() => this.setState({ selectedTab: 'journeys' })}
              >
                JOURNEYS
              </Link>
            </div>
            <div
              className={
                'select-panel ' +
                (this.state.selectedTab === 'posts' ? 'selected' : '')
              }
            >
              <Link
                className="select-title"
                to={`/admin/team-presets/${presetId}/posts`}
                onClick={() => this.setState({ selectedTab: 'posts' })}
              >
                POSTS
              </Link>
            </div>
          </div>
          <div>
            <Switch>
              <Route
                exact
                path={`/admin/team-presets/${presetId}`}
                render={props => (
                  <AdminTeamPresetJourneys
                    {...props}
                    journeys={this.state.preset.journeys}
                    presetId={this.state.presetId}
                  />
                )}
              />
              <Route
                exact
                path={`/admin/team-presets/${presetId}/posts`}
                render={() => (
                  <AdminTeamPresetPosts
                    posts={this.state.preset.posts}
                    presetId={this.state.presetId}
                  />
                )}
              />
            </Switch>
          </div>
          <div style={{ height: 200 }}></div>
        </AdminLayout>
      </div>
    );
  }
}
