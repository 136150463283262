import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import CardsView from '../CardsView';
import ContentCard from '../../Card/ContentCard/ContentCard';

//data source: this.props.contents
export default class ContentCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.contents,
      contents: this.props.contents || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
    if (this.props.contents !== props.contents) {
      this.setState({
        contents: props.contents
      });
    }
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: contents => {
        this.setState({
          contents
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) content = <div className="loader"></div>;

    return (
      <CardsView className="content-cards-view">
        {content}
        {Util.array.any(this.state.contents)
          ? this.state.contents.map(content => (
              <div key={content.contentId} className="card-wrapper">
                <ContentCard content={content} />
              </div>
            ))
          : null}
      </CardsView>
    );
  }
}
