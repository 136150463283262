import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';
import { logoutAction } from '../../../actions/userActions';
import Util from '../../../Util';

//Components
import BadgeCount from '../BadgeCount/BadgeCount';
import UserNotificationList from '../UserNotificationList/UserNotificationList';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import LedaLogo from '../LedaLogo/LedaLogo';
import ContextMenu from '../ContextMenu/ContextMenu';

class AppHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNotificationsOpen: false
    };

    this.notificationsHoverRef = React.createRef();

    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onMyActivitiesButtonClick = this.onMyActivitiesButtonClick.bind(this);
    this.onNotificationsButtonClick = this.onNotificationsButtonClick.bind(
      this
    );
    this.setIsNotificationsPopupOpen = this.setIsNotificationsPopupOpen.bind(
      this
    );
    this.onBodyClick = this.onBodyClick.bind(this);
  }
  onLogoutClick(e) {
    Util.analytics.track('UserLogout');

    this.props.logoutAction();
    window.location.href = '/'; //Intentional. we want to redirect to home and re-authenticate without a thenTo reditect.
  }
  onMyActivitiesButtonClick() {
    this.props.openModalAction({ type: Util.enum.ModalType.MyActivitiesModal });
  }
  onNotificationsButtonClick() {
    if (Util.css.breakpoint.overxs()) {
      this.setIsNotificationsPopupOpen(!this.state.isNotificationsOpen);
    } else {
      this.props.openModalAction({
        type: Util.enum.ModalType.UserNotificationsModal,
        data: {
          userNotifications: this.props.userNotifications
        }
      });
    }
  }
  setIsNotificationsPopupOpen(isOpen) {
    if (isOpen && !this.state.isNotificationsOpen) {
      Util.analytics.track('NotificationsOpened');
      document.addEventListener('mousedown', this.onBodyClick);

      this.setState({
        isNotificationsOpen: true
      });
    } else if (!isOpen && this.state.isNotificationsOpen) {
      document.removeEventListener('mousedown', this.onBodyClick);

      this.setState({
        isNotificationsOpen: false
      });
    }
  }
  onBodyClick(e) {
    if (this.notificationsHoverRef.current.contains(e.target)) return;
    this.setIsNotificationsPopupOpen(false);
  }
  render() {
    let myActCount = this.props.myActivities.filter(myAct => !myAct.completedAt)
      .length;
    let unseenUserNotifications = this.props.userNotifications.filter(
      userNotification => !userNotification.seenAt
    );

    // A notifications popup bubble thing that only displays on larger devices. Otherwise, goes straight to the modal version
    let notificationsPopup = (
      <div className="notifications-popup">
        <div className="notifications-popup-inner">
          <div className="notifications-upper">
            <p className="notifications-count">Notifications</p>
            <div className="flex-spacer"></div>
            <ReactSVG
              className="close-button"
              onClick={() => this.setIsNotificationsPopupOpen(false)}
              src={Util.icon.close}
            />
          </div>
          <UserNotificationList
            onItemClick={() => this.setIsNotificationsPopupOpen(false)}
            userNotifications={this.props.userNotifications}
          />
          {/* <Link className="view-all-link" to={Util.route.app.home()}>View All</Link> */}
        </div>
      </div>
    );

    return (
      <div className="leda-app-header sticky-header">
        {Util.context.user.getIsTrialLicense() ? (
          <div className="trial-header">
            <div className="leda-container">
              <div className="leda-row">
                <div className="trial-header-inner">
                  <ReactSVG className="trial-icon" src={Util.icon.treasure} />
                  <div className="trial-message">
                    <h3 className="trial-label white">TRIAL</h3>{' '}
                    <p>
                      |{' '}
                      {!!Util.context.user.getLicenseExpiry()
                        ? Util.context.user.getLicenceHeaderText(
                            Util.context.user.getLicenseExpiry()
                          )
                        : 'Unlimited'}
                    </p>
                  </div>
                  <Link
                    className="trial-header-link"
                    to={Util.route.site.platform()}
                  >
                    Platform Tour
                  </Link>
                  <Link
                    className="trial-header-link"
                    to={Util.route.site.how() + '#journeys-strip'}
                  >
                    Our Journeys
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="leda-container">
          <div className="leda-row">
            <div className="leda-header-inner">
              <button
                className="toggle-nav link-button"
                onClick={this.props.toggleNav}
              >
                <ReactSVG className="toggle-nav-icon" src={Util.icon.menu} />
              </button>
              <LedaLogo to={Util.route.app.home()} />
              <div className="header-links">
                <div className="flex-spacer"></div>
                <div className="header-sticker">
                  <div
                    className="header-sticker-inner"
                    onClick={this.onMyActivitiesButtonClick}
                  >
                    <ReactSVG
                      className="sticker-icon"
                      src={Util.icon.activity}
                    />
                    <BadgeCount className="myact-count" count={myActCount} />
                  </div>
                </div>
                <div
                  className="header-sticker"
                  ref={this.notificationsHoverRef}
                >
                  <div
                    className="header-sticker-inner"
                    onClick={this.onNotificationsButtonClick}
                  >
                    <ReactSVG
                      className="sticker-icon"
                      src={Util.icon.notification}
                    />
                    <BadgeCount count={unseenUserNotifications.length} />
                  </div>
                  {this.state.isNotificationsOpen ? notificationsPopup : null}
                </div>
              </div>
              <ContextMenu
                button={<ProfilePhoto isNoLink={true} />}
                items={[
                  {
                    icon: Util.icon.user,
                    label: 'My profile',
                    to: Util.route.app.profile()
                  },
                  {
                    icon: Util.icon.settings,
                    label: 'Settings',
                    to: Util.route.app.settings()
                  },
                  {
                    icon: Util.icon.help,
                    label: 'Support',
                    to: Util.route.app.help()
                  },
                  {
                    icon: Util.icon.logout,
                    label: 'Log out',
                    onClick: this.onLogoutClick
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myActivities: state.activityState.myActivities,
  userNotifications: state.notificationState.userNotifications
});

export default connect(mapStateToProps, { openModalAction, logoutAction })(
  AppHeader
);
