import React, { Component } from 'react';

//Component
import Accordion from '../Accordion/Accordion';
import Button from '../Button/Button';
import FeedbackMessage from '../FeedbackMessage/FeedbackMessage';
import FeedbackStars from '../FeedbackStars/FeedbackStars';
import JourneyRibbon from '../JourneyRibbon/JourneyRibbon';
import JourneyStepListNav from '../JourneyStepListNav/JourneyStepListNav';
import JourneyStepStatusSummary from '../JourneyStepStatusSummary/JourneyStepStatusSummary';
import JourneyStepView from './JourneyStepView/JourneyStepView';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import StickyBox from 'react-sticky-box';
import Truncate from '../Truncate/Truncate';
import Util from '../../../Util';
import { connect } from 'react-redux';
//Images
import img_summary_doc from './summary_doc.png';

//required: this.props.journey & this.props.activeStepId
class JourneyView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: this.props.isNavOpenDefault
    };

    this.journeyViewPanelRef = React.createRef();
    this.navAccordionRef = React.createRef();

    this.setIsNavOpen = this.setIsNavOpen.bind(this);
  }
  componentWillReceiveProps(props) {
    if (props.activeStepId !== this.props.activeStepId) {
      if (!Util.css.breakpoint.overlg() && this.navAccordionRef.current) {
        this.navAccordionRef.current.close();
      }
    }
  }
  setIsNavOpen(isNavOpen) {
    this.setState({
      isNavOpen
    });
  }
  render() {
    let stepsWithEnd = [
      ...this.props.journey.steps,
      { stepId: 'end', urlId: 'end', name: 'Journey End' }
    ];

    let activeStep = Util.array.firstIdOrUrlIdMatch(
      stepsWithEnd,
      this.props.activeStepId,
      'stepId'
    );
    let nextStep = stepsWithEnd[stepsWithEnd.indexOf(activeStep) + 1];
    let prevStep = stepsWithEnd[stepsWithEnd.indexOf(activeStep) - 1];

    // The forward/back isNavOpen bars
    let pageNav = (
      <div className="journey-page-nav">
        <div className="nav-action">
          {!prevStep ? null : (
            <div className="nav-button prev">
              <Button
                label="Back"
                size="sm"
                isSecondary={true}
                to={Util.route.app.journeyStep(
                  this.props.journey.journeyId,
                  prevStep.urlId
                )}
                icon={Util.icon.arrowHead.left}
              />
              <div className="nav-detail">
                <h3 className="grey-2">
                  {prevStep.ordinal
                    ? `STEP ${prevStep.ordinal}`
                    : prevStep.stepId}
                </h3>
                <Truncate component="h4" maxLine={1} text={prevStep.name} />
              </div>
            </div>
          )}
        </div>
        <div className="nav-title">
          <h1>
            {activeStep.ordinal
              ? `STEP ${activeStep.ordinal}`
              : activeStep.stepId}
          </h1>
        </div>
        <div className="nav-action">
          {!nextStep ? null : (
            <div className="nav-button next">
              <div className="nav-detail">
                <h3 className="grey-2">
                  {nextStep.ordinal
                    ? `STEP ${nextStep.ordinal}`
                    : nextStep.stepId}
                </h3>
                <Truncate
                  component="h4"
                  maxLine={1}
                  text={nextStep ? nextStep.name : 'Complete journey'}
                />
              </div>
              <Button
                label="Next"
                size="sm"
                iconAlign="right"
                to={Util.route.app.journeyStep(
                  this.props.journey.journeyId,
                  nextStep.urlId
                )}
                icon={Util.icon.arrowHead.right}
              />
            </div>
          )}
        </div>
      </div>
    );

    // The content inside the main view
    let viewContent = null;
    let userJourney = Util.array.firstIdOrUrlIdMatch(
      this.props.userJourneys,
      this.props.journey.journeyId,
      'journeyId'
    );

    let journeyStepListNav = (
      <JourneyStepListNav
        userJourney={userJourney}
        activeStepId={activeStep.stepId}
      />
    );
    let isCompleted = !!userJourney.completedAt;
    let rewardStatus = isCompleted ? 'unlocked' : 'locked';

    let getRewardItem = (label, link) => {
      let icon = (
        <ReactSVG
          className="reward-item-icon"
          src={isCompleted ? Util.icon.unlock : Util.icon.lock}
        />
      );
      let text = (
        <div className="reward-text">
          {label} <b>{rewardStatus}</b>
        </div>
      );
      if (link && isCompleted) {
        return Util.route.isInternal(link) ? (
          <Link className={`reward-item ${rewardStatus}`} to={link}>
            {icon}
            {text}
          </Link>
        ) : (
          <a
            className={`reward-item ${rewardStatus}`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
            {text}
          </a>
        );
      } else {
        return (
          <div className={`reward-item ${rewardStatus}`}>
            {icon}
            {text}
          </div>
        );
      }
    };

    viewContent =
      activeStep.stepId === 'end' ? (
        <div className="end-panel">
          <div className="end-panel-upper">
            <JourneyRibbon
              className={isCompleted ? '' : 'disabled'}
              size="lg"
              journey={this.props.journey}
            />
            <div className="end-panel-upper-info">
              <div className="congrats-message">
                <h1 className="gilroy bold">
                  {isCompleted ? 'Congratulations!' : 'Journey in progress'}
                </h1>
                {isCompleted ? (
                  <h2 className="bold">You have finished this Journey.</h2>
                ) : null}
              </div>
              <JourneyStepStatusSummary
                journeyId={this.props.journey.journeyId}
              />
              <p>
                {isCompleted
                  ? "You've completed an activity on each step."
                  : 'Complete the activities on each step to finish the journey.'}
              </p>
              <div className="reward-items">
                {getRewardItem(
                  'Journey badge',
                  isCompleted
                    ? Util.route.app.profile()
                    : Util.route.getCurrent()
                )}
                {this.props.journey.summaryNotesUrl
                  ? getRewardItem(
                      'Journey summary notes',
                      isCompleted ? this.props.journey.summaryNotesUrl : null
                    )
                  : null}
              </div>
            </div>
          </div>
          {isCompleted ? (
            <div className="end-feedback">
              <FeedbackStars
                journeyId={this.props.journey.journeyId}
                label="How would you rate this journey?"
                feedbackType={Util.enum.FeedbackType.JourneyStars}
              />
              <FeedbackMessage
                journeyId={this.props.journey.journeyId}
                feedbackType={Util.enum.FeedbackType.JourneyMessage}
              />
            </div>
          ) : null}
          {this.props.journey.summaryNotesUrl ? (
            <div
              className={`end-summary-notes ${isCompleted ? '' : 'disabled'}`}
            >
              <img
                className="summary-notes-image"
                src={img_summary_doc}
                alt=""
              />
              <div className="summary-notes-info">
                <h2>Summary notes</h2>
                <p>
                  Journey summary with learning outcomes and suggested talking
                  points to chat about with a leader or mentor.
                </p>
                <Button
                  size="lg"
                  label="Download summary notes"
                  href={isCompleted ? this.props.journey.summaryNotesUrl : null}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <JourneyStepView userJourney={userJourney} step={activeStep} />
      );

    return (
      <div className="journey-view">
        <div className="journey-content">
          <StickyBox className="journey-list-nav-sticky-box">
            <div
              className={`nav-expandable-panel ${
                this.state.isNavOpen ? 'nav-open' : ''
              }`}
            >
              <div className="nav-control">
                <ReactSVG
                  className="nav-control-icon"
                  src={Util.icon.arrowDouble.right}
                  onClick={() => this.setIsNavOpen(!this.state.isNavOpen)}
                />
              </div>
              {journeyStepListNav}
            </div>
          </StickyBox>
          <div className="journey-view-inner" ref={this.journeyViewPanelRef}>
            <div className="journey-list-nav-accordion">
              <Accordion ref={this.navAccordionRef} head="Journey overview">
                {journeyStepListNav}
              </Accordion>
            </div>
            <div className="journey-step-content">
              {pageNav}
              <div className="active-panel">{viewContent}</div>
              {pageNav}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(mapStateToProps, {})(JourneyView);
