import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import StoryChapterSelectCarousel from './StoryChapterSelectCarousel/StoryChapterSelectCarousel';
import WatchedLabel from '../WatchedLabel/WatchedLabel';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VideoOverlay from '../VideoOverlay/VideoOverlay';
import FeedbackThumbsBar from '../FeedbackThumbsBar/FeedbackThumbsBar';
import VideoCarousel from '../VideoCarousel/VideoCarousel';
import SupplementaryPanel from '../SupplementaryPanel/SupplementaryPanel';
import RelatedSidebarPanels from '../RelatedSidebarPanels/RelatedSidebarPanels';
import Button from '../Button/Button';

//this.props.story
export default class StoryChapterView extends Component {
  constructor() {
    super();

    this.state = {
      activeChapter: null,
      nextChapter: null,
      nextChapterOverlayVisible: false
    };

    this.videoPlayerRef = React.createRef();

    this.showNextChapterOverlay = this.showNextChapterOverlay.bind(this);
    this.hideNextChapterOverlay = this.hideNextChapterOverlay.bind(this);
  }
  componentDidMount() {
    let activeChapter = this.props.chapterId
      ? Util.array.firstIdOrUrlIdMatch(
          this.props.story.chapters,
          this.props.chapterId,
          'chapterId'
        )
      : Util.array.first(this.props.story.chapters);

    this.setActiveChapter(activeChapter);
  }
  showNextChapterOverlay() {
    this.setState({
      nextChapterOverlayVisible: true
    });
  }
  hideNextChapterOverlay() {
    this.setState({
      nextChapterOverlayVisible: false
    });
  }
  setActiveChapter(activeChapter) {
    let nextChapter = Util.array.any(this.props.story.chapters)
      ? this.props.story.chapters[
          this.props.story.chapters.indexOf(activeChapter) + 1
        ]
      : null;

    if (this.state.activeChapter && activeChapter) {
      this.trackChapterChange(
        'ChapterSelected',
        this.state.activeChapter,
        activeChapter
      );
    }

    //Update the url in the title bar sneakily
    window.history.pushState(
      null,
      null,
      Util.route.app.storyChapter(this.props.story.urlId, activeChapter.urlId)
    );

    this.setState({
      activeChapter,
      nextChapter,
      nextChapterOverlayVisible: false
    });
  }
  trackChapterChange(eventName, fromChapter, toChapter) {
    Util.analytics.track(eventName, {
      fromChapterId: fromChapter.chapterId,
      fromChapterName: fromChapter.name,
      fromChapterUrlId: fromChapter.urlId,
      toChapterId: toChapter.chapterId,
      toChapterName: toChapter.name,
      toChapterUrlId: toChapter.urlId
    });
  }
  render() {
    return (
      <div className="story-view">
        {/* Section containing the story chapters (the carousel selector/video/details) */}
        {!this.state.activeChapter ? (
          <div className="no-chapters-msg">
            This Story has no chapters to display
          </div>
        ) : (
          <div className="story-chapter-section">
            <StoryChapterSelectCarousel
              chapters={this.props.story.chapters}
              selectedChapter={this.state.activeChapter}
              onChapterClick={chapter => this.setActiveChapter(chapter)}
            />
            <div className="chapter-title">
              <h1 className="gilroy">
                Chapter {this.state.activeChapter.ordinal}
              </h1>
              <WatchedLabel videoId={this.state.activeChapter.videoId} />
            </div>
            <div className="page-content-cols">
              <div className="page-content-col-main">
                {/* The chapter featured video */}
                {!this.state.activeChapter.video ? null : (
                  <div className="chapter-video">
                    <VideoPlayer
                      ref={this.videoPlayerRef}
                      key={this.state.activeChapter.video.videoId} // Without this key, the colour does not update (probably amongst other things)
                      video={this.state.activeChapter.video}
                      autoPlay={true}
                      silentAutoPlay={true}
                      onVideoFinished={this.showNextChapterOverlay}
                      onVideoResume={this.hideNextChapterOverlay}
                    />
                    {!this.state.nextChapter ? null : (
                      <VideoOverlay
                        className="next-chapter-overlay"
                        isVisible={this.state.nextChapterOverlayVisible}
                        showCloseButton={true}
                        onClose={this.hideNextChapterOverlay}
                      >
                        <h1 className="next-chapter-heading white">
                          Keep watching this Story!
                        </h1>
                        <h2 className="next-chapter-overview white">
                          {this.state.nextChapter.name}
                        </h2>
                        {Util.array.none(
                          this.state.nextChapter.relatedVideos
                        ) ? null : (
                          <h4 className="next-chapter-duration white">
                            {Util.format.secsToDuration(
                              this.state.nextChapter.video.durationSeconds
                            )}
                          </h4>
                        )}
                        <Button
                          label="Go to next chapter"
                          to={Util.route.app.storyChapter(
                            this.props.story.urlId,
                            this.state.nextChapter.urlId
                          )}
                          onClick={() =>
                            this.setActiveChapter(this.state.nextChapter)
                          }
                          size="lg"
                        />
                      </VideoOverlay>
                    )}
                  </div>
                )}
                {/* The chapter details (below the video) */}
                <div className="chapter-info">
                  <div className="chapter-name">
                    <h2 className="gilroy">{this.state.activeChapter.name}</h2>
                    <h4 className="chapter-duration grey-4">
                      {Util.format.secsToDuration(
                        this.state.activeChapter.video.durationSeconds
                      )}
                    </h4>
                  </div>
                  <p className="chapter-overview gilroy">
                    {this.state.activeChapter.overview}
                  </p>
                  <section
                    className="gilroy"
                    dangerouslySetInnerHTML={{
                      __html: this.state.activeChapter.htmlContent
                    }}
                  ></section>
                  {Util.array.any(this.state.activeChapter.relatedVideos) ? (
                    <SupplementaryPanel
                      title={`Related Videos (${this.state.activeChapter.relatedVideos.length})`}
                    >
                      <VideoCarousel
                        key={this.state.activeChapter.chapterId}
                        videos={this.state.activeChapter.relatedVideos}
                        onVideoClick={() =>
                          this.videoPlayerRef.current
                            .getWrappedInstance()
                            .setIsPlaying(false)
                        }
                      />
                    </SupplementaryPanel>
                  ) : null}
                  <FeedbackThumbsBar
                    feedbackType={Util.enum.FeedbackType.StoryChapterPageThumbs}
                  />
                </div>
              </div>
              <div className="page-content-col-support xl-margin">
                <RelatedSidebarPanels
                  key={this.state.activeChapter.chapterId}
                  activities={this.state.activeChapter.relatedActivities}
                  assessments={this.state.activeChapter.relatedAssessments}
                  skills={this.props.story.relatedSkills}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
