import React, { Component } from 'react';

import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import DateField from '../DateTimeField/DateField/DateField';
import DateTimeField from '../DateTimeField/DateTimeField';
import Dropdown from '../Dropdown/Dropdown';
import FileUpload from '../FileUpload/FileUpload';
import HtmlEditor from '../HtmlEditor/HtmlEditor';
import Textarea from 'react-textarea-autosize';
import TimeField from '../DateTimeField/TimeField/TimeField';
import Util from '../../../Util';

//Higher ordrer component to manage formdata, formerrors, validity checks and field creation
const withForm = (WrappedForm, formConfig) => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        formData: this.prepareFormData(this.props.formData),
        dropDownOptions: this.props.dropDownOptions,
        formErrors: {
          _overall: null
        }
      };

      this.prepareFormData = this.prepareFormData.bind(this);
      this.setFormData = this.setFormData.bind(this);
      this.updateFormData = this.updateFormData.bind(this);

      this.getField = this.getField.bind(this);
      this.isValid = this.isValid.bind(this);

      this.setOverallError = this.setOverallError.bind(this);

      this.onSubmit = this.onSubmit.bind(this);
    }
    setLoading(isLoading) {
      this.setState({
        isLoading
      });
    }
    prepareFormData(formData) {
      //Sets nulls/undefines to ''
      let preparedFormData = formData || {};

      let getDefaultValue = fieldConfig => {
        switch (fieldConfig.type) {
          case Util.enum.FieldType.Checkbox:
            return false;
          case Util.enum.FieldType.DateField:
          case Util.enum.FieldType.TimeField:
          case Util.enum.FieldType.DateTimeField:
            return null;
          default:
            return '';
        }
      };

      Object.keys(formConfig.fields).forEach(formFieldKey => {
        if (!preparedFormData[formFieldKey])
          preparedFormData[formFieldKey] =
            Util.route.getParameterByName(formFieldKey) ||
            getDefaultValue(formConfig.fields[formFieldKey]);
      });

      return preparedFormData;
    }
    setFormData(formData) {
      this.setState({
        formData: this.prepareFormData(formData)
      });
    }
    updateFormData(key, value) {
      this.setState({
        formData: {
          ...this.state.formData,
          [key]: value
        },
        formErrors: {
          ...this.state.formErrors,
          _overall: null,
          [key]: null
        }
      });
    }
    onSubmit(e) {
      e.preventDefault();

      if (!this.isValid()) return;

      if (this.props.onSubmit) this.props.onSubmit(this, this.state.formData);
    }
    isValid() {
      let formErrors = {};

      Object.keys(formConfig.fields).forEach(formFieldKey => {
        let fieldConfig = formConfig.fields[formFieldKey];
        let fieldValue = this.state.formData[formFieldKey];

        //First, validate the value using the passed in getError function
        if (fieldConfig.getError)
          formErrors[formFieldKey] = fieldConfig.getError(fieldValue);

        //Then, also ensure the field has a value at all (in case a null or empty string somehow passed the above check)
        if (
          !fieldConfig.isOptional && //skipped if optional
          fieldConfig.type !== Util.enum.FieldType.Checkbox && //skipped if checkbox (can't be optional)
          fieldValue !== 0 && //zero is still considered a value
          !fieldValue
        ) {
          formErrors[formFieldKey] = 'This field is required';
        }
      });

      if (formConfig.getOverallError)
        formErrors._overall = formConfig.getOverallError(this.state.formData);

      this.setState({
        formErrors
      });

      //TODO(enhancement) - form submit analytics event, BUT CHECK IF FIELD isPassword = true to mask that
      // and also record whether it was successful or not

      return !Util.array.any(
        Object.keys(formErrors).filter(
          formErrorKey => !!formErrors[formErrorKey]
        )
      );
    }
    setOverallError(error) {
      //Used to set an overall error from the specific form (useful for serverside post errors)
      this.setState({
        formErrors: {
          _overall: error
        }
      });
    }
    getField(fieldName) {
      let fieldConfig = formConfig.fields[fieldName];
      let fieldValue = this.state.formData[fieldName];
      let fieldError = this.state.formErrors[fieldName];
      let fieldPlaceholder = fieldConfig.placeholder || fieldConfig.label;

      let wrapField = (fieldElement, excludeLabel) => {
        return (
          <div className="field-container">
            {!excludeLabel ? (
              <label htmlFor={fieldName}>
                {fieldConfig.label}
                {fieldConfig.isOptional ? (
                  <h5 className="optional-label grey-3">(optional)</h5>
                ) : null}
              </label>
            ) : null}
            {fieldElement}
            {fieldError ? (
              <h5 className="form-error red bold">{fieldError}</h5>
            ) : null}
          </div>
        );
      };

      switch (fieldConfig.type) {
        case Util.enum.FieldType.Textarea:
          return wrapField(
            <Textarea
              className={fieldError ? 'field-error' : ''}
              name={fieldName}
              placeholder={fieldPlaceholder}
              value={fieldValue}
              autoComplete={'off'}
              onChange={e => this.updateFormData(fieldName, e.target.value)}
            />
          );
        case Util.enum.FieldType.Dropdown:
          return wrapField(
            <Dropdown
              className={fieldError ? 'field-error' : ''}
              value={fieldValue}
              options={fieldConfig.getOptions(
                this.state.dropDownOptions || this.props
              )}
              displayProp={fieldConfig.displayProp}
              valueProp={fieldConfig.valueProp}
              placeholder={fieldPlaceholder}
              isEmptyAllowed={true} //this is different to isOptional. The form itself will validate an unfilled dropdown
              onChange={val => this.updateFormData(fieldName, val)}
            />
          );
        case Util.enum.FieldType.Checkbox:
          return wrapField(
            <Checkbox
              checked={fieldValue}
              onChange={e => this.updateFormData(fieldName, e.target.checked)}
              label={fieldConfig.label}
            />,
            true
          );
        case Util.enum.FieldType.DateTimeField:
          return wrapField(
            <DateTimeField
              value={fieldValue}
              min={fieldConfig.min}
              max={fieldConfig.max}
              onChange={newDateTime =>
                this.updateFormData(fieldName, newDateTime)
              }
            />
          );
        case Util.enum.FieldType.TimeField:
          return wrapField(
            <TimeField
              defaultValue={fieldValue}
              min={fieldConfig.min}
              max={fieldConfig.max}
              onChange={newTime => this.updateFormData(fieldName, newTime)}
            />
          );
        case Util.enum.FieldType.DateField:
          return wrapField(
            <DateField
              defaultValue={fieldValue}
              min={fieldConfig.min}
              max={fieldConfig.max}
              onChange={newDate => this.updateFormData(fieldName, newDate)}
            />
          );
        case Util.enum.FieldType.HtmlEditor:
          return wrapField(
            <HtmlEditor
              value={fieldValue}
              onChange={val => this.updateFormData(fieldName, val)}
            />
          );
        case Util.enum.FieldType.FileUpload:
          return wrapField(
            <div>
              {fieldConfig.isImage && fieldValue ? (
                <img
                  style={{ maxWidth: '100%' }}
                  src={Util.storage.blob(fieldValue)}
                  alt="File Upload"
                />
              ) : null}
              <FileUpload
                value={fieldValue}
                // isMultiple={fieldConfig.isMultiple} // not implemented yet
                uploadUrl={fieldConfig.uploadUrl}
                allowedExtensions={fieldConfig.allowedExtensions}
                onUploadSuccess={files => {
                  //No support for multiple yet
                  if (files.length === 1) {
                    let file = files[0];
                    if (fieldConfig.fieldFileProperty) {
                      // form data is storing only one property of the file (eg. blobId, fileId)
                      this.updateFormData(
                        fieldName,
                        file[fieldConfig.fieldFileProperty]
                      );
                    } else {
                      // form data is storing the whole file object
                      this.updateFormData(fieldName, file);
                    }
                  }
                }}
              >
                <Button label="Upload" icon={Util.icon.camera} />
              </FileUpload>
            </div>
          );
        case Util.enum.FieldType.Text:
        default:
          return wrapField(
            <input
              type={fieldConfig.isPassword ? 'password' : 'text'}
              className={fieldError ? 'field-error' : ''}
              name={fieldName}
              placeholder={fieldPlaceholder}
              value={fieldValue}
              onChange={e => this.updateFormData(fieldName, e.target.value)}
            />
          );
      }
    }
    render() {
      return (
        <div className="form-wrapper">
          <div className={this.state.isLoading ? 'disabled ' : ''}>
            <WrappedForm
              {...this.props}
              onSubmit={this.onSubmit}
              formData={this.state.formData}
              formErrors={this.state.formErrors}
              getField={this.getField}
              isValid={this.isValid}
              updateFormData={this.updateFormData}
              setOverallError={this.setOverallError}
            />
          </div>
          {this.state.isLoading ? <div className="loader"></div> : null}
          {this.state.formErrors._overall ? (
            <h5 className="form-error red bold">
              {this.state.formErrors._overall}
            </h5>
          ) : null}
        </div>
      );
    }
  };
};

export default withForm;
