import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';
import Util from '../../../Util';

class PostImages extends Component {
  constructor(props) {
    super(props);

    this.onImageClick = this.onImageClick.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
  }
  onImageClick(selectedPostFile) {
    //open the imageview modal
    this.props.openModalAction({
      type: Util.enum.ModalType.ImageCarouselModal,
      title:
        this.props.postFiles.length +
        ` post ${Util.format.pluralise(this.props.postFiles, 'image')}`,
      data: {
        initialPanelIdx: this.props.postFiles.indexOf(selectedPostFile),
        images: this.props.postFiles.map(postFile => (
          <img alt="" src={Util.storage.blob(postFile.file.blobId)} />
        ))
      }
    });

    if (this.props.onClick) this.props.onClick();
  }
  onRemoveImage(postFile) {
    if (this.props.onRemoveImage)
      this.props.onRemoveImage(postFile.file.fileId);
  }
  render() {
    if (!this.props.postFiles || Util.array.none(this.props.postFiles))
      return null;

    let getCroppedImage = postFile => {
      return (
        <div
          className="cropped-image"
          onClick={() => this.onImageClick(postFile)}
          style={{
            backgroundImage: `url('${Util.storage.blob(postFile.file.blobId)}')`
          }}
        ></div>
      );
    };

    let content = null;

    if (this.props.isCollageLayout) {
      let featurePostFile = null;
      const visibleExtraPostFiles = 3;
      let extraPostFiles = [];

      let hiddenPostFiles = this.props.postFiles.filter(
        (postFile, idx) => idx > visibleExtraPostFiles
      );

      if (this.props.postFiles.length === 2) {
        extraPostFiles = this.props.postFiles;
      } else {
        featurePostFile = this.props.postFiles[0];
        extraPostFiles = this.props.postFiles.filter(
          (postFile, idx) => idx > 0 && idx <= visibleExtraPostFiles
        );
      }

      content = (
        <div className="post-images-layout-collage">
          {featurePostFile ? (
            <img
              alt=""
              className="hero-post-image"
              onClick={() => this.onImageClick(featurePostFile)}
              src={Util.storage.blob(featurePostFile.file.blobId)}
            />
          ) : null}
          {Util.array.any(extraPostFiles) ? (
            <div className="extra-images">
              {extraPostFiles.map((postFile, idx) => (
                <div key={idx}>
                  {getCroppedImage(postFile)}
                  {/* We add +1 to the length on the overlay, because the vaguely concealed image is included as hidden */}
                  {idx === extraPostFiles.length - 1 &&
                  hiddenPostFiles.length > 0 ? (
                    <div className="more-images-overlay">
                      +{hiddenPostFiles.length + 1} more{' '}
                      {Util.format.pluralise(
                        hiddenPostFiles.length + 1,
                        'image'
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    } else {
      content = (
        <div className="post-images-layout-blocks">
          {this.props.postFiles.map((postFile, idx) => (
            <div key={idx}>
              {getCroppedImage(postFile)}
              {this.props.onRemoveImage ? (
                <ReactSVG
                  className="remove-button"
                  src={Util.icon.close}
                  onClick={() => this.onRemoveImage(postFile)}
                />
              ) : null}
            </div>
          ))}
        </div>
      );
    }

    return <div className="post-images">{content}</div>;
  }
}

export default connect(
  null,
  { openModalAction }
)(PostImages);
