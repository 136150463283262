import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

import JourneyStepStatus from '../JourneyStepStatus/JourneyStepStatus';

//this.props.journey
export default class JourneyStepListNav extends Component {
  render() {
    let stepsWithEnd = [
      ...this.props.userJourney.steps,
      { stepId: 'end', urlId: 'end', name: 'Journey End', isGenerated: true }
    ];

    return (
      <div className="journey-step-list-nav">
        <div className="nav-items">
          {stepsWithEnd.map(step => (
            <Link
              key={step.stepId}
              className={`nav-item ${
                this.props.activeStepId === step.stepId ? 'active-step' : ''
              } ${step.isGenerated ? 'generated' : ''}`}
              to={Util.route.app.journeyStep(
                this.props.userJourney.journeyId,
                step.urlId
              )}
            >
              <div className="step-number">
                <h3 className="bold step-number-inner">
                  {step.ordinal || step.stepId}
                </h3>
                {!step.isGenerated ? (
                  <JourneyStepStatus step={step} isLabelHidden={true} />
                ) : null}
              </div>
              <div className="step-detail">
                <h4 className="text-left">{step.name}</h4>
                {!step.isGenerated ? <JourneyStepStatus step={step} /> : null}
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}
