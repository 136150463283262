import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

//Components
import GameCardsView from '../../../../UI/CardsView/GameCardsView/GameCardsView';

export default class AppGamesPage extends Component {
  render() {
    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Games'
      }
    ];

    return (
      <div className="leda-page-app-games">
        <AppExploreLayout
          pageTitle="Games"
          pageSubtitle="Put your Leda lessons into practice with some games before you unleash them on your colleagues."
          pageIcon={Util.icon.game}
          breadcrumbs={breadcrumbs}
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <GameCardsView games={Util.context.referenceData.getGames()} />
            </div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
