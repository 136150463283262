import React, { Component } from 'react';
import Util from '../../../../Util';

import Button from '../../../UI/Button/Button';

//images
import img_construction_focus from '../../../../img/site/construction_focus.png';
import img_construction_background from '../../../../img/site/construction_background.png';

export default class SiteUnderConstructionPage extends Component {
  render() {
    return (
      <div
        className="leda-page-site-under-construction"
        style={{ backgroundImage: `url('${img_construction_background}')` }}
      >
        <div className="leda-container-lg">
          <div className="leda-row">
            <div className="under-construction-banner">
              <div className="banner-detail">
                <div>
                  <h1>We're in the middle of building something special!</h1>
                  <h2>
                    Check back on{' '}
                    <span className="date">
                      {Util.context.env.getUnderConstructionDate()}
                    </span>
                  </h2>
                  <Button
                    label="Back to main site"
                    to={Util.route.site.home()}
                  />
                </div>
              </div>
              <div className="banner-image">
                <img
                  src={img_construction_focus}
                  alt="Error - Under construction"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
