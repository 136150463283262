import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import Util from '../../../Util';
import Button from '../Button/Button';
import JourneyAccordion from '../JourneyAccordion/JourneyAccordion';

//data source: this.props.teamJourneys
class TeamJourneysView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: null
    };

    this.setFilter = this.setFilter.bind(this);
    this.currentJourneyRef = React.createRef();
    this.scrollHelperRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToCurrentJourney();
  }

  scrollToCurrentJourney() {
    // For whatever reason, the web API scrollTo function doesn't work on the app (probably somethins liked to CSS).
    // Only scrollIntoView works.
    // An invisible div helper has been created 100px higher than the current journey in order to use scrollIntoview

    const scroll = () =>
      setTimeout(
        () =>
          this.scrollHelperRef.current.scrollIntoView({
            behavior: 'smooth'
          }),
        1000
      );

    const currentJourneyY = this.currentJourneyRef.current.getBoundingClientRect()
      .y;

    if (window.innerHeight < 800) {
      // scrolls to the third journey on mobile
      if (currentJourneyY > 600) {
        scroll();
      }
    } else {
      // scrolls to the third journey on desktop
      if (currentJourneyY > 700) {
        scroll();
      }
    }
  }

  setFilter(filterVal) {
    if (filterVal !== this.state.filter) {
      this.setState({
        filter: filterVal
      });
    }
  }
  render() {
    let now = new Date();

    let journeysTimeline = null;
    let journeysList = null;

    let teamJourneys = this.props.team.teamJourneys
      .sort((tj1, tj2) =>
        Util.sort.by('scheduledAt', tj1, tj2, Util.sort.asDate)
      )
      .filter(teamJourney => {
        const scheduledAt = new Date(teamJourney.scheduledAt);
        scheduledAt.setHours('0');
        scheduledAt.setMinutes('0');
        scheduledAt.setSeconds('0');
        teamJourney.scheduledAt = scheduledAt;

        const unlockedAt = new Date(teamJourney.unlockedAt);
        unlockedAt.setHours('0');
        unlockedAt.setMinutes('0');
        unlockedAt.setSeconds('0');
        teamJourney.unlockedAt = unlockedAt;
        return teamJourney;
      });

    let filteredTeamJourneyIds = teamJourneys
      .filter(teamJourney => {
        let userJourney = this.props.userJourneys.find(
          userJourney => userJourney.journeyId === teamJourney.journeyId
        );
        switch (this.state.filter) {
          case 'inprogress':
            return userJourney && !userJourney.completedAt;
          case 'completed':
            return userJourney && userJourney.completedAt;
          default:
            return true;
        }
      })
      .map(teamJourney => teamJourney.teamJourneyId);

    let unscheduledTeamJourneys = teamJourneys.filter(
      teamJourney => !teamJourney.scheduledAt
    );
    let scheduledTeamJourneys = teamJourneys.filter(
      teamJourney => !!teamJourney.scheduledAt
    );

    let getJourneyAccordion = (teamJourney, isCurrent) => {
      let userJourney = this.props.userJourneys.find(
        userJourney => userJourney.journeyId === teamJourney.journeyId
      );

      let isLocked =
        !userJourney &&
        teamJourney.unlockedAt &&
        new Date(teamJourney.unlockedAt) > now;

      return (
        <JourneyAccordion
          key={teamJourney.teamJourneyId}
          className={isCurrent ? 'current' : ''}
          isLocked={isLocked}
          journeyId={teamJourney.journeyId}
          breakLabel={teamJourney.breakLabel}
        />
      );
    };

    let timelineLastDate = null;
    let getTimelineRow = (
      date,
      isPast,
      isCurrent,
      teamJourneys,
      journeyElContent = null,
      isNoLine = false
    ) => {
      let filterMatchRow =
        !Util.array.any(teamJourneys) ||
        (Util.array.any(teamJourneys) &&
          !!teamJourneys.find(teamJourney =>
            filteredTeamJourneyIds.includes(teamJourney.teamJourneyId)
          ));

      let momentDate = moment(date);
      let isNewMonth =
        !timelineLastDate || !momentDate.isSame(timelineLastDate, 'month');
      let isNewYear =
        timelineLastDate && !momentDate.isSame(timelineLastDate, 'year');

      let row = (
        <div className="timeline-row" key={Util.id.tempId.getTempId()}>
          <div className="timeline-date">
            {date ? (
              <div>
                {isNewYear ? (
                  <h5 className="year bold">
                    <ReactSVG className="party-icon" src={Util.icon.party} />
                    {momentDate.format('Y')}
                  </h5>
                ) : null}
                {/* Still show month even on non filter matches */}
                {isNewMonth ? (
                  <h5 className="month bold">{momentDate.format('MMM')}</h5>
                ) : null}
                {filterMatchRow ? (
                  <div className="day">
                    <h5>{momentDate.format('ddd')}</h5>
                    <h1>{momentDate.format('D')}</h1>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            className={`timeline-line ${!isCurrent && isPast ? 'past' : ''}`}
          >
            {!isNoLine ? <div className="line"></div> : null}
            {isCurrent && (
              <div
                ref={this.scrollHelperRef}
                style={{ position: 'relative', top: '-100px' }}
                id="scroll-helper"
              ></div>
            )}
            <div
              ref={isCurrent ? this.currentJourneyRef : null}
              className={`dot ${isCurrent ? 'current' : ''} ${
                filterMatchRow ? '' : 'small'
              } `}
            ></div>
          </div>
          <div className="timeline-journeys">
            {journeyElContent}
            {Util.array.any(teamJourneys)
              ? teamJourneys.map(teamJourney => {
                  return filteredTeamJourneyIds.includes(
                    teamJourney.teamJourneyId
                  ) ? (
                    getJourneyAccordion(teamJourney, isCurrent)
                  ) : (
                    <h4 key={teamJourney.teamJourneyId}>
                      {
                        Util.context.referenceData.getJourneyById(
                          teamJourney.journeyId
                        ).name
                      }
                    </h4>
                  );
                })
              : null}
          </div>
        </div>
      );

      timelineLastDate = date;
      return row;
    };

    if (Util.array.any(scheduledTeamJourneys)) {
      let scheduledTeamJourneysByDate = Util.array.groupBy(
        scheduledTeamJourneys,
        'scheduledAt'
      );
      let scheduledTeamJourneyDates = Object.keys(scheduledTeamJourneysByDate);
      let isTeamJourneysEnded =
        this.props.team.teamJourneysEndAt &&
        now >= new Date(this.props.team.teamJourneysEndAt);
      let currentScheduledTeamJourneyDate = scheduledTeamJourneyDates
        .filter(date => !isTeamJourneysEnded && new Date(date) <= now)
        .pop();

      journeysTimeline = (
        <div className="team-journeys-timeline">
          {getTimelineRow(
            null,
            !!currentScheduledTeamJourneyDate,
            !currentScheduledTeamJourneyDate,
            null,
            <h4 className="bold grey-2">Start</h4>
          )}
          {scheduledTeamJourneyDates.map((date, idx) => {
            return getTimelineRow(
              date,
              isTeamJourneysEnded || new Date(date) < now,
              !isTeamJourneysEnded && currentScheduledTeamJourneyDate === date,
              scheduledTeamJourneysByDate[date]
            );
          })}
          {getTimelineRow(
            this.props.team.teamJourneysEndAt ||
              moment()
                .endOf('year')
                .toDate(),
            isTeamJourneysEnded,
            false,
            null,
            <h4 className="bold grey-2">End</h4>,
            true
          )}
        </div>
      );
    }

    if (Util.array.any(unscheduledTeamJourneys)) {
      journeysList = (
        <div className="team-journeys-list">
          {unscheduledTeamJourneys.map(teamJourney =>
            getJourneyAccordion(teamJourney)
          )}
        </div>
      );
    }

    let getFilterButton = (label, filterVal) => (
      <Button
        size="sm"
        isSecondary={true}
        isActive={this.state.filter === filterVal}
        onClick={() => this.setFilter(filterVal)}
        label={label}
      />
    );

    return (
      <div className="team-journeys-view">
        <div className="filter-bar">
          <ReactSVG className="filter-icon" src={Util.icon.filter} />
          {getFilterButton('All Journeys', null)}
          {getFilterButton('In Progress Journeys', 'inprogress')}
          {getFilterButton('Completed Journeys', 'completed')}
        </div>
        {journeysTimeline}
        {journeysList}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(mapStateToProps, {})(TeamJourneysView);
