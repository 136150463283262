import React, { Component } from 'react';

export default class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };

    this.toggle = this.toggle.bind(this);
    this.setVisible = this.setVisible.bind(this);
  }
  toggle() {
    this.setState({
      isVisible: !this.state.isVisible
    });
  }
  setVisible(isVisible) {
    this.setState({
      isVisible
    });
  }
  render() {
    return (
      <div className="tooltip-container">
        <div className="tooltip-container-inner">
          {!this.state.isVisible ? null : (
            <div className={`tooltip ${this.props.position || 'top'}`}>
              <div className="tooltip-inner">{this.props.tipContent}</div>
            </div>
          )}
        </div>
        {this.props.tipTrigger ? (
          <div
            onMouseOver={() => this.setVisible(true)}
            onMouseLeave={() => this.setVisible(false)}
          >
            {this.props.tipTrigger}
          </div>
        ) : (
          <div
            className="default-trigger"
            onMouseOver={() => this.setVisible(true)}
            onMouseLeave={() => this.setVisible(false)}
          ></div>
        )}
      </div>
    );
  }
}
