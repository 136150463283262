import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

// Components
import Button from '../../../UI/Button/Button';
import ButtonInput from '../../../UI/ButtonInput/ButtonInput';
import CompanyLogos from '../../../UI/CompanyLogos/CompanyLogos';
import SiteNewsletterStrip from '../../../UI/SiteNewsletterStrip/SiteNewsletterStrip';
import ContentCardsView from '../../../UI/CardsView/ContentCardsView/ContentCardsView';

// Videos
import video_shorts from './video_shorts.mp4';

// Images
import img_video_holder from '../../../../img/site/video_holder.png';
import img_quote_mark from '../../../../img/site/quote_mark.png';
import img_org_logo_monash_wide from '../../../../img/site/org/logo_monash_wide.png';

import svg_smartphone from '../../../../img/site/icons/dating-smartphone-chat.svg';
import svg_performance from '../../../../img/site/icons/presentation-analytics.svg';
import svg_cash from '../../../../img/site/icons/cash-payment-coin.svg';
import svg_time from '../../../../img/site/icons/time-clock-hand.svg';

import img_devices from '../../../../img/site/devices.png';

export default class SiteHomePage extends Component {
  render() {
    let leadersInfoBoxes = [
      {
        title: `Love learning`,
        info: `Have fun with games, videos and real-world scenarios.`,
        imgSrc: svg_smartphone,
        colour: 'ec7454'
      },
      {
        title: `Growth that sticks`,
        info: `Learn leadership behaviours through daily activities.`,
        imgSrc: svg_performance,
        colour: '235987'
      },
      {
        title: `No time? No problem`,
        info: `Our bite-sized lessons are built to fit busy schedules.`,
        imgSrc: svg_time,
        colour: 'ef9c79'
      },
      {
        title: `Nobody left behind`,
        info: `Train — and retain — whole teams affordably and effectively, online.`,
        imgSrc: svg_cash,
        colour: '6fbfc8'
      }
    ];

    let testimonials = [
      {
        quote:
          'I like the activities... You can quite easily make them part of your daily tasks.',
        source: ''
      },
      {
        quote: 'I like that Leda is in a language that anyone can understand.',
        source: ''
      },
      {
        quote:
          'The empathy game was really eye-opening for me... It made me think what empathy really is.',
        source: ''
      }
    ];

    return (
      <div className="leda-page-site-home">
        <SiteLayout>
          <div className="strip-splash">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="splash-inner">
                  <div className="splash-detail">
                    <h2 className="gilroy">
                      Grow high-performing teams and leaders
                    </h2>
                    <h1 className="grey-2">
                      Meet Leda: a learning experience platform like no other.
                      Amplify the potential of tomorrow’s leaders — and today’s.
                    </h1>
                    <Button
                      label="Try Leda for free"
                      size="lg"
                      to={Util.route.site.tryForFree()}
                    />
                  </div>
                  <div className="splash-video">
                    <img alt="Splash video holder" src={img_video_holder} />
                    <video
                      src={video_shorts}
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      playsInline={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="strip-leaders-info">
            <div className="curve-bg"></div>
            <div className="beams"></div>
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="leaders-info-detail confined-row">
                  <h4 className="gilroy white">
                    From hope to habit, in 10 minutes a day
                  </h4>
                  <p className="gilroy white lg">
                    Hoping to develop better leaders? Leda embeds proven
                    leadership behaviours over days, weeks and months. And it’s{' '}
                    <i>fun</i>.
                  </p>
                </div>
                <div className="leaders-info-boxes">
                  {leadersInfoBoxes.map((box, idx) => (
                    <div key={idx} className="leaders-info-box">
                      <div className="leaders-info-box-inner">
                        <ReactSVG
                          className="info-icon"
                          src={box.imgSrc}
                          alt="Leadership info"
                        />
                        <p className="gilroy grey-1 bold">{box.title}</p>
                        <p>{box.info}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="strip-skills-info">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="strip-skills-info-inner">
                  <div className="skills-detail confined-row">
                    <h1 className="gilroy">What you'll learn</h1>
                    <p className="gilroy grey-2 lg">
                      Developed by world-class leadership experts, our
                      best-practice learning framework delivers the quality of
                      training your teams deserve.
                    </p>
                    <Button
                      isSecondary={true}
                      label="Find out more"
                      to={Util.route.site.how()}
                    />
                  </div>
                  <div className="skill-groups">
                    {Util.context.referenceData
                      .getCapabilities()
                      .map(capability => {
                        return (
                          <div
                            className="capability-wrap"
                            key={capability.capabilityId}
                          >
                            {capability.skillGroups.map(skillGroup => {
                              return (
                                <div
                                  className="skill-group-panel"
                                  key={skillGroup.skillGroupId}
                                >
                                  <div className="skill-group-panel-inner">
                                    <div
                                      className="dot"
                                      style={{
                                        backgroundColor: `#${skillGroup.primaryColour}`
                                      }}
                                    ></div>
                                    <h6 className="gilroy bold black">
                                      {skillGroup.name}
                                    </h6>
                                    <ul>
                                      {skillGroup.skills.map(skill => (
                                        <li key={skill.skillId}>
                                          <Link
                                            to={Util.route.site.articlesContent(
                                              skill.urlId
                                            )}
                                          >
                                            {skill.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="strip-e-learning">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="confined-row">
                  <h2 className="gilroy">Start the journey</h2>
                  <p className="gilroy">
                    Forget dull lectures and dry downloads. Our curated Journeys
                    adapt to individuals' goals, schedules, and learning styles.
                  </p>
                </div>
                <div className="e-learning-inner">
                  <img alt="" src={img_devices} />
                  <div className="e-learning-info">
                    <p className="gilroy lg">
                      Each Journey covers a skill from our learning framework,
                      and includes
                    </p>
                    <ul className="gilroy lg">
                      <li>Videos designed for easy recall</li>
                      <li>Games that captivate</li>
                      <li>Self-assessments to open learners' minds</li>
                      <li>Practical activities that inspire change.</li>
                    </ul>
                    <p className="gilroy lg">
                      Professional development has never been so enjoyable. Or
                      effective.
                    </p>
                    <Button
                      isSecondary={true}
                      label="See all Journeys"
                      to={Util.route.site.how() + '#journeys-strip'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="strip-courses">
            <div className="leda-container-md">
              <div className="leda-row">
                <h2 className="gilroy white text-center">Learn it your way</h2>
                <div className="courses-columns">
                  <div className="course-column">
                    <div className="course-column-inner">
                      <h5 className="gilroy">Take a course</h5>
                      <p>
                        Leda courses bundle our Journeys into 6- to 12-month
                        programs.
                      </p>
                      <p>
                        Master the skills of emotional intelligence, team
                        building, neuroleadership and more.
                      </p>
                    </div>
                  </div>
                  <div className="course-column">
                    <div className="course-column-inner">
                      <h5 className="gilroy">Build a course</h5>
                      <p>
                        Choose the Journeys that are most relevant to your team
                        and business goals, and wrap them up in a structured
                        Course that’s tailored just for you.
                      </p>
                    </div>
                  </div>
                  <div className="course-column">
                    <div className="course-column-inner">
                      <h5 className="gilroy">Get full access</h5>
                      <p>
                        A Leda License gives you full access to all our Journeys
                        for 12 months.
                      </p>
                      <p>
                        This gives learners the ability to learn what they want,
                        at their own pace.
                      </p>
                      <p>
                        A Leda License is ideal for emerging leaders who want to
                        strengthen specific skills, and for autonomous leaders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SiteNewsletterStrip
              tagline={`Receive more details about our ${new Date().getFullYear()} courses`}
            />
          </div>
          <div className="strip-articles">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="article-pnael">
                  <ContentCardsView
                    dataUrl="/api/public/getPublicContents"
                    dataParams={{ limit: 3 }}
                  />
                  <h4 className="gilroy text-center">
                    What our users are saying
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="strip-testimonials">
            <div className="testimonials-quotes">
              <div className="leda-container-md">
                <div className="leda-row">
                  <div className="testimonial-boxes">
                    {testimonials.map((testimonial, idx) => (
                      <div key={idx} className="testimonial-box">
                        <div
                          className="testimonial-box-inner"
                          style={{ backgroundImage: `url(${img_quote_mark})` }}
                        >
                          <div className="quote-line">{testimonial.quote}</div>
                          <div className="source-line">
                            {/* {testimonial.source} */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="leda-container-md">
              <div className="leda-row">
                <h2 className="text-center">
                  Trusted by leaders representing <b>over 50 leading</b>{' '}
                  organisations.
                </h2>
                <CompanyLogos />
              </div>
            </div>
          </div>
          <div className="strip-start">
            <div className="confined-row">
              <div className="monash-panel">
                <h1>
                  Trusted by Australia's largest university and developed by
                  world class leadership professors and experts.
                </h1>
                <img
                  className="monash-logo"
                  src={img_org_logo_monash_wide}
                  alt="Monash University"
                />
              </div>
              <div className="start-panel">
                <h4 className="gilroy white">See our platform in action</h4>
                <ButtonInput
                  className="email-try-it"
                  buttonLabel="Try for free"
                  buttonSize="lg"
                  buttonToFn={val => Util.route.site.tryForFree({ email: val })}
                  placeholder="Enter your work email"
                />
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
