import React, { Component } from 'react';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

//Components
import CardsView from '../../../UI/CardsView/CardsView';
import Card from '../../../UI/Card/Card';
import GetLedaForm from '../../../UI/Forms/GetLedaForm/GetLedaForm';

//Images
import img_newsletter_plane from './newsletter_plane.png';
import img_bg_left from './bg_left.png';
import img_bg_right from './bg_right.png';

export default class SitePricingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false
    };

    this.setIsSubmitted = this.setIsSubmitted.bind(this);
  }

  setIsSubmitted(isSubmitted) {
    this.setState({
      isSubmitted
    });
  }

  render() {
    let onPricingCardClick = () => {
      document
        .getElementById('offset-helper')
        .scrollIntoView({ behavior: 'smooth' });
    };

    let pricingCards = [
      {
        header: 'Starter',
        colour: 'b2b3b3',
        usersLabel: '1-5',
        pricePerMonth: 200,
        bullets: [
          `Full access to all journeys, games and stories`,
          `Team features (Basic)`,
          `Reporting (Basic)`
        ]
      },
      {
        header: 'Teams',
        colour: '61c0e0',
        usersLabel: '5-20',
        pricePerMonth: 600,
        bullets: [
          `Full access to all journeys, games and stories`,
          `Team features`,
          `Reporting`,
          `Customization (Basic)`
        ]
      },
      {
        header: 'Teams PLUS',
        colour: '5ba2b5',
        usersLabel: '21-50',
        pricePerMonth: 1200,
        bullets: [
          `Full access to all journeys, games and stories`,
          `Team features`,
          `Reporting`,
          `Customisation`
        ]
      },
      {
        header: 'Enterprise',
        usersLabel: '50+',
        colour: '225987',
        pricePerMonth: null,
        bullets: [
          `Full access to all journeys, games and stories`,
          `Team features`,
          `Reporting`,
          `Customisation`
        ]
      }
    ];

    return (
      <div className="leda-page-site-pricing">
        <SiteLayout
          pageTitle="Get Leda"
          pageSubtitle="Accessible pricing for every team"
        >
          <div className="pricing-options">
            <div className="leda-container-md">
              <div className="leda-row">
                <CardsView>
                  {pricingCards.map((card, idx) => (
                    <Card key={idx} onClick={onPricingCardClick}>
                      <div
                        className="card-header"
                        style={{ backgroundColor: '#' + card.colour }}
                      >
                        {card.header}
                      </div>
                      <div className="card-users">
                        <h3 className="gilroy">{card.usersLabel}</h3>
                        <p>USERS</p>
                      </div>
                      <div className="card-price">
                        <p className="monthly-price">
                          {card.pricePerMonth
                            ? `$${card.pricePerMonth} per month`
                            : 'Varies by number of users'}
                        </p>
                        {card.pricePerMonth ? (
                          <div>
                            <p>Annual subscription</p>
                            <p className="fee">$100</p>
                            <p>One time setup fee</p>
                          </div>
                        ) : (
                            <div>
                              <p>&nbsp;</p>
                              <p className="fee">$100</p>
                              <p>One time setup fee</p>
                            </div>
                          )}
                      </div>
                      <div className="card-features">
                        <ul className="tick-list">
                          {card.bullets.map((bullet, bullIdx) => (
                            <li key={bullIdx}>{bullet}</li>
                          ))}
                        </ul>
                      </div>
                    </Card>
                  ))}
                </CardsView>
              </div>
            </div>
          </div>

          <div className="pricing-form">
            <div className="leda-container-md">
              <div
                className={
                  'leda-row ' + (this.state.isSubmitted ? 'submitted' : '')
                }
              >
                <div className="contact-container" id="contact-form">
                  <div id="offset-helper" />
                  <div className="contact-form">
                    <div className="contact-form-inner">
                      {this.state.isSubmitted ? (
                        <p className="text-center">
                          Thank you, we'll be in touch shortly.
                        </p>
                      ) : (
                          <GetLedaForm
                            onSubmit={(form, formData) => {
                              this.setIsSubmitted(true);
                              Util.api.post('/api/public/submitGetLeda', {
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                                email: formData.email,
                                organisation: formData.phoneNumber,
                                jobTitle: formData.teamSize,
                                message: formData.comments
                              });

                              // Analytics
                              Util.analytics.track('GetLedaFormSubmitted', {
                                formData
                              });
                            }}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="decorations">
              <img
                src={img_bg_left}
                alt="buildings left"
                className="buildings left"
              />
              <img
                src={img_bg_right}
                alt="buildings right"
                className="buildings right"
              />
              <img src={img_newsletter_plane} alt="plane" className="plane" />
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
