import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import ReactSVG from 'react-svg';

//Components
import Card from '../Card';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.assessment
class AssessmentCard extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    this.props.openModalAction({
      type: Util.enum.ModalType.AssessmentModal,
      data: {
        assessmentId: this.props.assessment.assessmentId
      }
    });

    Util.analytics.track('SurveyClicked', {
      assessmentId: this.props.assessment.assessmentId,
      surveyId: this.props.assessment.surveyId,
      surveyUrlId: this.props.assessment.urlId,
      surveyName: this.props.assessment.name
    });
  }
  render() {
    let isCompleted = !!this.props.userProgressAssessment[
      this.props.assessment.assessmentId
    ];
    let title = this.props.assessment.name;
    let callToAction = 'Take Assessment';

    switch (this.props.assessment.type) {
      case Util.enum.AssessmentType.Survey:
        title = title + ' Survey';
        callToAction = 'Take Survey';
        break;
      default:
        break;
    }

    return (
      <Card
        className="assessment-card"
        isNeverLargeMode={this.props.isNeverLargeMode}
        onClick={this.onClick}
      >
        <div className="card-body">
          <div className="assessment-title">
            <div className="assessment-name mini-hidden">
              <Truncate component="h3" maxLine={1} text={title} />
            </div>
            <div className="assessment-name mini-only">
              <Truncate
                component="p"
                className="mini-only"
                maxLine={1}
                text={title}
              />
            </div>
            <ReactSVG src={Util.icon.assessment} className="assessment-icon" />
          </div>
          <div className="assessment-description">
            <Truncate
              component="p"
              maxLine={4}
              text={this.props.assessment.overview}
            />
          </div>
        </div>
        <div className="card-footer">
          <div className="footer-text">
            {isCompleted ? (
              <h5 className="status-label last-completed">
                <ReactSVG className="tick-icon" src={Util.icon.tick} />{' '}
                {Util.format.date.shortDate(
                  this.props.userProgressAssessment[
                    this.props.assessment.assessmentId
                  ]
                )}
              </h5>
            ) : null}
            <p>{callToAction}</p>
          </div>
          <ReactSVG
            className="footer-icon svg-button"
            src={Util.icon.arrow.right}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProgressAssessment: state.userState.userProgress.assessment
});

export default connect(
  mapStateToProps,
  { openModalAction }
)(AssessmentCard);
