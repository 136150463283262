import {
  APP_USER_AUTHENTICATED,
  APP_USER_LOGGED_OUT,
  APP_COMPLETED_ACTIVITY,
  APP_COMPLETED_VIDEO,
  APP_COMPLETED_ASSESSMENT,
  APP_COMPLETED_GAME,
  APP_COMPLETED_CONTENT
} from '../actions/types';

const initialState = {
  userProgress: {
    activity: {},
    assessment: {},
    content: {},
    game: {},
    video: {}
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_USER_AUTHENTICATED:
      return {
        ...state,
        userProgress: action.userProgress
      };

    case APP_USER_LOGGED_OUT:
      return initialState; // Reset the entire state to the initialState

    case APP_COMPLETED_ACTIVITY:
      return {
        ...state,
        //Update userProgress state
        userProgress: {
          ...state.userProgress,
          activity: {
            ...state.userProgress.activity,
            [action.activityId]: new Date()
          }
        }
      };

    case APP_COMPLETED_VIDEO:
      return {
        ...state,
        //Update userProgress state
        userProgress: {
          ...state.userProgress,
          video: {
            ...state.userProgress.video,
            [action.videoId]: new Date()
          }
        }
      };

    case APP_COMPLETED_ASSESSMENT:
      return {
        ...state,
        //Update userProgress state
        userProgress: {
          ...state.userProgress,
          assessment: {
            ...state.userProgress.assessment,
            [action.assessmentId]: new Date()
          }
        }
      };

    case APP_COMPLETED_GAME:
      return {
        ...state,
        //Update userProgress state
        userProgress: {
          ...state.userProgress,
          game: {
            ...state.userProgress.game,
            [action.gameId]: new Date()
          }
        }
      };

    case APP_COMPLETED_CONTENT:
      return {
        ...state,
        //Update userProgress state
        userProgress: {
          ...state.userProgress,
          content: {
            ...state.userProgress.content,
            [action.contentId]: new Date()
          }
        }
      };

    default:
      return state;
  }
}
