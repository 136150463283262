import React, { Component } from 'react';
import { connect } from 'react-redux';
import Util from '../../../../Util';

import Button from '../../../UI/Button/Button';
import FeatureBanner from '../FeatureBanner';

class StoryFeatureBanner extends Component {
  constructor(props) {
    super(props);

    let story = this.props.story;

    if (!story) {
      story = Util.context.referenceData.getStories().find(sto => {
        return this.props.teamId
          ? Util.context.user.getTeamById(this.props.teamId).featuredStoryId ===
              sto.storyId
          : sto.isFeatured;
      });
    }

    this.state = {
      story: story
    };
  }
  render() {
    if (!this.state.story) return null;

    let userCompletedChapters = this.state.story.chapters.filter(
      ch => !!this.props.userProgressVideo[ch.videoId]
    );
    let firstUnwatchedChapter = this.state.story.chapters
      .sort((i1, i2) => Util.sort.by('ordinal', i1, i2, Util.sort.asNumber))
      .find(ch => !userCompletedChapters.includes(ch));

    let linkTo = firstUnwatchedChapter
      ? Util.route.app.storyChapter(
          this.state.story.urlId,
          firstUnwatchedChapter.urlId
        )
      : Util.route.app.story(this.state.story.urlId);

    let buttonLabel =
      userCompletedChapters.length > 0 ? 'View Story' : 'Start Story';

    return (
      <FeatureBanner
        className="story-feature-banner"
        featureImage={
          <img
            className="banner-feature-image"
            src={Util.storage.storyIcon(this.state.story.urlId)}
            alt="Feature banner"
          />
        }
        style={{ backgroundColor: '#' + Util.css.colour.ledaLightBlue }}
        bannerIcon={Util.icon.story}
        bannerTitle="Featured Story"
      >
        <div className="banner-content">
          <h1 className="gilroy">{this.state.story.name}</h1>
          <p className="gilroy">{this.state.story.overview}</p>
          <h5 className="bold">
            {userCompletedChapters.length +
              '/' +
              this.state.story.chapters.length}{' '}
            watched
          </h5>
          <Button label={buttonLabel} size="lg" to={linkTo} />
        </div>
      </FeatureBanner>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(StoryFeatureBanner);
