import React, { Component } from 'react';
import validator from 'validator';
import withForm from '../withForm';

import Button from '../../Button/Button';

class LoginForm extends Component {
  render() {
    return (
      <form className="form-login" onSubmit={this.props.onSubmit}>
        {this.props.getField('email')}
        {this.props.getField('password')}
        <div className="button-container">
          <Button label="Login" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(LoginForm, {
  fields: {
    email: {
      label: 'Email',
      getError: val => {
        if (!validator.isEmail(val)) return 'Enter a valid email address';
      }
    },
    password: {
      label: 'Password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Enter a password (between 8 - 64 characters)';
      }
    }
  }
});
