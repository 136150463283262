import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

// Data source: this.props.activityId
export default class CompletedActivityLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      completedMyActivities: []
    };
  }
  componentDidMount() {
    if (this.props.activityId) {
      Util.api.post(
        '/api/myActivity/getCompletedMyActivitiesByActivityId',
        {
          activityId: this.props.activityId
        },
        {
          success: myActivities =>
            this.setState({
              completedMyActivities: myActivities,
              isLoading: false
            })
        }
      );
    }
  }
  render() {
    let content = null;

    if (this.state.isLoading) content = <div className="loader"></div>;

    return (
      <div className="completed-activity-log">
        {content}
        {this.state.completedMyActivities
          .sort((a1, a2) =>
            Util.sort.by('completedAt', a1, a2, Util.sort.asDate)
          )
          .map(myActivity => (
            <div
              className="log-item"
              key={myActivity.myActivityId}
              onClick={() => this.props.onItemClick(myActivity)}
            >
              <ReactSVG className="tick-icon" src={Util.icon.tick} />
              <div className="completed-at">
                {Util.format.date.fromNowReadable(myActivity.completedAt)}
              </div>
              <button className="view-link link-button">View</button>
            </div>
          ))}
      </div>
    );
  }
}
