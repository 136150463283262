import React, { Component } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';
import withForm from '../../withForm';
import Util from '../../../../../Util';

import Button from '../../../Button/Button';

class CreateTeamModalForm extends Component {
  state = {
    presets: []
  };
  componentDidMount() {}
  render() {
    return (
      <form
        className="form-admin-create-team-modal"
        onSubmit={this.props.onSubmit}
      >
        {this.props.getField('teamName')}
        {this.props.getField('preSettings')}
        {this.props.getField('startDate')}
        <div className="button-container">
          <Button
            label="Cancel"
            isSecondary={true}
            onClick={this.props.onCancel}
          />
          <Button label="Save and Next" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(
  connect(null, { openModalAction })(CreateTeamModalForm),
  {
    fields: {
      teamName: {
        label: 'Team Name',
        placeholder: 'Team name',
        getError: val => {
          if (!validator.isLength(val, { max: 250 }))
            return 'Max 250 characters';
        }
      },
      preSettings: {
        label: 'Pre-settings',
        type: Util.enum.FieldType.Dropdown,
        valueProp: 'presetId',
        displayProp: 'name',
        defaultValue: 1,
        placeholder: 'Choose',
        getOptions: ({ presets }) => {
          return Object.values(presets);
        }
      },
      startDate: {
        label: 'Start Date',
        placeholder: '',
        type: Util.enum.FieldType.DateField
      }
    }
  }
);
