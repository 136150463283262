import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Div100vh from 'react-div-100vh';
import Util from '../../../Util';

// Components
import AppUserNotificationsManager from '../../Misc/AppUserNotificationsManager/AppUserNotificationsManager';
import AppNavSidebar from '../../UI/AppNavSidebar/AppNavSidebar';
import AppHeader from '../../UI/AppHeader/AppHeader';
import AppFooter from '../../UI/AppFooter/AppFooter';

class AppFoundation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: Util.css.breakpoint.oversm()
    };

    this.foundationContentRef = React.createRef();

    this.setIsNavOpen = this.setIsNavOpen.bind(this);
  }
  componentDidMount() {
    this.props.history.listen((location, action) =>
      Util.scroll.to(0, this.foundationContentRef.current)
    );
  }
  setIsNavOpen(isOpen) {
    this.setState({
      isNavOpen: isOpen
    });
  }
  render() {
    return (
      <div
        className={`leda-foundation-app ${
          this.state.isNavOpen ? 'nav-open' : ''
        }`}
      >
        <AppNavSidebar
          isOpen={this.state.isNavOpen}
          toggleNav={() => this.setIsNavOpen(!this.state.isNavOpen)}
        />
        <div
          className="nav-mask"
          onClick={() => this.setIsNavOpen(false)}
        ></div>
        <Div100vh
          className="foundation-content"
          ref={this.foundationContentRef}
        >
          <AppHeader
            toggleNav={() => this.setIsNavOpen(!this.state.isNavOpen)}
          />
          {this.props.children}
          <AppFooter />
        </Div100vh>
        <AppUserNotificationsManager />
      </div>
    );
  }
}

export default withRouter(AppFoundation);
