import React, { Component } from 'react';

import Util from '../../../../Util';
import withForm from '../../../UI/Forms/withForm';

import Button from '../../../UI/Button/Button';

class AdminTeamEditorGeneral extends Component {
  render() {
    return (
      <form className="admin-form-team-editor" onSubmit={this.props.onSubmit}>
        <br />
        {this.props.getField('preset')}
        <br />
        {this.props.getField('startDate')}
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
        </div>
        <div style={{ height: '100px' }}></div>
      </form>
    );
  }
}

export default withForm(AdminTeamEditorGeneral, {
  fields: {
    preset: {
      label: 'Pre settings',
      type: Util.enum.FieldType.Dropdown,
      valueProp: 'presetId',
      displayProp: 'name',
      defaultValue: '',
      placeholder: 'Select Preset',
      getOptions: presets => {
        return Object.values(presets);
      }
    },
    startDate: {
      label: 'Start Date',
      type: Util.enum.FieldType.DateField,
      isOptional: false
    }
  }
});
