import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import GameCard from '../../Card/GameCard/GameCard';
import CardsView from '../../CardsView/CardsView';

// Data source: this.props.dataUrl or this.props.games
export default class GameCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.games,
      games: this.props.games || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: result => {
        this.setState({
          games: result
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (Util.array.none(this.state.games)) {
      content = <div className="empty-text">No games to display.</div>;
    }

    return (
      <CardsView
        className="game-cards-view"
        isNeverLargeMode={this.props.isNeverLargeMode}
      >
        {content}
        {this.state.games.map(game => (
          <GameCard
            key={game.gameId}
            game={game}
            isNeverLargeMode={this.props.isNeverLargeMode}
          />
        ))}
      </CardsView>
    );
  }
}
