import React, { Component } from 'react';
import validator from 'validator';
import Util from '../../../../../Util';
import withForm from '../../withForm';

import Button from '../../../Button/Button';

class AdminTeamPresetEditorForm extends Component {
  render() {
    return (
      <form className="admin-form-preset-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('name')}
        {this.props.getField('description')}
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(AdminTeamPresetEditorForm, {
  fields: {
    name: {
      label: 'Preset name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a preset name';
      }
    },
    description: {
      label: 'Preset description',
      type: Util.enum.FieldType.Textarea,
      isOptional: true,
      getError: val => {
        if (!validator.isLength(val, { max: 2000 }))
          return 'Preset description too long (max 2,000 characters)';
      }
    }
  }
});
