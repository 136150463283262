import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

export default class SiteTermsConditionsPage extends Component {
  render() {
    return (
      <div className="leda-page-site-terms-conditions">
        <SiteLayout pageTitle="Terms and Conditions">
          <div className="leda-container-md">
            <div className="leda-row">
              <section className="gilroy">
                <p>Productive Procrastination PTY LTD Terms of Service</p>
                <p>Thanks for using Leda.</p>
                <p>
                  Access to and use of Leda is provided by Productive
                  Procrastination Pty Ltd&nbsp;(
                  <strong>&ldquo;Productive Procrastination&rdquo;</strong>
                  ,&nbsp;<strong>&ldquo;we&rdquo;</strong>&nbsp;&lsquo;&rsquo;
                  <strong>our</strong>&rsquo;&rsquo;or&nbsp;
                  <strong>&ldquo;us&rdquo;</strong>).
                </p>
                <p>
                  These Terms of Service (these&nbsp;
                  <strong>&ldquo;Terms&rdquo;</strong>) describe your rights and
                  responsibilities as a customer of Productive Procrastination.
                </p>
                <p>
                  By accessing Leda, you agree that these Terms are legally
                  binding, and you agree to be bound by these Terms. If you do
                  not agree with any part of these terms and conditions, you
                  must not access or use any part of Leda.
                </p>
                <p>
                  <strong>&ldquo;You&rdquo;</strong>&nbsp;means the entity you
                  represent in accepting these Terms or, if you are not using
                  Leda for and on behalf of an entity, these Terms apply to you
                  individually.
                </p>
                <p>
                  If you are accepting these Terms on behalf of your employer or
                  another entity, you represent and warrant that:
                </p>
                <ul>
                  <li>
                    you have full legal authority to bind your employer or such
                    entity to these Terms;
                  </li>
                  <li>you have read and understand these Terms; and</li>
                  <li>
                    you agree to these Terms on behalf of the party that you
                    represent.
                  </li>
                </ul>
                <p>
                  If you don&rsquo;t have the legal authority to bind your
                  employer or the applicable entity please do not click &ldquo;I
                  agree&rdquo; (or similar button or checkbox) that is presented
                  to you.
                </p>
                <p>
                  <strong>
                    PLEASE NOTE THAT IF YOU SIGN UP TO LEDA USING AN EMAIL
                    ADDRESS FROM YOUR EMPLOYER OR ANOTHER ENTITY, THEN:
                  </strong>
                </p>
                <ul>
                  <li>
                    <strong>YOU WILL BE DEEMED TO REPRESENT SUCH PARTY;</strong>
                  </li>
                  <li>
                    <strong>
                      YOUR CLICK TO ACCEPT, OR ACCESSING OF LEDA, WILL BIND YOUR
                      EMPLOYER OR THAT ENTITY TO THESE TERMS; AND{' '}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      THE WORD &ldquo;YOU&rdquo; IN THESE TERMS WILL REFER TO
                      YOUR EMPLOYER OR THAT ENTITY.
                    </strong>
                  </li>
                </ul>
                <p>
                  <strong>
                    <em>
                      These Terms are effective as of the date you first click
                      &ldquo;I agree&rdquo; (or similar button or checkbox) and
                      create an account (&ldquo;Leda Account&rdquo;) or use or
                      access Leda, whichever is earlier (the &ldquo;Effective
                      Date&rdquo;).{' '}
                    </em>
                  </strong>
                </p>
                <p>
                  <strong>
                    <em>
                      These Terms do not have to be signed in order to be
                      binding.{' '}
                    </em>
                  </strong>
                  In order to access and use any of our products and services
                  and to indicate your assent to these Terms, you will need to
                  click{' '}
                  <strong>
                    <em>
                      &ldquo;I agree&rdquo; (or similar button or checkbox) at
                      the time you register for Leda create a Leda Account, or
                      place an Order.&nbsp;For No-Charge Products, you also
                      indicate your assent to these Terms by clicking &ldquo;I
                      Agree&rdquo; (or a similar button or checkbox) at the time
                      you register for Leda, or access or use the applicable
                      No-Charge Product.{' '}
                    </em>
                  </strong>
                </p>
                <ol>
                  <li>
                    <strong> What these Terms cover.</strong>
                  </li>
                </ol>
                <p>
                  <strong>1.1.</strong>Leda. These Terms govern Leda, your Leda
                  Account, related Support, and Additional Services. These Terms
                  include Our Policies (including our&nbsp;
                  <Link to={Util.route.site.privacyPolicy()}>
                    Privacy Policy
                  </Link>
                  ), and your Orders.
                </p>
                <ol start="2">
                  <li>
                    <strong> How Leda is administered.</strong>
                  </li>
                </ol>
                <p>
                  <strong>2.1.</strong>Administrators. Through Leda, you may be
                  able to specify certain End Users as Administrators, who will
                  have important rights and controls over your use of your Leda
                  Account and End User Accounts. This may include making Orders
                  on Leda, creating, de-provisioning, monitoring or modifying
                  End User Accounts, and setting End User usage permissions and
                  managing access to Your Data by End Users or others.
                </p>
                <p>
                  Administrators may also take over management of accounts
                  previously registered using an email address belonging to your
                  domain. Without limiting Section 2.4 (Responsibility for End
                  Users), which fully applies to Administrators, you are
                  responsible for whom you allow to become Administrators and
                  any actions they take, including as described above. You agree
                  that our responsibilities do not extend to the internal
                  management or administration of your Leda accounts for you.
                </p>
                <p>
                  <strong>2.3.</strong>End User Consent.&nbsp;You will provide
                  all required disclosures to and will obtain and maintain all
                  required consents from End Users to allow:
                </p>
                <ul>
                  <li>
                    Administrators to have the access described in these Terms;
                    and
                  </li>
                  <li>
                    Productive Procrastination&rsquo;s provision of Leda to
                    Administrators and End Users. You will provide evidence of
                    such consents upon our reasonable request.&nbsp;
                  </li>
                </ul>
                <p>
                  <strong>2.4.</strong>Responsibility for End Users. Leda has
                  various user onboarding flows. Some Leda onboarding flows
                  require users to be designated by Administrators; some allow
                  users to sign up for individual accounts which can become
                  associated with teams or organisations at a later time; and
                  some may allow users to invite other users. You are
                  responsible for understanding the settings and controls for
                  each onboarding flow you use and for controlling whom you
                  allow to become an End User. If payment is required for End
                  Users to use or access Leda, then we are only required to
                  provide Leda to those End Users for whom you have paid the
                  applicable fees, and only such End Users are permitted to
                  access and use Leda. You are responsible for compliance with
                  these Terms by all End Users, including for any payment
                  obligations. Please note that you are responsible for the
                  activities of all your End Users, including how End Users use
                  Your Data, even if those End Users are not from your
                  organization or domain.
                </p>
                <p>
                  We also require any End User to click &ldquo;I agree&rdquo;
                  (or similar button or checkbox) at the time each End User logs
                  into their End User Account, either at sign up, account
                  creation, or in-product.
                </p>
                <p>
                  <strong>2.5.</strong>Credentials.&nbsp;You must require that
                  all End Users keep their user IDs and passwords for Leda
                  strictly confidential and do not share such information with
                  any unauthorized person. User IDs are granted to individual,
                  named persons and may not be shared. You are responsible for
                  any and all actions taken using End User Accounts and
                  passwords, and you agree to immediately notify us of any
                  unauthorised use of which you become aware.
                </p>
                <p>
                  <strong>2.6.</strong>Age Requirement for End Users.&nbsp;Leda
                  is not intended for, and should not be used by, anyone under
                  the age of 16. You are responsible for ensuring that all End
                  Users are at least 16 years old.
                </p>
                <ol start="3">
                  <li>
                    <strong>
                      {' '}
                      What&rsquo;s included in your Leda subscriptions and what
                      are the restrictions.
                    </strong>
                  </li>
                </ol>
                <p>
                  <strong>3.1.</strong>Access to Leda.&nbsp;Subject to these
                  Terms and during the applicable Subscription Term, you may
                  access and use Leda for your own business purposes or personal
                  use, as applicable, all in accordance with these Terms, the
                  applicable Order and the Documentation. The rights granted to
                  you in this Section 3.1 are non-exclusive, non-sublicensable
                  and non-transferable.
                </p>
                <p>
                  <strong>3.2.</strong>Support.&nbsp;Unless otherwise agreed in
                  your order form, Leda is provided &ldquo;as-is&rdquo; and no
                  Support is provided.
                </p>
                <p>
                  <strong>3.3.</strong>Restrictions.&nbsp;Except as otherwise
                  expressly permitted in these Terms, you will not:
                </p>
                <ul>
                  <li>
                    reproduce, modify, adapt or create derivative works of Leda;
                  </li>
                  <li>
                    rent, lease, distribute, sell, sublicense, transfer or
                    provide access to Leda to a third party; (c) use Leda for
                    the benefit of any third party;
                  </li>
                  <li>
                    incorporate any Leda into a product or service you provide
                    to a third party;
                  </li>
                  <li>
                    interfere with or otherwise circumvent mechanisms in Leda
                    intended to limit your use;
                  </li>
                  <li>
                    reverse engineer, disassemble, decompile, translate or
                    otherwise seek to obtain or derive the source code,
                    underlying ideas, algorithms, file formats or non-public
                    APIs to any Leda, except to the extent expressly permitted
                    by applicable law (and then only upon advance notice to us);
                  </li>
                  <li>
                    remove or obscure any proprietary or other notices contained
                    in any Leda;
                  </li>
                  <li>
                    use Leda for competitive analysis or to build competitive
                    products;
                  </li>
                  <li>
                    publicly disseminate information regarding the performance
                    of Leda; or
                  </li>
                  <li>
                    encourage or assist any third party to do any of the
                    foregoing.
                  </li>
                </ul>
                <ol start="4">
                  <li>
                    <strong> Our security and data privacy policies.</strong>
                  </li>
                </ol>
                <p>
                  <strong>4.1.</strong>Security. We implement security
                  procedures designed to help protect Your Data from security
                  attacks.
                </p>
                <p>
                  <strong>4.2.</strong>Privacy. We collect certain data and
                  information about you and your End Users in connection with
                  your and your End Users&rsquo; use of Leda and otherwise in
                  connection with these Terms. Other than personally
                  identifiable information, which is covered under our{' '}
                  <Link to={Util.route.site.privacyPolicy()}>
                    Privacy Policy
                  </Link>
                  , any material that you transmit or post to our website will
                  be considered non-confidential and non-proprietary. We will
                  have no obligations with respect to such material. We and our
                  related entities, officers, directors, shareholders and agents
                  will be free to copy, disclose, distribute, incorporate and
                  otherwise use such material and all data, images, sounds, text
                  and other things embodied therein for any and all commercial
                  or non-commercial purposes pursuant to clause 5.1 below.
                </p>
                <p>
                  <strong>4.3.</strong>Improving Leda. We are always striving to
                  improve Leda. In order to do so, we use [analytics techniques]
                  to better understand how our Leda is being used. [For more
                  information on these techniques and the type of data
                  collected, please read our&nbsp;
                  <Link to={Util.route.site.privacyPolicy()}>
                    Privacy Policy
                  </Link>
                  .]
                </p>
                <ol start="5">
                  <li>
                    <strong> Terms that apply to Your Data.</strong>
                  </li>
                </ol>
                <p>
                  <strong>5.1.</strong>Using Your Data to provide Leda to You.
                  You retain all right, title and interest in and to Your Data
                  in the form submitted to Leda. Subject to these Terms, and
                  solely to the extent necessary to provide Leda to you, you
                  grant us a worldwide, limited term license to access, use,
                  process, copy, distribute, perform, export, and
                  display&nbsp;Your Data. Solely to the extent that reformatting
                  Your Data for display in Leda constitutes a modification or
                  derivative work, the foregoing license also includes the right
                  to make modifications and derivative works. We may also access
                  your accounts, and End User Accounts with End User permission
                  in order to respond to your support requests.
                </p>
                <p>
                  Nothing in these Terms prevents us from disclosing Your Data
                  to the extent required by law, subpoenas or court orders, but
                  we will use commercially reasonable efforts to notify you
                  where permitted to do so.
                </p>
                <p>
                  <strong>5.2.</strong>Your Data Compliance
                  Obligations.&nbsp;You and your use of Leda (including use by
                  your End Users) must comply at all times with these Terms,
                  [the&nbsp;Acceptable Use Policy]&nbsp;and all Laws.
                </p>
                <p>You represent and warrant that:</p>
                <ul>
                  <li>
                    you have obtained all necessary rights, releases and
                    permissions to submit all Your Data to Leda and to grant the
                    rights granted to us in these Terms; and
                  </li>
                  <li>
                    Your Data and its submission and use as you authorizes in
                    these Terms will not violate (1) any Laws, (2) any
                    third-party intellectual property, privacy, publicity or
                    other rights, or (3) any of your or third-party policies or
                    terms governing Your Data.
                  </li>
                </ul>
                <p>
                  You are not permitted to post or transmit to or from Leda any
                  material:
                </p>
                <ul>
                  <li>
                    that is unlawful, threatening, abusive, defamatory, obscene,
                    indecent, seditious, offensive, pornographic, liable to
                    incite racial hatred, discriminatory, menacing, scandalous,
                    inflammatory, blasphemous, in breach of confidence, in
                    breach of privacy or which may cause annoyance or
                    inconvenience; or
                  </li>
                  <li>
                    which constitutes or encourages conduct that would be
                    considered a criminal offence, give rise to civil liability,
                    or otherwise be contrary to the law of or infringe the
                    rights of any third party, in any country in the world; or
                  </li>
                  <li>
                    which is technically harmful (including, without limitation,
                    computer viruses, logic bombs, Trojan horses, worms, harmful
                    components, corrupted data or other malicious software or
                    harmful data) or otherwise affects the functionality or
                    operation of our website or its serves or the functionality
                    or operation of any other users' computer systems; or
                  </li>
                  <li>
                    which violates or infringes the rights of others (including
                    their intellectual property, privacy and publicity rights).
                  </li>
                </ul>
                <p>
                  Other than our express obligations under Section 4 (Our
                  security and data privacy policies), we assume no
                  responsibility or liability for Your Data, and you are solely
                  responsible for Your Data and the consequences of submitting
                  and using it with Leda.
                </p>
                <p>
                  <strong>[5.3.</strong>No Sensitive Data.&nbsp;You will not
                  submit to Leda (or use Leda to collect) any [Sensitive Data.]
                  You also acknowledge that we are not acting as your [Business
                  Associate] or subcontractor. Notwithstanding any other
                  provision to the contrary, we have no liability under these
                  Terms for Sensitive Data.]
                </p>
                <p>
                  <strong>5.4.</strong>Your Indemnity.&nbsp;You will defend,
                  indemnify and hold harmless us (and our Affiliates, officers,
                  directors, agents and employees) from and against any and all
                  claims, costs, damages, losses, liabilities and expenses
                  (including reasonable attorneys&rsquo; fees and costs)
                  resulting from any claim arising from or related to, including
                  but not limited to:
                </p>
                <ul>
                  <li>
                    your breach of Section 2.3 (End User Consent) or any claims
                    or disputes brought by your End Users arising out of their
                    use of Leda;
                  </li>
                  <li>
                    your breach (or alleged breach) of Sections 5.2 (Your Data
                    Compliance Obligations) or [5.3 (No Sensitive Data)];
                  </li>
                  <li>any other breach of these Terms; or</li>
                  <li>Your Materials</li>
                </ul>
                <p>
                  <strong>5.5.</strong>Removals and Suspension.&nbsp;We have no
                  obligation to monitor any content uploaded to Leda.
                  Nonetheless, if we deem such action necessary based on your
                  violation of these Terms, including Our Policies, we may (1)
                  remove Your Data from Leda or (2) suspend your access to Leda.
                  We will use reasonable efforts to provide you with advance
                  notice of removals and suspensions when practicable, but if we
                  determine that your actions endanger the operation of Leda or
                  other users, we may suspend your access or remove Your Data
                  immediately without notice. We have no liability to you for
                  removing or deleting Your Data from or suspending your access
                  to any Leda as described in this Section 5.5.
                </p>
                <ol start="9">
                  <li>
                    <strong> Billing, renewals, and payment.</strong>
                  </li>
                </ol>
                <p>
                  <strong>9.1.</strong>Monthly and Annual Plans. Except for
                  No-Charge Products, and where specified in your Order, Leda is
                  offered either on a monthly subscription basis or an annual
                  subscription basis.
                </p>
                <p>
                  <strong>9.2.</strong>Renewals.&nbsp;Except as otherwise
                  specified in your Order, unless either party cancels your
                  subscription prior to expiration of the current Subscription
                  Term, your subscription will automatically renew for another
                  Subscription Term of a period equal to your initial
                  Subscription Term. You will provide any notice of non-renewal
                  through the means we designate, which may include account
                  settings in Leda or contacting our support team. Cancelling
                  your subscription means that you will not be charged for the
                  next billing cycle, but you will not receive any refunds or
                  credits for amounts that have already been charged. All
                  renewals are subject to the applicable Leda continuing to be
                  offered and will be charged at the then-current rates.
                </p>
                <p>
                  <strong>9.3.</strong>Adding Users.&nbsp;You may add users,
                  increase storage limits, or otherwise increase your use of
                  Leda by placing a new Order or modifying an existing Order.
                  Unless otherwise specified in the applicable Order, we will
                  charge you for any increased use at our then-current rates,
                  prorated for the remainder of the then-current Subscription
                  Term.
                </p>
                <p>
                  <strong>9.4.</strong>Payment.&nbsp;You will pay all fees in
                  accordance with each Order, by the due dates and in the
                  currency specified in the Order. If a PO number is required in
                  order for an invoice to be paid, then you must provide such PO
                  number to Productive Procrastination by emailing the PO number
                  to&nbsp;hello@getleda.com. You agree that we may bill your
                  credit card or other payment method for renewals, additional
                  users, overages to set limits or scopes of use, expenses, and
                  unpaid fees, as applicable.
                </p>
                <p>
                  <strong>9.5.</strong>Delivery.&nbsp;We will deliver the login
                  instructions for Leda to your&nbsp;account or through other
                  reasonable means no later than when we have received payment
                  of the applicable fees. You are responsible for accessing your
                  account to determine that we have received payment and that
                  your Order has been processed. All deliveries under these
                  Terms will be electronic.
                </p>
                <ol start="10">
                  <li>
                    <strong> Our return policy.&nbsp;</strong>As part of our
                    commitment to customer satisfaction and without limiting the
                    Performance Warranty in Section 18 (Warranties and
                    Disclaimer), [you may terminate your initial Order of Leda
                    under these Terms, for no reason or any reason, by providing
                    notice of termination to us no later than thirty (30) days
                    after the Order date. In the event you terminate your
                    initial Order under this Section 10, at your request (which
                    may be made through your account with us), we will refund
                    you the amount paid under such Order.] This termination and
                    refund right applies only to your initial Order of Leda and
                    only if you exercise your termination right within the
                    period specified above, and does not apply to Additional
                    Services. You understand that we may change this practice in
                    the future in accordance with Section 24 (Changes to these
                    Terms).
                  </li>
                  <li>
                    <strong> Taxes not included.</strong>
                  </li>
                </ol>
                <p>
                  <strong>11.1.</strong>Taxes. Your fees under these Terms
                  exclude any taxes or duties payable in respect of Leda in the
                  jurisdiction where the payment is either made or received. To
                  the extent that any such taxes or duties are payable by us,
                  you must pay to us the amount of such taxes or duties in
                  addition to any fees owed under these Terms. Notwithstanding
                  the foregoing, if you have obtained an exemption from relevant
                  taxes or duties as of the time such taxes or duties are levied
                  or assessed, you may provide us with such exemption
                  information, and we will use reasonable efforts to provide you
                  with invoicing documents designed to enable you to obtain a
                  refund or credit from the relevant revenue authority, if such
                  a refund or credit is available.
                </p>
                <p>
                  <strong>11.2.</strong>Withholding Taxes.&nbsp;You will pay all
                  fees net of any applicable withholding taxes. You and we will
                  work together to avoid any withholding tax if exemptions, or a
                  reduced treaty withholding rate, are available. If we qualify
                  for a tax exemption, or a reduced treaty withholding rate, we
                  will provide you with reasonable documentary proof. You will
                  provide us reasonable evidence that you have paid the relevant
                  authority for the sum withheld or deducted.
                  <strong>
                    13. No contingencies on other products of future
                    functionality.&nbsp;
                  </strong>{' '}
                  You agree that your purchases are not contingent on the
                  delivery of any future functionality or features (including
                  future availability of any Leda beyond the current
                  Subscription Term), or dependent on any oral or written public
                  comments we make regarding future functionality or features.
                </p>
                <ol start="14">
                  <li>
                    <strong> Evaluations, trials, and betas.</strong>
                  </li>
                </ol>
                <p>
                  14.1 <strong>Evaluations, trials, and betas.</strong>
                </p>
                <p>
                  We may offer Leda or Leda features to you at no charge,
                  including free accounts, trial use and Beta Versions as
                  defined below (collectively,&nbsp;
                  <strong>&ldquo;No-Charge Products&rdquo;</strong>). Your use
                  of No-Charge Products is subject to any additional terms that
                  we specify and is only permitted during the Subscription Term
                  we designate (or, if not designated, until terminated in
                  accordance with these Terms). Except as otherwise set forth in
                  this Section 14.1, the terms and conditions of these Terms
                  governing Leda, including Section 3.3 (Restrictions), fully
                  apply to No-Charge Products. We may modify or terminate your
                  right to use No-Charge Products at any time and for any reason
                  in our sole discretion, without liability to you. You
                  understand that any pre-release and beta products, and any
                  pre-release and beta features within generally available Leda
                  products or features, that we make available (collectively,
                  &ldquo;<strong>Beta Versions</strong>&rdquo;) are still under
                  development, may be inoperable or incomplete and are likely to
                  contain more errors and bugs than generally available Leda
                  products. We make no promises that any Beta Versions will ever
                  be made generally available. In some circumstances, we may
                  charge a fee in order to allow you to access Beta Versions,
                  but the Beta Versions will still remain subject to this
                  Section 14.1. All information regarding the characteristics,
                  features or performance of any No-Charge Products (including
                  Beta Versions) constitutes our Confidential Information.
                </p>
                <p>14.2 Exclusion of liability</p>
                <p>
                  To the maximum extent permitted by applicable law, we
                  (together with our related entities, and our officers,
                  directors, employees, shareholders and agents) expressly
                  exclude all obligations or liabilities whatsoever for any loss
                  or damage of any kind (including without limitation any
                  direct, indirect, special, or consequential loss or damage, or
                  any loss of income, profits, goodwill, data, contracts, or
                  loss or damage arising from or connected in any way to
                  business interruption) whether arising in tort, contract or
                  otherwise, that arises out of or in connection with your use
                  of Leda or the material on Leda, including but not limited to
                  loss or damage due to viruses that may infect your computer
                  equipment, software, data or other property on account of your
                  access to or use of Leda or your downloading of any material
                  from Leda. Nothing in this paragraph will exclude or limit
                  liability for fraud or any liability which cannot be excluded
                  or limited under applicable law.
                </p>
                <p>
                  By using Leda, you agree that the exclusions and limitations
                  of liability set out in these Terms are reasonable.
                </p>
                <ol start="15">
                  <li>
                    <strong> IP Rights in Leda and Feedback.</strong>Leda is
                    made available on a limited access basis, and no ownership
                    right is conveyed to you, irrespective of the use of terms
                    such as &ldquo;purchase&rdquo; or &ldquo;sale&rdquo;. We and
                    our licensors have and retain all right, title and interest,
                    including all intellectual property rights, in and to Our
                    Technology. From time to time, you may choose to submit
                    Feedback to us. We may in connection with any of our
                    products or services freely use, copy, disclose, license,
                    distribute and exploit any Feedback in any manner without
                    any obligation, royalty or restriction based on intellectual
                    property rights or otherwise. No Feedback will be considered
                    your Confidential Information, and nothing in these Terms
                    limits our right to independently use, develop, evaluate, or
                    market products or services, whether incorporating Feedback
                    or otherwise.
                  </li>
                  <li>
                    <strong> Confidentiality.</strong>Except as otherwise set
                    forth in these Terms, each party agrees that all code,
                    inventions, know-how and business, technical and financial
                    information disclosed to such party (
                    <strong>&ldquo;Receiving Party&rdquo;</strong>) by the
                    disclosing party (
                    <strong>&ldquo;Disclosing Party&rdquo;</strong>) constitute
                    the confidential property of the Disclosing Party (
                    <strong>&ldquo;Confidential Information&rdquo;</strong>),
                    provided that it is identified as confidential at the time
                    of disclosure or should be reasonably known by the Receiving
                    Party to be confidential or proprietary due to the nature of
                    the information disclosed and the circumstances surrounding
                    the disclosure. Any of Our Technology and any performance
                    information relating to Leda will be deemed our Confidential
                    Information without any marking or further designation.
                    Except as expressly authorised herein, the Receiving Party
                    will (1) hold in confidence and not disclose any
                    Confidential Information to third parties and (2) not use
                    Confidential Information for any purpose other than
                    fulfilling its obligations and exercising its rights under
                    these Terms. The Receiving Party may disclose Confidential
                    Information to its employees, agents, contractors and other
                    representatives having a legitimate need to know, provided
                    that they are bound to confidentiality obligations no less
                    protective of the Disclosing Party than this Section 16 and
                    that the Receiving Party remains responsible for compliance
                    by them with the terms of this Section 16. The Receiving
                    Party&rsquo;s confidentiality obligations will not apply to
                    information which the Receiving Party can document:
                  </li>
                </ol>
                <ul>
                  <li>
                    was rightfully in its possession or known to it prior to
                    receipt of the Confidential Information;
                  </li>
                  <li>
                    is or has become public knowledge through no fault of the
                    Receiving Party;
                  </li>
                  <li>
                    is rightfully obtained by the Receiving Party from a third
                    party without breach of any confidentiality obligation; or
                  </li>
                  <li>
                    is independently developed by employees of the Receiving
                    Party who had no access to such information.
                  </li>
                </ul>
                <p>
                  The Receiving Party may also disclose Confidential Information
                  if so required pursuant to a regulation, law or court order
                  (but only to the minimum extent required to comply with such
                  regulation or order and with advance notice to the Disclosing
                  Party). The Receiving Party acknowledges that disclosure of
                  Confidential Information would cause substantial harm for
                  which damages alone would not be a sufficient remedy, and
                  therefore that upon any such disclosure by the Receiving Party
                  the Disclosing Party will be entitled to appropriate equitable
                  relief in addition to whatever other remedies it might have at
                  law.
                </p>
                <ol start="17">
                  <li>
                    <strong> Term and Termination.</strong>
                  </li>
                </ol>
                <p>
                  <strong>17.1.</strong>Term. These Terms are effective as of
                  the Effective Date and expire on the date of expiration or
                  termination of all Subscription Terms.
                </p>
                <p>
                  <strong>17.2.</strong>Termination for Cause. Either party may
                  terminate these Terms (including all related Orders) if the
                  other party:
                </p>
                <ul>
                  <li>
                    fails to cure any material breach of these Terms within
                    thirty (30) days after notice;
                  </li>
                  <li>ceases operation without a successor; or</li>
                  <li>
                    seeks protection under any bankruptcy, receivership, trust
                    deed, creditors&rsquo; arrangement, composition or
                    comparable proceeding, or if any such proceeding is
                    instituted against that party (and not dismissed within
                    sixty (60) days thereafter).
                  </li>
                </ul>
                <p>
                  <strong>17.3.</strong>Termination for Convenience.&nbsp;You
                  may choose to stop using Leda and terminate these Terms
                  (including all Orders) at any time for any reason upon written
                  notice to us, but, unless you are exercising your right to
                  terminate early pursuant to Section 10 (Our return policy),
                  upon any such termination:
                </p>
                <ul>
                  <li>
                    you will not be entitled to a refund of any pre-paid fees;
                    and
                  </li>
                  <li>
                    if you have not already paid all applicable fees for the
                    then-current Subscription Term or related services period
                    (as applicable), any such fees that are outstanding will
                    become immediately due and payable.
                  </li>
                </ul>
                <p>
                  <strong>17.4.</strong>Effects of Termination.&nbsp;Upon any
                  expiration or termination of these Terms, you must cease using
                  all Leda and delete (or at our request, return) all
                  Confidential Information or other materials of ours in your
                  possession, including on any third-party systems operated on
                  your behalf. You will certify such deletion upon our request.
                  You will not have access to Your Data (and we may delete all
                  of Your Data unless legally prohibited) after expiration or
                  termination of these Terms (or its applicable Subscription
                  Term), so you should make sure to export Your Data using the
                  functionality of Leda during the applicable Subscription Term.
                </p>
                <p>
                  If you terminate these Terms in accordance with Section 17.2
                  (Termination for Cause), we will refund you any prepaid fees
                  covering the remainder of the then-current Subscription Term
                  after the effective date of termination.
                </p>
                <p>
                  If we terminate these Terms in accordance with Section 17.2
                  (Termination for Cause), you will pay any unpaid fees covering
                  the remainder of the then-current Subscription Term after the
                  effective date of termination. In no event will termination
                  relieve you of your obligation to pay any fees payable to us
                  for the period prior to the effective date of termination.
                  Except where an exclusive remedy may be specified in these
                  Terms, the exercise by either party of any remedy, including
                  termination, will be without prejudice to any other remedies
                  it may have under these Terms, by law or otherwise.
                </p>
                <p>
                  <strong>17.5.</strong>Survival.&nbsp;The following provisions
                  will survive any termination or expiration of these Terms:
                  Sections 3.3 (Restrictions), 5.4 (Your Indemnity), 6.1
                  (Third-Party Products), 9.4 (Payment), 11 (Taxes not
                  included), 14 (Evaluations, trials, and betas), 15 (IP Rights
                  in Leda and Feedback), 16 (Confidentiality), 17 (Term and
                  Termination), 18.4 (Warranty Disclaimer), 19 (Limitations of
                  Liability), 20 (IP Indemnification) (but solely with respect
                  to claims arising from your use of Leda during the
                  Subscription Term), 22 (Dispute Resolution) and 26 (General
                  Provisions).
                </p>
                <p>
                  <strong>18 Warranties and Disclaimer.</strong>
                </p>
                <p>
                  <strong>18.1.</strong>Mutual Warranties. Each party represents
                  and warrants that it has the legal power and authority to
                  enter into these Terms.
                </p>
                <p>
                  <strong>18.2.</strong>Our Warranties. We warrant, for your
                  benefit only, that we use commercially reasonable efforts to
                  prevent introduction of viruses, Trojan horses or similar
                  harmful materials into Leda (but we are not responsible for
                  harmful materials submitted by you or End Users) (the&nbsp;
                  <strong>&ldquo;Performance Warranty&rdquo;</strong>).
                </p>
                <p>
                  <strong>18.3.</strong>Warranty Remedy.&nbsp;We will use
                  commercially reasonable efforts, at no charge to you, to
                  correct reported non-conformities with the Performance
                  Warranty. If we determine corrections to be impracticable,
                  either party may terminate the applicable Subscription Term.
                  In this case, you will receive a refund of any fees you have
                  pre-paid for use of Leda for the terminated portion of the
                  applicable Subscription Term. The Performance Warranty will
                  not apply:
                </p>
                <p>
                  (i) unless you make a claim within thirty (30) days of the
                  date on which you first noticed the non-conformity;
                </p>
                <p>
                  (ii) if the non-conformity was caused by misuse, unauthorized
                  modifications or third-party products, software, services or
                  equipment or
                </p>
                <ul>
                  <li>to No-Charge Products.</li>
                </ul>
                <p>
                  Our sole liability, and your sole and exclusive remedy, for
                  any breach of the Performance Warranty are set forth in this
                  Section 18.
                </p>
                <p>
                  <strong>18.4.</strong>WARRANTY DISCLAIMER.&nbsp;EXCEPT AS
                  EXPRESSLY PROVIDED IN THIS SECTION 18, ALL LEDA, SUPPORT AND
                  ADDITIONAL SERVICES ARE PROVIDED &ldquo;AS IS,&rdquo; AND WE
                  AND OUR [SUPPLIERS] EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES
                  AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTY OF
                  NON-INFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
                  FUNCTIONALITY OR MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR
                  STATUTORY.
                </p>
                <p>
                  WITHOUT LIMITING OUR EXPRESS OBLIGATIONS IN THESE TERMS, WE DO
                  NOT WARRANT THAT YOUR USE OF LEDA WILL BE UNINTERRUPTED OR
                  ERROR-FREE, THAT WE WILL REVIEW YOUR DATA FOR ACCURACY OR THAT
                  WE WILL PRESERVE OR MAINTAIN YOUR DATA WITHOUT LOSS.
                </p>
                <p>
                  YOU UNDERSTAND THAT USE OF LEDA NECESSARILY INVOLVES
                  TRANSMISSION OF YOUR DATA OVER NETWORKS THAT WE DO NOT OWN,
                  OPERATE OR CONTROL, AND WE ARE NOT RESPONSIBLE FOR ANY OF YOUR
                  DATA LOST, ALTERED, INTERCEPTED OR STORED ACROSS SUCH
                  NETWORKS.
                </p>
                <p>
                  WE CANNOT GUARANTEE THAT OUR SECURITY PROCEDURES WILL BE
                  ERROR-FREE, THAT TRANSMISSIONS OF YOUR DATA WILL ALWAYS BE
                  SECURE OR THAT UNAUTHORISED THIRD PARTIES WILL NEVER BE ABLE
                  TO DEFEAT OUR SECURITY MEASURES OR THOSE OF OUR THIRD PARTY
                  SERVICE PROVIDERS. WE WILL NOT BE LIABLE FOR DELAYS,
                  INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN
                  USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER
                  SYSTEMS OUTSIDE OUR REASONABLE CONTROL. YOU MAY HAVE OTHER
                  STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED
                  WARRANTIES, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD
                  PERMITTED BY LAW.
                </p>
                <ol start="19">
                  <li>
                    <strong> Limitation of Liability.</strong>
                  </li>
                </ol>
                <p>
                  <strong>19.1.</strong>Consequential Damages Waiver. NEITHER
                  PARTY (NOR ITS SUPPLIERS) WILL HAVE ANY LIABILITY ARISING OUT
                  OF OR RELATED TO THESE TERMS FOR ANY LOSS OF USE, LOST OR
                  INACCURATE DATA, LOST PROFITS, FAILURE OF SECURITY MECHANISMS,
                  INTERRUPTION OF BUSINESS, COSTS OF DELAY, OR ANY INDIRECT,
                  SPECIAL, INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY
                  KIND, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN
                  ADVANCE.&nbsp;
                </p>
                <p>
                  <strong>19.2.</strong>Liability Cap.&nbsp;NOTWITHSTANDING ANY
                  OTHER PROVISION IN THESE TERMS, OUR AGGREGATE LIABILITY TO YOU
                  ARISING OUT OF , RELATED TO OR IN CONNECTION WITH THESE TERMS
                  WILL BE LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU TO US UNDER
                  THESE TERMS IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING
                  THE CLAIM.
                </p>
                <p>
                  <strong>19.3.</strong>Nature of Claims and Failure of
                  Essential Purpose.&nbsp;The parties agree that the waivers and
                  limitations specified in this Section 19 apply regardless of
                  the form of action, whether in contract, tort (including
                  negligence), strict liability or otherwise and will survive
                  and apply even if any limited remedy specified in these Terms
                  is found to have failed of its essential purpose.
                </p>
                <ol start="21">
                  <li>
                    <strong>Publicity Rights.</strong>We may identify you as a
                    Productive Procrastination customer in our promotional
                    materials. We will promptly stop doing so upon your request
                    sent to&nbsp;hello@getleda.com.
                  </li>
                  <li>
                    <strong> Dispute Resolution.&nbsp;</strong>
                  </li>
                </ol>
                <p>
                  <strong>22.1.&nbsp;</strong>Informal Resolution.&nbsp;In the
                  event of any controversy or claim arising out of or relating
                  to these Terms, the parties will consult and negotiate with
                  each other and, recognizing their mutual interests, attempt to
                  reach a solution satisfactory to both parties. If the parties
                  do not reach settlement within a period of sixty (60) days,
                  either party may pursue relief as may be available under these
                  Terms pursuant to Section 22.2 (Governing Law; Jurisdiction).
                  All negotiations pursuant to this Section 22.1 will be
                  confidential and treated as settlement negotiations for
                  purposes of all rules and codes of evidence of applicable
                  legislation and jurisdictions.
                </p>
                <p>
                  <strong>22.2.&nbsp;</strong>Governing Law;
                  Jurisdiction.&nbsp;These Terms will be governed by and
                  construed in accordance with the applicable laws of Victoria,
                  Australia, without giving effect to the principles of that
                  State relating to conflicts of laws. Each party irrevocably
                  agrees that any legal action, suit or proceeding arising out
                  of or related to these Terms must be brought solely and
                  exclusively in, and will be subject to the service of process
                  and other applicable procedural rules of, the State or Federal
                  court in Victoria, Australia, and each party irrevocably
                  submits to the sole and exclusive jurisdiction of the courts
                  in Victoria, Australia, generally and unconditionally, with
                  respect to any action, suit or proceeding brought by it or
                  against it by the other party.
                </p>
                <p>
                  <strong>22.3.&nbsp;</strong>Injunctive Relief;
                  Enforcement.&nbsp;Notwithstanding the provisions of Section
                  22.1 (Informal Resolution) and 22.2 (Governing Law;
                  Jurisdiction), nothing in these Terms will prevent us from
                  seeking injunctive relief with respect to a violation of
                  intellectual property rights, confidentiality obligations or
                  enforcement or recognition of any award or order in any
                  appropriate jurisdiction.
                </p>
                <ol start="24">
                  <li>
                    <strong> Changes to these Terms.&nbsp;</strong>We may modify
                    the terms and conditions of these Terms (including Our
                    Policies) from time to time, [with notice to you in
                    accordance with Section 26.1](Notices) or by posting the
                    modified Terms on our website. [Together with notice, we
                    will specify the effective date of the modifications.]
                  </li>
                </ol>
                <p>
                  <strong>24.1.&nbsp;</strong>No-Charge Products
                  <strong>.</strong>&nbsp;You must accept the modifications to
                  continue using the No-Charge Products. If you object to the
                  modifications, your exclusive remedy is to cease using the
                  No-Charge Products.
                </p>
                <p>
                  <strong>24.2.</strong>Paid Subscriptions. Except as otherwise
                  indicated below, modifications to these Terms will take effect
                  at the next renewal of your Subscription Term and will
                  automatically apply as of the renewal date unless you elect
                  not to renew pursuant to Section 9.2 (Renewals).
                  Notwithstanding the foregoing, in some cases (e.g., to address
                  compliance with Laws, or as necessary for new features) we may
                  specify that such modifications become effective during your
                  then-current Subscription Term. If the effective date of such
                  modifications is during your then-current Subscription Term
                  and you object to the modifications, then (as your exclusive
                  remedy) you may terminate your affected Orders upon notice to
                  us, and we will refund you any fees you have pre-paid for the
                  use of Leda for the terminated portion of the applicable
                  Subscription Term. To exercise this right, you must provide us
                  with notice of your objection and termination within thirty
                  (30) days of us providing notice of the modifications. For the
                  avoidance of doubt, any Order is subject to the version of
                  these Terms in effect at the time of the Order.
                </p>
                <p>
                  <strong>24.3.</strong>Our Policies. We may modify Our Policies
                  to take effect during your then-current Subscription Term in
                  order to respond to changes in our products, our business, or
                  Laws. [In this case, unless required by Laws, we agree not to
                  make modifications to Our Policies that, considered as a
                  whole, would substantially diminish our obligations during
                  your then-current Subscription Term.] Modifications to Our
                  Policies will take effect automatically as of the effective
                  date specified for the updated policies.
                </p>
                <ol start="25">
                  <li>
                    <strong> Changes to Leda.&nbsp;</strong>You acknowledge that
                    Leda is on-line, uses subscription-based products, and that
                    in order to provide improved customer experience we may make
                    changes to Leda, and we may update the applicable
                    Documentation accordingly. Subject to our obligation to
                    provide Leda and Additional Services under existing Orders,
                    we can discontinue, any Additional Services, or any portion
                    or feature of any Leda for any reason at any time without
                    liability to you.
                  </li>
                  <li>
                    <strong> General Provisions.</strong>
                  </li>
                </ol>
                <p>
                  <strong>26.1.&nbsp;</strong>Notices. Any notice under these
                  Terms must be given in writing. We may provide notice to you
                  through your Notification Email Address, your account or
                  in-product notifications. You agree that any electronic
                  communication will satisfy any applicable legal communication
                  requirements, including that such communications be in
                  writing. Any notice to you will be deemed given upon the first
                  business day after we send it. You will provide notice to us
                  by post to Productive Procrastination Pty Ltd, Level 4, 10 St
                  Andrews Place, East Melbourne, Victoria, Australia, Attn:
                  Legal. Your notices to us will be deemed given upon receipt.
                </p>
                <p>
                  <strong>26.2.</strong>Force Majeure.&nbsp;Neither party will
                  be liable to the other for any delay or failure to perform any
                  obligation under these Terms (except for a failure to pay
                  fees) if the delay or failure is due to events which are
                  beyond the reasonable control of such party, such as a strike,
                  blockade, war, act of terrorism, riot, natural disaster,
                  failure or diminishment of power or telecommunications or data
                  networks or services, or refusal of a license by a government
                  agency.
                </p>
                <p>
                  <strong>26.3.</strong>Assignment.&nbsp;You may not assign or
                  transfer these Terms without our prior written consent. As an
                  exception to the foregoing, you may assign these Terms in
                  their entirety (including all Orders) to your successor
                  resulting from a merger, acquisition, or sale of all or
                  substantially all of your assets or voting securities,
                  provided that you provide us with prompt written notice of the
                  assignment and the assignee agrees in writing to assume all of
                  your obligations under these Terms. Any attempt by you to
                  transfer or assign these Terms except as expressly authorized
                  above will be null and void.
                </p>
                <p>
                  We may assign our rights and obligations under these Terms (in
                  whole or in part) without your consent. We may also permit our
                  Affiliates, agents and contractors to exercise our rights or
                  perform our obligations under these Terms, in which case we
                  will remain responsible for their compliance with these Terms.
                  Subject to the foregoing, these Terms will inure to the
                  parties&rsquo; permitted successors and assigns.
                </p>
                <p>
                  <strong>26.5.</strong>Entire Agreement.&nbsp;These Terms are
                  the entire agreement between you and us relating to Leda and
                  any other subject matter covered by these Terms, and supersede
                  all prior or contemporaneous oral or written communications,
                  proposals and representations between you and us with respect
                  to Leda or any other subject matter covered by these Terms. No
                  provision of any purchase order or other business form
                  employed by you will supersede or supplement the terms and
                  conditions of these Terms, and any such document relating to
                  these Terms will be for administrative purposes only and will
                  have no legal effect.
                </p>
                <p>
                  <strong>26.6.</strong>Conflicts.&nbsp;In event of any conflict
                  between Our Policies , these Terms will prevail with respect
                  to their subject matter.
                </p>
                <p>
                  <strong>26.7.</strong>Waivers; Modifications.&nbsp;No failure
                  or delay by the injured party to these Terms in exercising any
                  right, power or privilege hereunder will operate as a waiver
                  thereof, nor will any single or partial exercise thereof
                  preclude any other or further exercise thereof or the exercise
                  of any right, power or privilege hereunder at law or equity.
                  Except as set forth in Section 24 (Changes to these Terms),
                  any amendments or modifications to these Terms must be
                  executed in writing by an authorised representative of each
                  party.
                </p>
                <p>
                  <strong>26.8.</strong>Interpretation.&nbsp;As used herein,
                  &ldquo;including&rdquo; (and its variants) means
                  &ldquo;including without limitation&rdquo; (and its variants).
                  Headings are for convenience only. If any provision of these
                  Terms is held to be void, invalid, unenforceable or illegal,
                  the other provisions will continue in full force and effect.
                </p>
                <p>
                  <strong>26.9.</strong>Independent Contractors.&nbsp;The
                  parties are independent contractors. These Terms will not be
                  construed as constituting either party as a partner of the
                  other or to create any other form of legal association that
                  would give either party the express or implied right, power or
                  authority to create any duty or obligation of the other party.
                </p>
                <ol start="27">
                  <li>
                    <strong> Definitions.</strong>Certain capitalized terms are
                    defined in this Section 27, and others are defined
                    contextually in these Terms.
                  </li>
                </ol>
                <p>
                  <strong>&ldquo;Additional Services&rdquo;</strong>&nbsp;means
                  any other services related to Leda we provide to you, as
                  identified in an Order. For the avoidance of doubt, Additional
                  Services do not include the standard level of support included
                  in your subscription.
                </p>
                <p>
                  <strong>&ldquo;Administrators&rdquo;&nbsp;</strong>mean the
                  personnel designated by you who administer Leda to End Users
                  on your behalf.
                </p>
                <p>
                  <strong>&ldquo;Affiliate&rdquo;</strong>&nbsp;means an entity
                  which, directly or indirectly, owns or controls, is owned or
                  is controlled by or is under common ownership or control with
                  a party, where &ldquo;control&rdquo; means the power to direct
                  the management or affairs of an entity, and
                  &ldquo;ownership&rdquo; means the beneficial ownership of
                  greater than 50% of the voting equity securities or other
                  equivalent voting interests of the entity.
                </p>
                <p>
                  <strong>&ldquo;Documentation&rdquo;</strong>&nbsp;means our
                  standard published documentation for Leda.
                </p>
                <p>
                  <strong>&ldquo;End User&rdquo;</strong>&nbsp;means an
                  individual you or an Affiliate permits or invites to use Leda.
                  For the avoidance of doubt an end user includes:&nbsp;
                </p>
                <ul>
                  <li>individuals invited by your End Users;</li>
                  <li>individuals under managed accounts; and</li>
                  <li>
                    individuals interacting with Leda as your customer are also
                    considered End Users.
                  </li>
                </ul>
                <p>
                  <strong>&ldquo;End User Account&rdquo;</strong>&nbsp;means an
                  account established by you or an End User to enable the End
                  User to use or access Leda.
                </p>
                <p>
                  <strong>&ldquo;Feedback&rdquo;</strong>&nbsp;means comments,
                  questions, ideas, suggestions or other feedback relating to
                  Leda, Support or Additional Services.
                </p>
                <p>
                  <strong> &ldquo;Laws&rdquo;</strong>&nbsp;means all applicable
                  local, state, federal and international laws, regulations and
                  conventions, including those related to data privacy and data
                  transfer, international communications and the exportation of
                  technical or personal data.
                </p>
                <p>
                  <strong>&ldquo;Leda&rdquo;&nbsp;</strong>means our cloud-based
                  solutions.
                </p>
                <p>
                  <strong>&ldquo;Notification Email Address&rdquo;</strong>
                  &nbsp;means the email address(es) you used to register for
                  Leda account or otherwise sign up for Leda. It is your
                  responsibility to keep your email address(es) valid and
                  current so that we are able to send notices, statements, and
                  other information to you.
                </p>
                <p>
                  <strong>&ldquo;Order&rdquo;</strong>&nbsp;means Productive
                  Procrastination&rsquo;s applicable online order page(s),
                  flows, in-product screens or other Productive
                  Procrastination-approved ordering document or process
                  describing the products and services you are ordering from us
                  and, as applicable, their permitted scope of use. As
                  applicable, the Order will identify: (i) Leda, (ii) the number
                  of End Users, Subscription Term associated with your use of
                  Leda and (iii) (for paid Orders) the amount or rate you will
                  be charged, the billing and renewal terms, applicable
                  currency, and form of payment. Orders may also include
                  Additional Services and No-Charge Products.
                </p>
                <p>
                  <strong>&ldquo;Our Deliverables&rdquo;</strong>&nbsp;means any
                  materials, deliverables, modifications, derivative works or
                  developments that we provide in connection with any Additional
                  Services.
                </p>
                <p>
                  <strong>&ldquo;Our Policies&rdquo;</strong>&nbsp;means these
                  Terms and our{' '}
                  <Link to={Util.route.site.privacyPolicy()}>
                    Privacy Policy
                  </Link>{' '}
                  and (unless specified) any other policies or terms referenced
                  in these Terms.
                </p>
                <p>
                  <strong>&ldquo;Our Technology&rdquo;</strong>&nbsp;means Leda
                  (including all No-Charge Products), Our Deliverables, their
                  &ldquo;look and feel&rdquo;, any and all related or underlying
                  technology and any modifications or derivative works of the
                  foregoing, including as they may incorporate Feedback.
                </p>
                <p>
                  <strong> &ldquo;PO&rdquo;</strong>&nbsp;means a purchase
                  order.
                </p>
                <p>
                  <strong>&ldquo;Subscription Term&rdquo;</strong>&nbsp;means
                  your permitted subscription period for Leda, as set forth in
                  the applicable Order.
                </p>
                <p>
                  <strong>&ldquo;Support&rdquo;</strong>&nbsp;means support for
                  Leda, as further described in the&nbsp;applicable Order (to
                  the extent applicable). Your Support level will also be
                  specified in the applicable Order.
                </p>
                <p>
                  <strong> &ldquo;Your Data&rdquo;</strong>&nbsp;means any data,
                  content, code, video, images or other materials of any type
                  that you (including any of your End Users) submit to Leda. In
                  this context, &ldquo;submit&rdquo; (and any similar term)
                  includes submitting, uploading, transmitting or otherwise
                  making available Your Data to or through Leda.
                </p>
                <p>
                  <strong>&ldquo;Your Materials&rdquo;&nbsp;</strong>means your
                  materials, systems, personnel or other resources.
                </p>
                <p>
                  <br />
                  <br />
                </p>
              </section>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
