import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';
import { addMyActivityAction } from '../../../../actions/activityActions';
import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

import Grid from '../../../UI/Grid/Grid';
import Button from '../../../UI/Button/Button';

class AdminActivityListPage extends Component {
  constructor(props) {
    super(props);

    this.testActivity = this.testActivity.bind(this);
  }
  testActivity(activityId) {
    this.props.addMyActivityAction(activityId, null, () => {
      this.props.openModalAction({
        type: Util.enum.ModalType.MyActivitiesModal,
        data: {
          selectedActivityId: activityId
        }
      });
    });
  }
  render() {
    return (
      <div className="leda-page-admin-activity-list">
        <AdminLayout pageTitle="Activity List">
          <Grid
            dataUrl="/api/admin/getAllActivities"
            searchKeys={['activityId', 'name', 'overview']}
            isRefreshVisible={true}
            isCountVisible={true}
            columns={[
              {
                name: 'ACT_ActivityId',
                key: 'activityId',
                sortFn: Util.sort.asNumber
              },
              {
                name: 'Created At',
                key: 'createdAt',
                sortFn: Util.sort.asDate,
                render: val => Util.format.date.shortDateTime(val)
              },
              {
                name: 'Name',
                key: 'name',
                align: 'center'
              },
              {
                name: 'IsFeatured',
                key: 'isFeatured',
                sortFn: Util.sort.asBoolean
              },
              {
                name: 'IsGlobal',
                key: 'isGlobal',
                align: 'center',
                sortFn: Util.sort.asBoolean
              },
              {
                key: 'activityId',
                isSortDisabled: true,
                render: val => {
                  return (
                    <div className="button-container">
                      <Button
                        icon={Util.icon.edit}
                        size="sm"
                        isSecondary={true}
                        to={Util.route.admin.activityEditor(val)}
                      />
                      <Button
                        label="Test"
                        size="sm"
                        icon={Util.icon.activity}
                        isSecondary={true}
                        onClick={() => this.testActivity(val)}
                      />
                    </div>
                  );
                }
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction, addMyActivityAction }
)(AdminActivityListPage);
