import React, { Component } from 'react';
import Util from '../../../Util';
import { HorizontalBar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';

class TeamJourneyProgressChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawData: null,
      data: null,
      options: null,
      isLoading: true
    };

    this.onClick = this.onClick.bind(this);
  }
  componentDidMount() {
    Util.api.post(
      '/api/insight/getTeamJourneyProgressRawData',
      {
        teamId: this.props.teamId
      },
      {
        success: rawData => {
          this.prepareData(rawData);
        }
      }
    );
  }
  prepareData(rawData) {
    let team = Util.context.user.getTeamById(this.props.teamId);

    /*** journeyCompletion insight ***/

    let data = {
      labels: [],
      datasets: [
        {
          label: 'In progress',
          backgroundColor: `#${Util.css.colour.ledaBlue}`,
          borderColor: `#${Util.css.colour.ledaBlue}`,
          data: []
        },
        {
          label: 'Completed',
          backgroundColor: `#${Util.css.colour.ledaGreen}`,
          borderColor: `#${Util.css.colour.ledaGreen}`,
          data: []
        },
        {
          label: 'Not started',
          backgroundColor: `#${Util.css.colour.ledaGrey5}`,
          borderColor: `#${Util.css.colour.ledaGrey5}`,
          data: []
        }
      ]
    };

    team.teamJourneys.forEach(teamJourney => {
      let journey = Util.context.referenceData.getJourneyById(
        teamJourney.journeyId
      );

      data.labels.push(journey.name);

      let matchingUserJourneys = rawData.userJourneys.filter(
        userJourney => userJourney.journeyId === journey.journeyId
      );
      data.datasets[0].data.push(
        matchingUserJourneys.filter(userJourney => !userJourney.completedAt)
          .length
      );
      data.datasets[1].data.push(
        matchingUserJourneys.filter(userJourney => !!userJourney.completedAt)
          .length
      );
      data.datasets[2].data.push(
        rawData.users.filter(
          user =>
            !matchingUserJourneys.find(
              userJourney => userJourney.userId === user.userId
            )
        ).length
      );
    });

    let options = {
      // onClick: this.onClick,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              max: rawData.users.length
            }
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      },
      animation: {
        duration: 0
      }
    };

    this.setState({
      isLoading: false,
      rawData,
      data,
      options
    });
  }
  onClick(chartElements) {
    if (chartElements && chartElements.length > 0) {
      let chartElement = chartElements[0];

      let title = '';
      let users = [];

      let journeyName = chartElement._model.label;
      let team = Util.context.user.getTeamById(this.props.teamId);
      let teamJourney = team.teamJourneys[chartElement._index];

      let selectedUserJourneys = this.state.rawData.userJourneys.filter(
        userJourney => userJourney.journeyId === teamJourney.journeyId
      );

      if (chartElement._datasetIndex === 0) {
        title = `${journeyName}: In progress users`;
        users = selectedUserJourneys
          .filter(userJourney => !userJourney.completedAt)
          .map(userJourney =>
            this.state.rawData.users.find(
              user => user.userId === userJourney.userId
            )
          );
      } else if (chartElement._datasetIndex === 1) {
        title = `${journeyName}: Completed users`;
        users = selectedUserJourneys
          .filter(userJourney => !!userJourney.completedAt)
          .map(userJourney =>
            this.state.rawData.users.find(
              user => user.userId === userJourney.userId
            )
          );
      } else if (chartElement._datasetIndex === 2) {
        title = `${journeyName}: Not started users`;
        users = this.state.rawData.users.filter(
          user =>
            !selectedUserJourneys.find(
              userJourney => userJourney.userId === user.userId
            )
        );
      }

      this.props.openModalAction({
        type: Util.enum.ModalType.UserListModal,
        title: title,
        icon: Util.icon.journey,
        data: {
          users: users
        }
      });
    }
  }
  render() {
    if (this.state.isLoading) return <div className="loader"></div>;

    return (
      <div className="chart team-journey-progress-chart">
        <h2 className="chart-title bold">Team Journey progress</h2>
        <HorizontalBar
          data={this.state.data}
          options={this.state.options}
          getElementAtEvent={this.onClick}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction }
)(TeamJourneyProgressChart);
