import React, { Component } from 'react';
import Util from '../../../Util';

import Post from '../Post/Post';
import Button from '../Button/Button';

export default class PostFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      isLoading: true,

      offset: 0,
      limit: 10,
      isNoMoreData: false
    };

    this.fetchData = this.fetchData.bind(this);
    this.onPostDelete = this.onPostDelete.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    this.setState({
      isLoading: true
    });

    let pageParams = {
      offset: this.state.offset,
      limit: this.state.limit
    };

    let dataParams = this.props.dataParams || {};

    let dataUrl = this.props.dataUrl || '/api/post/getPosts';

    Util.api.post(
      dataUrl,
      { ...pageParams, ...dataParams },
      {
        success: posts => {
          this.setState({
            posts: [...posts, ...this.state.posts],
            offset: this.state.offset + this.state.limit,
            isNoMoreData: posts.length < this.state.limit
          });
        },
        complete: () => {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }
  addPostToFeed(newPost) {
    this.setState({
      posts: [newPost, ...this.state.posts]
    });

    setTimeout(() => {
      let newPostElement = document.getElementById(`post-${newPost.postId}`);
      if (newPostElement) Util.scroll.toElement(newPostElement);
    }, 200);
  }
  onPostDelete(deletedPostId) {
    this.setState({
      posts: [...this.state.posts.filter(post => post.postId !== deletedPostId)]
    });
  }
  render() {
    return (
      <div className="post-feed">
        {this.state.posts
          .sort((p1, p2) => {
            return !!p1.pinnedAt === !!p2.pinnedAt
              ? Util.sort.asDate(p2.postedAt, p1.postedAt)
              : p1.pinnedAt
              ? -1
              : 1;
          })
          .map(post => (
            <Post
              key={post.postId}
              post={post}
              onDelete={postId => this.onPostDelete(postId)}
            />
          ))}
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div className="more-posts">
            {this.state.isNoMoreData ? (
              this.state.posts.length > 0 ? (
                'No more posts to display.'
              ) : (
                'This team has no posts to display.'
              )
            ) : (
              <Button label="Load more posts" onClick={this.fetchData} />
            )}
          </div>
        )}
      </div>
    );
  }
}
