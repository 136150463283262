import {
  APP_USER_AUTHENTICATED,
  APP_USER_LOGGED_OUT,
  APP_COMPLETED_VIDEO,
  APP_COMPLETED_GAME,
  APP_COMPLETED_ASSESSMENT,
  APP_COMPLETED_CONTENT
} from '../actions/types';

import Util from '../Util';

/* Authentication */

export const authenticateAction = newlyAssignedToken => dispatch => {
  /* 
		The initial authentication when arriving on the platform 

		Responsible for:
			- Setting user details (if there is one)
			- Storing important/frequently-used data (that will not change during regular activity)
	
	*/

  if (newlyAssignedToken) Util.auth.setToken(newlyAssignedToken);

  const cid = new URL(window.location.href).searchParams.get('cid');
  //The following request will use the token stored in Util to authenticate
  Util.api.post(
    '/api/auth/authenticate',
    {
      mlt: Util.route.getParameterByName('mlt'),
      cid: cid
    },
    {
      success: res => {
        //1. Set context
        Util.context.env.setCurrent(res.env, res.underConstructionDate); // Set env settings (must happen at the earliest moment)

        if (res.token) {
          Util.auth.setToken(res.token); // If authentication WAS successful, we will receive a refreshed JWT
          Util.context.user.setCurrent(res.user);
        } else {
          Util.auth.setAnonUserId(res.anonymousId); // If authentication was NOT successful, we will receieve an anonymousId for the user

          Util.ads.google.track(Util.ads.google.ConversionEvent.SiteView);
          Util.ads.facebook.track(Util.ads.facebook.Event.ViewContent, {
            content_ids: 'home-page'
          });
          Util.ads.linkedIn.track(Util.ads.linkedIn.ConversionEvent.SiteView);
        }

        //reference data (must happen before session state)
        Util.context.referenceData.setCurrent(res.referenceData);

        //2. Set context to ready, must happen before the dispatch below to trigger a re-render of AppContainer. this is a bit shaky.
        Util.context._isReady = true;

        //3. Setup initial redux session state
        dispatch({
          type: APP_USER_AUTHENTICATED,

          myActivities: res.myActivities,
          userJourneys: res.userJourneys,
          userNotifications: res.userNotifications,
          userProgress: res.userProgress
        });
      },
      failure: () => {
        dispatch({
          type: APP_USER_LOGGED_OUT
        });
      }
    }
  );
};

export const logoutAction = () => dispatch => {
  // Clear all session data (env can stay)
  Util.auth.clearToken();
  Util.context.user.clearCurrent();
  Util.context.referenceData.clearCurrent();

  dispatch({
    type: APP_USER_LOGGED_OUT
  });
};

export const completedVideoAction = videoId => dispatch => {
  Util.api.post('/api/video/userCompletedVideo', {
    videoId
  });

  dispatch({
    type: APP_COMPLETED_VIDEO,
    videoId
  });
};

export const completedAssessmentAction = assessmentId => dispatch => {
  Util.api.post('/api/assessment/userCompletedAssessment', {
    assessmentId
  });

  dispatch({
    type: APP_COMPLETED_ASSESSMENT,
    assessmentId
  });
};

export const completedGameAction = gameId => dispatch => {
  Util.api.post('/api/game/userCompletedGame', {
    gameId
  });

  dispatch({
    type: APP_COMPLETED_GAME,
    gameId
  });
};

export const completedContentAction = contentId => dispatch => {
  Util.api.post('/api/content/userCompletedContent', {
    contentId
  });

  dispatch({
    type: APP_COMPLETED_CONTENT,
    contentId
  });
};
