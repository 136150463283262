import React, { Component } from 'react';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

import Button from '../../../UI/Button/Button';

// Images
import imageExpired from '../../../../img/expired.png';

export default class SiteLicenseExpiredPage extends Component {
  render() {
    return (
      <div className="leda-page-site-license-expired">
        <SiteLayout>
          <div className="leda-container-sm">
            <div className="leda-row">
              <div className="error-panel">
                <img src={imageExpired} alt="License expired" />
                <h2>Time flies when you're having fun.</h2>
                <p>
                  Your license has expired, but we'd love to have you back.
                  Contact us about extending your license.
                </p>
                <div className="button-container">
                  <Button
                    onClick={() =>
                      (window.location.href = 'mailto:hello@getleda.com')
                    }
                    label="Get in touch"
                  />
                  <Button
                    to={Util.route.site.home()}
                    label="Back to main site"
                    isSecondary={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
