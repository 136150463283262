import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authenticateAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

import AuthLayout from '../../../Layouts/Auth/AuthLayout';

class AuthVerifyAccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFailed: false
    };
  }
  componentDidMount() {
    Util.api.post(
      '/api/auth/verifyAccount',
      {
        token: this.props.token
      },
      {
        success: result => this.props.authenticateAction(result.token),
        failure: () => {
          this.setState({
            isFailed: true
          });
        }
      }
    );
  }
  render() {
    return (
      <div className="leda-page-auth-verify-account">
        <AuthLayout>
          <div style={{ position: 'relative' }}>
            {this.state.isFailed ? (
              <p>Sorry, something went wrong.</p>
            ) : (
              <div className="loader"></div>
            )}
          </div>
        </AuthLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { authenticateAction }
)(AuthVerifyAccountPage);
