/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb';
import Button from '../../../UI/Button/Button';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import Grid from '../../../UI/Grid/Grid';
import ReactSVG from 'react-svg';
import { Redirect } from 'react-router-dom';
import Switch from '../../../UI/Switch/Switch';
import Util from '../../../../Util';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';

class AdminTeamListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      filter: 'no filter',
      organisations: [],
      selectedSorting: 'createdAtNewest',
      searchValue: '',
      newOrgName: ''
    };
  }

  componentDidMount() {
    if (
      (((this.props.location || {}).state || {}).openNewOrgModal || {}) === true
    )
      this.createNewOrgClicked();

    Util.api.post(
      '/api/admin/getAllOrganisations',
      {},
      {
        success: organisations => {
          this.setState({
            organisations: this.addLastActivityDates(organisations)
          });
        }
      }
    );

    Util.api.post(
      '/api/admin/getAllPresets',
      {},
      {
        success: presets => {
          this.setState({ presets });
        }
      }
    );
  }

  addLastActivityDates = organisations => {
    organisations.map(organisation => {
      let lastLogins = [];
      organisation.teams.forEach(team => {
        team.teamUsers.forEach(({ lastLoginAt }) =>
          lastLogins.push(lastLoginAt)
        );
      });
      lastLogins = lastLogins.sort(Util.sort.asDate).reverse();
      organisation.lastActivity = lastLogins[0];
      return organisation;
    });
    return organisations;
  };

  setFilter = filterVal => {
    if (filterVal !== this.state.filter) {
      this.setState({
        filter: filterVal
      });
    }
  };

  onSortingChange = sortingValue => {
    this.setState({
      selectedSorting: sortingValue
    });
  };

  onSearch = searchValue => {
    this.setState({
      searchValue: searchValue
    });
  };

  quickJoin = (organisation, teamId) => {
    Util.api.post(
      '/api/admin/quickJoinOrganisation',
      {
        organisationId: organisation.organisationId,
        teamId: teamId
      },
      {
        success: () => {
          //Need to redirect fully so auth happens again
          if (teamId) {
            window.open(Util.route.app.teamMembers(teamId));
          } else {
            window.open(Util.route.app.home());
          }
        }
      }
    );
  };

  quickJoinTeam = (organisation, teamId) => {
    Util.api.post(
      '/api/admin/quickJoinTeam',
      {
        organisationId: organisation.organisationId,
        teamId: teamId
      },
      {
        success: () => {
          //Need to redirect fully so auth happens again
          window.open(Util.route.app.home());
        }
      }
    );
  };

  rowClicked = (teamId, nodeName) => {
    if (nodeName === 'TD') {
      this.setState({
        redirectTo: Util.route.admin.teamEditor(teamId)
      });
    }
  };

  createTeamClicked = organisationId => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Create Team',
      icon: Util.icon.team,
      data: {
        value: '',
        label: 'Team name',
        onSubmit: teamName => {
          Util.api.post(
            '/api/admin/createTeam',
            {
              teamName,
              organisationId
            },
            {
              success: team => {
                this.setState({
                  redirectTo: Util.route.admin.teamEditor(team.teamId)
                });
              }
            }
          );
        }
      }
    });
  };

  createNewOrgClicked = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Create Organisation',
      data: {
        value: '',
        label: 'Organisation name',
        onSubmit: value => {
          this.setState({
            newOrgName: value,
            redirectTo: Util.route.admin.organisationCreator()
          });
        }
      }
    });
  };

  gridHeaderClicked = (organisationId, nodeName) => {
    if (nodeName === 'DIV') {
      this.setState({
        redirectTo: Util.route.admin.organisationEditor(organisationId)
      });
    }
  };

  toggleIsTeamDisabled = team => {
    team.isDisabled = !team.isDisabled;

    console.log(team.isDisabled);

    Util.api.post(
      '/api/admin/updateTeam',
      { team }
      // { failure: alert('Error while disabling team') }
    );
  };

  getOrganisationIdFromTeamId = teamId => {
    const organisations = this.state.organisations;
    for (const organisation of organisations) {
      for (const team of organisation.teams) {
        if (teamId === team.teamId) return organisation.organisationId;
      }
    }
  };

  render() {
    if (this.state.redirectTo) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectTo,
            state: { newOrgName: this.state.newOrgName }
          }}
        />
      );
    }

    const selectedSorting = this.state.selectedSorting;
    let organisations = this.state.organisations;
    const filter = this.state.filter;
    const searchValue = this.state.searchValue;

    // Licence Filter
    if (filter !== 'no filter') {
      organisations = organisations.filter(
        ({ licenseType }) => licenseType === this.state.filter
      );
    }

    // Search filter
    if (searchValue !== '') {
      organisations = organisations.filter(
        ({ organisationId, name, teams }) => {
          let textToSearch = `${organisationId} ${name} `;

          teams.forEach(({ teamId, createdAt, name }) => {
            textToSearch += `${teamId} ${Util.format.date.shortDateTime(
              createdAt
            )} ${name} `;
          });
          return textToSearch.toLowerCase().includes(searchValue.toLowerCase());
        }
      );
    }

    // Sort by
    if (selectedSorting === 'alphabeticalAZ') {
      organisations = organisations.sort((i1, i2) =>
        Util.sort.by('name', i1, i2, Util.sort.asString)
      );
    } else if (selectedSorting === 'alphabeticalZA') {
      organisations = organisations.sort((i1, i2) =>
        Util.sort.by('name', i2, i1, Util.sort.asString)
      );
    } else if (selectedSorting === 'createdAtOldest') {
      organisations = organisations.sort((i1, i2) =>
        Util.sort.by('createdAt', i1, i2, Util.sort.asDate)
      );
    } else if (selectedSorting === 'createdAtNewest') {
      organisations = organisations.sort((i1, i2) =>
        Util.sort.by('createdAt', i2, i1, Util.sort.asDate)
      );
    } else if (selectedSorting === 'lastActivity') {
      organisations = organisations
        .sort((i1, i2) =>
          Util.sort.by('lastActivity', i1, i2, Util.sort.asDate)
        )
        .reverse();
    }

    const dropDownOptions = [
      {
        display: 'Sort by date created (Newest)',
        value: 'createdAtNewest'
      },
      {
        display: 'Sort by date created (Oldest)',
        value: 'createdAtOldest'
      },
      {
        display: 'Sort by name: Alphabetical (A-Z)',
        value: 'alphabeticalAZ'
      },
      {
        display: 'Sort by name: Alphabetical (Z-A)',
        value: 'alphabeticalZA'
      },
      {
        display: 'Sort by last activity date',
        value: 'lastActivity'
      }
    ];

    const getFilterButton = (label, filterVal) => (
      <Button
        size="sm"
        isSecondary={true}
        isActive={this.state.filter === filterVal}
        onClick={() => this.setFilter(filterVal)}
        label={label}
      />
    );

    return (
      <div className="leda-page-admin-team-list">
        <AdminLayout>
          <div>
            <BreadCrumb />
            <div className="flex">
              <h1 className="admin-heading">Organisation &amp; Team List</h1>
              <div>
                <Button
                  size="md"
                  label="Create New Org"
                  onClick={() => this.createNewOrgClicked()}
                />
              </div>
            </div>
          </div>
          <div className="filter-bar">
            <ReactSVG className="filter-icon" src={Util.icon.filter} />
            {getFilterButton('View All', 'no filter')}
            {getFilterButton('Full License', 2)}
            {getFilterButton('Trial license', 1)}
            {getFilterButton('Student license', 3)}
            {getFilterButton('No License', null)}
          </div>

          <div className="sort-bar">
            <ReactSVG
              className="reorder-down-icon"
              src={Util.icon.reorderDown}
            />
            <Dropdown
              isDisabled={false}
              placeholder="Select audience"
              options={dropDownOptions}
              valueProp="value"
              displayProp="display"
              onChange={this.onSortingChange}
              value={this.state.selectedSorting}
            />
            <ReactSVG className="reorder-down-icon" src={Util.icon.search} />
            <input
              autoComplete="off"
              value={this.state.searchValue}
              name="name"
              id="search_field"
              type="text"
              placeholder="Type to search"
              onChange={e => this.onSearch(e.target.value)}
            />
          </div>

          {organisations.map(organisation => (
            <div
              className="org-team-container"
              key={organisation.organisationId}
            >
              <div
                className="organisation-heading"
                onClick={e =>
                  this.gridHeaderClicked(
                    organisation.organisationId,
                    e.target.nodeName
                  )
                }
              >
                <div>
                  <h2>{organisation.name} </h2>
                  {organisation.licenseType ? (
                    <h4>
                      {Util.enum.toString(
                        Util.enum.LicenseType,
                        organisation.licenseType
                      )}{' '}
                      license -{' '}
                      {!!organisation.licenseExpiredAt
                        ? new Date() < new Date(organisation.licenseExpiredAt)
                          ? Util.format.date.fromNow(
                              organisation.licenseExpiredAt,
                              ' remaining'
                            )
                          : 'expired ' +
                            Util.format.date.fromNow(
                              organisation.licenseExpiredAt
                            ) +
                            ' ago'
                        : 'Unlimited'}
                    </h4>
                  ) : (
                    <h4>No license</h4>
                  )}
                </div>
                <div className="flex-spacer"></div>
                <Button
                  size="sm"
                  isBlack={true}
                  onClick={() => this.quickJoin(organisation)}
                  label="Quick join"
                />
                <Button
                  size="sm"
                  isBlack={true}
                  label="Edit Organisation"
                  to={Util.route.admin.organisationEditor(
                    organisation.organisationId
                  )}
                />
                <Button
                  size="sm"
                  label="Create Team"
                  onClick={() =>
                    this.createTeamClicked(organisation.organisationId)
                  }
                />
              </div>
              <Grid
                data={organisation.teams}
                rowClicked={({ teamId }, nodeName) =>
                  this.rowClicked(teamId, nodeName)
                }
                columns={[
                  {
                    name: 'Team Id',
                    key: 'teamId',
                    sortFn: Util.sort.asNumber
                  },
                  {
                    name: 'Created At',
                    key: 'createdAt',
                    sortFn: Util.sort.asDate,
                    render: val => Util.format.date.shortDateTime(val)
                  },
                  {
                    name: 'Name',
                    key: 'name',
                    width: '50%'
                  },
                  {
                    name: 'Team Members',
                    key: 'teamUsers',
                    sortFn: Util.sort.asNumber,
                    isRenderSort: true,
                    render: (val, record) => {
                      return record.teamUsers.length;
                    }
                  },
                  {
                    name: 'Is enabled?',
                    key: 'isDisabled',
                    render: (val, record) => {
                      return (
                        <Switch
                          checked={val ? false : true}
                          onChange={() => this.toggleIsTeamDisabled(record)}
                          hideText={true}
                        ></Switch>
                      );
                    }
                  },
                  {
                    key: 'teamId',
                    isSortDisabled: true,
                    render: teamId => {
                      return (
                        <div className="button-container">
                          <Button
                            title="View team members"
                            size="sm"
                            isIcon={true}
                            icon={Util.icon.view}
                            onClick={() => this.quickJoin(organisation, teamId)}
                          />
                          <Button
                            title="View team members"
                            size="sm"
                            isIcon={true}
                            icon={Util.icon.users}
                            to={Util.route.admin.teamEditor(teamId)}
                          />
                          <Button
                            title="Edit Team"
                            isIcon={true}
                            size="sm"
                            icon={Util.icon.edit}
                            to={Util.route.admin.teamEditorJourneys(teamId)}
                          />
                        </div>
                      );
                    }
                  }
                ]}
              />
            </div>
          ))}
        </AdminLayout>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(AdminTeamListPage);
