import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMyActivityAction } from '../../../actions/activityActions';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

import ActivityTagline from '../ActivityTagline/ActivityTagline';
import CompletedActivityLog from './CompletedActivityLog/CompletedActivityLog';
import Button from '../Button/Button';
import MyActivityTasksView from '../MyActivityTasksView/MyActivityTasksView';

// Data source: this.props.activityId OR this.props.activity
class CompletedActivityView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !!this.props.activityId,
      activity: this.props.activity,

      viewingMyActivity: null
    };

    this.addMyActivity = this.addMyActivity.bind(this);
    this.setViewingMyActivity = this.setViewingMyActivity.bind(this);
  }
  componentDidMount() {
    if (this.props.activityId) {
      Util.api.post(
        '/api/activity/getActivityById',
        {
          activityId: this.props.activityId
        },
        {
          success: activity =>
            this.setState({
              activity,
              isLoading: false
            })
        }
      );
    }
  }
  addMyActivity() {
    this.props.addMyActivityAction(
      this.state.activity.activityId,
      this.props.journeyStepId
    );

    if (this.props.onActivityAdd) this.props.onActivityAdd();
  }
  setViewingMyActivity(myActivity) {
    this.setState({
      viewingMyActivity: myActivity
    });
  }
  render() {
    if (this.state.isLoading) {
      return (
        <div className="completed-activity-view">
          <div className="loader"></div>
        </div>
      );
    }

    //TODO(enhancement) connect to userprogress activities and sort by last completed order

    return (
      <div className="completed-activity-view">
        <div className="activity-header">
          <ActivityTagline
            journeyStepId={null}
            skills={this.state.activity.activitySkills}
          />
          <h2>{this.state.activity.name}</h2>
          <h4 className="task-count bold">{`${
            this.state.activity.taskCount
          } ${Util.format.pluralise(
            this.state.activity.taskCount,
            'task'
          )}`}</h4>
        </div>
        <div className="activity-content">
          <div
            className="completion-info"
            style={this.state.viewingMyActivity ? { display: 'none' } : {}}
          >
            {' '}
            {/* display none'd here to prevent it from reloading again */}
            <p className="lg">Completion log</p>
            <CompletedActivityLog
              activityId={this.state.activity.activityId}
              onItemClick={this.setViewingMyActivity}
            />
          </div>
          {this.state.viewingMyActivity ? (
            <div className="viewing-activity-info">
              <div
                className="back-to-log-strip"
                onClick={() => this.setViewingMyActivity()}
              >
                <ReactSVG
                  src={Util.icon.arrow.left}
                  className="arrow-icon svg-button"
                />
                Back to completion log
              </div>
              <MyActivityTasksView myActivity={this.state.viewingMyActivity} />
            </div>
          ) : null}
        </div>
        <div className="activity-footer">
          <Button
            label="Do activity again"
            size="lg"
            className="do-activity-button"
            onClick={this.addMyActivity}
            icon={Util.icon.undo}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { addMyActivityAction }
)(CompletedActivityView);
