import React, { Component } from 'react';
import { seenUserNotificationsAction } from '../../../actions/notificationActions';
import { connect } from 'react-redux';
import Util from '../../../Util';

import UserNotificationListItem from './UserNotificationListItem/UserNotificationListItem';

// Data source: this.props.userNotification
class UserNotificationList extends Component {
  componentDidMount() {
    let unseenUserNotificationIds = this.props.userNotifications
      .filter(userNotification => !userNotification.seenAt)
      .map(userNotification => userNotification.userNotificationId);

    if (unseenUserNotificationIds.length > 0)
      this.props.seenUserNotificationsAction(unseenUserNotificationIds);
  }
  render() {
    return (
      <div className="user-notification-list">
        {this.props.userNotifications &&
        this.props.userNotifications.length > 0 ? (
          this.props.userNotifications
            .sort((n1, n2) =>
              Util.sort.by('createdAt', n2, n1, Util.sort.asDate)
            )
            .map(userNotification => (
              <UserNotificationListItem
                onClick={this.props.onItemClick}
                key={userNotification.userNotificationId}
                userNotification={userNotification}
              />
            ))
        ) : (
          <p className="empty-notifications">You have no notifications.</p>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  { seenUserNotificationsAction }
)(UserNotificationList);
