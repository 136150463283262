import React, { Component } from 'react';
import Util from '../../../Util';
import { Bar } from 'react-chartjs-2';

export default class TeamRetentionChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawData: null,
      data: null,
      options: null,
      isLoading: true
    };
  }
  componentDidMount() {
    Util.api.post(
      '/api/insight/getTeamRetentionRawData',
      {
        teamId: this.props.teamId
      },
      {
        success: rawData => {
          this.prepareData(rawData);
        }
      }
    );
  }
  prepareData(rawData) {
    /*** journeyCompletion insight ***/
    let data = {
      labels: [],
      datasets: [
        {
          label: 'Leda visits',
          fill: false,
          lineTension: 0,
          backgroundColor: `#${Util.css.colour.ledaBlue}`,
          borderColor: `#${Util.css.colour.ledaBlue}`,
          data: []
        }
      ]
    };

    let groupedByDate = rawData.reduce((reduceObject, date) => {
      let shortDate = Util.format.date.shortDate(date);
      reduceObject[shortDate] = (reduceObject[shortDate] || 0) + 1;
      return reduceObject;
    }, {});

    let suggestedMax = 0;
    Object.keys(groupedByDate).forEach(key => {
      let value = groupedByDate[key];

      data.datasets[0].data.push({
        x: Util.moment.parse(key, 'DD/MM/YY'),
        y: value
      });

      if (value > suggestedMax) suggestedMax = value;
    });

    let options = {
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMax: suggestedMax + 1,
              precision: 0
            }
          }
        ],
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
              unitStepSize: 1,
              displayFormats: {
                day: 'MMM DD'
              }
            }
          }
        ]
      }
    };

    this.setState({
      isLoading: false,
      rawData,
      data,
      options
    });
  }
  render() {
    if (this.state.isLoading) return <div className="loader"></div>;

    return (
      <div className="chart team-retention-chart">
        <h2 className="chart-title bold">Team retention</h2>
        <Bar
          data={this.state.data}
          options={this.state.options}
          // getElementAtEvent={this.onClick}
        />
      </div>
    );
  }
}
