import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import Button from '../../../UI/Button/Button';
import Grid from '../../../UI/Grid/Grid';

class AdminteamPresetPosts extends Component {
  state = {
    posts: this.props.posts
  };

  addPost = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.EditPresetPost,
      onSubmit: post => {
        console.log(post);
        Util.api.post(
          '/api/admin/createPresetPost',
          {
            post,
            presetId: this.props.presetId
          },
          {
            success: post => {
              const posts = [...this.state.posts];
              posts.push(post);

              this.setState({ posts });
            }
          }
        );
      }
    });
  };

  editPost = post => {
    this.props.openModalAction({
      type: Util.enum.ModalType.EditPresetPost,
      data: {
        post
      },
      onSubmit: newPost => {
        Util.api.post(
          '/api/admin/updatePresetPost',
          {
            post: newPost,
            presetId: this.props.presetId
          },
          {
            success: () => {
              const posts = [...this.state.posts];

              let i = 0;
              let indexPostToUpdate;
              for (post of posts) {
                if (post.presetPostId === newPost.presetPostId) {
                  indexPostToUpdate = i;
                  break;
                }
                i++;
              }

              posts[indexPostToUpdate] = newPost;

              this.setState({ posts });
            }
          }
        );
      }
    });
  };

  removePost = presetPostId => {
    Util.api.post(
      '/api/admin/removePresetPost',
      { presetPostId },
      {
        success: () => {
          const posts = [...this.state.posts].filter(
            post => post.presetPostId !== presetPostId
          );

          this.setState({ posts });
        }
      }
    );
  };
  render() {
    let posts = [...this.state.posts].sort((a, b) =>
      Util.sort.asNumber(a.scheduledIn, b.scheduledIn)
    );

    return (
      <form className="admin-form-team-editor" onSubmit={this.onSubmit}>
        <Grid
          title="Posts for this preset"
          data={posts}
          toolbarItems={[
            <Button
              icon={Util.icon.post}
              key={1}
              label="Create new post"
              onClick={this.addPost}
            />
          ]}
          columns={[
            {
              key: 'scheduledIn',
              name: 'Scheduled In',
              sortFn: Util.sort.asNumber,
              render: nbDays => {
                if (nbDays === 0) {
                  return 'Now';
                } else {
                  return Util.format.date.preciseFromNow(
                    Util.moment.addDays(new Date(), nbDays)
                  );
                }
              }
            },
            {
              key: 'time',
              name: 'Time',
              isSortDisabled: true,
              render: (time, { scheduledIn }) => {
                if (time === '00:00:00' && scheduledIn === 0) {
                  return 'Now';
                } else {
                  let hours = Number.parseInt(time.split(':')[0]);
                  const minutes = time.split(':')[1];
                  let dayPart = 'AM';
                  if (hours >= 12) dayPart = 'PM';
                  if (hours > 12) hours -= 12;

                  return `${hours.toString()}:${minutes} ${dayPart}`;
                }
              }
            },
            {
              key: 'text',
              name: 'Content',
              isSortDisabled: true
            },
            {
              key: 'presetPostId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <span
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <Button
                      isIcon={true}
                      size="sm"
                      icon={Util.icon.bin}
                      onClick={() => this.removePost(val)}
                    />

                    <div style={{ marginRight: 5 }}>
                      <Button
                        isIcon={true}
                        size="sm"
                        icon={Util.icon.edit}
                        onClick={() => this.editPost(record)}
                      />
                    </div>
                  </span>
                );
              }
            }
          ]}
        />
      </form>
    );
  }
}

export default connect(null, { openModalAction })(AdminteamPresetPosts);
