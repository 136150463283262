import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';
import { connect } from 'react-redux';

//Components
import SkillIcon from '../SkillIcon/SkillIcon';

//this.props.journeyStep + this.props.skills
class ActivityTagline extends Component {
  render() {
    let label = <h3 className="grey-3">Activity</h3>;
    let icon = null;

    if (this.props.journeyStepId) {
      let userJourney = this.props.userJourneys.find(userJourney =>
        userJourney.steps.some(step => step.stepId === this.props.journeyStepId)
      );

      if (userJourney) {
        let step = Util.array.firstIdOrUrlIdMatch(
          userJourney.steps,
          this.props.journeyStepId,
          'stepId'
        );
        label = (
          <div className="tagline-label">
            <h3 className="grey-3">STEP {step.ordinal}</h3>
            <h4 className="grey-4">
              {userJourney ? userJourney.name : 'Journey'}
            </h4>
          </div>
        );
        icon = (
          <div className="tagline-icon">
            <ReactSVG className="journey-icon" src={Util.icon.journey} />
          </div>
        );
      }
    } else if (Util.array.any(this.props.skills)) {
      let skillId = this.props.skills[0].skillId;
      let skill = Util.context.referenceData.getSkillById(skillId);
      if (!skill) return null;

      label = (
        <div className="tagline-label">
          <h3 className="grey-3">{skill.name}</h3>
        </div>
      );
      icon = (
        <div className="tagline-icon">
          <SkillIcon skillId={skillId} />
        </div>
      );
    }

    return (
      <div className="my-act-tagline">
        {label}
        {icon}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(
  mapStateToProps,
  {}
)(ActivityTagline);
