import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';
import Util from '../../../Util';

import FileList from '../FileList/FileList';
import PostImages from '../PostImages/PostImages';
import PostLink from '../PostLink/PostLink';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import CommentThread from '../CommentThread/CommentThread';
import Truncate from '../Truncate/Truncate';
import ContextMenu from '../ContextMenu/ContextMenu';

//this.props.post
class Post extends Component {
  constructor(props) {
    super(props);

    // the state variables here are mainly used to update the view upon interaction,
    // but only for OPTIMISTIC rendering purposes. As the post/feed is not stored in redux.
    // BUT SHOULD IT BE?
    this.state = {
      isCommentsVisible: this.props.isCommentsVisible,
      isPostingComment: false,
      post: this.props.post
    };

    this.commentThreadRef = React.createRef();

    this.setPostPinned = this.setPostPinned.bind(this);
    this.setPostLiked = this.setPostLiked.bind(this);
    this.showComments = this.showComments.bind(this);
    this.addComment = this.addComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.openLikesModal = this.openLikesModal.bind(this);
    this.canEditPost = this.canEditPost.bind(this);
    this.editPost = this.editPost.bind(this);
    this.deletePost = this.deletePost.bind(this);
  }
  setPostLiked(isLiked) {
    //client
    this.setState({
      post: {
        ...this.state.post,
        likes: isLiked
          ? [
              ...this.state.post.likes,
              {
                postLikeId: Util.id.tempId.getTempId(),
                createdAt: new Date(),
                user: Util.context.user.getCurrent()
              }
            ]
          : [
              ...this.state.post.likes.filter(
                like =>
                  like.user.userId !== Util.context.user.getCurrentUserId()
              )
            ]
      }
    });

    //server
    Util.api.post('/api/post/setPostLiked', {
      postId: this.state.post.postId,
      isLiked
    });
  }
  canEditPost() {
    if (Util.context.user.getIsLedaAdmin()) return true;
    if (this.state.post.organisationId)
      return Util.context.user.getIsOrganisationAdmin();
    if (this.state.post.teamId)
      return (
        Util.context.user.getIsOrganisationAdmin() ||
        Util.context.user.canAdminTeam(this.state.post.teamId)
      );
  }
  addComment(commentText) {
    this.setState({
      isPostingComment: true
    });

    Util.api.post(
      '/api/post/commentPost',
      {
        postId: this.state.post.postId,
        commentText
      },
      {
        success: newComment => {
          this.setState({
            post: {
              ...this.state.post,
              comments: [
                ...this.state.post.comments,
                {
                  ...newComment,
                  user: Util.context.user.getCurrent()
                }
              ]
            }
          });
        }
      }
    );
  }
  deleteComment(comment) {
    let deletePostCommentId = comment.postCommentId;

    //client
    this.setState({
      post: {
        ...this.state.post,
        comments: this.state.post.comments.filter(
          comment => comment.postCommentId !== deletePostCommentId
        )
      }
    });

    //server
    Util.api.post('/api/post/deletePostComment', {
      postCommentId: deletePostCommentId
    });
  }
  showComments(shouldFocusBox) {
    if (shouldFocusBox) {
      if (!this.state.isCommentsVisible) {
        setTimeout(() => {
          this.commentThreadRef.current.focusNewComment();
        }, 100);
      } else {
        this.commentThreadRef.current.focusNewComment();
      }
    }

    this.setState({
      isCommentsVisible: true
    });
  }
  editPost() {
    this.props.openModalAction({
      type: Util.enum.ModalType.PostEditorModal,
      data: {
        post: this.state.post,
        onPostSaved: post => {
          this.setState({
            post
          });
        }
      }
    });
  }
  deletePost() {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: 'Are you sure you wish to delete this post?',
        yesLabel: 'Delete',
        noLabel: 'Cancel',
        yesFn: () => {
          //client
          if (this.props.onDelete) this.props.onDelete(this.state.post.postId);
          //server
          Util.api.post('/api/post/deletePost', {
            postId: this.state.post.postId
          });
        }
      }
    });
  }
  setPostPinned(isPinned) {
    //client
    this.setState({
      post: {
        ...this.state.post,
        pinnedAt: isPinned ? new Date() : null
      }
    });

    //server
    Util.api.post('/api/post/setPostPinned', {
      postId: this.state.post.postId,
      isPinned
    });
  }
  openLikesModal() {
    let likeCount = this.state.post.likes.length;

    this.props.openModalAction({
      type: Util.enum.ModalType.UserListModal,
      title: likeCount + Util.format.pluralise(likeCount, ' like'),
      icon: Util.icon.thumb,
      data: {
        users: this.state.post.likes.map(like => like.user)
      }
    });
  }
  render() {
    let isLikedByMe = this.state.post.likes.find(
      like => like.user.userId === Util.context.user.getCurrentUserId()
    );

    let getAudienceLabel = post => {
      if (post.isGlobal) return '';
      if (post.organisationId)
        return Util.context.user.getOrganisationName() + ' - ';
      if (post.teamId)
        return Util.context.user.getTeamById(post.teamId).name + ' - ';
    };

    let imagePostFiles = this.state.post.postFiles.filter(postFile =>
      postFile.file.type.startsWith('image')
    );
    let otherPostFiles = this.state.post.postFiles.filter(
      postFile => !imagePostFiles.includes(postFile)
    );

    return (
      <div
        id={`post-${this.state.post.postId}`}
        className={`post ${
          this.state.post.isLedaOfficial ? 'leda-official' : ''
        }`}
      >
        <div className="post-inner">
          <div className="post-upper">
            <ProfilePhoto
              user={this.state.post.userCreator}
              isLedaOfficial={this.state.post.isLedaOfficial}
            />
            <div className="post-info">
              <h4 className="bold">
                {!this.state.post.isLedaOfficial ? (
                  this.state.post.userCreator ? (
                    <Link
                      to={Util.route.app.profile(
                        this.state.post.userCreator.userId
                      )}
                    >
                      {this.state.post.userCreator.fullName}
                    </Link>
                  ) : (
                    <span>Unknown user</span>
                  )
                ) : (
                  <span>Leda</span>
                )}
              </h4>
              <h5>
                <span className="bold">
                  {getAudienceLabel(this.state.post)}
                </span>
                {Util.format.date.fromNowReadable(this.state.post.postedAt)}
                {this.state.post.pinnedAt ? (
                  <span className="pinned-label">
                    <ReactSVG className="pin-icon" src={Util.icon.pin} />
                    Pinned
                  </span>
                ) : null}
              </h5>
            </div>
            {this.canEditPost() ? (
              <ContextMenu
                items={[
                  {
                    icon: Util.icon.pin,
                    label: !this.state.post.pinnedAt ? 'Pin' : 'Unpin',
                    onClick: () => this.setPostPinned(!this.state.post.pinnedAt)
                  },
                  {
                    icon: Util.icon.edit,
                    label: 'Edit',
                    onClick: this.editPost
                  },
                  {
                    icon: Util.icon.bin,
                    label: 'Delete',
                    onClick: this.deletePost
                  }
                ]}
              />
            ) : null}
          </div>
          <Truncate
            className="post-text"
            html={Util.text.toSafeHtml(this.state.post.text)}
            maxLine={6}
            revealLabel="See more"
          />
          {Util.array.any(otherPostFiles) ? (
            <FileList files={otherPostFiles.map(postFile => postFile.file)} />
          ) : null}
          {Util.text.getLinks(this.state.post.text).map((link, idx) => (
            <PostLink key={idx} url={link} />
          ))}
          {Util.array.any(imagePostFiles) ? (
            <PostImages isCollageLayout={true} postFiles={imagePostFiles} />
          ) : null}
          {!this.state.post.isGlobal ? (
            <div className="post-stats">
              <h4>
                <button className="post-stat link-button" onClick={this.openLikesModal}>{`${
                  this.state.post.likes.length
                } ${Util.format.pluralise(this.state.post.likes, ' Like')}`}</button>
              </h4>
              <h4>
                <button
                  className="post-stat link-button"
                  onClick={() => this.showComments(false)}
                >{`${this.state.post.comments.length} ${Util.format.pluralise(
                  this.state.post.comments,
                  ' Comment'
                )}`}</button>
              </h4>
              <div className="flex-spacer"></div>
            </div>
          ) : null}
          {!this.state.post.isGlobal ? (
            <div className="post-actions">
              <div
                onClick={() => this.setPostLiked(!isLikedByMe)}
                className={`post-action ${isLikedByMe ? 'selected' : ''}`}
              >
                <ReactSVG className="action-icon" src={Util.icon.thumb} />
                Like
              </div>
              <div
                onClick={() => this.showComments(true)}
                className="post-action"
              >
                <ReactSVG className="action-icon" src={Util.icon.comment} />
                Comment
              </div>
              <div className="flex-spacer"></div>
            </div>
          ) : null}
          {this.state.isCommentsVisible ? (
            <div className="comments">
              <CommentThread
                className={this.props.isPostingComment ? 'disabled' : null}
                ref={this.commentThreadRef}
                comments={this.state.post.comments}
                onAddComment={this.addComment}
                onDeleteComment={this.deleteComment}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction }
)(Post);
