import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

class StoryIcon extends Component {
  render() {
    let userCompletedChapters = this.props.story.chapters.filter(
      ch => !!this.props.userProgressVideo[ch.videoId]
    );
    let isStoryCompleted =
      userCompletedChapters.length > 0 &&
      this.props.story.chapters.length === userCompletedChapters.length;

    return (
      <div className={`story-icon ${isStoryCompleted ? 'completed' : ''}`}>
        <img
          className="icon-image"
          src={Util.storage.storyIcon(this.props.story.urlId)}
          alt="Story Icon"
        />
        {isStoryCompleted ? (
          <ReactSVG className="icon-tick" src={Util.icon.tick} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(StoryIcon);
