import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import Button from '../../../UI/Button/Button';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import Grid from '../../../UI/Grid/Grid';
import JourneyBadge from '../../../UI/JourneyBadge/JourneyBadge';

class AdminteamPresetJourneys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      journeys: props.journeys
    };
  }

  onSubmit = e => {
    e.preventDefault();
    Util.api.post(
      '/api/admin/updatePresetJourneys',
      {
        journeys: this.state.journeys
      },
      {
        success: () => {
          alert('Preset journeys saved');
        },
        error: () => {
          alert(
            'Error while saving this preset. Please reload the page and try again.'
          );
        }
      }
    );
  };

  removePresetJourney = presetJourneyId => {
    const newJourneys = { ...this.state.journeys };
    delete newJourneys[presetJourneyId];

    Util.api.post(
      '/api/admin/removePresetJourney',
      {
        presetJourneyId: presetJourneyId
      },
      {
        success: () => {
          this.setState({
            journeys: newJourneys
          });
        },
        error: () => {
          alert('Error while deleting this preset journey');
        }
      }
    );
  };

  addPresetJourney = value => {
    Util.api.post(
      '/api/admin/createPresetJourney',
      {
        journeyId: Util.context.referenceData.getJourneyById(value).journeyId,
        presetId: this.props.presetId
      },
      {
        success: presetJourney => {
          const newJourneys = { ...this.state.journeys };
          newJourneys[presetJourney.presetJourneyId] = presetJourney;
          this.setState({
            journeys: newJourneys
          });
        },
        error: () => {
          alert('Error while deleting this preset journey');
        }
      }
    );
  };

  addBreak = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Add Break',
      data: {
        value: '',
        label: 'Break Label',
        onSubmit: breakLabel => {
          Util.api.post(
            '/api/admin/addBreakToPresetJourney',
            {
              presetId: this.props.presetId,
              breakLabel,
              isBreak: true
            },
            {
              success: journeyBreak => {
                const newJourneys = { ...this.state.journeys };
                newJourneys[journeyBreak.presetJourneyId] = journeyBreak;
                this.setState({
                  journeys: newJourneys
                });
              },
              error: () => {
                alert('Error while deleting this preset journey');
              }
            }
          );
        }
      }
    });
  };

  editBreakName = presetJourneyId => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Edit Break Label',
      data: {
        value: '',
        label: 'Break Label',
        onSubmit: breakLabel => {
          let journeys = [...Object.values(this.state.journeys)];

          journeys = journeys.map(journey => {
            if (journey.presetJourneyId === presetJourneyId) {
              journey.breakLabel = breakLabel;
              return journey;
            } else {
              return journey;
            }
          });

          this.setState({ journeys });
        }
      }
    });
  };

  normalizeJourneys = journeys => {
    const normalizedJourneys = {};
    journeys.forEach(
      journey => (normalizedJourneys[journey.presetJourneyId] = journey)
    );
    return normalizedJourneys;
  };

  moveUp = presetJourneyId => {
    const newJourneys = Object.values(this.state.journeys);

    let journeyIndexInArray;
    newJourneys.forEach((journey, index) => {
      if (journey.presetJourneyId === presetJourneyId) {
        journeyIndexInArray = index;
      }
    });

    if (newJourneys[journeyIndexInArray - 1]) {
      const id1 = presetJourneyId;
      const id2 = newJourneys[journeyIndexInArray - 1].presetJourneyId;
      const temp = newJourneys[journeyIndexInArray];

      newJourneys[journeyIndexInArray] = newJourneys[journeyIndexInArray - 1];
      newJourneys[journeyIndexInArray].presetJourneyId = id1;
      newJourneys[journeyIndexInArray - 1] = temp;
      newJourneys[journeyIndexInArray - 1].presetJourneyId = id2;

      this.setState({
        journeys: this.normalizeJourneys(newJourneys)
      });
    }
  };

  moveDown = presetJourneyId => {
    const newJourneys = Object.values(this.state.journeys);

    let journeyIndexInArray;
    newJourneys.forEach((journey, index) => {
      if (journey.presetJourneyId === presetJourneyId) {
        journeyIndexInArray = index;
      }
    });

    if (newJourneys[journeyIndexInArray + 1]) {
      const id1 = presetJourneyId;
      const id2 = newJourneys[journeyIndexInArray + 1].presetJourneyId;
      const temp = newJourneys[journeyIndexInArray];

      newJourneys[journeyIndexInArray] = newJourneys[journeyIndexInArray + 1];
      newJourneys[journeyIndexInArray].presetJourneyId = id1;
      newJourneys[journeyIndexInArray + 1] = temp;
      newJourneys[journeyIndexInArray + 1].presetJourneyId = id2;

      this.setState({
        journeys: this.normalizeJourneys(newJourneys)
      });
    }
  };

  getColumnIndex = presetJourneyId => {
    let i = 0;
    Object.values(this.state.journeys).forEach((journey, index) => {
      if (journey.presetJourneyId === presetJourneyId) i = index + 1;
    });
    return i;
  };

  render() {
    console.log(Util.context.referenceData.getJourneys());
    return (
      <form className="admin-form-team-editor" onSubmit={this.onSubmit}>
        <Grid
          title="Journeys for this preset"
          data={Object.values(this.state.journeys)}
          toolbarItems={[
            <Dropdown
              key={1}
              options={[...Util.context.referenceData.getJourneys()]
                .sort((j1, j2) =>
                  Util.sort.by('name', j1, j2, Util.sort.asString)
                )
                .filter(({ journeyId }) => journeyId !== 1000)}
              valueProp={'journeyId'}
              displayProp={'name'}
              render={(val, record) =>
                val + (record.isLocked ? ' (GLOBAL LOCKED)' : '')
              }
              isEmptyAllowed={true}
              isEmptyOnChange={true}
              placeholder="Select a journey to add"
              onChange={this.addPresetJourney}
            />,
            <Button
              icon={Util.icon.breakWhite}
              className="add-break"
              isBlack={true}
              key={2}
              label="Add Break"
              onClick={this.addBreak}
            />
          ]}
          columns={[
            {
              key: 'presetJourneyId',
              name: 'Order',
              isSortDisabled: true,
              render: val => {
                return (
                  <div>
                    <Button
                      isSubmit={false}
                      label="&#9650;"
                      onClick={() => {
                        this.moveUp(val);
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {this.getColumnIndex(val)}
                    </div>
                    <Button
                      isSubmit={false}
                      label="&#9660;"
                      onClick={() => {
                        this.moveDown(val);
                      }}
                    />
                  </div>
                );
              }
            },
            {
              key: 'journeyId',
              name: '',
              isSortDisabled: true,
              render: (val, record) => {
                if (record.isBreak) {
                  return (
                    <div className="break-icon-wrapper">
                      <ReactSVG
                        src={Util.icon.breakGrey}
                        style={{ width: 30, color: '#828282' }}
                      />
                    </div>
                  );
                } else {
                  return <JourneyBadge journeyId={val} />;
                }
              }
            },
            {
              key: 'presetJourneyId',
              name: 'Name',
              width: '100%',
              isSortDisabled: true,
              isRenderSort: true,
              render: (val, record) => {
                let journey = Util.context.referenceData.getJourneyById(
                  record.journeyId
                );
                return (
                  <p>
                    {record.isBreak ? record.breakLabel : record.name}
                    {journey ? (
                      journey.isLocked ? (
                        <b> (GLOBAL LOCKED)</b>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </p>
                );
              }
            },
            {
              key: 'length',
              name: 'Length',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <span className="length-input">
                    <input
                      type="number"
                      min="0"
                      value={val}
                      onChange={e => {
                        let newJourneys = {
                          ...this.state.journeys
                        };
                        newJourneys[record.presetJourneyId].length =
                          e.target.value;
                        this.setState({ journeys: newJourneys });
                      }}
                    />
                    <p>days</p>
                  </span>
                );
              }
            },
            {
              key: 'nbWeeksBeforeUnlock',
              name: 'Unlocked At',
              isSortDisabled: true,
              render: (val, record) => {
                if (!record.isBreak) {
                  return (
                    <Dropdown
                      defaultValue={val}
                      options={[
                        { display: 'On scheduled day', value: 0 },
                        { display: '1 week earlier', value: 1 },
                        { display: '2 weeks earlier', value: 2 },
                        { display: '3 week earlier', value: 3 },
                        { display: '4 week earlier', value: 4 },
                        { display: 'Always unlocked', value: 1000 }
                      ]}
                      displayProp={'display'}
                      valueProp={'value'}
                      onChange={newValue => {
                        let newJourneys = {
                          ...this.state.journeys
                        };
                        newJourneys[
                          record.presetJourneyId
                        ].nbWeeksBeforeUnlock = newValue;
                        this.setState({ journeys: newJourneys });
                      }}
                      placeholder={'Now'}
                    />
                  );
                }
              }
            },
            {
              key: 'presetJourneyId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <span
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <Button
                      isIcon={true}
                      size="sm"
                      icon={Util.icon.bin}
                      onClick={() => this.removePresetJourney(val)}
                    />

                    {record.isBreak && (
                      <div style={{ marginRight: 5 }}>
                        <Button
                          isIcon={true}
                          size="sm"
                          icon={Util.icon.edit}
                          onClick={() => this.editBreakName(val)}
                        />
                      </div>
                    )}
                  </span>
                );
              }
            }
          ]}
        />
        <div className="button-container">
          <Button
            label="Cancel"
            isSecondary={true}
            to={Util.route.admin.teamList()}
          />
          <Button label="Save" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default connect(null, { openModalAction })(AdminteamPresetJourneys);
