import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';
import validator from 'validator';

import ButtonInput from '../ButtonInput/ButtonInput';

import img_newsletter_plane from './newsletter_plane.png';
import img_bg_left from './bg_left.png';
import img_bg_right from './bg_right.png';

export default class SiteNewsletterStrip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false
    };

    this.onNewsletterEmailSubmit = this.onNewsletterEmailSubmit.bind(this);
  }
  onNewsletterEmailSubmit(email) {
    Util.analytics.track('NewsletterSignUp', {
      email
    });

    this.setState({
      isNewsletterEmailSubmitted: true
    });
  }
  render() {
    return (
      <div
        className={`leda-wrapper newsletter-strip ${this.props.className ||
          ''}`}
      >
        <img
          src={img_bg_left}
          alt="Newsletter buildings left"
          className="buildings left"
        />
        <img
          src={img_bg_right}
          alt="Newsletter buildings right"
          className="buildings right"
        />
        <img
          src={img_newsletter_plane}
          alt="Newsletter plane"
          className="newsletter-plane"
        />
        <div className="leda-container-sm">
          <div className="leda-row">
            <div className="newsletter-info">
              <h5 className="gilroy text-center white">
                {this.props.tagline || 'Sign up to our newsletter'}
              </h5>
              {this.state.isNewsletterEmailSubmitted ? (
                <h2 className="white text-center">Thank you for signing up!</h2>
              ) : (
                <div>
                  <ButtonInput
                    className="email-try-it"
                    buttonLabel="Sign up"
                    buttonSize="lg"
                    placeholder="Enter your work email"
                    onSubmit={this.onNewsletterEmailSubmit}
                    getError={val =>
                      !validator.isEmail(val) ? 'Enter a valid email' : ''
                    }
                  />
                  <h5 className="disclaimer white">
                    By joining our newsletter you agree that you have read our{' '}
                    <Link to={Util.route.site.privacyPolicy()}>
                      Privacy Policy
                    </Link>
                    .
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
