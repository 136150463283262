import React, { Component } from 'react';
import ReactSVG from 'react-svg';

export default class SupplementaryPanel extends Component {
  render() {
    return (
      <div className={`supplementary-panel ${this.props.className || ''}`}>
        <div className="panel-title">
          <div className="panel-title-info">
            {this.props.title ? <h2>{this.props.title}</h2> : null}
            {this.props.subtitle ? <p>{this.props.subtitle}</p> : null}
          </div>
          {this.props.icon ? (
            <ReactSVG className="panel-title-icon" src={this.props.icon} />
          ) : null}
        </div>
        {this.props.children}
      </div>
    );
  }
}
