import React, { Component } from 'react';
import Util from '../../../Util';
//Redux
import { connect } from 'react-redux';
import { completeMyActivityTaskAction } from '../../../actions/activityActions';

//Components
import Button from '../Button/Button';
import FeedbackStars from '../FeedbackStars/FeedbackStars';
import FeedbackMessage from '../FeedbackMessage/FeedbackMessage';
import JourneyStepStatus from '../JourneyStepStatus/JourneyStepStatus';
import MyActivityTaskPanel from './MyActivityTaskPanel/MyActivityTaskPanel';

//Images
import imgGemHollow from '../../../img/app/gem/gem_hollow.png';

//this.props.myActivity
class MyActivityTasksView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAlreadyCompleted: !!this.props.myActivity.completedAt
    };

    this.latestMyActivityTask = React.createRef();
    this.myActivityTasksViewRef = React.createRef();

    this.onCompleteTask = this.onCompleteTask.bind(this);
  }
  componentDidMount() {
    this.scrollToCurrentTask();
  }
  componentDidUpdate(prevProps) {
    //If the user has clicked on a different myActivity, do an immediate scroll jump to the current task
    if (
      prevProps.myActivity.myActivityId !== this.props.myActivity.myActivityId
    ) {
      this.scrollToCurrentTask();
      this.setState({
        isAlreadyCompleted: !!this.props.myActivity.completedAt
      });
    }
  }
  onCompleteTask(myActivityTaskId) {
    this.props.completeMyActivityTaskAction(
      myActivityTaskId,
      this.props.myActivity
    );
    setTimeout(() => this.scrollToCurrentTask('smooth'), 100); //Scroll down smoothly with a slight delay
  }
  scrollToCurrentTask(behavior = 'auto') {
    let latestMyActivityTask = this.latestMyActivityTask.current;
    let myActivityTasksView = this.myActivityTasksViewRef.current;

    if (latestMyActivityTask && myActivityTasksView) {
      Util.scroll.to(
        latestMyActivityTask.offsetTop,
        myActivityTasksView,
        behavior
      );
    }
  }
  render() {
    let getCompletedJourneyStepPanel = myActivity => {
      if (!myActivity.journeyStepId) return null;

      let userJourney = this.props.userJourneys.find(userJourney =>
        userJourney.steps.some(step => step.stepId === myActivity.journeyStepId)
      );
      if (!userJourney) return null;

      let currentStep = Util.array.firstIdOrUrlIdMatch(
        userJourney.steps,
        myActivity.journeyStepId,
        'stepId'
      );
      let nextStep =
        userJourney.steps[userJourney.steps.indexOf(currentStep) + 1];

      return (
        <div className="congrats-journey">
          <div className="journey-step">
            <JourneyStepStatus step={currentStep} isLabelHidden={true} />
            <h3>STEP {currentStep.ordinal} completed</h3>
          </div>
          <div className="journey-name">Journey: {userJourney.name}</div>
          {userJourney.completedAt ? (
            <Button
              label="Go to Journey"
              to={Util.route.app.journeyStep(userJourney.urlId, 'end')}
            />
          ) : (
            <Button
              label="Go to next step"
              size="lg"
              to={Util.route.app.journeyStep(
                userJourney.urlId,
                nextStep ? nextStep.urlId : 'end'
              )}
            />
          )}
        </div>
      );
    };

    return (
      <div className="my-activity-tasks-view" ref={this.myActivityTasksViewRef}>
        {this.props.myActivity.myActivityTasks.map((myActivityTask, idx) => {
          //If this myActivityTask is not the first task, hasn't been completed, and is not directly after a completed myActivityTask, return
          if (
            idx > 0 &&
            !myActivityTask.completedAt &&
            !this.props.myActivity.myActivityTasks[idx - 1].completedAt
          )
            return null;

          //Thanks to the filtering above, the only myActivityTask without a completedAt will be the current myActivityTask
          let isCurrentTask = !myActivityTask.completedAt;

          return (
            <div
              ref={isCurrentTask ? this.latestMyActivityTask : null}
              className={`task-item ${isCurrentTask ? 'last-item' : ''}`}
              key={myActivityTask.myActivityTaskId}
            >
              <MyActivityTaskPanel
                myActivityTask={myActivityTask}
                onCompleteTask={() =>
                  this.onCompleteTask(myActivityTask.myActivityTaskId)
                }
              />
            </div>
          );
        })}
        {!this.state.isAlreadyCompleted && this.props.myActivity.completedAt ? (
          <div
            className="task-item last-item"
            ref={
              !!this.props.myActivity.completedAt
                ? this.latestMyActivityTask
                : null
            }
          >
            <div className="congrats-item">
              <div className="congrats-header">
                <img
                  className="image-gem"
                  src={imgGemHollow}
                  alt="Activity completed"
                />
                <h1>Congratulations!</h1>
                <p>You've finished this activity.</p>
              </div>
              {getCompletedJourneyStepPanel(this.props.myActivity)}
              <div className="congrats-feedback">
                <FeedbackStars
                  activityId={this.props.myActivity.sourceActivityId}
                  label="How would you rate this activity?"
                  feedbackType={Util.enum.FeedbackType.ActivityStars}
                />
                <FeedbackMessage
                  activityId={this.props.myActivity.sourceActivityId}
                  feedbackType={Util.enum.FeedbackType.ActivityMessage}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(
  mapStateToProps,
  { completeMyActivityTaskAction }
)(MyActivityTasksView);
