import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import ActivityCard from '../../Card/ActivityCard/ActivityCard';
import CardsView from '../../CardsView/CardsView';

// Data source: this.props.dataUrl OR this.props.activities
export default class ActivityCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.activities,
      activities: this.props.activities || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: result => {
        this.setState({
          activities: result
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (Util.array.none(this.state.activities)) {
      content = <div className="empty-text">No activities to display.</div>;
    }

    return (
      <CardsView
        className="activity-cards-view"
        isNeverLargeMode={this.props.isNeverLargeMode}
      >
        {content}
        {this.state.activities.map((activity, idx) => (
          <ActivityCard
            key={idx}
            isNeverLargeMode={this.props.isNeverLargeMode}
            activity={activity}
          />
        ))}
      </CardsView>
    );
  }
}
