import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';
import { addMyActivityAction } from '../../../../actions/activityActions';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

//Components
import Card from '../Card';
import ProgressBar from '../../ProgressBar/ProgressBar';
import ActivityTagline from '../../ActivityTagline/ActivityTagline';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.activity
class ActivityCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdding: false
    };

    this.onClick = this.onClick.bind(this);
  }
  onClick(isCompleted, isInProgress) {
    if (isInProgress) {
      this.props.openModalAction({
        type: Util.enum.ModalType.MyActivitiesModal,
        data: {
          selectedActivityId: this.props.activity.activityId
        }
      });
    } else if (!isCompleted) {
      this.setState({
        isAdding: true
      });

      this.props.addMyActivityAction(
        this.props.activity.activityId,
        this.props.journeyStepId,
        () => {
          this.setState({
            isAdding: false
          });

          this.props.openModalAction({
            type: Util.enum.ModalType.MyActivitiesModal,
            data: {
              selectedActivityId: this.props.activity.activityId
            }
          });
        }
      );
    } else {
      this.props.openModalAction({
        type: Util.enum.ModalType.CompletedActivityModal,
        data: {
          activity: this.props.activity,
          journeyStepId: this.props.journeyStepId
        }
      });
    }

    Util.analytics.track('ActivityClicked', {
      activityId: this.props.activity.activityId,
      activityName: this.props.activity.name
    });
  }
  render() {
    let correspondingMyActivity = this.props.myActivities.find(
      myAct =>
        myAct.sourceActivityId === this.props.activity.activityId &&
        !myAct.completedAt
    );
    let numCompletedTasks = correspondingMyActivity
      ? correspondingMyActivity.myActivityTasks.filter(
          myActTask => !!myActTask.completedAt
        ).length
      : null;

    let isInProgress = !!correspondingMyActivity;
    let isCompleted = !!this.props.userProgressActivity[
      this.props.activity.activityId
    ];

    let cardFooter =
      !isInProgress && !isCompleted ? (
        <div className="card-footer">
          <div className="footer-text">
            <p>
              {isInProgress ? (
                <span>
                  <span className="bold">View</span> My Activities
                </span>
              ) : (
                <span>
                  <span className="bold">Add</span> to My Activities
                </span>
              )}
            </p>
          </div>
          <ReactSVG
            className="footer-icon svg-button"
            src={isInProgress ? Util.icon.arrow.right : Util.icon.add}
          />
        </div>
      ) : null;

    return (
      <Card
        className={`activity-card ${this.state.isAdding ? 'disabled' : ''}`}
        isNeverLargeMode={this.props.isNeverLargeMode}
        onClick={() => this.onClick(isCompleted, isInProgress)}
      >
        <div className="card-body">
          <ActivityTagline
            journeyStepId={null}
            skills={this.props.activity.activitySkills}
          />
          <div className="activity-name mini-hidden">
            <Truncate
              component="h2"
              maxLine={2}
              text={this.props.activity.name}
            />
          </div>
          <div className="activity-name mini-only">
            <Truncate
              component="p"
              className="mini-only"
              maxLine={2}
              text={this.props.activity.name}
            />
          </div>
          <div className="activity-lower">
            <p className="task-count bold">{`${
              numCompletedTasks ? numCompletedTasks + '/' : ''
            }${this.props.activity.taskCount} ${Util.format.pluralise(
              this.props.activity.taskCount,
              'task'
            )}`}</p>
            <div className="flex-spacer"></div>
            {isInProgress ? (
              <h5 className="status-label in-progress">In Progress</h5>
            ) : null}
            {!isInProgress && isCompleted ? (
              <h5 className="status-label last-completed">
                <ReactSVG className="tick-icon" src={Util.icon.tick} />{' '}
                {Util.format.date.shortDate(
                  this.props.userProgressActivity[
                    this.props.activity.activityId
                  ]
                )}
              </h5>
            ) : null}
          </div>
        </div>
        {cardFooter}
        {isInProgress ? (
          <ProgressBar
            className="in-progress-bar"
            numerator={numCompletedTasks}
            denominator={correspondingMyActivity.myActivityTasks}
          />
        ) : isCompleted ? (
          <ProgressBar
            className="completed-bar"
            numerator={1}
            denominator={1}
          /> // Fake the full progress bar lol
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  myActivities: state.activityState.myActivities,
  userProgressActivity: state.userState.userProgress.activity
});

export default connect(
  mapStateToProps,
  { addMyActivityAction, openModalAction }
)(ActivityCard);
