import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Util from '../../../../../Util';

//Layout
import AppTeamLayout from '../../../../Layouts/App/AppTeamLayout/AppTeamLayout';

import TeamJourneysView from '../../../../UI/TeamJourneysView/TeamJourneysView';

export default class AppTeamJourneysPage extends Component {
  render() {
    let team = Util.context.user.getTeamById(this.props.teamId);
    if (!team) return <Redirect to={Util.route.app.home()} />;

    if (!team || Util.array.none(team.teamJourneys))
      return <Redirect to={Util.route.app.home()} />;

    let breadcrumbs = [
      {
        label: team.name,
        to: Util.route.app.team(team.teamId)
      },
      {
        label: 'Journeys'
      }
    ];

    return (
      <div className="leda-page-app-team-journeys">
        <AppTeamLayout
          pageTitle={`Journeys for ${team.name}`}
          breadcrumbs={breadcrumbs}
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <TeamJourneysView team={team} />
            </div>
          </div>
        </AppTeamLayout>
      </div>
    );
  }
}
