import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticateAction, logoutAction } from '../../../actions/userActions';
import Util from '../../../Util';

// Images
import img_leda_logo from '../../../img/leda_logo.svg';

class AppFooter extends Component {
  render() {
    return (
      <div className="leda-app-footer">
        <div className="leda-container-md">
          <div className="leda-row">
            <div className="leda-footer-inner">
              <div className="footer-left">
                <Link className="footer-logo" to={Util.route.site.home()}>
                  <ReactSVG src={img_leda_logo} />
                </Link>
                <p>Copyright &copy; {new Date().getFullYear()}</p>
              </div>
              <div className="footer-contact">
                <p>We want to hear from you.</p>
                <p>
                  <span className="contact-pre">
                    For questions, enquiries, feedback or help please contact us
                    at{' '}
                  </span>
                  <a href="mailto:hello@getleda.com">hello@getleda.com</a>
                </p>
                <div className="links">
                  <Link to={Util.route.site.privacyPolicy()}>
                    Privacy policy
                  </Link>
                  {Util.context.user.getCurrent() ? (
                    <span>
                      <button className="link-button" onClick={this.onLogoutClick}>Log out</button>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { authenticateAction, logoutAction }
)(AppFooter);
