import React, { Component } from 'react';
import Util from '../../../../Util';

import Carousel from '../../Carousel/Carousel';
import StoryChapterSelectPanel from './StoryChapterSelectPanel/StoryChapterSelectPanel';

export default class StoryChapterSelectCarousel extends Component {
  render() {
    let carouselInitialPanelIdx = this.props.chapters.indexOf(
      this.props.selectedChapter
    );
    let carouselChapterPanels = this.props.chapters.map((chapter, idx) => (
      <StoryChapterSelectPanel
        key={idx}
        chapter={chapter}
        isSelected={this.props.selectedChapter.chapterId === chapter.chapterId}
        onClick={() => this.props.onChapterClick(chapter)}
      />
    ));

    let getInitialSlideIdx = slidesToShow => {
      let maxIdx = carouselChapterPanels.length - slidesToShow;
      return carouselInitialPanelIdx > maxIdx
        ? maxIdx
        : carouselInitialPanelIdx;
    };

    let settings = {
      slidesToShow: 8,
      initialSlide: carouselInitialPanelIdx,
      responsive: [
        {
          breakpoint: Util.css.breakpoint.lg, //Below lg
          settings: {
            slidesToShow: 6,
            initialSlide: getInitialSlideIdx(6)
          }
        },
        {
          breakpoint: Util.css.breakpoint.md, //Below md
          settings: {
            slidesToShow: 4,
            initialSlide: getInitialSlideIdx(4)
          }
        },
        {
          breakpoint: Util.css.breakpoint.sm, //Below sm
          settings: {
            slidesToShow: 4,
            initialSlide: getInitialSlideIdx(4)
          }
        },
        {
          breakpoint: Util.css.breakpoint.xs, //Below xs
          settings: {
            slidesToShow: 5,
            initialSlide: getInitialSlideIdx(5)
          }
        }
      ]
    };

    return (
      <Carousel className="story-chapter-select-carousel" settings={settings}>
        {carouselChapterPanels}
      </Carousel>
    );
  }
}
