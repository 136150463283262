import React, { Component } from 'react';
import validator from 'validator';
import Util from '../../../../Util';
import withForm from '../withForm';

import Button from '../../Button/Button';

class GetLedaForm extends Component {
  render() {
    return (
      <form className="form-register" onSubmit={this.props.onSubmit}>
        <div className="form-row">
          {this.props.getField('firstName')}
          {this.props.getField('lastName')}
        </div>
        {this.props.getField('email')}
        {this.props.getField('phoneNumber')}
        {this.props.getField('teamSize')}
        {this.props.getField('comments')}
        <div className="button-container">
          <Button label="Get Leda" isPrimary={true} isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(GetLedaForm, {
  fields: {
    firstName: {
      label: 'First Name',
      placeholder: 'First Name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a first name';
      }
    },
    lastName: {
      label: 'Last Name',
      placeholder: 'Last Name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a last name';
      }
    },
    email: {
      label: 'Your email',
      placeholder: 'Email',
      getError: val => {
        if (!validator.isEmail(val)) return 'Enter a valid email address';
      }
    },
    phoneNumber: {
      label: 'Your contact number',
      placeholder: 'Phone Number',
      getError: val => {
        if (!validator.isMobilePhone(val)) return 'Enter a valid phone number';
      }
    },
    teamSize: {
      label: 'Team size',
      type: Util.enum.FieldType.Dropdown,
      valueProp: 'enumValue',
      displayProp: 'enumName',
      defaultValue: '1-5',
      placeholder: '1-5',
      getOptions: () => [
        { enumValue: '1-5', enumName: '1-5' },
        { enumValue: '5-20', enumName: '5-20' },
        { enumValue: '21-50', enumName: '21-50' },
        { enumValue: '50+', enumName: '50+' }
      ]
    },
    comments: {
      label: 'Questions or comments',
      placeholder: 'Send us your inquire or comment',
      isOptional: true
    }
  }
});
