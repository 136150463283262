import React, { Component } from 'react';
import Util from '../../../../Util';

//Components
import Card from '../Card';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.skill
export default class SkillCard extends Component {
  render() {
    return (
      <Card
        className="skill-card"
        isLocked={this.props.skill.isLocked}
        isLockedInterestEnabled={true}
        interestMessage={`Skill Card Interest - ${this.props.skill.name} (${this.props.skill.skillId})`}
        isNeverLargeMode={this.props.isNeverLargeMode}
        onClick={() =>
          Util.analytics.track('SkillCardClicked', {
            skillId: this.props.skill.skillId,
            skillName: this.props.skill.name
          })
        }
        to={
          this.props.skill.isLocked
            ? null
            : Util.route.app.skill(this.props.skill.urlId)
        }
      >
        <div className="card-body">
          <div className="skill-image">
            <img
              alt="Skill Icon"
              src={Util.storage.skillIcon(this.props.skill.urlId)}
            />
            <img
              className="animated"
              alt="Skill Icon"
              src={Util.storage.skillAnimatedIcon(this.props.skill.urlId)}
            />
          </div>
          <div className="skill-name">
            <h1 className="mini-hidden">{this.props.skill.name}</h1>
            <p className="mini-only">{this.props.skill.name}</p>
          </div>
          <div className="mini-hidden">
            <Truncate
              component="p"
              maxLine={5}
              text={this.props.skill.overview}
            />
          </div>
        </div>
      </Card>
    );
  }
}
