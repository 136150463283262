import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../../Util';

import img_grow_plant from './grow_plant.png';
import img_journey_activity_cards from './journey_activity_cards.jpg';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

import Grid from '../../../UI/Grid/Grid';
import JourneyBadge from '../../../UI/JourneyBadge/JourneyBadge';
import ButtonInput from '../../../UI/ButtonInput/ButtonInput';
import SkillIcon from '../../../UI/SkillIcon/SkillIcon';

export default class SiteHowPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterSkillGroupId: null
    };

    this.setFilterSkillGroupId = this.setFilterSkillGroupId.bind(this);
  }
  setFilterSkillGroupId(skillGroupId) {
    this.setState({
      filterSkillGroupId: skillGroupId
    });
  }
  render() {
    let getSkillLabels = skills => {
      return (
        <div>
          {skills.map(skill => {
            return (
              <div key={skill.skillId} className="skill-label">
                <SkillIcon size="sm" skillId={skill.skillId} />
                <Link to={Util.route.site.articlesContent(skill.urlId)}>
                  {skill.name}
                </Link>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <div className="leda-page-site-how">
        <SiteLayout>
          <div className="how-grow-strip">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="how-grow-inner">
                  <div className="how-grow-detail">
                    <h1 className="gilroy">
                      How do you <span className="blue">grow a leader?</span>
                    </h1>
                    <h1 className="grey-2">
                      Leaders aren't born, they're made. Here's how.
                    </h1>
                    <ol className="how-list">
                      <li>
                        We take world-class, hands-on leadership expertise.
                      </li>
                      <li>
                        Combine it with cutting-edge academic research in a
                        pragmatic learning framework.
                      </li>
                      <li>
                        Blend with best-practice education design in a Leda
                        Journey.
                      </li>
                      <li>
                        Let your teams loose on our learning experience
                        platform.
                      </li>
                      <li>
                        Use our mentor support resources to support learners in
                        your teams.
                      </li>
                    </ol>
                  </div>
                  <img className="how-grow-image" src={img_grow_plant} alt="Grow plant" />
                </div>
              </div>
            </div>
          </div>
          <div className="how-teach-strip">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="confined-row">
                  <h2 className="gilroy grey-1">How we teach</h2>
                  <p className="gilroy">
                    Leadership isn't talent. It's behaviour. Leda helps your
                    teams learn and practice stronger leadership behaviours,
                    from the ground up, every day.
                  </p>
                </div>
                <div className="how-teach-inner">
                  <img
                    src={img_journey_activity_cards}
                    alt=""
                    className="how-teach-image"
                  />
                  <div className="how-teach-detail">
                    <p className="gilroy">
                      A Leda Journey is a stepped learning program focused on a
                      single skill. A journey might take one to two weeks. Each
                      step takes around 10 minutes, including:
                    </p>
                    <ul className="gilroy">
                      <li>Videos</li>
                      <li>Games</li>
                      <li>Self-assessments</li>
                      <li>Activities</li>
                    </ul>
                    <p className="gilroy">
                      Leda Activities are the keys to behavioural change.
                      Practiced daily, activities become habits and, over time,
                      second nature.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="journeys-strip" className="journeys-strip">
            <div className="leda-container-md">
              <div className="leda-row">
                <div className="confined-row">
                  <h1 className="gilroy grey-1">
                    What you'll learn in our journeys
                  </h1>
                </div>
                <div className="filter-container">
                  <button
                    type="button"
                    className="filter-button link-button"
                    onClick={() => this.setFilterSkillGroupId(null)}
                  >
                    <span className="dot all"></span>All
                  </button>
                  {Util.context.referenceData
                    .getSkillGroups()
                    .map(skillGroup => (
                      <button
                        key={skillGroup.skillGroupId}
                        className="filter-button link-button"
                        onClick={() =>
                          this.setFilterSkillGroupId(skillGroup.skillGroupId)
                        }
                      >
                        <span
                          className="dot"
                          style={{
                            backgroundColor: `#${skillGroup.primaryColour}`
                          }}
                        ></span>
                        {skillGroup.name}
                      </button>
                    ))}
                </div>
                <div className="journeys-detail-container">
                  {Util.context.referenceData
                    .getSkillGroups()
                    .map(skillGroup => {
                      // if(this.state.filterSkillGroupId && skillGroup.skillGroupId !== this.state.filterSkillGroupId) return null;

                      let skillIds = skillGroup.skills.map(
                        skill => skill.skillId
                      );
                      let journeys = Util.context.referenceData
                        .getJourneys()
                        .filter(journey => {
                          return !!journey.skills.find(skill =>
                            skillIds.includes(skill.skillId)
                          );
                        });

                      return (
                        <div
                          key={skillGroup.skillGroupId}
                          style={{
                            display:
                              this.state.filterSkillGroupId &&
                                skillGroup.skillGroupId !==
                                this.state.filterSkillGroupId
                                ? 'none'
                                : 'block'
                          }}
                        >
                          <div
                            className="skill-group-heading"
                            style={{
                              backgroundColor: `#${skillGroup.primaryColour}`
                            }}
                          >
                            <h3 className="white">{skillGroup.name}</h3>
                          </div>
                          <Grid
                            className="journeys-detail-grid"
                            data={journeys}
                            columns={[
                              {
                                name: 'Journey name',
                                width: 200,
                                key: 'name',
                                render: val => <h2>{val}</h2>
                              },
                              {
                                name: 'Skills covered',
                                width: 200,
                                key: 'journeyId',
                                isSortDisabled: true,
                                render: (val, record) =>
                                  getSkillLabels(record.skills)
                              },
                              {
                                name: 'Journey description',
                                key: 'htmlSummary',
                                isSortDisabled: true,
                                render: (val, record) => (
                                  <section
                                    dangerouslySetInnerHTML={{
                                      __html: record.htmlSummary
                                    }}
                                  />
                                )
                              },
                              {
                                key: 'journeyId',
                                isSortDisabled: true,
                                render: (val, record) => (
                                  <JourneyBadge journey={record} />
                                )
                              }
                            ]}
                          />
                          {journeys.map(journey => {
                            return (
                              <div
                                className="journey-detail-panel"
                                key={journey.journeyId}
                              >
                                <h2>{journey.name}</h2>
                                {getSkillLabels(journey.skills)}
                                <section
                                  dangerouslySetInnerHTML={{
                                    __html: journey.htmlSummary
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="strip-start">
            <div className="confined-row">
              <div className="start-panel">
                <h4 className="gilroy white">See our platform in action</h4>
                <ButtonInput
                  className="email-try-it"
                  buttonLabel="Try for free"
                  buttonSize="lg"
                  buttonToFn={val => Util.route.site.contact({ email: val })}
                  placeholder="Enter your work email"
                />
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
