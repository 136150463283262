import React, { Component } from 'react';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

// Components
import UserFeedbackSkillRater from '../../../UI/UserFeedbackSkillRater/UserFeedbackSkillRater';

export default class SiteUserFeedbackPage extends Component {
  render() {
    return (
      <div className="leda-page-site-user-feedback">
        <SiteLayout>
          <div className="leda-container-md">
            <UserFeedbackSkillRater token={this.props.token} />
          </div>
        </SiteLayout>
      </div>
    );
  }
}
