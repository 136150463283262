import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BreadCrumb extends Component {
  constructor(props) {
    super(props);

    let paths = window.location.pathname.split('/').filter(path => path);

    if (!isNaN(paths[paths.length - 1])) {
      paths[paths.length - 2] += ` ${paths[paths.length - 1]}`;
      paths.pop();
    }

    if (paths[1] === 'content-editor' && paths[2]) {
      paths[1] = 'content-editor-existing/' + paths[2];
      paths.pop();
    } else if (paths[1] === 'communication-list') {
      paths[1] = paths[1] + '/' + paths[2];
      paths.pop();
    } else if (paths[1] === 'team-editor') {
      paths[1] = paths[1] + '/' + paths[2];
      paths.pop();
      paths.pop();
    }

    paths = paths.map(path => this.mapPathToPageNames(path)).flat();

    this.state = {
      paths: paths
    };
  }

  mapPathToPageNames(path) {
    if (path === 'admin') {
      return {
        displayName: 'Home',
        link: '/admin'
      };
    } else if (path === 'user-list') {
      return {
        displayName: 'User List',
        link: '/admin/user-list'
      };
    } else if (path === 'team-list') {
      return {
        displayName: 'Organisation & Team List',
        link: '/admin/team-list'
      };
    } else if (path.includes('team-user-list')) {
      const teamNumber = path.match(/\d+/g)[0];
      return [
        {
          displayName: 'Organisation & Team List',
          link: '/admin/team-list'
        },
        {
          displayName: `Team ${teamNumber} User List `,
          link: `/admin/team-user-list/${teamNumber}`
        }
      ];
    } else if (path.includes('team-editor')) {
      const teamNumber = path.match(/\d+/g)[0];
      return [
        {
          displayName: 'Organisation & Team List',
          link: '/admin/team-list'
        },
        {
          displayName: `Editing Team ${teamNumber}`,
          link: `/admin/team-editor/${teamNumber}`
        }
      ];
    } else if (path === 'organisation-creator') {
      return [
        {
          displayName: 'Organisation & Team List',
          link: '/admin/team-list'
        },
        {
          displayName: 'Create Organisation',
          link: '/admin/organisation-creator/'
        }
      ];
    } else if (path.includes('organisation-editor')) {
      const teamId = (path.match(/\d+/g) || [])[0];
      if (!isNaN(teamId)) {
        return [
          {
            displayName: 'Organisation & Team List',
            link: '/admin/team-list'
          },
          {
            displayName: 'Organisation Editor',
            link: '/admin/organisation-editor/' + teamId
          }
        ];
      } else {
        return [
          {
            displayName: 'Organisation & Team List',
            link: '/admin/team-list'
          },
          {
            displayName: 'Create Organisation',
            link: '/admin/organisation-editor'
          }
        ];
      }
    } else if (path === 'journey-list') {
      return {
        displayName: 'Journey List',
        link: '/admin/journey-list'
      };
    } else if (path === 'activity-list') {
      return {
        displayName: 'Activity List',
        link: '/admin/activity-list'
      };
    } else if (path.includes('activity-editor')) {
      const teamNumber = (path.match(/\d+/g) || [''])[0];
      return [
        {
          displayName: 'Activity List',
          link: '/admin/activity-list'
        },
        {
          displayName: teamNumber[0]
            ? `Edit Activity ${teamNumber}`
            : 'Create Activity',
          link: `/admin/activity-editor/${teamNumber}`
        }
      ];
    } else if (path === 'content-list') {
      return {
        displayName: 'Content List',
        link: '/admin/content-list'
      };
    } else if (path === 'content-editor') {
      return [
        {
          displayName: 'Content List',
          link: '/admin/content-list'
        },
        {
          displayName: 'Create Content',
          link: `/admin/content-editor`
        }
      ];
    } else if (path.includes('content-editor-existing')) {
      return [
        {
          displayName: 'Content List',
          link: '/admin/content-list'
        },
        {
          displayName: 'Edit Content',
          link: `/admin/content-editor/${path.split('/')[1]}`
        }
      ];
    } else if (path.includes('communication-list')) {
      return {
        displayName: `Communication List (${path.split('/')[1]})`,
        link: '/admin/' + path
      };
    } else if (path === 'team-presets') {
      return {
        displayName: `Team Presets`,
        link: '/admin/team-presets'
      };
    } else if (path.includes('team-presets')) {
      const presetNumber = (path.match(/\d+/g) || [''])[0];
      return [
        {
          displayName: `Team Presets`,
          link: '/admin/team-presets'
        },
        {
          displayName: `Edit Preset ${presetNumber}`,
          link: `/admin/team-presets/${presetNumber}`
        }
      ];
    } else {
      return {
        displayName: undefined,
        link: '/admin'
      };
    }
  }

  render() {
    return (
      <div className="bread-crumb">
        {this.state.paths.map(path => {
          return (
            <span key={path.displayName}>
              {' '}
              {path.displayName === 'Home' ? '' : <span> > </span>}
              <Link className="back-link" to={path.link}>
                {path.displayName}
              </Link>
            </span>
          );
        })}
      </div>
    );
  }
}

export default BreadCrumb;
