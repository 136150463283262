import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

//Components
import Card from '../Card';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.game
class GameCard extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    this.props.openModalAction({
      type: Util.enum.ModalType.GameModal,
      data: {
        gameId: this.props.game.gameId
      }
    });

    Util.analytics.track('GameClicked', {
      gameId: this.props.game.gameId,
      gameUrlId: this.props.game.urlId,
      gameName: this.props.game.name
    });
  }
  render() {
    let isCompleted = !!this.props.userProgressGame[this.props.game.gameId];
    let title = this.props.game.name;
    let callToAction = 'Play Game';

    switch (this.props.game.type) {
      case Util.enum.GameType.Dialogue:
        title = title + ' Game';
        break;
      default:
        break;
    }

    return (
      <Card
        className="game-card"
        isNeverLargeMode={this.props.isNeverLargeMode}
        onClick={this.onClick}
      >
        <div className="card-body">
          <div className="game-title">
            <div className="game-name mini-hidden">
              <Truncate component="h3" maxLine={1} text={title} />
            </div>
            <div className="game-name mini-only">
              <Truncate
                component="p"
                className="mini-only"
                maxLine={1}
                text={title}
              />
            </div>
            <ReactSVG src={Util.icon.game} className="game-icon" />
          </div>
          <div className="game-detail">
            <Truncate
              component="p"
              maxLine={4}
              text={this.props.game.overview}
            />
            <img
              alt={this.props.game.name + ' Game Icon'}
              src={Util.storage.gameIcon(this.props.game.urlId)}
            />
          </div>
        </div>
        <div className="card-footer">
          <div className="footer-text">
            {isCompleted ? (
              <h5 className="status-label last-completed">
                <ReactSVG className="tick-icon" src={Util.icon.tick} />{' '}
                {Util.format.date.shortDate(
                  this.props.userProgressGame[this.props.game.gameId]
                )}
              </h5>
            ) : null}
            <p>{callToAction}</p>
          </div>
          <ReactSVG
            className="footer-icon svg-button"
            src={Util.icon.arrow.right}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProgressGame: state.userState.userProgress.game
});

export default connect(
  mapStateToProps,
  { openModalAction }
)(GameCard);
