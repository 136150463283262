import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import Button from '../../../UI/Button/Button';
import DateField from '../../../UI/DateTimeField/DateField/DateField';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import Grid from '../../../UI/Grid/Grid';
import JourneyBadge from '../../../UI/JourneyBadge/JourneyBadge';

class AdminTeamEditorJourneys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      journeys: this.initialise(this.props.journeys.teamJourneys)
    };
  }

  initialise = journeys => {
    journeys = journeys.sort((j1, j2) =>
      Util.sort.asDate(j1.scheduledAt, j2.scheduledAt)
    );

    journeys.forEach((journey, i) => {
      // unlockedAt
      if (!journeys[i].unlockedAt) {
        journeys[i].unlockedAt = journeys[i].scheduledAt;
      } else {
        let nbWeeksBeforeUnlock = Math.round(
          Util.moment.diffDays(
            journeys[i].scheduledAt,
            journeys[i].unlockedAt
          ) / 7
        );
        nbWeeksBeforeUnlock = nbWeeksBeforeUnlock > 4 ? 4 : nbWeeksBeforeUnlock;
        journeys[i].unlockedAt = Util.moment.substractDays(
          journeys[i].scheduledAt,
          nbWeeksBeforeUnlock * 7
        );
      }
      if (i < journeys.length - 1) {
        journeys[i].length = Util.moment.diffDays(
          journeys[i + 1].scheduledAt,
          journeys[i].scheduledAt
        );
      } else {
        journeys[i].length = 0;
      }
    });

    return journeys;
  };

  getIdFromOrder = order =>
    this.state.journeys.filter(journey => journey.order === order);

  getIndexFromId = teamJourneyId => {
    let index;
    this.state.journeys.forEach((journey, i) => {
      if (journey.teamJourneyId === teamJourneyId) {
        index = i;
      }
    });
    return index;
  };

  onScheduledAtChange = (teamJourneyId, prevVal, newVal) => {
    let journeys = [...this.state.journeys];

    const journeyIndex = this.getIndexFromId(teamJourneyId);

    const CurrentJourneyNbWeeksBeforeUnlock = Math.floor(
      Util.moment.diffDays(
        journeys[journeyIndex].scheduledAt,
        journeys[journeyIndex].unlockedAt
      ) / 7
    );

    journeys[journeyIndex].scheduledAt = new Date(
      Util.format.date.as(newVal, 'YYYY-MM-DDTHH:mm:ss')
    ).toISOString();

    journeys[journeyIndex].unlockedAt = Util.moment.substractDays(
      journeys[journeyIndex].scheduledAt,
      CurrentJourneyNbWeeksBeforeUnlock * 7
    );

    for (let i = journeyIndex; i < journeys.length - 1; i++) {
      const previousJourney = journeys[i - 1];
      const currentJourney = journeys[i];
      const nextJourney = journeys[i + 1];

      if (i === journeyIndex && i > 0) {
        previousJourney.length = Util.moment.diffDays(
          currentJourney.scheduledAt,
          previousJourney.scheduledAt
        );
      }

      const NextJourneyNbWeeksBeforeUnlock = Math.floor(
        Util.moment.diffDays(nextJourney.scheduledAt, nextJourney.unlockedAt) /
          7
      );

      nextJourney.scheduledAt = Util.moment.addDays(
        currentJourney.scheduledAt,
        currentJourney.length
      );

      nextJourney.unlockedAt = Util.moment.substractDays(
        nextJourney.scheduledAt,
        NextJourneyNbWeeksBeforeUnlock * 7
      );
    }

    this.setState({
      journeys
    });
  };

  onUnlockedAtChange = (newValue, teamJourneyId) => {
    let journeys = Array.from(this.state.journeys);
    const index = this.getIndexFromId(teamJourneyId);

    journeys[index].unlockedAt = Util.moment.substractDays(
      journeys[index].scheduledAt,
      newValue * 7
    );

    this.setState({ journeys });
  };

  onLengthChange = (teamJourneyId, newLength) => {
    let journeys = Array.from(this.state.journeys);

    const index = this.getIndexFromId(teamJourneyId);
    journeys[index].length = Number.parseInt(newLength);

    for (let i = index + 1; i < journeys.length; i++) {
      let nbWeeksBeforeUnlock = Math.floor(
        Util.moment.diffDays(journeys[i].scheduledAt, journeys[i].unlockedAt) /
          7
      );

      journeys[i].scheduledAt = Util.moment.addDays(
        journeys[i - 1].scheduledAt,
        journeys[i - 1].length
      );

      journeys[i].unlockedAt = Util.moment.substractDays(
        journeys[i].scheduledAt,
        nbWeeksBeforeUnlock * 7
      );
    }

    this.setState({
      journeys
    });
  };

  addTeamJourney = journeyId => {
    const journeys = Array.from(this.state.journeys);

    if (
      !journeyId ||
      Object.values(this.state.journeys).find(
        teamJourney => teamJourney.journeyId === journeyId
      )
    )
      return null;

    const tempId = Util.id.tempId.getTempId();
    if (journeys.length) {
      const previousJourney = journeys[journeys.length - 1];
      const newJourneyScheduledAt = Util.moment.addDays(
        previousJourney.scheduledAt,
        previousJourney.length
      );
      journeys.push({
        teamJourneyId: tempId,
        journeyId,
        length: 0,
        scheduledAt: newJourneyScheduledAt,
        unlockedAt: newJourneyScheduledAt
      });
    } else {
      const newJourneyScheduledAt = new Date(
        Util.moment.startOfDay() + 'Z'
      ).toISOString();
      journeys.push({
        teamJourneyId: tempId,
        journeyId,
        length: 0,
        scheduledAt: newJourneyScheduledAt,
        unlockedAt: newJourneyScheduledAt
      });
    }

    this.setState({
      journeys
    });
  };
  removeTeamjourney = teamJourneyId => {
    const journeys = Array.from(this.state.journeys);
    journeys.splice(this.getIndexFromId(teamJourneyId), 1);

    journeys.forEach((journey, i) => {
      if (i < journeys.length - 1) {
        journeys[i].length = Util.moment.diffDays(
          journeys[i + 1].scheduledAt,
          journeys[i].scheduledAt
        );
      } else {
        journeys[i].length = 0;
      }
    });

    this.setState({
      journeys
    });
  };

  moveUp = teamJourneyIdDown => {
    const journeys = Object.assign(this.state.journeys);
    const indexDown = this.getIndexFromId(teamJourneyIdDown);

    if (indexDown > 0) {
      const nbWeeksBeforeUnlockDownIndexDown = Math.floor(
        Util.moment.diffDays(
          journeys[indexDown].scheduledAt,
          journeys[indexDown].unlockedAt
        ) / 7
      );

      const nbWeeksBeforeUnlockDownIndexUp = Math.floor(
        Util.moment.diffDays(
          journeys[indexDown - 1].scheduledAt,
          journeys[indexDown - 1].unlockedAt
        ) / 7
      );

      [journeys[indexDown], journeys[indexDown - 1]] = [
        journeys[indexDown - 1],
        journeys[indexDown]
      ];

      journeys[indexDown - 1].scheduledAt = journeys[indexDown].scheduledAt;

      journeys[indexDown].scheduledAt = Util.moment.addDays(
        journeys[indexDown - 1].scheduledAt,
        journeys[indexDown - 1].length
      );

      [journeys[indexDown].unlockedAt, journeys[indexDown - 1].unlockedAt] = [
        Util.moment.substractDays(
          journeys[indexDown].scheduledAt,
          nbWeeksBeforeUnlockDownIndexUp * 7
        ),
        Util.moment.substractDays(
          journeys[indexDown - 1].scheduledAt,
          nbWeeksBeforeUnlockDownIndexDown * 7
        )
      ];

      for (let i = indexDown + 1; i < journeys.length; i++) {
        let nbWeeksBeforeUnlock = Math.floor(
          Util.moment.diffDays(
            journeys[i].scheduledAt,
            journeys[i].unlockedAt
          ) / 7
        );

        journeys[i].scheduledAt = Util.moment.addDays(
          journeys[i - 1].scheduledAt,
          journeys[i - 1].length
        );

        journeys[i].unlockedAt = Util.moment.substractDays(
          journeys[i].scheduledAt,
          nbWeeksBeforeUnlock * 7
        );
      }

      this.setState({
        journeys
      });
    }
  };

  moveDown = teamJourneyIdUp => {
    const journeys = Object.assign(this.state.journeys);
    const indexUp = this.getIndexFromId(teamJourneyIdUp);

    if (indexUp < journeys.length - 1) {
      const nbWeeksBeforeUnlockDownIndexDown = Math.floor(
        Util.moment.diffDays(
          journeys[indexUp + 1].scheduledAt,
          journeys[indexUp + 1].unlockedAt
        ) / 7
      );

      const nbWeeksBeforeUnlockDownIndexUp = Math.floor(
        Util.moment.diffDays(
          journeys[indexUp].scheduledAt,
          journeys[indexUp].unlockedAt
        ) / 7
      );
      [journeys[indexUp], journeys[indexUp + 1]] = [
        journeys[indexUp + 1],
        journeys[indexUp]
      ];

      journeys[indexUp + 1].scheduledAt = journeys[indexUp].scheduledAt;

      journeys[indexUp].scheduledAt = Util.moment.addDays(
        journeys[indexUp - 1].scheduledAt,
        journeys[indexUp - 1].length
      );

      [journeys[indexUp].unlockedAt, journeys[indexUp + 1].unlockedAt] = [
        Util.moment.substractDays(
          journeys[indexUp].scheduledAt,
          nbWeeksBeforeUnlockDownIndexDown * 7
        ),
        Util.moment.substractDays(
          journeys[indexUp + 1].scheduledAt,
          nbWeeksBeforeUnlockDownIndexUp * 7
        )
      ];

      for (let i = indexUp + 2; i < journeys.length; i++) {
        let nbWeeksBeforeUnlock = Math.floor(
          Util.moment.diffDays(
            journeys[i].scheduledAt,
            journeys[i].unlockedAt
          ) / 7
        );
        journeys[i].scheduledAt = Util.moment.addDays(
          journeys[i - 1].scheduledAt,
          journeys[i - 1].length
        );

        journeys[i].unlockedAt = Util.moment.substractDays(
          journeys[i].scheduledAt,
          nbWeeksBeforeUnlock * 7
        );
      }

      this.setState({
        journeys
      });
    }
  };

  addBreak = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Add Break',
      data: {
        value: '',
        label: 'Break Label',
        onSubmit: breakName => {
          Util.api.post(
            '/api/admin/createBreak',
            {
              teamId: this.props.journeys.teamId,
              breakName
            },
            {
              success: journeyId => {
                const journeys = this.state.journeys;
                const previousJourney = journeys[journeys.length - 1];
                const newJourneyScheduledAt = Util.moment.addDays(
                  previousJourney.scheduledAt,
                  previousJourney.length
                );
                this.setState({
                  journeys: this.state.journeys.concat({
                    isBreak: true,
                    breakLabel: breakName,
                    teamJourneyId: Util.id.tempId.getTempId(),
                    journeyId,
                    length: 0,
                    scheduledAt: newJourneyScheduledAt,
                    unlockedAt: newJourneyScheduledAt
                  })
                });
              }
            }
          );
        }
      }
    });
  };

  editBreakName = teamJourneyId => {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: 'Edit Break Label',
      data: {
        value: '',
        label: 'Break Label',
        onSubmit: breakLabel => {
          let journeys = [...this.state.journeys];

          journeys = journeys.map(journey => {
            if (journey.teamJourneyId === teamJourneyId) {
              journey.breakLabel = breakLabel;
              return journey;
            } else {
              return journey;
            }
          });

          this.setState({ journeys });
        }
      }
    });
  };

  render() {
    return (
      <form
        className="admin-form-team-editor"
        onSubmit={e => {
          e.preventDefault();

          let newTeam = this.props.team;
          newTeam.teamJourneys = Object.values(this.state.journeys);
          Util.api.post(
            '/api/admin/updateTeam',
            { team: newTeam },
            {
              success: team => {
                alert('Team saved.');

                this.setState({
                  journeys: team.teamJourneys
                });
              },
              failure: () => {
                alert('error while saving team');
              }
            }
          );
        }}
      >
        <Grid
          title="Journeys for this team"
          data={this.state.journeys}
          toolbarItems={[
            <Dropdown
              key={1}
              options={[...Util.context.referenceData.getJourneys()]
                .sort((j1, j2) =>
                  Util.sort.by('name', j1, j2, Util.sort.asString)
                )
                .filter(({ journeyId }) => journeyId !== 1000)}
              valueProp={'journeyId'}
              displayProp={'name'}
              render={(val, record) =>
                val + (record.isLocked ? ' (GLOBAL LOCKED)' : '')
              }
              isEmptyAllowed={true}
              isEmptyOnChange={true}
              placeholder="Select a journey to add"
              onChange={this.addTeamJourney}
            />,
            <Button
              icon={Util.icon.breakWhite}
              className="add-break"
              isBlack={true}
              key={2}
              label="Add Break"
              onClick={this.addBreak}
            />
          ]}
          columns={[
            {
              key: 'teamJourneyId',
              name: 'Order',
              isSortDisabled: true,
              render: val => {
                return (
                  <div className="move-buttons">
                    <Button
                      isSubmit={false}
                      label="&#9650;"
                      onClick={() => {
                        this.moveUp(val);
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {this.getIndexFromId(val) + 1}
                    </div>
                    <Button
                      isSubmit={false}
                      label="&#9660;"
                      onClick={() => {
                        this.moveDown(val);
                      }}
                    />
                  </div>
                );
              }
            },
            {
              key: 'journeyId',
              name: '',
              isSortDisabled: true,
              render: (val, record) => {
                if (record.isBreak) {
                  return (
                    <div className="break-icon-wrapper">
                      <ReactSVG
                        src={Util.icon.breakGrey}
                        style={{ width: 30, color: '#828282' }}
                      />
                    </div>
                  );
                } else {
                  return <JourneyBadge journeyId={val} />;
                }
              }
            },
            {
              key: 'journeyId',
              name: 'Name',
              width: '100%',
              isSortDisabled: true,
              render: (val, record) => {
                let journey = Util.context.referenceData.getJourneyById(val);

                if (record.isBreak) {
                  return <p>{record.breakLabel}</p>;
                } else if (journey) {
                  return (
                    <p>
                      {journey.name}
                      {journey.isLocked ? <b> (GLOBAL LOCKED)</b> : null}
                    </p>
                  );
                } else {
                  return 'N/A';
                }
              }
            },
            {
              key: 'length',
              name: 'Length',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <span className="length-input">
                    <input
                      type="number"
                      min="0"
                      value={val}
                      onChange={e =>
                        this.onLengthChange(
                          record.teamJourneyId,
                          e.target.value
                        )
                      }
                    />
                    <p>days</p>
                  </span>
                );
              }
            },
            {
              key: 'scheduledAt',
              name: 'Scheduled At',
              sortFn: Util.sort.asDate,
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <DateField
                    key={record.teamJourneyId}
                    value={val}
                    onChange={newVal => {
                      this.onScheduledAtChange(
                        record.teamJourneyId,
                        val,
                        Util.format.date.as(newVal, 'YYYY-MM-DDTHH:mm:ss')
                      );
                    }}
                  />
                );
              }
            },
            {
              key: 'unlockedAt',
              name: 'Unlocked At',
              isSortDisabled: true,
              render: (val, record) => {
                const nbWeeksBeforeUnlock = Math.floor(
                  Util.moment.diffDays(record.scheduledAt, record.unlockedAt) /
                    7
                );

                const options = [
                  { display: 'On scheduled day', value: 0 },
                  { display: '1 week earlier', value: 1 },
                  { display: '2 weeks earlier', value: 2 },
                  { display: '3 week earlier', value: 3 },
                  { display: '4 week earlier', value: 4 },
                  { display: 'Always unlocked', value: 1000 }
                ];

                if (!record.isBreak) {
                  return (
                    <div>
                      <Dropdown
                        key={nbWeeksBeforeUnlock}
                        defaultValue={nbWeeksBeforeUnlock}
                        options={options}
                        displayProp={'display'}
                        valueProp={'value'}
                        onChange={newValue =>
                          this.onUnlockedAtChange(
                            newValue,
                            record.teamJourneyId
                          )
                        }
                        placeholder={'On scheduled day'}
                      />
                      {new Date(val) > new Date('2010-01-01') &&
                        Util.format.date.shortDate(val)}
                    </div>
                  );
                }
              }
            },
            {
              key: 'teamJourneyId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <span
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <Button
                      size="sm"
                      isIcon={true}
                      icon={Util.icon.bin}
                      onClick={() => this.removeTeamjourney(val)}
                    />

                    {record.isBreak && (
                      <div style={{ marginRight: 5 }}>
                        <Button
                          isIcon={true}
                          size="sm"
                          icon={Util.icon.edit}
                          onClick={() => this.editBreakName(val)}
                        />
                      </div>
                    )}
                  </span>
                );
              }
            }
          ]}
        />
        <div className="button-container" style={{ marginTop: '30px' }}>
          <Button
            label="Cancel"
            isSecondary={true}
            to={Util.route.admin.teamList()}
          />
          <Button label="Save" isSubmit={true} />
        </div>
        <div style={{ height: '100px' }}></div>
      </form>
    );
  }
}

export default connect(null, { openModalAction })(AdminTeamEditorJourneys);
