import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';

import LinesEllipsis from 'react-lines-ellipsis';

import Grid from '../../../UI/Grid/Grid';
import Button from '../../../UI/Button/Button';

import Util from '../../../../Util';

class AdminTeamEditorPosts extends Component {
  state = {
    posts: []
  };

  componentDidMount() {
    Util.api.post(
      '/api/post/getAllPostsByTeamId/',
      { teamId: this.props.teamId },
      {
        success: posts => {
          this.setState({ posts });
        }
      }
    );
  }

  createPost = () => {
    this.props.openModalAction({
      type: Util.enum.ModalType.PostEditorModal,
      data: {
        isGlobal: false,
        organisationId: this.props.organisationId,
        teamId: this.props.teamId,
        onPostSaved: post => {
          Util.api.post(
            '/api/post/savePost',
            { post },
            {
              success: post => {
                const posts = [...this.state.posts];
                posts.push(post);
                this.setState({
                  posts
                });
              }
            }
          );
        }
      }
    });
  };

  editPost = postId => {
    this.props.openModalAction({
      type: Util.enum.ModalType.PostEditorModal,
      data: {
        isGlobal: false,
        organisationId: this.props.organisationId,
        teamId: this.props.teamId,
        postId,
        onPostSaved: post => {
          Util.api.post(
            '/api/post/savePost',
            { post },
            {
              success: newPost => {
                const posts = [...this.state.posts];

                let i = 0;
                for (const post of posts) {
                  if (post.postId === postId) {
                    posts[i] = newPost;
                    break;
                  }
                  i++;
                }
                this.setState({
                  posts
                });
              }
            }
          );
        }
      }
    });
  };

  deletePost = postId => {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: 'Are you sure you wish to delete this post?',
        yesLabel: 'Delete',
        noLabel: 'Cancel',
        yesFn: () => {
          Util.api.post(
            '/api/post/deletePost',
            {
              postId
            },
            {
              success: () => {
                const posts = this.state.posts.filter(
                  post => post.postId !== postId
                );
                this.setState({ posts });
              }
            }
          );
        }
      }
    });
  };

  render() {
    let posts = [...this.state.posts];

    //sort by scheduled at, then by posted at
    posts.map(post => {
      if (post.postedAt) {
        post.scheduledAt = null;
      }

      return post;
    });

    // get only previous posts and order them
    const previousPosts = posts
      .filter(({ postedAt }) => postedAt)
      .sort((p1, p2) => Util.sort.by('postedAt', p1, p2, Util.sort.asDate));

    // get only future posts and order them
    const futurePosts = posts
      .filter(({ scheduledAt }) => scheduledAt)
      .sort((p1, p2) => Util.sort.by('scheduledAt', p1, p2, Util.sort.asDate));

    posts = [...previousPosts, ...futurePosts]; // posts are now initially properly sorted

    return (
      <Grid
        data={posts}
        toolbarItems={[
          <Button
            key={1}
            label="Create a post"
            onClick={this.createPost}
            icon={Util.icon.post}
          />
        ]}
        columns={[
          {
            name: 'Scheduled at',
            key: 'scheduledAt',
            sortFn: Util.sort.asDate,
            render: val => (val ? Util.format.date.shortDateTime(val) : null)
          },
          {
            name: 'Posted at',
            key: 'postedAt',
            sortFn: Util.sort.asDate,
            render: val => (val ? Util.format.date.shortDateTime(val) : null)
          },
          {
            name: 'Content',
            key: 'text',
            isSortDisabled: true,
            render: val => <LinesEllipsis maxLine={3} text={val} />
          },
          {
            name: '',
            key: 'postId',
            isSortDisabled: true,
            render: (val, record) => {
              return (
                <div className="button-container">
                  <Button
                    icon={Util.icon.edit}
                    size="sm"
                    isSecondary={true}
                    onClick={() => this.editPost(val)}
                  />
                  <Button
                    icon={Util.icon.bin}
                    size="sm"
                    isSecondary={true}
                    onClick={() => this.deletePost(val)}
                  />
                </div>
              );
            }
          }
        ]}
      />
    );
  }
}

export default connect(null, { openModalAction })(AdminTeamEditorPosts);
