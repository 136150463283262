import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionedUserNotificationAction } from '../../../../actions/notificationActions';
import Util from '../../../../Util';

// Data source: this.props.userNotification
class UserNotificationListItem extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    if (!this.props.userNotification.actionedAt)
      this.props.actionedUserNotificationAction(
        this.props.userNotification.userNotificationId
      );
    if (this.props.onClick) this.props.onClick();
  }
  render() {
    let notification = this.props.userNotification.notification;

    let getLinkTo = () => {
      if (notification.link) return notification.link;
      if (notification.journeyId)
        return Util.route.app.journey(notification.journeyId); // i don't like this, it will be ugly URL

      switch (notification.type) {
        case Util.enum.NotificationType.General:
        case Util.enum.NotificationType.Welcome:
        case Util.enum.NotificationType.Journey:
          return Util.route.app.home();
        case Util.enum.NotificationType.Post:
        case Util.enum.NotificationType.PostComment:
          return Util.route.app.post(notification.postId);
        default:
          return null;
      }
    };

    let getIcon = () => {
      switch (notification.type) {
        case Util.enum.NotificationType.Welcome:
          return Util.icon.treasure;
        case Util.enum.NotificationType.Journey:
          return Util.icon.journey;
        case Util.enum.NotificationType.Post:
          return Util.icon.post;
        case Util.enum.NotificationType.PostComment:
          return Util.icon.comment;
        default:
          return Util.icon.notification;
      }
    };

    let icon = getIcon(); //Default to bell icon
    let linkTo = getLinkTo(); // Default to specific link if exists

    let isAwaitingAction = linkTo && !this.props.userNotification.actionedAt;

    return (
      <div
        className={`user-notification-list-item ${
          isAwaitingAction ? 'awaiting-action' : ''
        }`}
        onClick={this.onClick}
      >
        {linkTo ? (
          <Link className="link-no-style link-cover" to={linkTo} />
        ) : null}
        <div className="notification-icon">
          <ReactSVG className="icon" src={icon} />
        </div>
        <div className="notification-info">
          <p className="grey-3">
            {Util.format.date.fromNowReadable(
              new Date(this.props.userNotification.createdAt),
              ' ago'
            )}
          </p>
          <p className="bold">{notification.title}</p>
          <p
            className="message"
            dangerouslySetInnerHTML={{
              __html: Util.text.toSafeHtml(notification.message)
            }}
          ></p>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { actionedUserNotificationAction }
)(UserNotificationListItem);
