import React, { Component } from 'react';
import Util from '../../../../Util';

// Images
import imageError from '../../../../img/error.png';

export default class SiteInvalidBrowserPage extends Component {
  render() {
    return (
      <div className="leda-page-site-invalid-browser">
        <div className="leda-container-sm">
          <div className="leda-row">
            <div className="error-panel">
              <img src={imageError} alt="Error - Browser out of date" />
              <h2>Your browser is out of date.</h2>
              <p>
                Sorry, Leda is not compatible with your current web browser (
                {Util.userAgent.getCurrent().browser.name}). Please update your
                browser to gain improved security, speed and overall experience.
              </p>
              <p>
                We recommend using{' '}
                <a href="https://www.google.com/chrome/">Google Chrome</a> which
                is available for download{' '}
                <a href="https://www.google.com/chrome/">here</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
