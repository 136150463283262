import React, { Component } from 'react';
import Util from '../../../Util';

import SkillLinkItem from '../SkillLinkItem/SkillLinkItem';

export default class CapabilitiesLinkList extends Component {
  render() {
    return (
      <div className="capabilities-link-list">
        {Util.context.referenceData.getCapabilities().map(capability => (
          <div key={capability.capabilityId} className="capability-panel">
            {capability.skillGroups.map(skillGroup => (
              <div key={skillGroup.skillGroupId} className="skill-group-panel">
                {skillGroup.name}
                {skillGroup.skills.map(skill => (
                  <SkillLinkItem
                    key={skill.skillId}
                    skill={skill}
                    onClick={this.props.onItemClick}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
