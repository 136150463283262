import React, { Component } from 'react';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

export default class SitePrivacyPolicyPage extends Component {
  render() {
    return (
      <div className="leda-page-site-privacy-policy">
        <SiteLayout pageTitle="Privacy Policy">
          <div className="leda-container-md">
            <div className="leda-row">
              <div>
                <div className="columns">
                  <div className="column">
                    <section className="gilroy">
                      <strong>Leda Privacy policy</strong>
                      <p>
                        This privacy policy relates to how Productive
                        Procrastination Pty Ltd ACN 608 981 577 (<b>LEDA</b>)
                        collects and handles your personal information. In this
                        policy “we”, “our” and “us” refers to LEDA.
                      </p>
                      <p>
                        For the purposes of this policy, “
                        <b>personal information</b>” means information or an
                        opinion about an identified individual, or an individual
                        who is reasonably identifiable.
                      </p>
                      <p>
                        This Privacy policy explains how we will treat your
                        personal information that you provide to us in any way.
                        We review our Privacy policy regularly to ensure it is
                        up-to-date so we encourage you to review it from time to
                        time via the link on our website (
                        <a href="http://www.getleda.com">www.getleda.com</a>).
                      </p>
                      <strong>1. Open and transparent management</strong>
                      <p>
                        We take our obligations under the Privacy Act 1988 (the{' '}
                        <b>Act</b>) and the Australian Privacy Principles (
                        <b>APPs</b>) very seriously and have implemented
                        practices, procedures and systems to ensure we comply
                        with those laws.
                      </p>
                      <p>
                        We are committed to maintaining the confidentiality and
                        security of your personal information and managing it in
                        an open and transparent way.
                      </p>
                      <strong>2. Using a pseudonym or being anonymous</strong>
                      <p>
                        Wherever it is lawful and practical, you have the option
                        of using a pseudonym or not identifying yourself when
                        dealing with us. However, by doing so you acknowledge
                        that it may affect our ability to provide our goods and
                        services to you. You will need to provide us with
                        accurate personal details when you sign up to LEDA.
                      </p>
                      <p>
                        For clarification on circumstances where you must
                        identify yourself, please contact us at{' '}
                        <a href="mailto:hello@getleda.com">hello@getleda.com</a>
                        . You may use a pseudonym or not identify yourself when
                        making such a request.
                      </p>
                      <strong>
                        3. Collection of solicited personal information
                      </strong>
                      <p>3.1 Types of information collected</p>
                      <p>
                        In the process of conducting our businesses, we collect
                        a range of personal information about our current and
                        prospective users, suppliers, agents, service providers,
                        other business associates and the people who run the
                        businesses we deal with. This information can include
                        such things as name, email address, date of birth,
                        gender, school and postcode.
                      </p>
                      <p>
                        3.2 Personal information (that is not sensitive
                        information)
                      </p>
                      <p>
                        We will only collect your personal information where:
                      </p>
                      <p>
                        (a) it is reasonably necessary for us to pursue one or
                        more of our functions or activities; or
                      </p>
                      <p>(b) we are required to by law.</p>
                      <p>3.3 Sensitive information</p>
                      <p>
                        Some personal information (e.g. race, ethnicity, health
                        information etc.) is sensitive and requires a higher
                        level of protection under the Privacy Act. We will only
                        collect your sensitive information when:
                      </p>
                      <p>(a) we have your consent; and</p>
                      <p>
                        (b) the collection is reasonably necessary for us to
                        carry out one or more of our functions or activities.
                      </p>
                      <p>3.4 Exceptions to the need for your consent</p>
                      <p>
                        We will not need your consent to collect your sensitive
                        information when:
                      </p>
                      <p>(a) it is required or authorised by law;</p>
                      <p>
                        (b) a “permitted general situation” exists as defined
                        under the Act; and
                      </p>
                      <p>
                        (c) a “permitted health situation” exists as defined
                        under the Act.
                      </p>
                      <p>3.5 Collection by lawful and fair means</p>
                      <p>
                        We will only collect your personal information by lawful
                        and fair means. This includes using analytics tracking
                        in our app, website and web app, and “Cookies” on our
                        website. Cookies are small files that are transferred to
                        your computer's hard drive through your web browser and
                        enable our site to recognise your browser and remember
                        certain information. We use cookies and tracking
                        software to compile aggregate data about site traffic,
                        site interaction and usage of the app so that we can
                        offer better experiences and tools in the future. You
                        should be able to configure your computer so that it
                        disables cookies or does not accept them. For example,
                        some third party vendors including Google use cookies to
                        deliver advertisements based on your prior visits to
                        their website. You have the option to opt out of
                        Google's use of cookies by visiting the Google
                        advertising opt out page at{' '}
                        <a href="www.google.com/privacy_ads.html">
                          www.google.com/privacy_ads.html
                        </a>
                        .
                      </p>
                      <p>3.6 Collection from you</p>
                      <p>
                        Our preference is to try and collect your personal
                        information directly from you via the sign-up form on
                        our website, as this is the best way to ensure its
                        accuracy. It also provides you with an opportunity to
                        contact us with any questions about our Privacy policy
                        before collection. We may also collect your personal
                        information through correspondence (whether by letter,
                        fax, phone or email) or through our forms.
                      </p>
                      <p>
                        We will directly collect your personal information
                        unless:
                      </p>
                      <p>
                        (a) we have your consent to collect it from a third
                        party; or
                      </p>
                      <p>(b) we are required or authorised by law; or</p>
                      <p>(c) it is unreasonable or impracticable to do so.</p>
                      <strong>
                        4. Dealing with unsolicited personal information
                      </strong>
                      <p>
                        If we receive your personal information from a third
                        party without having asked you for it, then within a
                        reasonable time, we will determine whether we could have
                        collected it in the ways outlined in paragraph 3 above.
                        If we determine that it could not have been collected in
                        one of those ways and it is lawful and reasonable to do
                        so, then as soon as practicable we will:
                      </p>
                      <p>(a) destroy the information; or</p>
                      <p>(b) ensure that it is de-identified.</p>
                      <strong>5. Notification of collection</strong>
                      <p>
                        Before or at the time of collecting your personal
                        information (or as soon as practicable afterwards) we
                        will take reasonable steps to notify you or ensure you
                        are aware of the following:
                      </p>
                      <ul>
                        <li>our identity and contact details;</li>
                        <li>
                          circumstances where we have collected your personal
                          information from you without your knowledge or from
                          someone other than you;
                        </li>
                        <li>
                          circumstances where we are required or authorised by
                          law to collect your personal information;
                        </li>
                        <li>
                          reasons why we have collected your personal
                          information;
                        </li>
                        <li>
                          what may happen if we do not collect all or some of
                          your personal information;
                        </li>
                        <li>
                          details of the persons or entities that we usually
                          disclose personal information to;
                        </li>
                        <li>
                          how you may access and seek correction of your
                          personal information;
                        </li>
                        <li>how you can lodge a complaint with us;</li>
                        <li>
                          whether we are likely to disclose your personal
                          information to overseas recipients and if so, details
                          of the likely countries that may receive your personal
                          information.
                        </li>
                      </ul>
                      <strong>6. Use or disclosure</strong>
                      <p>6.1 Personal information that is not sensitive</p>
                      <p>
                        We will only hold your personal information for the
                        purpose of conducting our business as an online
                        educational resource (<b>Primary Purpose</b>).
                      </p>
                      <p>
                        We will not use or disclose your personal information
                        (not being sensitive information) for another purpose (
                        <b>Secondary Purpose</b>) unless:
                      </p>
                      <p>(a) we first obtain your consent; or</p>
                      <p>
                        (b) you would reasonably expect us to use or disclose it
                        for a Secondary Purpose that is related to the Primary
                        Purpose or - in the case of sensitive information –
                        directly related to the Primary Purpose; or
                      </p>
                      <p>(c) we are required to by law; or</p>
                      <p>(d) a permitted general purpose exists; or</p>
                      <p>(e) a permitted health situation exists; or</p>
                      <p>
                        (f) we reasonably believe it is reasonably necessary for
                        one or more enforcement related activities conducted by,
                        or on behalf of, an enforcement body. In this
                        circumstance we will make a note of such disclosure.
                      </p>
                      <strong>7. Direct marketing</strong>
                      <p>7.1 What is direct marketing?</p>
                      <p>
                        For the purposes of this policy, “
                        <b>direct marketing</b>” is the promotion of goods and
                        services directly to you including through emails, phone
                        calls and the post.
                      </p>
                      <p>7.2 Adoption of direct marketing laws</p>
                      <p>
                        How we use your personal information for direct
                        marketing is tightly controlled by the Act. We will
                        follow those laws to ensure you only receive direct
                        marketing in circumstances where you are expecting to.
                      </p>
                    </section>
                  </div>
                  <div className="column">
                    <section className="gilroy">
                      <p>
                        Under the Act we may use your personal information for
                        the purposes of direct marketing if:
                      </p>
                      <p>
                        (a) we collected the information directly from you; and
                      </p>
                      <p>
                        (b) you would reasonably expect us to use or disclose
                        your personal information for the purpose of direct
                        marketing.
                      </p>
                      <p>7.3 Personal information provided by third parties.</p>
                      <p>
                        Unless it would be impracticable or unreasonable, we
                        need your consent when:
                      </p>
                      <p>
                        (a) collecting your personal information from a third
                        party for the purpose of direct marketing; or
                      </p>
                      <p>
                        (b) you would not reasonably expect to receive the
                        direct marketing.
                      </p>
                      <p>
                        If at any time you want to know who provided us with
                        your personal information, then please send a request to
                        us at{' '}
                        <a href="mailto:hello@getleda.com">hello@getleda.com</a>
                        . We will provide the details of that third party within
                        a reasonable time and without charge.
                      </p>
                      <p>7.4 Sensitive information</p>
                      <p>
                        We will not use your sensitive information for the
                        purposes of direct marketing unless you have given us
                        permission in writing.
                      </p>
                      <p>7.5 Opting-out</p>
                      <p>
                        We will always provide a simple means for you to
                        “opt-out” from receiving direct marketing, which
                        typically involves an “opt-out” or “unsubscribe” link on
                        emails, a check box on the collection notice or through
                        a pop-up on your screen when you provide personal
                        information online or via the app.
                      </p>
                      <p>
                        We will not use or disclose your personal information
                        for the purposes of direct marketing material if you
                        have previously told us not to.
                      </p>
                      <p>
                        If at any time in the future you do not want us (or one
                        of our service providers) to send you direct marketing
                        material or you wish to cancel a previous consent,
                        please inform us by contacting us at{' '}
                        <a href="mailto:hello@getleda.com">hello@getleda.com</a>
                        . We will affect the change in a reasonable time and
                        without charge.
                      </p>
                      <strong>
                        8. Cross-border disclosure of personal information
                      </strong>
                      <p>
                        We will always endeavour to store your information on an
                        Australian server. However, in circumstances where this
                        is not possible, we may disclose your personal
                        information to an overseas entity when we:
                      </p>
                      <p>
                        (a) have taken reasonable steps to ensure that they also
                        treat it in accordance with the Act; or
                      </p>
                      <p>
                        (b) reasonably believe that the overseas entity is
                        subject to the same or similar laws to that found in the
                        Act and there are ways that you can take action to
                        enforce those overseas laws; or
                      </p>
                      <p>
                        (c) expressly inform you of your option to consent to
                        that disclosure and you then provide us with informed
                        consent to do so; or
                      </p>
                      <p>(d) are required or authorised by law; or</p>
                      <p>(e) a permitted general purpose exists; or</p>
                      <p>(f) a permitted health situation exists; or</p>
                      <p>
                        (g) we reasonably believe it is reasonably necessary for
                        one or more enforcement related activities conducted by,
                        or on behalf of, an enforcement body. In this
                        circumstance we will make a note of such disclosure.
                      </p>
                      <strong>
                        9. Adoption, use or disclosure of government related
                        identifiers
                      </strong>
                      <p>
                        We will not adopt a government related identifier as
                        your identifier unless:
                      </p>
                      <p>(a) we are required or authorised by law; or</p>
                      <p>
                        (b) it is reasonably necessary to verify your identity
                        for the purposes of our activities or functions; or
                      </p>
                      <p>
                        (c) it is reasonably necessary to fulfil our obligations
                        to an agency or State or Territory authority; or
                      </p>
                      <p>
                        (d) it is required or authorised by or under an
                        Australian law, or a court/tribunal order; or
                      </p>
                      <p>
                        (e) some (but not all) permitted general situations
                        exist; or
                      </p>
                      <p>
                        (f) we reasonably believe it is reasonably necessary for
                        enforcement related activities by, or on behalf of, an
                        enforcement body; or
                      </p>
                      <p>(g) where it is allowed under the regulations.</p>
                      <strong>10. Quality of personal information</strong>
                      <p>
                        We will take such steps (if any) as are reasonable in
                        the circumstances to ensure that your personal
                        information we collect, use or disclose is accurate,
                        up-to-date, complete and relevant.
                      </p>
                      <strong>11. Security of personal information</strong>
                      <p>11.1 Protection</p>
                      <p>
                        We will take such steps as are reasonable in the
                        circumstances to protect your personal information:
                      </p>
                      <p>(a) from misuse, interference and loss; and</p>
                      <p>
                        (b) from unauthorised access, modification or
                        disclosure.
                      </p>
                      <p>11.2 Destruction</p>
                      <p>
                        When we no longer need your personal information for a
                        permitted purpose and we are not required to keep it to
                        comply with any laws, we will take such steps as are
                        reasonable in the circumstances to destroy your personal
                        information or to ensure that the information is
                        de-identified.
                      </p>
                      <strong>12. Access to personal information</strong>
                      <p>
                        Upon your written request we will provide you with a
                        copy of your personal information that we hold unless:
                      </p>
                      <p>
                        (a) we reasonably believe that giving access would pose
                        a serious threat to the life, health or safety of any
                        individual, or to public health or public safety; or
                      </p>
                      <p>
                        (b) giving access would have an unreasonable impact on
                        the privacy of other individuals; or
                      </p>
                      <p>
                        (c) your request for access is frivolous or vexatious;
                        or
                      </p>
                      <p>
                        (d) the information relates to existing or anticipated
                        legal proceedings between us and you, and would not be
                        accessible by the process of discovery in those
                        proceedings; or
                      </p>
                      <p>
                        (e) giving access would reveal our intentions in
                        relation to negotiations with you in such a way as to
                        prejudice those negotiations; or
                      </p>
                      <p>(f) giving access would be unlawful; or</p>
                      <p>
                        (g) denying access is required or authorised by or under
                        an Australian law or a court/tribunal order; or
                      </p>
                      <p>
                        (h) we have reason to suspect that unlawful activity, or
                        misconduct of a serious nature, that relates to our
                        functions or activities has been, is being or may be
                        engaged in and giving you access would be likely to
                        prejudice the taking of appropriate action in relation
                        to those matters; or
                      </p>
                      <p>
                        (i) giving access would be likely to prejudice one or
                        more enforcement related activities conducted by, or on
                        behalf of, an enforcement body; or
                      </p>
                      <p>
                        (j) giving access would reveal evaluative information
                        generated within the entity in connection with a
                        commercially sensitive decision-making process.
                      </p>
                      <strong>13. Correction of personal information</strong>
                      <p>13.1 Correction of personal information</p>
                      <p>
                        We will take reasonable steps to correct your personal
                        information (at no charge) if we are satisfied that it
                        is inaccurate, out-of-date, incomplete, irrelevant or
                        misleading. This extends to third parties that we have
                        provided your personal information to unless it is
                        impracticable or unlawful to do so.
                      </p>
                      <p>
                        13.2 Circumstances when we decline to make corrections
                      </p>
                      <p>
                        In certain circumstances we may decline to correct your
                        personal information. When this occurs we will provide
                        you with a written notice that sets out:
                      </p>
                      <p>(a) the reasons for the refusal; and</p>
                      <p>
                        (b) the mechanisms available to complain about the
                        refusal.
                      </p>
                      <strong>14. Making a Complaint</strong>
                      <p>
                        If you have a concern or complaint relating to our
                        handling of your personal information or any breaches of
                        the APPs, please notify us at{' '}
                        <a href="mailto:hello@getleda.com">hello@getleda.com</a>{' '}
                        by outlining the nature of the complaint. We will
                        endeavour to respond to your complaint within 30 days of
                        receipt. If unresolved, the complaint may be referred to
                        an external complaints resolution entity and finally, if
                        necessary, taken to the Office of the Australian
                        Information Commissioner.
                      </p>
                      <p>
                        If you would like a copy of this Privacy policy sent to
                        you then please request it by contacting us at{' '}
                        <a href="mailto:hello@getleda.com">hello@getleda.com</a>{' '}
                        and we will provide you a copy of this Privacy policy
                        free of charge.
                      </p>
                      <p>&copy; 2018 LEDA</p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
