import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import Util from '../../../Util';

import Comment from './Comment/Comment';
import Button from '../Button/Button';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';

// Data source: this.props.comments
export default class CommentThread extends Component {
  constructor(props) {
    super(props);

    this.commentFieldRef = React.createRef();

    this.state = {
      commentValue: null
    };

    this.onCommentChange = this.onCommentChange.bind(this);
    this.onAddComment = this.onAddComment.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
    this.focusNewComment = this.focusNewComment.bind(this);
  }
  onCommentChange(e) {
    this.setState({
      commentValue: e.target.value
    });
  }
  onAddComment() {
    let commentValue = this.state.commentValue;
    if (!commentValue) return;

    this.props.onAddComment(commentValue);

    this.setState({
      commentValue: null
    });

    this.commentFieldRef.value = '';
  }
  onDeleteComment(comment) {
    if (this.props.onDeleteComment) this.props.onDeleteComment(comment);
  }
  focusNewComment() {
    this.commentFieldRef.focus();
  }
  render() {
    return (
      <div className={`comment-thread ${this.props.className || ''}`}>
        <div className="comments">
          {this.props.comments
            .sort((c1, c2) =>
              Util.sort.by('createdAt', c1, c2, Util.sort.asDate)
            )
            .map((comment, idx) => (
              <Comment
                key={idx}
                comment={comment}
                onDelete={this.onDeleteComment}
              />
            ))}
        </div>
        <div className="new-comment">
          <div className="new-comment-upper">
            <ProfilePhoto />
            <Textarea
              value={this.commentValue}
              onChange={this.onCommentChange}
              inputRef={ref => (this.commentFieldRef = ref)}
              placeholder={'Write a comment'}
            />
          </div>
          <Button
            label={this.props.newCommentButtonLabel || 'Add comment'}
            isDisabled={!this.state.commentValue}
            onClick={this.onAddComment}
            size="sm"
          />
        </div>
      </div>
    );
  }
}
