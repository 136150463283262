import React, { Component } from 'react';
import Util from '../../../Util';
import Textarea from 'react-textarea-autosize';

import Button from '../Button/Button';

export default class FeedbackMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      isSubmitted: false
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onMessageChange(e) {
    this.setState({
      message: e.target.value
    });
  }
  onSubmit(e) {
    e.preventDefault();

    if (!!this.state.message) {
      Util.api.post('/api/feedback/submitSystemFeedback', {
        feedbackType: this.props.feedbackType,
        url: Util.route.getCurrent(),
        message: this.state.message,

        activityId: this.props.activityId
      });

      this.setState({
        isSubmitted: true
      });

      if (this.props.onSubmit) this.props.onSubmit();
    }
  }
  render() {
    return (
      <form className="feedback-message" onSubmit={this.onSubmit}>
        {this.state.isSubmitted ? null : (
          <Textarea
            placeholder={this.props.placeholder || 'Tell us your feedback'}
            onChange={this.onMessageChange}
            value={this.state.message}
          />
        )}
        {this.state.isSubmitted || !this.state.message ? null : (
          <Button label="Submit feedback" isSubmit={true} />
        )}
        {this.state.isSubmitted ? (
          <div>Thank you for your feedback!</div>
        ) : null}
      </form>
    );
  }
}
