import React, { Component } from 'react';
import Util from '../../../Util';

// Data source: skill or skillId
export default class SkillIcon extends Component {
  render() {
    let skill =
      this.props.skill ||
      Util.context.referenceData.getSkillById(this.props.skillId);
    if (!skill) return 'N/A';

    return (
      <div
        className={`skill-icon skill-icon-${this.props.size || 'md'}`}
        style={{
          backgroundImage:
            'url(' + Util.storage.skillGroupIcon(skill.skillGroupUrlId) + ')'
        }}
      >
        <div className="skill-icon-inner">
          <p className="skill-acronym">{Util.format.acronym(skill.name)}</p>
        </div>
      </div>
    );
  }
}
