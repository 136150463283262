import React, { Component } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';
import withForm from '../../withForm';
import Util from '../../../../../Util';

import Grid from '../../../Grid/Grid';
import Button from '../../../Button/Button';

//data source: this.props.activityTask OR NULL ( for new )
class AdminActivityTaskEditorForm extends Component {
  constructor(props) {
    super(props);

    this.openActivityTaskFieldEditorModal = this.openActivityTaskFieldEditorModal.bind(
      this
    );
    this.removeActivityTaskField = this.removeActivityTaskField.bind(this);
  }
  openActivityTaskFieldEditorModal(activityTaskField) {
    this.props.openModalAction({
      type: Util.enum.ModalType.ActivityTaskFieldEditorModal,
      data: {
        activityTaskField,
        onSubmit: editedActivityTaskField => {
          let existingActivityTaskFields =
            this.props.formData.activityTaskFields || [];

          if (editedActivityTaskField.activityTaskFieldId) {
            this.props.updateFormData(
              'activityTaskFields',
              existingActivityTaskFields.map(activityTaskField =>
                activityTaskField.activityTaskFieldId !==
                  editedActivityTaskField.activityTaskFieldId
                  ? activityTaskField
                  : editedActivityTaskField
              )
            );
          } else {
            editedActivityTaskField.activityTaskFieldId = Util.id.tempId.getTempId();
            editedActivityTaskField.activityTaskId = this.props.formData.activityTaskId;
            editedActivityTaskField.ordinal =
              Util.array.max(existingActivityTaskFields, 'ordinal') + 1;
            this.props.updateFormData('activityTaskFields', [
              ...existingActivityTaskFields,
              editedActivityTaskField
            ]);
          }
        }
      }
    });
  }
  removeActivityTaskField(activityTaskFieldId) {
    this.props.updateFormData(
      'activityTaskFields',
      this.props.formData.activityTaskFields.filter(
        activityTaskField =>
          activityTaskField.activityTaskFieldId !== activityTaskFieldId
      )
    );
  }
  render() {
    return (
      <form className="form-admin-task-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('name')}
        {this.props.getField('htmlContent')}
        <Grid
          title="Fields for this task"
          data={this.props.formData.activityTaskFields}
          toolbarItems={[
            <Button
              size="sm"
              label="Create new field"
              onClick={() => this.openActivityTaskFieldEditorModal()}
            />
          ]}
          columns={[
            {
              name: 'ACT_TaskFieldId',
              key: 'activityTaskFieldId',
              sortFn: Util.sort.asNumber
            },
            {
              name: 'Label',
              key: 'label',
              width: '100%'
            },
            {
              name: 'Type',
              key: 'type',
              isRenderSort: true,
              render: val => Util.enum.toString(Util.enum.FieldType, val)
            },
            {
              key: 'activityTaskFieldId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <div className="button-container">
                    <Button
                      icon={Util.icon.edit}
                      size="sm"
                      isSecondary={true}
                      onClick={() =>
                        this.openActivityTaskFieldEditorModal(record)
                      }
                    />
                    <Button
                      icon={Util.icon.bin}
                      size="sm"
                      isSecondary={true}
                      onClick={() => this.removeActivityTaskField(val)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
        </div>
      </form>
    );
  }
}

export default withForm(
  connect(
    null,
    { openModalAction }
  )(AdminActivityTaskEditorForm),
  {
    fields: {
      name: {
        label: 'Name',
        getError: val => {
          if (!validator.isLength(val, { max: 250 }))
            return 'Max 250 characters';
        }
      },
      htmlContent: {
        label: 'Content',
        type: Util.enum.FieldType.HtmlEditor
      }
    }
  }
);
