import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { completedVideoAction } from '../../../actions/userActions';
import { connect } from 'react-redux';
import Util from '../../../Util';

//this.props.video OR this.props.videoUrl
class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      isErrored: false
    };

    this.reactPlayerRef = React.createRef();

    this.onVideoReady = this.onVideoReady.bind(this);
    this.onVideoStart = this.onVideoStart.bind(this);
    this.onVideoFinished = this.onVideoFinished.bind(this);
    this.onVideoResume = this.onVideoResume.bind(this);
    this.onVideoError = this.onVideoError.bind(this);
  }
  onVideoReady() {
    this.setState({
      isReady: true
    });
  }
  onVideoStart() {
    this.trackVideoEvent('VideoStarted');
  }
  onVideoResume() {
    this.trackVideoEvent('VideoResumed');

    if (this.props.onVideoResume) this.props.onVideoResume();
  }
  onVideoFinished() {
    this.props.completedVideoAction(this.props.video.videoId);
    this.trackVideoEvent('VideoFinished');

    if (this.props.onVideoFinished) this.props.onVideoFinished();
  }
  onVideoError() {
    this.setState({
      isErrored: true
    });
  }
  trackVideoEvent(event, extraProps) {
    let props = extraProps || {};

    if (this.props.video) {
      props = {
        ...props,
        videoId: this.props.video.videoId,
        videoUrld: this.props.video.urlId,
        videoUrl: this.props.video.videoUrl,
        videoName: this.props.video.name
      };
    } else if (this.props.videoUrl) {
      props = { ...props, videoUrl: this.props.videoUrl };
    }

    Util.analytics.track(event, props);
  }
  setIsPlaying(isPlaying) {
    this.reactPlayerRef.current.player.player.pause();
  }
  render() {
    return (
      <ReactPlayer
        className={`video-player ${this.state.isReady ? 'ready' : ''} ${
          this.state.isErrored ? 'error' : ''
          }`}
        url={this.props.video ? this.props.video.videoUrl : this.props.videoUrl}
        ref={this.reactPlayerRef}
        playing={this.props.autoPlay}
        controls={true} // Vimeo/Wistia players will always show controls, but this is needed for youtube
        width="100%"
        height="100%"
        onReady={this.onVideoReady}
        onEnded={this.onVideoFinished}
        onError={this.onVideoError}
        onPlay={this.onVideoResume}
        onStart={this.onVideoStart}
        onPause={() => this.trackVideoEvent('VideoPaused')}
        onSeek={(currentTime, prevTime) =>
          this.trackVideoEvent('VideoSeek', {
            videoSeekFromTime: parseInt(prevTime, 10),
            videoSeekToTime: parseInt(currentTime, 10)
          })
        }
        config={{
          wistia: {
            options: {
              playerColor: `#${this.props.colour || Util.css.colour.ledaBlue}`,
              silentAutoPlay: this.props.silentAutoPlay
            }
          },
          youtube: {
            options: {
              controlsVisibleOnLoad: true
            }
          }
        }}
      />
    );
  }
}

export default connect(
  null,
  { completedVideoAction },
  null,
  { forwardRef: true }
)(VideoPlayer);
