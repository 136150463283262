import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

// Data source: this.props.htmlContent
export default class ReadingTimeLabel extends Component {
  render() {
    return (
      <div className="reading-time-label">
        <ReactSVG className="clock-icon" src={Util.icon.clock} />
        <h3>{Util.format.readingTime(this.props.htmlContent)}</h3>
      </div>
    );
  }
}
