import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

export default class RadioButton extends Component {
  render() {
    let config = {
      name: this.props.name, //determines radio grouping
      label: this.props.label,
      isDisabled: this.props.isDisabled,

      //One of these will be ignored based on a condition below
      checked: this.props.checked || false,
      defaultChecked: this.props.defaultChecked || false,

      onChange: e => {
        Util.analytics.track('RadioButtonChange', {
          label: this.props.label,
          isChecked: e.target.checked
        });
        if (this.props.onChange) this.props.onChange(e);
      }
    };

    let radioButtonClass = `radio-button ${
      config.isDisabled ? 'disabled' : ''
    } ${this.props.className || ''}`;

    let radioButtonConfig = {
      type: 'radio',
      name: config.name,
      onChange: config.onChange
    };

    //Is the checkbox controlled or uncontrolled?
    if ('checked' in this.props) {
      radioButtonConfig.checked = config.checked;
    } else {
      radioButtonConfig.defaultChecked = config.defaultChecked;
    }

    return (
      <label className={radioButtonClass}>
        {config.label}
        <input {...radioButtonConfig} />
        <span className="radio-button-mark">
          <ReactSVG className="tick" src={Util.icon.tick} />
        </span>
      </label>
    );
  }
}
