import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Util from '../../../../Util';

import 'react-datepicker/dist/react-datepicker.css';

export default class DateField extends Component {
  constructor(props) {
    super(props);

    let initialValue = this.props.value || this.props.defaultValue;

    this.state = {
      //Ideally will be a UTC string
      value: this.getParsedValue(initialValue)
    };

    this.getParsedValue = this.getParsedValue.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }
  componentWillReceiveProps(props) {
    if (props.value) {
      this.setState({
        value: this.getParsedValue(props.value)
      });
    }
  }
  getParsedValue(value) {
    return typeof value === 'string'
      ? value === 'now'
        ? new Date()
        : !value
        ? null //empty string
        : new Date(value)
      : value;
  }
  changeDate(date) {
    let validatedValue = date;

    let minAsDate = this.getParsedValue(this.props.min);
    let maxAsDate = this.getParsedValue(this.props.max);

    if (minAsDate && validatedValue && minAsDate > validatedValue)
      validatedValue = minAsDate;
    if (maxAsDate && validatedValue && maxAsDate < validatedValue)
      validatedValue = maxAsDate;

    this.setState({
      value: validatedValue
    });

    // While the value state of this component uses a local date,
    // we ALWAYS want to handle dates as UTC outside this component/in the db
    // Second parameter (date value) is available if needed though
    if (this.props.onChange)
      this.props.onChange(
        validatedValue ? validatedValue.toUTCString() : validatedValue,
        validatedValue
      );
  }
  render() {
    return Util.userAgent.isDesktop() ? (
      <DatePicker
        selected={this.state.value}
        onChange={this.changeDate}
        dateFormat={'dd/MM/yyyy'}
        minDate={this.getParsedValue(this.props.min)}
        maxDate={this.getParsedValue(this.props.max)}
      />
    ) : (
      <input
        type="date"
        onChange={e => this.changeDate(e.target.valueAsDate)}
        value={
          this.state.value ? Util.format.date.inputDate(this.state.value) : ''
        }
        min={
          this.props.min //ignored by safari
            ? Util.format.date.inputDate(this.getParsedValue(this.props.min))
            : null
        }
        max={
          this.props.max //ignored by safari
            ? Util.format.date.inputDate(this.getParsedValue(this.props.max))
            : null
        }
      />
    );
  }
}
