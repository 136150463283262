import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

import Button from '../../UI/Button/Button';

//Data source: this.props.dataUrl (with optional this.props.dataParams) or this.props.data
export default class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: this.props.dataUrl && !this.props.data,
      columns: this.props.columns,
      data: this.props.data || [],
      isCopyDataHidden: this.props.isCopyDataHidden || false,
      isCountHidden: this.props.isCountHidden || false,

      //Filters
      searchValue: this.props.searchValue || '',
      searchKeys: this.props.searchKeys || [],

      //Sorters
      sortCol: this.props.sortCol,
      isSortDesc: this.props.isSortDesc || false
    };

    this.gridTableRef = React.createRef();
  }
  componentWillReceiveProps(props) {
    if (props.data) {
      this.setState({
        data: props.data
      });
    }
  }
  componentDidMount() {
    if (this.props.dataUrl && !this.props.data) {
      this.fetchData();
    }
  }
  refresh(data = null) {
    if (data) {
      this.setState({
        data
      });
    } else if (this.props.dataUrl) {
      this.setState({
        isLoading: true
      });
      this.fetchData();
    }
  }
  fetchData() {
    Util.api.post(this.props.dataUrl, this.props.dataParams || {}, {
      success: data => {
        this.setState({
          data,
          isLoading: false
        });
      }
    });
  }
  onHeaderClick(col) {
    if (!col.isSortDisabled) {
      if (col === this.state.sortCol && this.state.isSortDesc) {
        //Remove all sorters
        this.setState({
          sortCol: null,
          isSortDesc: false
        });
      } else {
        this.setState({
          sortCol: col,
          isSortDesc: col === this.state.sortCol ? true : false
        });
      }
    }
  }
  onSearch(searchValue) {
    this.setState({
      searchValue: searchValue.toLowerCase()
    });
  }
  render() {
    if (this.state.isLoading) return <div className="loader"></div>;

    // 1. Filter the data by any filters
    let adjustedData = this.state.data.filter(record => {
      let match = true;

      if (this.state.searchValue) {
        match = false;

        this.state.searchKeys.forEach(key => {
          if (
            String(record[key])
              .toLowerCase()
              .includes(this.state.searchValue)
          ) {
            match = true;
          }
        });
      }

      return match;
    });

    // 2. Sort the data by any sorters
    let sortCol = this.state.sortCol;
    if (sortCol) {
      adjustedData = adjustedData.sort((record1, record2) => {
        let sortFn = sortCol.sortFn || Util.sort.asString;

        //isRenderSort will sort by the rendered value rather than the column key
        let val1 = sortCol.isRenderSort
          ? sortCol.render(record1[sortCol.key], record1)
          : record1[sortCol.key];
        let val2 = sortCol.isRenderSort
          ? sortCol.render(record2[sortCol.key], record2)
          : record2[sortCol.key];

        //Swap values if desc sort
        if (this.state.isSortDesc) [val1, val2] = [val2, val1];

        return sortFn(val1, val2);
      });
    }

    return (
      <div className={`grid ${this.props.className || ''}`}>
        {this.props.title ? (
          <div className="grid-title">
            <p>{this.props.title}</p>
          </div>
        ) : null}
        <div className="grid-toolbar">
          {!this.props.searchKeys ? null : (
            <div className="grid-search">
              <label htmlFor="search_field">Search</label>
              <input
                autoComplete="off"
                value={this.state.searchValue}
                name="name"
                id="search_field"
                type="text"
                placeholder="Type to search..."
                onChange={e => this.onSearch(e.target.value)}
              />
            </div>
          )}
          {this.props.isCopyVisible ? (
            <Button
              size="sm"
              icon={Util.icon.copy}
              title="Copy data"
              onClick={() => Util.clipboard.copyEl(this.gridTableRef.current)}
            />
          ) : null}
          {this.props.isCountVisible ? (
            <div className="grid-count">
              Showing {adjustedData.length} of {this.state.data.length} row
              {adjustedData.length === 1 ? '' : 's'}
            </div>
          ) : null}
          {this.props.isRefreshVisible ? (
            <Button
              size="sm"
              title="Refresh data"
              isSecondary={true}
              icon={Util.icon.refresh}
              onClick={() => this.refresh()}
            />
          ) : null}
        </div>
        {Util.array.any(this.props.toolbarItems) ? (
          <div className="grid-toolbar">
            {this.props.toolbarItems.map((item, idx) =>
              React.cloneElement(item, { key: idx })
            )}
          </div>
        ) : null}
        <table className="grid-table" ref={this.gridTableRef}>
          <tbody>
            <tr>
              {this.state.columns.map((col, colIdx) => {
                return (
                  <th
                    key={colIdx}
                    style={{
                      width: col.width || 'auto',
                      cursor: col.isSortDisabled ? 'default' : 'pointer'
                    }}
                    onClick={e => {
                      this.onHeaderClick(col);
                    }}
                  >
                    <div className="th-inner">
                      {col.name}
                      {col === this.state.sortCol ? (
                        <ReactSVG
                          src={
                            this.state.isSortDesc
                              ? Util.icon.arrowHead.up
                              : Util.icon.arrowHead.down
                          }
                          className={`sort-arrow ${
                            this.state.isSortDesc ? 'down' : 'up'
                          }`}
                        />
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
            {adjustedData.map((record, recordIdx) => (
              <tr
                key={recordIdx}
                onClick={e =>
                  this.props.rowClicked
                    ? this.props.rowClicked(record, e.target.nodeName)
                    : null
                }
              >
                {this.state.columns.map((col, colIdx) => {
                  // The data val is either the value itself, or it will use the passed in property's render funcion
                  let dataVal = col.render
                    ? col.render(record[col.key], record)
                    : record[col.key];

                  //Booleans need to be stringified
                  if (typeof dataVal === 'boolean') {
                    dataVal = (
                      <ReactSVG
                        title={`${col.name} ${dataVal ? '(true)' : '(false)'}`}
                        className={`boolean-icon boolean-icon-${
                          dataVal ? 'true' : 'false'
                        }`}
                        src={dataVal ? Util.icon.tick : Util.icon.close}
                      />
                    );
                  }

                  return <td key={colIdx}>{dataVal}</td>;
                })}
              </tr>
            ))}
            {!Util.array.any(this.state.data) ? (
              <tr>
                <td className="empty-text" colSpan={this.state.columns.length}>
                  {this.props.noDataText || 'No records to display.'}
                </td>
              </tr>
            ) : !Util.array.any(adjustedData) ? (
              <tr>
                <td className="empty-text" colSpan={this.state.columns.length}>
                  {this.props.noAdjustedDataText ||
                    'No matching records to display.'}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}
