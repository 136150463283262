import { JRN_STARTED_JOURNEY } from '../actions/types';
import Util from '../Util';

export const startJourneyAction = (
  journeyId,
  onSuccess,
  onFailure
) => dispatch => {
  //Convert to journeyId if urlId
  if (isNaN(journeyId))
    journeyId = Util.context.referenceData.getJourneyById(journeyId).journeyId;

  Util.api.post(
    '/api/journey/createUserJourney',
    {
      journeyId: parseInt(journeyId, 10)
    },
    {
      success: userJourney => {
        dispatch({
          type: JRN_STARTED_JOURNEY,
          userJourney
        });

        Util.analytics.track('JourneyStarted', {
          journeyId
        });

        if (onSuccess) onSuccess(userJourney);
      },
      failure: () => {
        //Someone tried to access an invalid or locked journey
        window.location.href = Util.route.site.error404();
      }
    }
  );
};
