import React, { Component } from 'react';

//Components
import FeedbackThumbsButton from './FeedbackThumbsButton/FeedbackThumbsButton';

export default class FeedbackThumbsBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFeedbackSubmitted: false
    };

    this.onFeedbackSubmit = this.onFeedbackSubmit.bind(this);
  }
  onFeedbackSubmit() {
    this.setState({
      isFeedbackSubmitted: true
    });
  }
  render() {
    return (
      <div className="feedback-bar">
        <p className="bar-title">
          {this.props.title || 'Did you find this helpful?'}
        </p>
        {!this.state.isFeedbackSubmitted ? (
          <div className="bar-buttons">
            <FeedbackThumbsButton
              rating={-1}
              feedbackType={this.props.feedbackType}
              onClick={this.onFeedbackSubmit}
            />
            <FeedbackThumbsButton
              rating={1}
              feedbackType={this.props.feedbackType}
              onClick={this.onFeedbackSubmit}
            />
          </div>
        ) : (
          <div className="bar-response">Thank you for your feedback!</div>
        )}
      </div>
    );
  }
}
