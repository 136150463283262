import React, { Component } from 'react';

import img_logo_monash from '../../../img/site/org/logo_monash.png';
import img_logo_oxfam from '../../../img/site/org/logo_oxfam.png';
import img_logo_stategov from '../../../img/site/org/logo_stategov.png';
import img_logo_myob from '../../../img/site/org/logo_myob.png';
import img_logo_auspost from '../../../img/site/org/logo_auspost.png';

export default class CompanyLogos extends Component {
  render() {
    let companyImages = [
      img_logo_myob,
      img_logo_oxfam,
      img_logo_auspost,
      img_logo_stategov,
      img_logo_monash
    ];

    return (
      <div className="company-logos">
        {companyImages.map((image, idx) => (
          <div key={idx}>
            <img src={image} alt="" />
          </div>
        ))}
      </div>
    );
  }
}
