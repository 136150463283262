import {
  APP_COMPLETED_ACTIVITY,
  ACT_ADDED_MY_ACTIVITY,
  ACT_COMPLETED_MY_ACTIVITY,
  ACT_COMPLETED_MY_ACTIVITY_TASK,
  ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS,
  ACT_DELETED_MY_ACTIVITY,
  JRN_COMPLETED_JOURNEY
} from '../actions/types';

import Util from '../Util';

export const addMyActivityAction = (
  activityId,
  journeyStepId,
  onComplete
) => dispatch => {
  //Serverside
  Util.api.post(
    '/api/myActivity/addMyActivity',
    {
      activityId,
      journeyStepId
    },
    {
      success: myActivity => {
        //Clientside
        dispatch({
          type: ACT_ADDED_MY_ACTIVITY,
          myActivity
        });

        //Analytics
        Util.analytics.track('MyActivityAdded', {
          activityId,
          myActivityName: myActivity.name
        });

        if (onComplete) onComplete(myActivity);
      }
    }
  );
};

export const saveMyActivityTaskFieldsAction = (
  myActivityId,
  myActivityTaskId,
  myActivityTaskFields
) => dispatch => {
  // Clientside (optimistic)
  dispatch({
    type: ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS,
    myActivityId,
    myActivityTaskId,
    myActivityTaskFields
  });

  //Serverside
  Util.api.post('/api/myActivity/saveMyActivityTaskFields', {
    myActivityId,
    myActivityTaskId,
    myActivityTaskFields
  });

  //Analytics
  Util.analytics.track('editedMyActivityField', {
    myActivityId: myActivityId,
    myActivityTaskId: myActivityTaskId
  });
};

export const completeMyActivityTaskAction = (
  myActivityTaskId,
  myActivity
) => dispatch => {
  // Clientside (optimistic)
  dispatch({
    type: ACT_COMPLETED_MY_ACTIVITY_TASK,
    myActivityId: myActivity.myActivityId,
    myActivityTaskId: myActivityTaskId
  });

  // If there are no tasks on the updated activity that are incomplete, the activity is done
  let isMyActivityCompleted = !myActivity.myActivityTasks.some(
    task => task.myActivityTaskId !== myActivityTaskId && !task.completedAt
  );

  if (isMyActivityCompleted) {
    dispatch({
      type: ACT_COMPLETED_MY_ACTIVITY,
      myActivityId: myActivity.myActivityId
    });

    dispatch({
      type: APP_COMPLETED_ACTIVITY,
      activityId: myActivity.sourceActivityId
    });
  }

  // Serverside
  Util.api.post(
    '/api/myActivity/completeMyActivityTask',
    {
      myActivityId: myActivity.myActivityId,
      myActivityTaskId: myActivityTaskId
    },
    {
      success: result => {
        if (result.completedUserJourneyId) {
          dispatch({
            type: JRN_COMPLETED_JOURNEY,
            userJourneyId: result.completedUserJourneyId
          });

          Util.analytics.track('JourneyCompleted', {
            journeyId: result.completedJourneyId,
            userJourneyId: result.completedUserJourneyId
          });
        }
      }
    }
  );

  // Analytics
  Util.analytics.track('CompleteMyActivityTask', {
    myActivityId: myActivity.myActivityId,
    myActivityTaskId: myActivityTaskId
  });

  if (isMyActivityCompleted) {
    Util.analytics.track('CompleteMyActivity', {
      activitySourceId: myActivity.activitySourceId,
      myActivityId: myActivity.myActivityId
    });

    if (myActivity.journeyStepId) {
      Util.analytics.track('CompletedJourneyStep', {
        journeyStepId: myActivity.journeyStepId,
        activitySourceId: myActivity.activitySourceId,
        myActivityId: myActivity.myActivityId
      });
    }
  }
};

export const deleteMyActivityAction = myActivityId => dispatch => {
  //Clientside (optimistic)
  dispatch({
    type: ACT_DELETED_MY_ACTIVITY,
    myActivityId
  });

  //Serverside
  Util.api.post('/api/myActivity/deleteMyActivity', {
    myActivityId: myActivityId
  });

  //Analytics
  Util.analytics.track('deleteMyActivity', {
    myActivityId: myActivityId
  });
};
