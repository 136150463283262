import { combineReducers } from 'redux';
import activityReducer from './activityReducer';
import modalReducer from './modalReducer';
import userReducer from './userReducer';
import journeyReducer from './journeyReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
  activityState: activityReducer,
  journeyState: journeyReducer,
  notificationState: notificationReducer,
  modalState: modalReducer,
  userState: userReducer
});
