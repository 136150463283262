import React, { Component } from 'react';

export default class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked || false
    };

    this.onChange = this.onChange.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({
      checked: props.checked
    });
  }
  onChange() {
    let newVal = !this.state.checked;

    this.setState({
      checked: newVal
    });

    if (this.props.onChange) this.props.onChange(newVal);
  }
  isOn() {
    return this.state.checked;
  }
  render() {
    const hideText = this.props.hideText || false;
    //The switch is just a fancy checkbox
    return (
      <div className={`switch ${this.state.checked ? 'on' : ''}`}>
        <div className="switch-display">
          <div className="nub"></div>
          {hideText ? null : <div className="label on">ON</div>}
          {hideText ? null : <div className="label off">OFF</div>}
        </div>
        <input
          className="switch-control"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.onChange}
        ></input>
      </div>
    );
  }
}
