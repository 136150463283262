import React, { Component } from 'react';

export default class SiteLayout extends Component {
  render() {
    return (
      <div className="leda-layout-site">
        {this.props.pageTitle ? (
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="page-title">
                <h2 className="gilroy">{this.props.pageTitle}</h2>
                {this.props.titleContent}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.pageSubtitle ? (
          <div className="leda-container-md">
            <div className="leda-row">
              <h1 className="page-subtitle">{this.props.pageSubtitle}</h1>
            </div>
          </div>
        ) : null}
        <div
          className={`page-container ${
            this.props.wrapperCls ? `leda-wrapper ${this.props.wrapperCls}` : ''
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
