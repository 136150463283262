import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppSettingsLayout from '../../../../Layouts/App/AppSettingsLayout/AppSettingsLayout';

import ProfilePhoto from '../../../../UI/ProfilePhoto/ProfilePhoto';
import FileUpload from '../../../../UI/FileUpload/FileUpload';
import Button from '../../../../UI/Button/Button';
import UserProfileEditorForm from '../../../../UI/Forms/UserProfileEditorForm/UserProfileEditorForm';
import UpdatePasswordForm from '../../../../UI/Forms/UpdatePasswordForm/UpdatePasswordForm';

export default class AppSettingsProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Util.context.user.getCurrent(),
      isEditing: false,
      isChangingPassword: false
    };

    this.onProfilePhotoUpload = this.onProfilePhotoUpload.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.removeProfilePhoto = this.removeProfilePhoto.bind(this);
    this.setIsEditing = this.setIsEditing.bind(this);
  }
  setIsEditing(isEditing) {
    this.setState({
      isEditing
    });
  }
  setIsChangingPassword(isChangingPassword) {
    this.setState({
      isChangingPassword
    });
  }
  removeProfilePhoto() {
    //client
    let updatedUser = {
      ...Util.context.user.getCurrent(),
      profilePhotoBlobId: null
    };

    Util.context.user.updateCurrent(updatedUser);
    this.setState({ user: updatedUser });

    //server
    Util.api.post('/api/user/removeProfilePhoto');
  }
  onProfilePhotoUpload(file) {
    let updatedUser = {
      ...Util.context.user.getCurrent(),
      profilePhotoBlobId: file.blobId
    };

    Util.context.user.updateCurrent(updatedUser);
    this.setState({ user: updatedUser });
  }
  onPasswordChanged() {
    let updatedUser = {
      ...Util.context.user.getCurrent(),
      passwordUpdatedAt: new Date()
    };

    Util.context.user.updateCurrent(updatedUser);
    this.setState({ user: updatedUser });
  }
  render() {
    let currentUser = Util.context.user.getCurrent();

    return (
      <div className="leda-page-app-settings-profile">
        <AppSettingsLayout pageTitle="Profile settings" wrapperCls="grey-6">
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="settings-panel">
                <div className="profile-photo-info">
                  <ProfilePhoto size="lg" />
                  <div className="flex-spacer"></div>
                  <div className="button-container">
                    <FileUpload
                      onUploadSuccess={this.onProfilePhotoUpload}
                      uploadUrl={'/api/file/uploadProfilePhoto'}
                      allowedExtensions={['.png', '.jpg', '.jpeg']}
                    >
                      <Button
                        size="sm"
                        isSecondary={false}
                        label="Upload photo"
                        icon={Util.icon.camera}
                      />
                    </FileUpload>
                    {!!this.state.user.profilePhotoBlobId ? (
                      <Button
                        size="sm"
                        isSecondary={true}
                        label="Remove photo"
                        onClick={this.removeProfilePhoto}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="settings-panel settings-info-panel">
                {this.state.isEditing ? (
                  <UserProfileEditorForm
                    formData={{
                      firstName: currentUser.firstName,
                      lastName: currentUser.lastName,
                      phone: currentUser.phone,
                      bio: currentUser.bio,
                      jobTitle: currentUser.jobTitle
                    }}
                    onCancel={() => this.setIsEditing(false)}
                    onSubmit={(form, formData) => {
                      form.setLoading(true);

                      Util.api.post(
                        '/api/user/saveUserDetails',
                        {
                          userDetails: formData
                        },
                        {
                          success: () => {
                            Util.context.user.updateCurrent({
                              ...Util.context.user.getCurrent(),
                              ...formData,
                              fullName:
                                formData.firstName + ' ' + formData.lastName
                            });

                            this.setIsEditing(false);
                          },
                          failure: errorMsg => form.setOverallError(errorMsg),
                          complete: () => form.setLoading(false)
                        }
                      );
                    }}
                  />
                ) : (
                  <div className="settings-info">
                    <div className="settings-info-row">
                      <span className="bold">Name:</span>
                      <span>{currentUser.fullName}</span>
                    </div>
                    <div className="settings-info-row">
                      <span className="bold">Email:</span>
                      <span>{currentUser.email}</span>
                    </div>
                    <div className="settings-info-row">
                      <span className="bold">Company:</span>
                      <span>{Util.context.user.getOrganisationName()}</span>
                    </div>
                    <div className="settings-info-row">
                      <span className="bold">Job Title:</span>
                      <span>{currentUser.jobTitle || <i>No job title</i>}</span>
                    </div>
                    <div className="settings-info-row">
                      <span className="bold">Phone:</span>
                      <span>{currentUser.phone || <i>No phone number</i>}</span>
                    </div>
                    <div className="settings-info-row">
                      <span className="bold">Bio:</span>
                      <span className="bio">
                        {currentUser.bio || <i>No bio</i>}
                      </span>
                    </div>
                  </div>
                )}
                {this.state.isEditing ? null : (
                  <div className="button-container">
                    <Button
                      size="sm"
                      label="Edit details"
                      icon={Util.icon.edit}
                      onClick={() => this.setIsEditing(true)}
                    />
                    {Util.context.user.getOrganisationId() ? (
                      <Button
                        size="sm"
                        label="View my profile"
                        isSecondary={true}
                        to={Util.route.app.profile(this.state.user.userId)}
                      />
                    ) : null}
                  </div>
                )}
              </div>
              <div className="settings-panel settings-info-panel">
                {this.state.isChangingPassword ? (
                  <UpdatePasswordForm
                    onCancel={() => {
                      this.setIsChangingPassword(false);
                    }}
                    onSubmit={(form, formData) => {
                      form.setLoading(true);

                      Util.api.post(
                        '/api/user/updatePassword',
                        {
                          currentPassword: formData.currentPassword,
                          newPassword: formData.newPassword
                        },
                        {
                          success: () => {
                            this.setIsChangingPassword(false);
                            this.onPasswordChanged();
                          },
                          failure: errorMsg => form.setOverallError(errorMsg),
                          complete: () => form.setLoading(false)
                        }
                      );
                    }}
                  />
                ) : (
                  <div className="settings-info">
                    <div className="settings-info-row">
                      <span className="bold">Password:</span>
                      <div>
                        <i>
                          {'Last updated ' +
                            Util.format.date.fromNowReadable(
                              currentUser.passwordUpdatedAt
                            )}
                        </i>
                      </div>
                      <div className="flex-spacer"></div>
                      <Button
                        size="sm"
                        label="Change password"
                        onClick={() => this.setIsChangingPassword(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </AppSettingsLayout>
      </div>
    );
  }
}
