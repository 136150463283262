import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';
import { Link } from 'react-router-dom';
import Util from '../../../../../Util';

//Layout
import AppTeamLayout from '../../../../Layouts/App/AppTeamLayout/AppTeamLayout';
import JourneyFeatureBanner from '../../../../UI/FeatureBanner/JourneyFeatureBanner/JourneyFeatureBanner';
import PostFeed from '../../../../UI/PostFeed/PostFeed';
import TeamInfoPanel from '../../../../UI/TeamInfoPanel/TeamInfoPanel';
import TeamJourneysPanel from '../../../../UI/TeamJourneysPanel/TeamJourneysPanel';
import PostEditor from '../../../../UI/PostEditor/PostEditor';

class AppTeamPage extends Component {
  constructor(props) {
    super(props);

    this.feedRef = React.createRef();

    this.onPostSaved = this.onPostSaved.bind(this);
  }
  onPostSaved(newPost) {
    if (newPost.scheduledAt) {
      this.props.openModalAction({
        type: Util.enum.ModalType.Alert,
        title: 'Post scheduled',
        icon: Util.icon.post,
        data: {
          content: (
            <div>
              Your post has been scheduled for{' '}
              {Util.format.date.shortDateTime(newPost.scheduledAt)}.
            </div>
          )
        }
      });
    } else {
      let teamId = parseInt(this.props.teamId, 10);
      if (!isNaN(teamId) && newPost.teamId && newPost.teamId !== teamId) return;

      this.feedRef.current.addPostToFeed(newPost);
    }
  }
  render() {
    let content = null;
    let teamId = parseInt(this.props.teamId, 10);
    let team = Util.context.user.getTeamById(teamId);

    if (!team) {
      if (Util.array.any(Util.context.user.getTeams()))
        return (
          <Redirect
            to={Util.route.app.team(Util.context.user.getTeams()[0].teamId)}
          />
        );
      content = (
        <div className="empty-text">
          You don't currently belong to any teams.
        </div>
      );
    } else {
      let isPostEditorEnabled = Util.context.user.canAdminTeam(teamId);

      content = (
        <div className="team-content" key={teamId}>
          <JourneyFeatureBanner teamId={teamId} />
          <div className="team-content-lower">
            <div className="post-feed-container">
              {isPostEditorEnabled ? (
                <PostEditor
                  onPostSaved={this.onPostSaved}
                  defaultAudienceTeamId={teamId}
                />
              ) : null}
              <PostFeed
                ref={this.feedRef}
                dataUrl={'/api/post/getFeedPostsByTeamId'}
                dataParams={{ teamId: teamId }}
                defaultAudienceTeamId={teamId}
              />
            </div>
            <div className="feed-support">
              <div className="feed-support-panel">
                <div className="feed-support-panel-title">
                  <ReactSVG className="panel-icon" src={Util.icon.team} />
                  <h4 className="panel-title bold">Team Information</h4>
                  <Link to={Util.route.app.teamMembers(teamId)}>
                    <ReactSVG
                      className="arrow-icon svg-button"
                      src={Util.icon.arrow.right}
                    />
                  </Link>
                </div>
                <TeamInfoPanel team={team} />
              </div>
              {Util.array.any(team.teamJourneys) ? (
                <div className="feed-support-panel">
                  <div className="feed-support-panel-title">
                    <ReactSVG className="panel-icon" src={Util.icon.journey} />
                    <h4 className="panel-title bold">Team Journeys</h4>
                    <Link to={Util.route.app.teamJourneys(teamId)}>
                      <ReactSVG
                        className="arrow-icon svg-button"
                        src={Util.icon.arrow.right}
                      />
                    </Link>
                  </div>
                  <TeamJourneysPanel team={team} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="leda-page-app-team">
        <AppTeamLayout
          pageTitle={team ? team.name : Util.context.user.getOrganisationName()}
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">{content}</div>
          </div>
        </AppTeamLayout>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(AppTeamPage);
