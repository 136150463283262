import React, { Component } from 'react';

import BreadCrumb from '../../UI/BreadCrumb/BreadCrumb';

export default class AdminLayout extends Component {
  render() {
    const bottomSpacerHeight = this.props.bottomSpacerHeight || 0;
    const overflow = this.props.overflowVisible ? 'visible' : 'hidden';
    return (
      <div
        className="leda-layout-admin"
        style={{
          overflow
        }}
      >
        <div
          className="page-container"
          style={{
            overflow
          }}
        >
          <div
            className="leda-container-lg"
            style={{
              overflow
            }}
          >
            <div className="leda-row">
              {this.props.pageTitle ? (
                <div>
                  <BreadCrumb />
                  <h1 className="admin-heading">{this.props.pageTitle}</h1>
                </div>
              ) : null}
              {this.props.children}
            </div>
          </div>
          <div style={{ height: bottomSpacerHeight + 'px' }}></div>
        </div>
      </div>
    );
  }
}
