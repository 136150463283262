import React, { Component } from 'react';
import Util from '../../../Util';
import { Link } from 'react-router-dom';

//this.props.user (only needs names + profilephotoblobid if exists)
//If no user, will use the current logged in user
export default class ProfilePhoto extends Component {
  render() {
    let content = null;
    let link = null;
    let title = null;

    if (this.props.isLedaOfficial) {
      content = (
        <img
          alt=""
          src="https://ledastorageaccount.blob.core.windows.net/assets/static/heyleda.png"
        />
      );
      title = 'Leda';
    } else {
      let user = this.props.user || Util.context.user.getCurrent();
      if (!user) return null; //Should not happen!

      let isMe = user.userId === Util.context.user.getCurrentUserId();

      title = user.fullName + (isMe ? ' (You)' : '');
      link =
        this.props.to ||
        (isMe ? Util.route.app.profile() : Util.route.app.profile(user.userId));

      let colours = [
        'F2C577',
        'E8B660',
        'EF9D78',
        'FF7F82',
        'AB80DE',
        'EA7652',
        '76CECE',
        '225987',
        '5BA2B5'
      ];
      let userColour = '#' + colours[user.fullName.length % colours.length];

      content = user.profilePhotoBlobId ? (
        <img alt="" src={Util.storage.blob(user.profilePhotoBlobId)} />
      ) : (
        <div
          className="initials"
          style={{
            borderColor: userColour,
            color: userColour,
            backgroundColor: userColour + '33'
          }}
        >
          <span className="bold">
            {Util.format.initials(user.firstName, user.lastName)}
          </span>
        </div>
      );
    }

    return (
      <div className={`profile-photo ${this.props.size || 'sm'}`} title={title}>
        {content}
        {!!link && !Util.route.isCurrently(link) && !this.props.isNoLink ? (
          <Link to={link} className="link-cover" />
        ) : null}
      </div>
    );
  }
}
