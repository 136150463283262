import React, { Component } from 'react';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import BreadCrumb from '../../../UI/BreadCrumb/BreadCrumb';
import Button from '../../../UI/Button/Button';
import ContextMenu from '../../../UI/ContextMenu/ContextMenu';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import Grid from '../../../UI/Grid/Grid';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../actions/modalActions';

class AdminUserListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      isLoading: true,
      filter: 'no filter',
      selectedSorting: 'createdAtNewest',
      searchValue: ''
    };

    this.openUserEditorModal = this.openUserEditorModal.bind(this);
    this.toggleIsOrganisationAdmin = this.toggleIsOrganisationAdmin.bind(this);
  }
  componentDidMount() {
    Util.api.post(
      '/api/admin/getAllUsers',
      {},
      {
        success: users => {
          this.setState({
            users,
            isLoading: false
          });
        }
      }
    );
  }
  openUserEditorModal(editUser) {
    this.props.openModalAction({
      type: Util.enum.ModalType.AdminUserEditorModal,
      data: {
        user: editUser,
        onUserSaved: savedUser => {
          if (editUser) {
            //if this was an update (this does not support user creation, just user updates)
            this.setState({
              users: this.state.users.map(user => {
                return user.userId !== savedUser.userId ? user : savedUser;
              })
            });
          }
        }
      }
    });
  }
  toggleIsOrganisationAdmin(toggleForUser) {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: (
          <p>
            {toggleForUser.isOrganisationAdmin
              ? `Revoke this user's organisation admin privelages?`
              : `Grant this user organisation admin privelages?`}
          </p>
        ),
        yesFn: () => {
          Util.api.post(
            '/api/admin/setIsOrganisationAdmin',
            {
              userId: toggleForUser.userId,
              isOrganisationAdmin: !toggleForUser.isOrganisationAdmin
            },
            {
              success: () => {
                this.setState({
                  users: this.state.users.map(user => {
                    return user.userId !== toggleForUser.userId
                      ? user
                      : {
                          ...user,
                          isOrganisationAdmin: !user.isOrganisationAdmin
                        };
                  })
                });
              }
            }
          );
        }
      }
    });
  }
  setFilter = filterVal => {
    if (filterVal !== this.state.filter) {
      this.setState({
        filter: filterVal
      });
    }
  };

  onSortingChange = sortingValue => {
    this.setState({
      selectedSorting: sortingValue
    });
  };

  onSearch = searchValue => {
    this.setState({
      searchValue: searchValue
    });
  };

  render() {
    if (this.state.isLoading) return <div className="loader"></div>;
    let users = [...this.state.users];

    const getFilterButton = (label, filterVal) => (
      <Button
        size="sm"
        isSecondary={true}
        isActive={this.state.filter === filterVal}
        onClick={() => this.setFilter(filterVal)}
        label={label}
      />
    );

    const dropDownOptions = [
      {
        display: 'Sort by date created (Newest)',
        value: 'createdAtNewest'
      },
      {
        display: 'Sort by date created (Oldest)',
        value: 'createdAtOldest'
      },
      {
        display: 'Sort by name: Alphabetical (A-Z)',
        value: 'alphabeticalAZ'
      },
      {
        display: 'Sort by name: Alphabetical (Z-A)',
        value: 'alphabeticalZA'
      },
      {
        display: 'Sort by last activity date',
        value: 'lastActivity'
      }
    ];

    const filter = this.state.filter;
    const selectedSorting = this.state.selectedSorting;
    const searchValue = this.state.searchValue;

    // License filter
    if (filter !== 'no filter') {
      users = users.filter(
        ({ licenseType }) => licenseType === this.state.filter
      );
    }

    // Sort by
    if (selectedSorting === 'alphabeticalAZ') {
      users = users.sort((i1, i2) =>
        Util.sort.by('fullName', i1, i2, Util.sort.asString)
      );
    } else if (selectedSorting === 'alphabeticalZA') {
      users = users.sort((i1, i2) =>
        Util.sort.by('fullName', i2, i1, Util.sort.asString)
      );
    } else if (selectedSorting === 'createdAtOldest') {
      users = users.sort((i1, i2) =>
        Util.sort.by('createdAt', i1, i2, Util.sort.asDate)
      );
    } else if (selectedSorting === 'createdAtNewest') {
      users = users.sort((i1, i2) =>
        Util.sort.by('createdAt', i2, i1, Util.sort.asDate)
      );
    } else if (selectedSorting === 'lastActivity') {
      users = users
        .sort((i1, i2) => Util.sort.by('lastLoginAt', i1, i2, Util.sort.asDate))
        .reverse();
    }

    // Search filter
    if (searchValue !== '') {
      users = users.filter(({ firstName, lastName, email }) => {
        let textToSearch = `${firstName} ${lastName} ${email}`;
        return textToSearch.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    return (
      <div className="leda-page-admin-user-list">
        <AdminLayout bottomSpacerHeight={200}>
          <BreadCrumb />
          <div className="pageTitle">
            <h1>User List</h1>
            <Button
              label="View Orgs & Team Lists"
              to={Util.route.admin.teamList()}
            />
          </div>

          <div className="filter-bar">
            <ReactSVG className="filter-icon" src={Util.icon.filter} />
            {getFilterButton('View All', 'no filter')}
            {getFilterButton('Full License', 2)}
            {getFilterButton('Trial license', 1)}
            {getFilterButton('Student license', 3)}
            {getFilterButton('No License', null)}
          </div>

          <div className="sort-bar">
            <ReactSVG
              className="reorder-down-icon"
              src={Util.icon.reorderDown}
            />
            <Dropdown
              isDisabled={false}
              placeholder="Select audience"
              options={dropDownOptions}
              valueProp="value"
              displayProp="display"
              onChange={this.onSortingChange}
              value={this.state.selectedSorting}
            />
            <ReactSVG className="reorder-down-icon" src={Util.icon.search} />
            <input
              autoComplete="off"
              value={this.state.searchValue}
              name="name"
              id="search_field"
              type="text"
              placeholder="Type to search"
              onChange={e => this.onSearch(e.target.value)}
            />
          </div>

          <Grid
            isCountVisible={true}
            data={users}
            columns={[
              {
                name: '',
                key: 'isPasswordNonNull',
                sortFn: Util.sort.asBoolean,
                render: val => {
                  return (
                    <span
                      className={'circle-' + (val ? 'green' : 'red')}
                    ></span>
                  );
                }
              },
              {
                name: 'ID',
                key: 'userId',
                sortFn: Util.sort.asNumber,
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey'}>
                      {val}
                    </span>
                  );
                }
              },
              {
                name: 'Created at',
                key: 'createdAt',
                sortFn: Util.sort.asDate,
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey'}>
                      {Util.format.date.shortDateTime(val)}
                    </span>
                  );
                }
              },
              {
                name: 'First name',
                key: 'firstName',
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey-3'}>
                      {val}
                    </span>
                  );
                }
              },
              {
                name: 'Last name',
                key: 'lastName',
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey-3'}>
                      {val}
                    </span>
                  );
                }
              },
              {
                name: 'Email',
                key: 'email',
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey-3'}>
                      {val}
                    </span>
                  );
                }
              },
              {
                name: 'Access',
                key: 'isLocked',
                sortFn: Util.sort.asBoolean,
                render: val =>
                  val ? (
                    <span className="grey-3">Locked</span>
                  ) : (
                    <span>Enabled</span>
                  )
              },
              {
                name: 'Last Login',
                key: 'lastLoginAt',
                sortFn: Util.sort.asDate,
                render: (val, { isPasswordNonNull }) => {
                  return (
                    <span className={isPasswordNonNull ? '' : 'grey-3'}>
                      {val ? Util.format.date.shortDateTime(val) : 'Never'}
                    </span>
                  );
                }
              },
              {
                name: 'Organisation',
                key: 'organisationId',
                isRenderSort: true,
                render: (val, { isPasswordNonNull, organisationName }) => {
                  if (!val || !isPasswordNonNull)
                    return <span className="grey-3">No organisation</span>;

                  return organisationName;
                }
              },
              {
                key: 'userId',
                isSortDisabled: true,
                render: (val, record) => {
                  return (
                    <ContextMenu
                      items={[
                        {
                          icon: Util.icon.edit,
                          label: 'Edit user',
                          onClick: () => this.openUserEditorModal(record)
                        },
                        {
                          icon: Util.icon.email,
                          label: 'View emails',
                          to: Util.route.admin.communicationList(record.email)
                        },
                        {
                          onClick: () => this.toggleIsOrganisationAdmin(record),
                          icon: record.isOrganisationAdmin
                            ? Util.icon.lock
                            : Util.icon.unlock,
                          label: `${
                            record.isOrganisationAdmin ? 'Revoke' : 'Grant'
                          } org admin`
                        }
                      ]}
                    />
                  );
                }
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(AdminUserListPage);
