import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import CardsView from '../CardsView';
import MyActivityCard from '../../Card/MyActivityCard/MyActivityCard';

export default class MyActivityCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.myActivities,
      myActivities: this.props.myActivities || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.myActivities) {
      this.setState({
        myActivities: props.myActivities
      });
    } else if (this.props.dataUrl !== props.dataUrl) {
      this.fetchData(props.dataUrl, props.dataParams);
    }
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: myActivities => {
        this.setState({
          myActivities
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (Util.array.none(this.state.myActivities)) {
      content = <div className="empty-text">No activities to display.</div>;
    }

    return (
      <CardsView className="my-activity-cards-view">
        {content}
        {this.state.myActivities.map(myActivity => (
          <MyActivityCard
            key={myActivity.myActivityId}
            isSelected={
              myActivity.sourceActivityId === this.props.selectedActivityId ||
              myActivity.myActivityId === this.props.selectedMyActivityId
            }
            myActivity={myActivity}
            onClick={this.props.onCardClick}
          />
        ))}
      </CardsView>
    );
  }
}
