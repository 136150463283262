import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticateAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

import AuthLayout from '../../../Layouts/Auth/AuthLayout';

// Components
import LoginForm from '../../../UI/Forms/LoginForm/LoginForm';

class AuthLoginPage extends Component {
  render() {
    return (
      <div className="leda-page-auth-login">
        <AuthLayout>
          <LoginForm
            onSubmit={(form, formData) => {
              form.setLoading(true);

              Util.api.post(
                '/api/auth/login',
                {
                  email: formData.email,
                  password: formData.password
                },
                {
                  success: result => {
                    if (result.success) {
                      this.props.authenticateAction(result.token);
                    } else {
                      if (result.isLocked) {
                        window.location.href = Util.route.site.licenseExpired();
                      } else {
                        form.setOverallError(result.errorMsg);
                        form.setLoading(false);
                      }
                    }
                  },
                  failure: errorMsg => {
                    form.setOverallError(errorMsg);
                    form.setLoading(false);
                  }
                }
              );
            }}
          />
          <div className="link-container">
            <Link to={Util.route.auth.passwordForgot()}>
              I forgot my password
            </Link>
            <Link to={Util.route.site.contact()}>
              I don't have an account yet
            </Link>
          </div>
        </AuthLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { authenticateAction }
)(AuthLoginPage);
