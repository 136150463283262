import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import Util from '../../../Util';

//this.props.users
export default class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: this.props.dataUrl,
      users: this.props.users || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: users => {
        this.setState({
          users
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    return (
      <div className="user-list">
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div className="user-list-inner">
            {this.state.users
              .sort((u1, u2) => Util.sort.by('fullName', u1, u2))
              .map((user, idx) => (
                <Link
                  key={idx}
                  to={Util.route.app.profile(user.userId)}
                  className="user-list-item"
                >
                  <ProfilePhoto user={user} isNoLink={true} />
                  <div>
                    <p className="bold">{user.fullName}</p>
                    <h4>{user.jobTitle}</h4>
                  </div>
                </Link>
              ))}
          </div>
        )}
      </div>
    );
  }
}
