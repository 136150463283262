import React, { Component } from 'react';
import {
  closeModalAction,
  openModalAction
} from '../../../actions/modalActions';

import AdminActivityTaskEditorForm from '../Forms/Admin/AdminActivityTaskEditorForm/AdminActivityTaskEditorForm';
import AdminActivityTaskFieldEditorForm from '../Forms/Admin/AdminActivityTaskFieldEditorForm/AdminActivityTaskFieldEditorForm';
import AdminCreateTeamModalForm from '../Forms/Admin/AdminCreateTeamModalForm/AdminCreateTeamModalForm';
import AdminUserEditorForm from '../Forms/Admin/AdminUserEditorForm/AdminUserEditorForm';
import Assessment from '../Assessment/Assessment';
//Components
import Button from '../Button/Button';
import CapabilitiesLinkList from '../CapabilitiesLinkList/CapabilitiesLinkList';
import CompletedActivityView from '../CompletedActivityView/CompletedActivityView';
import DateTimeField from '../DateTimeField/DateTimeField';
import Game from '../Game/Game';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import MyActivitiesView from '../MyActivitiesView/MyActivitiesView';
import PostEditor from '../PostEditor/PostEditor';
import PresetPostEditor from '../PresetPostEditor/PresetPostEditor';
import ReactSVG from 'react-svg';
import TimeField from '../DateTimeField/TimeField/TimeField';
import UrlForm from '../Forms/UrlForm/UrlForm';
import UserFeedbackRequestPanel from '../UserFeedbackRequestPanel/UserFeedbackRequestPanel';
import UserList from '../UserList/UserList';
import UserNotificationList from '../UserNotificationList/UserNotificationList';
import Util from '../../../Util';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { connect } from 'react-redux';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }
  close() {
    this.props.closeModalAction(this.props.modal.id);
    if (this.props.modal.onClose) this.props.modal.onClose();
  }
  render() {
    let modal = this.props.modal;

    let modalClass, modalContent, modalTitle, modalIcon;

    switch (modal.type) {
      case Util.enum.ModalType.Alert:
        modalClass = 'alert-modal never-fullscreen';
        modalContent = (
          <div className="alert">
            <div>{modal.data.content}</div>
            <div className="button-container modal-button-footer">
              <Button label={modal.data.okLabel || 'Ok'} onClick={this.close} />
            </div>
          </div>
        );
        break;

      case Util.enum.ModalType.Confirm:
        modalClass = 'confirm-modal never-fullscreen';
        modalContent = (
          <div className="confirm">
            <div>{modal.data.content}</div>
            <div className="button-container modal-button-footer">
              <Button
                label={modal.data.noLabel || 'No'}
                isSecondary={true}
                onClick={this.close}
              />
              <Button
                label={modal.data.yesLabel || 'Yes'}
                isSecondary={false}
                onClick={() => {
                  this.close();
                  modal.data.yesFn();
                }}
              />
            </div>
          </div>
        );
        break;

      case Util.enum.ModalType.VideoModal:
        modalClass = 'video-modal';
        modalContent = (
          <div className="video-container">
            <div className="video-title">{modal.data.video.name}</div>
            <VideoPlayer
              video={modal.data.video}
              onVideoFinished={modal.data.onVideoFinished}
            />
          </div>
        );
        break;

      case Util.enum.ModalType.CompletedActivityModal:
        modalClass = 'completed-activity-modal';
        modalIcon = Util.icon.activity;
        modalTitle = 'Completed Activity';
        modalContent = (
          <CompletedActivityView
            activityId={modal.data.activityId} //The CompletedActivityView might use this
            activity={modal.data.activity} //Or this
            journeyStepId={modal.data.journeyStepId}
            onActivityAdd={() => {
              this.close();
              this.props.openModalAction({
                type: Util.enum.ModalType.MyActivitiesModal,
                data: {
                  selectedActivityId: modal.data.activity
                    ? modal.data.activity.activityId
                    : modal.data.activityId
                }
              });
            }}
          />
        );
        break;

      case Util.enum.ModalType.SkillMatrixModal:
        modalClass = 'skill-matrix-modal';
        modalContent = <CapabilitiesLinkList onItemClick={this.close} />;
        break;

      case Util.enum.ModalType.ActivityTaskEditorModal:
        modalClass = 'activity-task-editor-modal';
        modalContent = (
          <AdminActivityTaskEditorForm
            formData={modal.data.activityTask}
            onCancel={() => this.close()}
            onSubmit={(form, formData) => {
              if (modal.data.onSubmit) modal.data.onSubmit(formData);
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.ActivityTaskFieldEditorModal:
        modalClass = 'activity-task-field-editor-modal';
        modalContent = (
          <AdminActivityTaskFieldEditorForm
            formData={modal.data.activityTaskField}
            onCancel={() => this.close()}
            onSubmit={(form, formData) => {
              if (modal.data.onSubmit) modal.data.onSubmit(formData);
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.AdminUserEditorModal:
        modalClass = 'user-editor-modal';
        modalTitle = modal.data.user ? 'Edit user' : 'Create new user';

        modalIcon = Util.icon.user;
        modalContent = (
          <AdminUserEditorForm
            formData={modal.data.user}
            onCancel={() => this.close()}
            onSubmit={(form, formData) => {
              form.setLoading(true);

              Util.api.post(
                '/api/admin/saveUser',
                {
                  user: {
                    ...formData,
                    userId: modal.data.user ? modal.data.user.userId : null
                  },
                  organisationLicenseType: modal.data.organisation.licenseType,
                  organisationName: modal.data.organisation.name
                },
                {
                  success: user => {
                    if (modal.data.onUserSaved) modal.data.onUserSaved(user);
                    this.close();
                  },
                  failure: errorMsg => {
                    form.setOverallError(errorMsg);
                  },
                  complete: () => form.setLoading(false)
                }
              );
            }}
          />
        );
        break;

      case Util.enum.ModalType.UserFeedbackRequest:
        modalClass = 'user-feedback-request-modal';
        modalContent = <UserFeedbackRequestPanel />;
        break;

      case Util.enum.ModalType.MyActivitiesModal:
        modalClass = 'my-activities-modal';
        modalTitle = 'My Activities';
        modalIcon = Util.icon.activity;
        modalContent = (
          <MyActivitiesView
            selectedActivityId={modal.data.selectedActivityId}
            selectedMyActivityId={modal.data.selectedMyActivityId}
          />
        );
        break;

      case Util.enum.ModalType.GameModal:
        modalClass = 'game-modal';
        modalContent = (
          <Game
            gameId={modal.data.gameId}
            onQuit={() => {
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.AssessmentModal:
        modalClass = 'assessment-modal';
        modalContent = <Assessment assessmentId={modal.data.assessmentId} />;
        break;

      case Util.enum.ModalType.UserNotificationsModal:
        modalClass = 'user-notifications-modal';
        modalTitle = 'Notifications';
        modalIcon = Util.icon.notification;
        modalContent = (
          <UserNotificationList
            userNotifications={modal.data.userNotifications}
          />
        );
        break;

      case Util.enum.ModalType.ImageCarouselModal:
        modalClass = 'image-carousel-modal';
        modalIcon = Util.icon.image;
        modalContent = (
          <ImageCarousel
            images={modal.data.images}
            settings={{ initialSlide: modal.data.initialPanelIdx }}
          />
        );
        break;

      case Util.enum.ModalType.UserListModal:
        modalClass = 'user-list-modal never-fullscreen';
        modalIcon = Util.icon.team;
        modalContent = (
          <UserList
            users={modal.data.users}
            dataUrl={modal.data.dataUrl}
            dataParams={modal.data.dataParams}
          />
        );
        break;

      case Util.enum.ModalType.ScheduleAtModal:
        modalClass = 'schedule-at-modal never-fullscreen';
        modalTitle = 'Scheduled';
        modalContent = (
          <div className="schedule-at-select">
            <DateTimeField
              min="now"
              onChange={newDateTime => (modal.data.dateTime = newDateTime)}
            />
            <div className="button-container modal-button-footer">
              <Button
                label="Cancel"
                isSecondary={true}
                onClick={() => this.close()}
              />
              <Button
                label="Schedule"
                onClick={() => {
                  modal.data.onSubmit(modal.data.dateTime);
                  this.close();
                }}
              />
            </div>
          </div>
        );
        break;

      case Util.enum.ModalType.PostEditorModal:
        modalClass = 'post-editor-modal';
        modalContent = (
          <PostEditor
            post={modal.data.post}
            postId={modal.data.postId}
            defaultAudienceIsGlobal={modal.data.isGlobal}
            defaultAudienceOrganisationId={modal.data.organisationId}
            defaultAudienceTeamId={modal.data.teamId}
            onPostSaved={post => {
              if (modal.data.onPostSaved) modal.data.onPostSaved(post);
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.UrlModal:
        modalClass = 'url-modal never-fullscreen';
        modalContent = (
          <UrlForm
            formData={{ url: modal.data.value }}
            onCancel={() => this.close()}
            onSubmit={(form, formData) => {
              modal.data.onSubmit(formData.url);
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.TextInputModal:
        modalClass = 'text-input-modal never-fullscreen';
        modalContent = (
          <div>
            <div className="field-container">
              <label>{modal.data.label}</label>
              <input
                defaultValue={modal.data.value}
                onChange={e => (modal.data.value = e.target.value)}
              />
            </div>
            <div className="button-container modal-button-footer">
              <Button
                label="Cancel"
                isSecondary={true}
                onClick={() => this.close()}
              />
              <Button
                label="Submit"
                onClick={() => {
                  modal.data.onSubmit(modal.data.value);
                  this.close();
                }}
              />
            </div>
          </div>
        );
        break;

      case Util.enum.ModalType.CreateTeamModal:
        modalClass = 'create-team';
        modalContent = (
          <AdminCreateTeamModalForm
            presets={this.props.modal.data.presets}
            onCancel={() => this.close()}
            onSubmit={(form, formData) => {
              modal.data.onSubmit(formData);
              this.close();
            }}
          />
        );
        break;

      case Util.enum.ModalType.EditPresetPost:
        modalClass = 'edit-preset-post';
        modalContent = (
          <PresetPostEditor
            post={modal.data.post}
            postId={modal.data.postId}
            onSubmit={(post, fileIds) => {
              modal.onSubmit(post, fileIds);
              this.close();
            }}
          />
        );
        break;

      default:
        modalClass = '';
        modalContent = modal.content;
        break;
    }

    // title and icon overrides
    if (modal.title) modalTitle = modal.title;
    if (modal.icon) modalIcon = modal.icon;

    return (
      <div className="modal-container">
        <div className="modal-container-backdrop" onClick={this.close}></div>
        <div className="modal-container-inner" onMouseDown={this.close}>
          <div
            className={`modal ${modalClass}`}
            onClick={Util.event.stopPropagation}
            onMouseDown={Util.event.stopPropagation}
          >
            {!modal.showCloseButton ? null : (
              <ReactSVG
                src={Util.icon.close}
                onClick={this.close}
                className="close-button svg-button"
              />
            )}
            {modalTitle || modalIcon ? (
              <div className="modal-header">
                {modalIcon ? (
                  <ReactSVG className="modal-icon" src={modalIcon} />
                ) : null}
                {modalTitle ? (
                  <h2 className="modal-title">{modalTitle}</h2>
                ) : null}
              </div>
            ) : null}
            <div className="modal-inner">{modalContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { openModalAction, closeModalAction })(Modal);
