import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';

export default class TeamInfoPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      teamUsers: []
    };
  }
  componentDidMount() {
    Util.api.post(
      '/api/organisation/getTeamUsers',
      {
        teamId: this.props.team.teamId
      },
      {
        success: teamUsers => {
          this.setState({
            teamUsers: teamUsers.sort((u1, u2) =>
              Util.sort.asBoolean(
                !!u1.profilePhotoBlobId,
                !!u2.profilePhotoBlobId
              )
            )
          });
        },
        complete: () => {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }
  render() {
    let adminTeamUsers = this.state.teamUsers.filter(
      teamUser => teamUser.isTeamAdmin
    );
    let basicTeamUsers = this.state.teamUsers.sort((tu1, tu2) =>
      Util.sort.by('isTeamAdmin', tu2, tu1, Util.sort.asBoolean)
    ); //send admins to the BACK OF THE LINE!!

    let getUserProfilePhotos = users => {
      let userLimit = 6;

      return (
        <div className="user-profile-photos">
          {users.slice(0, userLimit).map(teamUser => (
            <ProfilePhoto key={teamUser.teamUserId} user={teamUser} />
          ))}
          {users.length > userLimit ? (
            <h4 className="remaining-count">
              <Link
                className="link-cover white"
                to={Util.route.app.teamMembers(this.props.team.teamId)}
              >
                +{users.length - userLimit}
              </Link>
            </h4>
          ) : null}
        </div>
      );
    };

    return (
      <div className="team-info-panel">
        <div className="team-info">
          <h2 className="team-name lg">{this.props.team.name}</h2>
          <h4
            className="team-description"
            dangerouslySetInnerHTML={{
              __html: Util.text.toSafeHtml(this.props.team.description)
            }}
          ></h4>
          {!this.state.isLoading && Util.array.none(adminTeamUsers) ? null : (
            <div className="team-users">
              <h4>Team admins</h4>
              {getUserProfilePhotos(adminTeamUsers)}
            </div>
          )}
          {!this.state.isLoading && Util.array.none(basicTeamUsers) ? null : (
            <div className="team-users">
              <h4>Team members</h4>
              {getUserProfilePhotos(basicTeamUsers)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
