import React, { Component } from 'react';
import Util from '../../../../Util';

// Images
import imageLost from '../../../../img/lost.png';

import Button from '../../../UI/Button/Button';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

export default class Site404Page extends Component {
  componentDidMount() {
    let redirectedFrom = Util.route.getParameterByName('redirectedFrom');
    if (redirectedFrom) Util.analytics.track('404');
  }
  render() {
    return (
      <div className="leda-page-site-404">
        <SiteLayout>
          <div className="leda-container-sm">
            <div className="leda-row">
              <div className="error-panel">
                <img src={imageLost} alt="Error 404 - Not found" />
                <h2>Sorry, we couldn't find what you were looking for.</h2>
                <Button label="Back to home" to={Util.route.site.home()} />
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
