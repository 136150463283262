import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import Util from '../../../Util';

//Components
import WatchedLabel from '../WatchedLabel/WatchedLabel';
import Truncate from '../Truncate/Truncate';

// Data source: this.props.video
class VideoThumbnail extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    Util.analytics.track('VideoThumbnailClicked', {
      videoId: this.props.video.videoId,
      videoName: this.props.video.name
    });

    if (this.props.onClick) this.props.onClick(this.props.video);
  }
  render() {
    let isWatched = !!this.props.userProgressVideo[this.props.video.videoId];

    return (
      <div
        className="video-thumbnail"
        key={this.props.video.chapterVideoId}
        onClick={this.onClick}
      >
        <div className="videos-thumbnail-inner">
          <div className="thumbnail">
            <div
              className={`thumbnail-image ${isWatched ? 'watched' : ''}`}
              style={{
                backgroundImage:
                  'url(' +
                  Util.storage.videoThumbnail(this.props.video.urlId) +
                  ')'
              }}
            ></div>
            <ReactSVG className="play-icon" src={Util.icon.play} />
            <div className="video-duration">
              {Util.format.secsToDuration(this.props.video.durationSeconds)}
            </div>
            <WatchedLabel videoId={this.props.video.videoId} />
          </div>
          <div className="video-thumbnail-lower">
            <div className="video-name">
              <Truncate
                component="p"
                maxLine={2}
                text={this.props.video.name}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(VideoThumbnail);
