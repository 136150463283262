import React, { Component } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';
import withForm from '../../withForm';
import Util from '../../../../../Util';

import Grid from '../../../Grid/Grid';
import Dropdown from '../../../Dropdown/Dropdown';
import Button from '../../../Button/Button';

class AdminActivityEditorForm extends Component {
  constructor(props) {
    super(props);

    this.openActivityTaskEditorModal = this.openActivityTaskEditorModal.bind(
      this
    );
    this.removeActivityTask = this.removeActivityTask.bind(this);

    this.addActivitySkill = this.addActivitySkill.bind(this);
    this.removeActivitySkill = this.removeActivitySkill.bind(this);
  }
  openActivityTaskEditorModal(activityTask) {
    this.props.openModalAction({
      type: Util.enum.ModalType.ActivityTaskEditorModal,
      data: {
        activityTask,
        onSubmit: editedActivityTask => {
          let existingActivityTasks = this.props.formData.activityTasks || [];

          if (editedActivityTask.activityTaskId) {
            //Replace the activityTask with the same Id as the incoming one
            this.props.updateFormData(
              'activityTasks',
              existingActivityTasks.map(activityTask =>
                activityTask.activityTaskId !==
                  editedActivityTask.activityTaskId
                  ? activityTask
                  : editedActivityTask
              )
            );
          } else {
            //Give the incomingActivityTask a tempId and the activityId (which may just be undefined if the activity isnt saved yet, that is fine)
            editedActivityTask.activityTaskId = Util.id.tempId.getTempId();
            editedActivityTask.activityId = this.props.formData.activityId;
            editedActivityTask.ordinal =
              Util.array.max(existingActivityTasks, 'ordinal') + 1;
            this.props.updateFormData('activityTasks', [
              ...existingActivityTasks,
              editedActivityTask
            ]);
          }
        }
      }
    });
  }
  removeActivityTask(activityTaskId) {
    this.props.updateFormData(
      'activityTasks',
      this.props.formData.activityTasks.filter(
        activityTask => activityTask.activityTaskId !== activityTaskId
      )
    );
  }
  addActivitySkill(skillId) {
    let existingSkills = this.props.formData.activitySkills || [];

    //If dropdown empty or skill is already in the array, don't add it again
    if (!skillId || Util.array.firstIdMatch(existingSkills, skillId, 'skillId'))
      return;

    this.props.updateFormData('activitySkills', [
      ...existingSkills,
      {
        activitySkillId: Util.id.tempId.getTempId(),
        activityId: this.props.formData.activityId,
        skillId
      }
    ]);
  }
  removeActivitySkill(activitySkillId) {
    this.props.updateFormData(
      'activitySkills',
      this.props.formData.activitySkills.filter(
        activitySkill => activitySkill.activitySkillId !== activitySkillId
      )
    );
  }
  render() {
    return (
      <form
        className="form-admin-activity-editor"
        onSubmit={this.props.onSubmit}
      >
        {this.props.getField('name')}
        {this.props.getField('isGlobal')}
        {this.props.getField('isFeatured')}
        <Grid
          title="Tasks for this activity"
          data={this.props.formData.activityTasks}
          toolbarItems={[
            <Button
              size="sm"
              label="Create new task"
              onClick={() => this.openActivityTaskEditorModal()}
            />
          ]}
          columns={[
            {
              name: 'ACT_TaskId',
              key: 'activityTaskId',
              sortFn: Util.sort.asNumber
            },
            {
              name: 'Name',
              key: 'name',
              width: '100%'
            },
            {
              key: 'activityTaskId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <div className="button-container">
                    <Button
                      icon={Util.icon.edit}
                      size="sm"
                      isSecondary={true}
                      onClick={() => this.openActivityTaskEditorModal(record)}
                    />
                    <Button
                      icon={Util.icon.bin}
                      size="sm"
                      isSecondary={true}
                      onClick={() => this.removeActivityTask(val)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
        <Grid
          title="Skills for this activity"
          data={this.props.formData.activitySkills}
          toolbarItems={[
            <Dropdown
              options={Util.context.referenceData.getSkills()}
              valueProp={'skillId'}
              displayProp={'name'}
              isEmptyAllowed={true}
              isEmptyOnChange={true}
              placeholder="Select a skill to add"
              onChange={this.addActivitySkill}
            />
          ]}
          columns={[
            {
              name: 'SKI_SkillId',
              key: 'skillId',
              sortFn: Util.sort.asNumber
            },
            {
              name: 'Name',
              key: 'skillId',
              width: '100%',
              isRenderSort: true,
              render: val => {
                let matchingSkill = Util.context.referenceData.getSkillById(
                  val
                );
                return matchingSkill ? matchingSkill.name : 'MISSING SKILL';
              }
            },
            {
              key: 'activitySkillId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <div className="button-container">
                    <Button
                      icon={Util.icon.bin}
                      size="sm"
                      isSecondary={true}
                      onClick={() => this.removeActivitySkill(val)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
          <Button
            label="Cancel"
            isSecondary={true}
            to={Util.route.admin.activityList()}
          />
        </div>
      </form>
    );
  }
}

export default withForm(
  connect(
    null,
    { openModalAction }
  )(AdminActivityEditorForm),
  {
    fields: {
      name: {
        label: 'Name',
        getError: val => {
          if (!validator.isLength(val, { max: 250 }))
            return 'Max 250 characters';
        }
      },
      isGlobal: {
        label: 'IsGlobal',
        type: Util.enum.FieldType.Checkbox
      },
      isFeatured: {
        label: 'IsFeatured',
        type: Util.enum.FieldType.Checkbox
      }
    }
  }
);
