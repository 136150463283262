import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Button from '../Button/Button';

export default class ButtonInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || '',
      formError: null,

      redirectTo: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({
      value: props.value || '',
      formError: props.formError
    });
  }
  onChange(e) {
    this.setState({ value: e.target.value });
    if (this.props.onChange) this.props.onChange(e);
  }
  onSubmit(e) {
    e.preventDefault();

    let error = this.props.getError
      ? this.props.getError(this.state.value)
      : null;

    if (error) {
      this.setState({
        formError: error
      });
    } else {
      if (this.props.onSubmit) this.props.onSubmit(this.state.value);
      if (this.props.buttonToFn)
        this.setState({ redirectTo: this.props.buttonToFn(this.state.value) });
    }
  }
  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;
    return (
      <form
        className={`button-input ${this.props.className || ''}`}
        onSubmit={this.onSubmit}
      >
        <div className="button-input-inner">
          <input
            readOnly={this.props.readOnly}
            placeholder={this.props.placeholder || ''}
            value={this.state.value}
            onChange={this.onChange}
          />
          <Button
            isSubmit={true}
            label={this.props.buttonLabel || 'Submit'}
            size={this.props.buttonSize || 'md'}
            to={this.props.buttonTo}
          />
        </div>
        {this.state.formError ? (
          <h5 className="form-error white">{this.state.formError}</h5>
        ) : null}
      </form>
    );
  }
}
