import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

class WatchedLabel extends Component {
  render() {
    return (
      <h4
        className={`watched-label white ${
          !!this.props.userProgressVideo[this.props.videoId] ? '' : ' hidden'
        }`}
      >
        <ReactSVG className="icon-tick" src={Util.icon.tick} />
        Watched
      </h4>
    );
  }
}

const mapStateToProps = state => ({
  userProgressVideo: state.userState.userProgress.video
});

export default connect(
  mapStateToProps,
  {}
)(WatchedLabel);
