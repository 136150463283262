import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

// Components
import ActivityCardsView from '../../../../UI/CardsView/ActivityCardsView/ActivityCardsView';

export default class AppActivitiesPage extends Component {
  render() {
    return (
      <div className="leda-page-app-activities">
        <AppExploreLayout
          pageTitle="Activities"
          pageSubtitle="Activities help you put specific leadership behaviours into practice, daily. Mark each one as complete when you're done."
          pageIcon={Util.icon.activity}
        >
          <ActivityCardsView dataUrl={'/api/activity/getGlobalActivities'} />
        </AppExploreLayout>
      </div>
    );
  }
}
