import React, { Component } from 'react';
import validator from 'validator';
import withForm from '../withForm';

import Button from '../../Button/Button';

class UrlForm extends Component {
  render() {
    return (
      <form className="form-url" onSubmit={this.props.onSubmit}>
        {this.props.getField('url')}
        <div className="button-container">
          <Button label="Submit" isSubmit={true} />
          <Button
            label="Cancel"
            onClick={this.props.onCancel}
            isSecondary={true}
          />
        </div>
      </form>
    );
  }
}

export default withForm(UrlForm, {
  fields: {
    url: {
      label: 'URL',
      placeholder: 'Enter or paste URL',
      getError: val => {
        if (!validator.isLength(val, { max: 10000 })) return 'URL is too long';
        if (val && !validator.isURL(val)) return 'URL is invalid';
      }
    }
  }
});
