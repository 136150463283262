import React, { Component } from 'react';
import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

import Grid from '../../../UI/Grid/Grid';

export default class AdminCommunicationListPage extends Component {
  render() {
    return (
      <div className="leda-page-admin-communication-list">
        <AdminLayout pageTitle={`Communication List - ${this.props.recipient}`}>
          <Grid
            dataUrl="/api/admin/getCommunicationsByRecipient"
            dataParams={{ recipient: this.props.recipient }}
            searchKeys={['content']}
            columns={[
              {
                name: 'ID',
                key: 'communicationId',
                sortFn: Util.sort.asNumber
              },
              {
                name: 'Created at',
                key: 'createdAt',
                sortFn: Util.sort.asDate,
                render: val => Util.format.date.shortDateTime(val)
              },
              {
                name: 'Content',
                key: 'content',
                isSortDisabled: true,
                render: val => {
                  return (
                    <div
                      style={{ pointerEvents: 'none' }}
                      dangerouslySetInnerHTML={{ __html: val }}
                    ></div>
                  );
                }
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}
