import React, { Component } from 'react';
import { openModalAction } from '../../../../../actions/modalActions';
import { connect } from 'react-redux';
import validator from 'validator';
import withForm from '../../withForm';
import Util from '../../../../../Util';

import Grid from '../../../Grid/Grid';
import Button from '../../../Button/Button';

//data source: this.props.activityTask OR NULL ( for new )
class AdminActivityTaskFieldEditorForm extends Component {
  constructor(props) {
    super(props);

    this.openAddTaskFieldOptionModal = this.openAddTaskFieldOptionModal.bind(
      this
    );
    this.removeActivityTaskFieldOption = this.removeActivityTaskFieldOption.bind(
      this
    );
  }
  openAddTaskFieldOptionModal() {
    this.props.openModalAction({
      type: Util.enum.ModalType.TextInputModal,
      title: `Add Option`,
      data: {
        value: '',
        label: 'Option label',
        onSubmit: label => {
          let existingActivityTaskFieldOptions =
            this.props.formData.activityTaskFieldOptions || [];

          let newOption = {
            label,
            activityTaskFieldOptionId: Util.id.tempId.getTempId(),
            activityTaskFieldId: this.props.formData.activityTaskFieldId,
            ordinal:
              Util.array.max(existingActivityTaskFieldOptions, 'ordinal') + 1
          };

          this.props.updateFormData('activityTaskFieldOptions', [
            ...existingActivityTaskFieldOptions,
            newOption
          ]);
        }
      }
    });
  }
  removeActivityTaskFieldOption(activityTaskFieldOptionId) {
    this.props.updateFormData(
      'activityTaskFieldOptions',
      this.props.formData.activityTaskFieldOptions.filter(
        activityTaskFieldOption =>
          activityTaskFieldOption.activityTaskFieldOptionId !==
          activityTaskFieldOptionId
      )
    );
  }
  render() {
    let typesWithOptions = [
      Util.enum.FieldType.CheckboxGroup,
      Util.enum.FieldType.RadioButtons,
      Util.enum.FieldType.Dropdown
    ];
    let typesWithPlaceholder = [
      Util.enum.FieldType.Text,
      Util.enum.FieldType.Textarea,
      Util.enum.FieldType.Dropdown
    ];
    let typesWithMinMax = [
      Util.enum.FieldType.Dropdown,
      Util.enum.FieldType.DateTimeField,
      Util.enum.FieldType.DateField,
      Util.enum.FieldType.TimeField
    ];

    return (
      <form className="form-admin-task-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('type')}
        {this.props.getField('label')}
        {typesWithPlaceholder.includes(this.props.formData.type)
          ? this.props.getField('placeholder')
          : null}
        {typesWithMinMax.includes(this.props.formData.type) ? (
          <div className="form-row">
            {this.props.getField('min')}
            {this.props.getField('max')}
          </div>
        ) : null}
        {this.props.getField('htmlHelp')}
        {typesWithOptions.includes(this.props.formData.type) ? (
          <Grid
            title="Options for this field"
            data={this.props.formData.activityTaskFieldOptions}
            toolbarItems={[
              <Button
                size="sm"
                label="Create new option"
                onClick={() => this.openAddTaskFieldOptionModal()}
              />
            ]}
            columns={[
              {
                name: 'ACT_TaskFieldOptionId',
                key: 'activityTaskFieldOptionId',
                sortFn: Util.sort.asNumber
              },
              {
                name: 'Label',
                key: 'label',
                width: '100%'
              },
              {
                key: 'activityTaskFieldOptionId',
                isSortDisabled: true,
                render: (val, record) => {
                  return (
                    <div className="button-container">
                      <Button
                        icon={Util.icon.bin}
                        size="sm"
                        isSecondary={true}
                        onClick={() => this.removeActivityTaskFieldOption(val)}
                      />
                    </div>
                  );
                }
              }
            ]}
          />
        ) : null}
        <div className="button-container">
          <Button label="Save" isSubmit={true} />
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
        </div>
      </form>
    );
  }
}

export default withForm(
  connect(
    null,
    { openModalAction }
  )(AdminActivityTaskFieldEditorForm),
  {
    fields: {
      type: {
        label: 'Type',
        type: Util.enum.FieldType.Dropdown,
        valueProp: 'enumValue',
        displayProp: 'enumName',
        getOptions: () => Util.enum.toList(Util.enum.FieldType)
      },
      label: {
        label: 'Label',
        type: Util.enum.FieldType.Textarea,
        getError: val => {
          if (!validator.isLength(val, { max: 250 }))
            return 'Max 250 characters';
        }
      },
      placeholder: {
        label: 'Placeholder',
        placeholder: 'Leave blank for same as label',
        isOptional: true,
        getError: val => {
          if (!validator.isLength(val, { max: 250 }))
            return 'Max 250 characters';
        }
      },
      min: {
        label: 'Min',
        isOptional: true
      },
      max: {
        label: 'Max',
        isOptional: true
      },
      htmlHelp: {
        label: 'Hint (?)',
        type: Util.enum.FieldType.HtmlEditor,
        isOptional: true
      }
    }
  }
);
