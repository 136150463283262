import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

import Dropdown from '../Dropdown/Dropdown';

export default class AudienceSelect extends Component {
  constructor(props) {
    super(props);

    let possibleAudiences = [];

    if (Util.context.user.getIsLedaAdmin()) {
      possibleAudiences.push({
        tempId: Util.id.tempId.getTempId(),
        audienceType: Util.enum.AudienceType.Global,
        audienceName: 'Global: ALL LEDA',
        audienceIcon: Util.icon.globalUser,
        audienceId: null
      });
    }

    if (Util.context.user.getIsOrganisationAdmin()) {
      possibleAudiences.push({
        tempId: Util.id.tempId.getTempId(),
        audienceType: Util.enum.AudienceType.Organisation,
        audienceName:
          'Organisation: ' + Util.context.user.getOrganisationName(),
        audienceIcon: Util.icon.home,
        audienceId: Util.context.user.getOrganisationId()
      });
    }

    Util.context.user.getTeamsUserCanAdmin().forEach(team => {
      possibleAudiences.push({
        tempId: Util.id.tempId.getTempId(),
        audienceType: Util.enum.AudienceType.Team,
        audienceName: 'Team: ' + team.name,
        audienceIcon: Util.icon.team,
        audienceId: team.teamId
      });
    });

    this.state = {
      possibleAudiences,
      selectedAudience: this.getSelectedAudience(this.props, possibleAudiences)
    };

    this.onAudienceChange = this.onAudienceChange.bind(this);
  }
  getSelectedAudience(props, possibleAudiences) {
    if (props.audienceIsGlobal) {
      return possibleAudiences.find(
        audience => audience.audienceType === Util.enum.AudienceType.Global
      );
    } else if (props.audienceOrganisationId) {
      return possibleAudiences.find(
        audience =>
          audience.audienceType === Util.enum.AudienceType.Organisation &&
          audience.audienceId === props.audienceOrganisationId
      );
    } else if (props.audienceTeamId) {
      return possibleAudiences.find(
        audience =>
          audience.audienceType === Util.enum.AudienceType.Team &&
          audience.audienceId === props.audienceTeamId
      );
    }
  }
  onAudienceChange(value, selectedAudience) {
    this.setState({
      selectedAudience
    });

    //External components get a different object, comprised of isGlobal(bool), teamId(int), organisationId(int)
    //This can be extended in future to take arrays of teamids and organisationids
    let audience = {
      isGlobal: false,
      organisationId: null,
      teamId: null
    };

    switch (selectedAudience.audienceType) {
      case Util.enum.AudienceType.Global:
        audience.isGlobal = true;
        break;
      case Util.enum.AudienceType.Organisation:
        audience.organisationId = selectedAudience.audienceId;
        break;
      case Util.enum.AudienceType.Team:
        audience.teamId = selectedAudience.audienceId;
        break;
      default:
        break;
    }

    if (this.props.onAudienceChange) this.props.onAudienceChange(audience);
  }
  render() {
    if (Util.array.none(this.state.possibleAudiences)) return null;

    return (
      <div
        className={`audience-select custom-input ${
          this.props.isDisabled ? 'disabled' : ''
        }`}
      >
        {this.state.selectedAudience ? (
          <ReactSVG
            className="audience-icon"
            src={this.state.selectedAudience.audienceIcon}
          />
        ) : (
          <div className="empty-icon"></div>
        )}
        <Dropdown
          isDisabled={this.props.isDisabled}
          placeholder="Select audience"
          options={this.state.possibleAudiences}
          valueProp="tempId"
          displayProp="audienceName"
          onChange={this.onAudienceChange}
          isEmptyAllowed={this.props.isEmptyAllowed}
          value={
            this.state.selectedAudience
              ? this.state.selectedAudience.tempId
              : null
          }
        />
      </div>
    );
  }
}
