import {
  NTF_REFRESHED_NOTIFICATIONS,
  NTF_SEEN_NOTIFICATIONS,
  NTF_ACTIONED_NOTIFICATION
} from '../actions/types';

import Util from '../Util';

export const getRecentUserNotificationsAction = () => dispatch => {
  Util.api.post(
    '/api/notification/getRecentUserNotifications',
    {},
    {
      success: userNotifications => {
        dispatch({
          type: NTF_REFRESHED_NOTIFICATIONS,
          userNotifications
        });
      }
    }
  );
};

export const seenUserNotificationsAction = userNotificationIds => dispatch => {
  //clientside optimistic
  dispatch({
    type: NTF_SEEN_NOTIFICATIONS,
    userNotificationIds
  });

  //serverside
  Util.api.post('/api/notification/seenUserNotifications', {
    userNotificationIds
  });
};

export const actionedUserNotificationAction = userNotificationId => dispatch => {
  //clientside optimistic
  dispatch({
    type: NTF_ACTIONED_NOTIFICATION,
    userNotificationId
  });

  //serverside
  Util.api.post('/api/notification/actionedUserNotification', {
    userNotificationId
  });
};
