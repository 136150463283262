import React, { Component } from 'react';
import Util from '../../../../Util';

import DateTimeField from '../../../UI/DateTimeField/DateTimeField';
import DateField from '../../../UI/DateTimeField/DateField/DateField';
import TimeField from '../../../UI/DateTimeField/TimeField/TimeField';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

export default class AdminTestPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testValues: {}
    };

    this.setTestValue = this.setTestValue.bind(this);
  }
  pwaInstall() {
    Util.event.deferredInstallPrompt.prompt();
    Util.event.deferredInstallPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      Util.event.deferredInstallPrompt = null;
    });
  }
  notficationPermission() {
    Notification.requestPermission();
  }
  notificationTest(secondsDelay = 0) {
    setTimeout(() => {
      new Notification('Leda Test Notification', {
        body:
          'This is a test notification from Leda. If you are reading this, the test was successful!',
        vibrate: [100, 100]
      });
    }, secondsDelay * 1000);
  }
  setTestValue(key, val) {
    this.setState({
      testValues: {
        ...this.state.testValues,
        [key]: val
      }
    });
  }
  render() {
    let testDate = new Date(2019, 1, 2);

    return (
      <div className="leda-page-admin-test">
        <AdminLayout pageTitle="Testing">
          <button onClick={this.pwaInstall}>PWA - Install</button>
          <br />
          <button onClick={this.notficationPermission}>
            Notification - Permission
          </button>
          <br />
          <button onClick={this.notificationTest}>Notification - Test</button>
          <br />
          <button onClick={() => this.notificationTest(10)}>
            Notification - Test (10 seconds)
          </button>
          <br />
          <div className="input-tests">
            <h2>Date/Time input test</h2>
            <div className="input-test">
              <div className="input">
                <DateTimeField
                  onChange={val =>
                    this.setTestValue(
                      'datetime',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datetime}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <DateField
                  onChange={val =>
                    this.setTestValue(
                      'date',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.date}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <TimeField onChange={val => this.setTestValue('time', val)} />
              </div>
              <div className="value">{this.state.testValues.time}</div>
            </div>

            <h3>Max ({Util.format.date.shortDateTime(testDate)})</h3>
            <div className="input-test">
              <div className="input">
                <DateTimeField
                  max={testDate}
                  onChange={val =>
                    this.setTestValue(
                      'datetimemax',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datetimemax}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <DateField
                  max={testDate}
                  onChange={val =>
                    this.setTestValue(
                      'datemax',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datemax}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <TimeField
                  max={testDate}
                  onChange={val => this.setTestValue('timemax', val)}
                />
              </div>
              <div className="value">{this.state.testValues.timemax}</div>
            </div>

            <h3>Min ({Util.format.date.shortDateTime(testDate)})</h3>
            <div className="input-test">
              <div className="input">
                <DateTimeField
                  min={testDate}
                  onChange={val =>
                    this.setTestValue(
                      'datetimemin',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datetimemin}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <DateField
                  min={testDate}
                  onChange={val =>
                    this.setTestValue(
                      'datemin',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datemin}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <TimeField
                  min={testDate}
                  onChange={val => this.setTestValue('timemin', val)}
                />
              </div>
              <div className="value">{this.state.testValues.timemin}</div>
            </div>

            <h3>Max now</h3>
            <div className="input-test">
              <div className="input">
                <DateTimeField
                  max={'now'}
                  onChange={val =>
                    this.setTestValue(
                      'datetimemaxnow',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">
                {this.state.testValues.datetimemaxnow}
              </div>
            </div>
            <div className="input-test">
              <div className="input">
                <DateField
                  max={'now'}
                  onChange={val =>
                    this.setTestValue(
                      'datemaxnow',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.datemaxnow}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <TimeField
                  max={'now'}
                  onChange={val => this.setTestValue('timemaxnow', val)}
                />
              </div>
              <div className="value">{this.state.testValues.timemaxnow}</div>
            </div>

            <h3>Min now</h3>
            <div className="input-test">
              <div className="input">
                <DateTimeField
                  min={'now'}
                  onChange={val =>
                    this.setTestValue(
                      'datetimeminnow',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">
                {this.state.testValues.datetimeminnow}
              </div>
            </div>
            <div className="input-test">
              <div className="input">
                <DateField
                  min={'now'}
                  onChange={val =>
                    this.setTestValue(
                      'dateminnow',
                      Util.format.date.shortDateTime(val)
                    )
                  }
                />
              </div>
              <div className="value">{this.state.testValues.dateminnow}</div>
            </div>
            <div className="input-test">
              <div className="input">
                <TimeField
                  min={'now'}
                  onChange={val => this.setTestValue('timeminnow', val)}
                />
              </div>
              <div className="value">{this.state.testValues.timeminnow}</div>
            </div>
          </div>
        </AdminLayout>
      </div>
    );
  }
}
