import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';
import Util from '../../../Util';

// Components
import SiteHeader from '../../UI/SiteHeader/SiteHeader';
import SiteFooter from '../../UI/SiteFooter/SiteFooter';

export default class SiteFoundation extends Component {
  componentDidMount() {
    Util.leadBooster.show();
  }
  componentWillUnmount() {
    Util.leadBooster.hide();
  }
  render() {
    return (
      <div className="leda-foundation-site">
        <Div100vh className="foundation-content">
          <SiteHeader />
          {this.props.children}
          <SiteFooter />
        </Div100vh>
      </div>
    );
  }
}
