import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../../Util';
import ProfilePhoto from '../../ProfilePhoto/ProfilePhoto';

import ContextMenu from '../../ContextMenu/ContextMenu';

//data source: this.props.comment
export default class Comment extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
  }
  onDelete() {
    if (this.props.onDelete) this.props.onDelete(this.props.comment);
  }
  render() {
    return (
      <div className="comment">
        <ProfilePhoto user={this.props.comment.user} />
        <div className="comment-body">
          <h4 className="bold">
            <Link to={Util.route.app.profile(this.props.comment.user.userId)}>
              {this.props.comment.user.fullName}
            </Link>
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: Util.text.toSafeHtml(this.props.comment.text)
            }}
            className="comment-text"
          />
          <h5>
            {Util.format.date.fromNow(this.props.comment.createdAt, ' ago')}
          </h5>
        </div>
        {this.props.comment.user.userId ===
        Util.context.user.getCurrentUserId() ? (
          <ContextMenu
            items={[
              {
                icon: Util.icon.bin,
                label: 'Delete comment',
                onClick: this.onDelete
              }
            ]}
          />
        ) : null}
      </div>
    );
  }
}
