import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppActivitiesLayout from '../../../../Layouts/App/AppActivitiesLayout/AppActivitiesLayout';

// Components
import MyActivitiesView from '../../../../UI/MyActivitiesView/MyActivitiesView';

export default class AppMyActivitiesPage extends Component {
  render() {
    // Automatically find any automatic modals to open up based on url params
    let selectedMyActivityId = parseInt(
      Util.route.getParameterByName('myActivityId'),
      10
    );

    return (
      <div className="leda-page-app-my-activities">
        <AppActivitiesLayout
          pageTitle="My Activities"
          pageSubtitle="Activities help you put specific leadership behaviours into practice. Mark each one as complete when you're done."
          wrapperCls="blue-grey-2"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <MyActivitiesView
                selectedMyActivityId={
                  isNaN(selectedMyActivityId) ? null : selectedMyActivityId
                }
              />
            </div>
          </div>
        </AppActivitiesLayout>
      </div>
    );
  }
}
