import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

// Components
import ArticlePanel from '../../../../UI/ArticlePanel/ArticlePanel';
import FeedbackThumbsBar from '../../../../UI/FeedbackThumbsBar/FeedbackThumbsBar';
import SupplementaryPanel from '../../../../UI/SupplementaryPanel/SupplementaryPanel';
import VideoCarousel from '../../../../UI/VideoCarousel/VideoCarousel';
import SkillLinkMenu from '../../../../UI/SkillLinkMenu/SkillLinkMenu';
import SkillCardsView from '../../../../UI/CardsView/SkillCardsView/SkillCardsView';

//data source: this.props.skillId
export default class AppSkillPage extends Component {
  constructor(props) {
    super(props);

    //State will initially have the light skill from refData and then fetch the full thing
    let skill = Util.context.referenceData.getSkillById(this.props.skillId);

    this.state = {
      isLoading: true,
      skill
    };
  }
  componentDidMount() {
    this.fetchContent(this.props.skillId);
  }
  componentWillReceiveProps(props) {
    if (props.skillId && props.skillId !== this.props.skillId)
      this.fetchContent(props.skillId);
  }
  fetchContent(skillId) {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/skill/getSkillWithContent',
      {
        skillId
      },
      {
        success: skill => {
          this.setState({
            skill
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  render() {
    if (!this.state.skill) return <Redirect to={Util.route.site.error404()} />;

    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (!this.state.skill.content) {
      content = <div className="empty-text">No skill content to display.</div>;
    } else {
      let skillGroup = Util.context.referenceData.getSkillGroupById(
        this.state.skill.skillGroupId
      );

      content = (
        <div className="page-content-cols">
          <div className="page-content-col-main">
            <h1 className="skill-page-title gilroy">
              <img
                alt=""
                className="title-icon"
                src={Util.storage.skillIcon(this.state.skill.urlId)}
              />
              {this.state.skill.name}
            </h1>
            <ArticlePanel
              className="gilroy"
              content={this.state.skill.content}
              isEditable={true}
            />
            {Util.array.any(this.state.skill.content.relatedVideos) ? (
              <SupplementaryPanel
                title={`Related Videos (${this.state.skill.content.relatedVideos.length})`}
              >
                <VideoCarousel
                  videos={this.state.skill.content.relatedVideos}
                />
              </SupplementaryPanel>
            ) : null}
            <FeedbackThumbsBar
              feedbackType={Util.enum.FeedbackType.SkillPageThumbs}
            />
            <div className="skill-group-info">
              {/* <img alt="" src={Util.storage.skillAnimatedIcon(this.state.skill.urlId)} /> */}
              <section className="gilroy">
                <h2>
                  {this.state.skill.name} is one aspect of {skillGroup.name}.
                </h2>
                <p>
                  <b>{skillGroup.name}</b>: {skillGroup.overview}
                </p>
                <p>The following skills also relate to {skillGroup.name}:</p>
              </section>
              <SkillCardsView
                skills={skillGroup.skills.filter(
                  skill => skill.skillId !== this.state.skill.skillId
                )}
                isNeverLargeMode={true}
              />
            </div>
          </div>
          <div className="page-content-col-support xl-margin">
            <SkillLinkMenu openSkillGroupId={this.state.skill.skillGroupId} />
          </div>
        </div>
      );
    }

    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Skills',
        to: Util.route.app.skills()
      },
      {
        label: this.state.skill.name
      }
    ];

    return (
      <div className="leda-page-app-skill">
        <AppExploreLayout
          breadcrumbs={breadcrumbs}
          pageTitle="Skills"
          pageSubtitle="Learn about the key skills great leaders rely on every day."
          pageIcon={Util.icon.skill}
        >
          <div className="leda-container-md">
            <div className="leda-row">{content}</div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
