import React, { Component } from 'react';
import Util from '../../../../Util';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';

import Button from '../../../UI/Button/Button';
import Grid from '../../../UI/Grid/Grid';

export default class AdminContentListPage extends Component {
  render() {
    return (
      <div className="leda-page-admin-content-list">
        <AdminLayout pageTitle="Content List">
          <Grid
            dataUrl="/api/content/getAllContents"
            searchKeys={['name', 'urlId', 'overview']}
            isRefreshVisible={true}
            isCountVisible={true}
            columns={[
              {
                name: 'CON_ContentId',
                key: 'contentId',
                sortFn: Util.sort.asNumber
              },
              {
                name: 'Created at',
                key: 'createdAt',
                sortFn: Util.sort.asDate,
                render: val => Util.format.date.shortDateTime(val)
              },
              {
                name: 'Name',
                key: 'name'
              },
              {
                name: 'UrlId',
                key: 'urlId'
              },
              {
                name: 'Overview',
                key: 'overview',
                width: '50%'
              },
              {
                name: 'IsPublic',
                key: 'isPublic',
                sortFn: Util.sort.asBoolean,
                align: 'center'
              },
              {
                key: 'urlId',
                isSortDisabled: true,
                render: val => {
                  return (
                    <div className="button-container">
                      <Button
                        icon={Util.icon.edit}
                        size="sm"
                        isSecondary={true}
                        to={Util.route.admin.contentEditor(val)}
                      />
                    </div>
                  );
                }
              }
            ]}
          />
        </AdminLayout>
      </div>
    );
  }
}
