import React, { Component } from 'react';
import validator from 'validator';
import ReactSVG from 'react-svg';
import moment from 'moment';
import Util from '../../../Util';

import ButtonInput from '../ButtonInput/ButtonInput';
import Button from '../Button/Button';

export default class UserFeedbackRequestPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      userContacts: [],
      publicFeedbackToken: null,
      isCopiedVisible: false,

      newContactValue: '',
      newContactError: null
    };

    this.emailFieldRef = React.createRef();

    this.onNewContactSubmit = this.onNewContactSubmit.bind(this);
    this.onNewContactChange = this.onNewContactChange.bind(this);
    this.copyPublicFeedbackLink = this.copyPublicFeedbackLink.bind(this);
    this.requestUserContactFeedback = this.requestUserContactFeedback.bind(
      this
    );
    this.deleteUserContact = this.deleteUserContact.bind(this);
  }
  componentDidMount() {
    let getContactsPromise = new Promise((resolve, reject) => {
      Util.api.post(
        '/api/user/getUserContacts',
        {},
        {
          success: userContacts => {
            resolve(userContacts);
          }
        }
      );
    });

    let getPublicFeedbackTokenPromise = new Promise((resolve, reject) => {
      Util.api.post(
        '/api/feedback/getPublicFeedbackToken',
        {},
        {
          success: token => {
            resolve(token);
          }
        }
      );
    });

    Promise.all([getContactsPromise, getPublicFeedbackTokenPromise]).then(
      ([userContacts, publicFeedbackToken]) => {
        this.setState({
          userContacts,
          publicFeedbackToken,
          isLoading: false
        });
      }
    );
  }
  copyPublicFeedbackLink(link) {
    Util.clipboard.copy(link);

    this.setState({
      isCopiedVisible: true
    });

    setTimeout(() => {
      this.setState({
        isCopiedVisible: false
      });
    }, 2000);
  }
  onNewContactChange(e) {
    this.setState({
      newContactValue: e.target.value,
      newContactError: null
    });
  }
  validateForm(formData) {
    let errorMsg = null;

    switch (true) {
      case !validator.isEmail(formData.email):
        errorMsg = 'Enter a valid email address.';
        break;
      default:
        break;
    }

    this.setState({
      newContactError: errorMsg
    });

    return !errorMsg;
  }
  onNewContactSubmit(email) {
    let formData = { email };

    if (!this.validateForm(formData)) return;

    this.setState({
      newContactValue: null
    });

    this.requestUserContactFeedback(formData.email, true);
  }
  deleteUserContact(userContactId) {
    //Optimistic rendering
    this.setState({
      userContacts: this.state.userContacts.filter(
        userContact => userContact.userContactId !== userContactId
      )
    });

    Util.api.post('/api/user/deleteUserContact', { userContactId });
  }
  requestUserContactFeedback(email, isNew) {
    //Optimistic rendering
    if (!isNew) {
      this.setState({
        userContacts: this.state.userContacts.map(userContact => {
          return email === userContact.email
            ? {
                ...userContact,
                lastFeedbackRequestAt: new Date()
              }
            : userContact;
        })
      });
    } else {
      this.emailFieldRef.current.value = '';
    }

    Util.api.post(
      '/api/feedback/requestUserContactFeedback',
      { email },
      {
        success: result => {
          if (result.userContact) {
            let wasReplaced = false;
            let updatedContacts = this.state.userContacts.map(userContact => {
              if (
                userContact.userContactId === result.userContact.userContactId
              ) {
                wasReplaced = true;
                return result.userContact;
              }

              return userContact;
            });

            if (!wasReplaced)
              updatedContacts = [...updatedContacts, result.userContact];

            this.setState({
              userContacts: updatedContacts
            });
          }
        },
        failure: errorMsg => {
          this.setState({
            newContactError: errorMsg
          });
        }
      }
    );
  }
  render() {
    let publicFeedbackLink = `${
      window.location.origin
    }${Util.route.site.userFeedback(this.state.publicFeedbackToken)}`;

    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else {
      content = (
        <div>
          <div className="feedback-section">
            <h2>Request feedback using contact email</h2>
            <p>
              Each contact is sent a unique feedback link. Once their feedback
              has been submitted the link will expire. You can request more
              feedback after 2 weeks.
            </p>
            <ButtonInput
              ref={this.emailFieldRef}
              value={this.state.newContactValue}
              formError={this.state.newContactError}
              placeholder="Enter the email address of a new contact"
              onChange={this.onNewContactChange}
              onSubmit={this.onNewContactSubmit}
              buttonSize="sm"
              buttonLabel="Request feedback"
            />
            <table className="contacts">
              <tbody>
                {this.state.userContacts.map(userContact => {
                  // This is also checked serverside. If changing, please change on server too
                  let datePlusCooldown = moment(
                    userContact.lastFeedbackRequestAt
                  )
                    .add(2, 'weeks')
                    .toDate();
                  let canResend =
                    !userContact.lastFeedbackRequestAt ||
                    datePlusCooldown < new Date();

                  return (
                    <tr className="contact-row" key={userContact.userContactId}>
                      <td className="contact-delete">
                        <ReactSVG
                          className="delete-button"
                          onClick={() =>
                            this.deleteUserContact(userContact.userContactId)
                          }
                          src={Util.icon.bin}
                        />
                      </td>
                      <td className="contact-email">
                        <p>{userContact.email}</p>
                      </td>
                      <td className="contact-last-requested">
                        <p>
                          {userContact.lastFeedbackRequestAt
                            ? Util.format.date.fromNow(
                                userContact.lastFeedbackRequestAt,
                                ' ago',
                                'Requested '
                              )
                            : ''}
                        </p>
                      </td>
                      <td className="contact-button">
                        <Button
                          isDisabled={!canResend}
                          isSubmit={true}
                          label={
                            canResend
                              ? `Request feedback`
                              : `Wait ${Util.format.date.fromNow(
                                  datePlusCooldown
                                )}`
                          }
                          size="sm"
                          onClick={
                            canResend
                              ? () =>
                                  this.requestUserContactFeedback(
                                    userContact.email
                                  )
                              : null
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="feedback-section">
            <h2>Request feedback using public link</h2>
            <p>
              This feedback link can be shared with whoever you like. It is{' '}
              <b>not</b> single-use. Anyone with access to this link can provide
              you with anonymous feedback.
            </p>
            <div className="public-link">
              <input readOnly value={publicFeedbackLink} />
              <Button
                label={this.state.isCopiedVisible ? 'Copied!' : 'Copy link'}
                size="sm"
                onClick={() => this.copyPublicFeedbackLink(publicFeedbackLink)}
              />
            </div>
          </div>
        </div>
      );
    }

    return <div className="user-feedback-request-panel">{content}</div>;
  }
}
