import React, { Component } from 'react';
import Util from '../../../../Util';

import SiteLayout from '../../../Layouts/Site/SiteLayout';

// Components
import Button from '../../../UI/Button/Button';
import ButtonInput from '../../../UI/ButtonInput/ButtonInput';

export default class SiteUnsubscribePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailValue: '',
      isSubmitted: false
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onEmailSubmit = this.onEmailSubmit.bind(this);
  }
  onEmailChange(e) {
    this.setState({
      emailValue: e.target.value
    });
  }
  onEmailSubmit() {
    if (this.state.emailValue) {
      Util.api.post('/api/public/unsubscribe', {
        email: this.state.emailValue.trim()
      });
      this.setState({
        isSubmitted: true
      });
    }
  }
  render() {
    return (
      <div className="leda-page-site-unsubscribe">
        <SiteLayout pageTitle="Unsubscribe">
          <div className="leda-container-sm">
            <div className="leda-row">
              <div className="unsub-panel">
                <h3>We're sorry to see you go!</h3>
                {this.state.isSubmitted ? (
                  <div>
                    <p>
                      Your have been unsubscribed. If you have a moment, please
                      let us know why you decided to unsubscribe.
                    </p>
                    <Button
                      label="Tell us why"
                      onClick={() =>
                        (window.location.href = 'mailto:hello@getleda.com')
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <p>
                      Enter your email address to unsubcribe from all Leda
                      emails.
                    </p>
                    <ButtonInput
                      value={this.state.emailValue}
                      onChange={this.onEmailChange}
                      onSubmit={this.onEmailSubmit}
                      placeholder="Enter you email"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </SiteLayout>
      </div>
    );
  }
}
