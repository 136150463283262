import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

import LedaLogo from '../LedaLogo/LedaLogo';

export default class SiteFooter extends Component {
  render() {
    return (
      <div className="leda-footer">
        <div className="leda-footer-upper">
          <div className="leda-container">
            <div className="leda-row">
              <div className="footer-inner">
                <div className="footer-left">
                  <LedaLogo />
                  <div className="footer-contact">
                    <h5>We want to hear from you.</h5>
                    <h5 className="contact-pre">
                      For questions, enquiries, feedback or help
                    </h5>
                    <h5>
                      please contact us at{' '}
                      <a className="bold" href="mailto:hello@getleda.com">
                        hello@getleda.com
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="footer-right">
                  <div className="links-container">
                    <div className="links">
                      <Link to={Util.route.site.articles()}>Articles</Link>
                    </div>
                    <div className="links">
                      <Link to={Util.route.site.how()}>How it works</Link>
                      <Link to={Util.route.site.platform()}>Our Platform</Link>
                      {/* <Link to={Util.route.site.courses()}>Our Courses</Link> */}
                      <Link to={Util.route.site.how() + '#journeys-strip'}>
                        Our Journeys
                      </Link>
                    </div>
                    <div className="links">
                      <Link to={Util.route.site.tryForFree()}>
                        Sign up for Leda
                      </Link>
                      <Link to={Util.route.site.contact()}>Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="leda-footer-lower">
          <div className="leda-container">
            <div className="leda-row">
              <div className="links">
                <h5>
                  <Link className="bold" to={Util.route.site.privacyPolicy()}>
                    Privacy policy
                  </Link>
                </h5>
                <h5>
                  <Link className="bold" to={Util.route.site.termsConditions()}>
                    Terms and Conditions
                  </Link>
                </h5>
                <h5>Copyright &copy; {new Date().getFullYear()}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
