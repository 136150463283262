import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import AssessmentCard from '../../Card/AssessmentCard/AssessmentCard';
import CardsView from '../../CardsView/CardsView';

// Data source: this.props.dataUrl OR this.props.assessments
export default class AssessmentCardsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: !this.props.assessments,
      assessments: this.props.assessments || []
    };
  }
  componentWillReceiveProps(props) {
    if (this.props.dataUrl !== props.dataUrl)
      this.fetchData(props.dataUrl, props.dataParams);
  }
  componentDidMount() {
    if (this.state.isLoading)
      this.fetchData(this.props.dataUrl, this.props.dataParams);
  }
  fetchData(dataUrl, dataParams = {}) {
    this.setState({
      isLoading: true
    });

    Util.api.post(dataUrl, dataParams, {
      success: assessments => {
        this.setState({
          assessments
        });
      },
      complete: () => this.setState({ isLoading: false })
    });
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (Util.array.none(this.state.assessments)) {
      content = <div className="empty-text">No surveys to display.</div>;
    }

    return (
      <CardsView
        className="assessment-cards-view"
        isNeverLargeMode={this.props.isNeverLargeMode}
      >
        {content}
        {this.state.assessments.map(assessment => (
          <AssessmentCard
            key={assessment.assessmentId}
            assessment={assessment}
            isNeverLargeMode={this.props.isNeverLargeMode}
          />
        ))}
      </CardsView>
    );
  }
}
