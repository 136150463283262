import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';

import LedaLogo from '../../UI/LedaLogo/LedaLogo';

import img_pfc_foreground from '../../../img/app/login/scenic_login/pfc_foreground.png';
import img_hill_background from '../../../img/app/login/scenic_login/hill_background.png';
import img_left_cloud from '../../../img/app/login/scenic_login/left_cloud.png';
import img_left_screen from '../../../img/app/login/scenic_login/left_screen.png';
import img_right_cloud from '../../../img/app/login/scenic_login/right_cloud.png';
import img_right_screen from '../../../img/app/login/scenic_login/right_screen.png';

export default class AuthLayout extends Component {
  render() {
    return (
      <div className="leda-layout-auth">
        <div className="background-items">
          <img src={img_left_cloud} className="cloud" alt="" />
          <img src={img_right_cloud} className="cloud" alt="" />
          <img src={img_hill_background} className="hills-background" alt="" />
          <img src={img_left_screen} className="screen" alt="" />
          <img src={img_right_screen} className="screen" alt="" />
          <Div100vh className="white-mask"></Div100vh>
          <img src={img_pfc_foreground} className="pfc-foreground" alt="" />
        </div>
        <div className="auth-container">
          <LedaLogo />
          {this.props.children}
        </div>
      </div>
    );
  }
}
