import React, { Component } from 'react';
import validator from 'validator';
import Util from '../../../../../Util';
import withForm from '../../withForm';

import Button from '../../../Button/Button';

class AdminOrganisationEditorForm extends Component {
  render() {
    return (
      <form
        className="form-admin-organisation-editor"
        onSubmit={this.props.onSubmit}
      >
        {this.props.getField('name')}
        {this.props.getField('isPrivateUsers')}
        {this.props.getField('licenseType')}
        {this.props.getField('licenseExpiredAt')}
        {this.props.getField('licenseNotes')}
        <div className="button-container">
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
          {this.props.or}
          {this.props.organisationId ? (
            <Button className="button-black" label="Save" isSubmit={true} />
          ) : (
            <Button label="Save and Next" isSubmit={true} />
          )}
          <Button
            className="quick-join"
            size="md"
            isSecondary={true}
            icon={Util.icon.view}
            label="Preview Org"
            onClick={this.props.quickJoin}
          />
        </div>
      </form>
    );
  }
}

export default withForm(AdminOrganisationEditorForm, {
  fields: {
    name: {
      label: 'Organisation name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a team name';
      }
    },
    isPrivateUsers: {
      label: 'Disable team functionality (private mode)',
      type: Util.enum.FieldType.Checkbox
    },
    licenseType: {
      label: 'License type',
      type: Util.enum.FieldType.Dropdown,
      placeholder: 'No license (no access)',
      isOptional: true,
      valueProp: 'enumValue',
      displayProp: 'enumName',
      getOptions: () => Util.enum.toList(Util.enum.LicenseType)
    },
    licenseExpiredAt: {
      label: 'License expiry',
      type: Util.enum.FieldType.DateTimeField,
      isOptional: true
    },
    licenseNotes: {
      label: 'License notes',
      type: Util.enum.FieldType.Textarea,
      placeholder: 'eg. Extended at the request of Gary on 3/5/19',
      isOptional: true
    }
  }
});
