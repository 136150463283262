import React, { Component } from 'react';

// A progress bar component that can either have:
// 1. a 'percent' prop passed in
// 2. a 'numerator' prop and a 'denominator' prop (eg 1/4 - 1 = numerator, 4 = denominator)

export default class ProgressBar extends Component {
  render() {
    let numerator = Array.isArray(this.props.numerator)
      ? this.props.numerator.length
      : this.props.numerator;
    let denominator = Array.isArray(this.props.denominator)
      ? this.props.denominator.length
      : this.props.denominator;

    let percent =
      !isNaN(numerator) && !isNaN(denominator)
        ? (numerator / denominator) * 100
        : this.props.percent || 0;

    return (
      <div className={`progress-bar ${this.props.className || ''}`}>
        <div className="progress-outer">
          <div
            className={`progress-inner ${percent >= 100 ? 'full' : ''}`}
            style={{
              width: percent + '%'
            }}
          ></div>
        </div>
        {this.props.isPercentVisible ? (
          <h4 className="percent grey-3 bold">{percent.toFixed(0)}%</h4>
        ) : null}
      </div>
    );
  }
}
