import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

// Images
import imgRatingStar from '../../../img/icons/rating-star.svg';

export default class FeedbackStars extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    Util.api.post('/api/feedback/submitSystemFeedback', {
      feedbackType: this.props.feedbackType,
      url: Util.route.getCurrent(),
      rating: parseInt(e.target.value, 10),

      activityId: this.props.activityId,
      journeyId: this.props.journeyId
    });

    if (this.props.onClick) this.props.onClick();
  }
  render() {
    return (
      <div className="feedback-stars">
        <p className="label-container">
          {this.props.label || 'Leave a rating'}
        </p>
        <div className="star-input-container">
          <input
            type="radio"
            onChange={this.onChange}
            id="star5"
            name="rating"
            value="5"
          />
          <label htmlFor="star5">
            <ReactSVG src={imgRatingStar} className="star-icon" />
          </label>
          <input
            type="radio"
            onChange={this.onChange}
            id="star4"
            name="rating"
            value="4"
          />
          <label htmlFor="star4">
            <ReactSVG src={imgRatingStar} className="star-icon" />
          </label>
          <input
            type="radio"
            onChange={this.onChange}
            id="star3"
            name="rating"
            value="3"
          />
          <label htmlFor="star3">
            <ReactSVG src={imgRatingStar} className="star-icon" />
          </label>
          <input
            type="radio"
            onChange={this.onChange}
            id="star2"
            name="rating"
            value="2"
          />
          <label htmlFor="star2">
            <ReactSVG src={imgRatingStar} className="star-icon" />
          </label>
          <input
            type="radio"
            onChange={this.onChange}
            id="star1"
            name="rating"
            value="1"
          />
          <label htmlFor="star1">
            <ReactSVG src={imgRatingStar} className="star-icon" />
          </label>
        </div>
      </div>
    );
  }
}
