import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import Util from '../../../Util';

//this.props.step
class JourneyStepStatus extends Component {
  render() {
    let userJourney = this.props.userJourneys.find(
      userJourney => userJourney.journeyId === this.props.step.journeyId
    );

    let iconStatus = Util.context.referenceData.getJourneyStepStatus(
      this.props.step,
      userJourney,
      this.props.myActivities,
      this.props.userProgressActivity
    );
    let iconImage = null;
    let iconLabel = null;

    switch (iconStatus) {
      case 'completed':
        iconLabel = this.props.finishedText || (
          <h5 className="bold">Finished</h5>
        );
        iconImage = Util.icon.tick;
        break;
      case 'inprogress':
        iconLabel = this.props.inProgressText || (
          <h5 className="bold">In progress</h5>
        );
        iconImage = Util.icon.arrow.right;
        break;
      case 'unstarted':
        iconLabel = this.props.readyToStartText || (
          <h5 className="bold">Ready to start</h5>
        );
        break;
      default:
    }

    return (
      <div
        className={`journey-activity-status ${this.props.className || ''}`}
        title={`Step ${this.props.step.ordinal}`}
      >
        {this.props.isIconHidden ? null : (
          <div className={`icon ${iconStatus}`}>
            {iconImage ? (
              <ReactSVG className="icon-image" src={iconImage} />
            ) : null}
          </div>
        )}
        {this.props.isLabelHidden ? null : iconLabel}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys,
  myActivities: state.activityState.myActivities,
  userProgressActivity: state.userState.userProgress.activity
});

export default connect(
  mapStateToProps,
  {}
)(JourneyStepStatus);
