import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

export default class FeedbackThumbsButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    Util.api.post('/api/feedback/submitSystemFeedback', {
      feedbackType: this.props.feedbackType,
      rating: this.props.rating,
      url: Util.route.getCurrent(),
      message: this.props.message || null
    });

    if (this.props.onClick) this.props.onClick();
  }
  render() {
    return (
      <div
        onClick={this.onClick}
        className={'feedback-button ' + (this.props.rating < 0 ? 'down' : 'up')}
      >
        <ReactSVG className="thumb-icon" src={Util.icon.thumb} />
      </div>
    );
  }
}
