import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Util from '../../../../../Util';

//Layout
import AppTeamLayout from '../../../../Layouts/App/AppTeamLayout/AppTeamLayout';

import UserList from '../../../../UI/UserList/UserList';

export default class AppTeamMembersPage extends Component {
  render() {
    let team = Util.context.user.getTeamById(this.props.teamId);
    if (!team) return <Redirect to={Util.route.app.home()} />;

    let breadcrumbs = [
      {
        label: team.name,
        to: Util.route.app.team(team.teamId)
      },
      {
        label: 'Team Members'
      }
    ];

    return (
      <div className="leda-page-app-team-settings">
        <AppTeamLayout
          pageTitle={`Members of ${team.name}`}
          breadcrumbs={breadcrumbs}
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <UserList
                dataUrl="/api/organisation/getTeamUsers"
                dataParams={{ teamId: team.teamId }}
              />
            </div>
          </div>
        </AppTeamLayout>
      </div>
    );
  }
}
