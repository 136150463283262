import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

export default class AppLayout extends Component {
  render() {
    return (
      <div className="leda-layout-app">
        <div className="layout-content">
          {!!this.props.pageTitle ? (
            <div className="page-title">
              <div className="leda-container-md">
                <div className="leda-row">
                  <div className="title">
                    {this.props.pageIconElement ? (
                      this.props.pageIconElement
                    ) : this.props.pageIcon ? (
                      <ReactSVG
                        className="title-icon"
                        src={this.props.pageIcon}
                      />
                    ) : null}
                    <h1 className="gilroy">{this.props.pageTitle}</h1>
                  </div>
                  {this.props.pageSubtitle ? (
                    <p className="subtitle gilroy">{this.props.pageSubtitle}</p>
                  ) : null}
                  {this.props.breadcrumbs ? (
                    <div className="breadcrumbs">
                      {this.props.breadcrumbs.map((breadcrumb, idx) => {
                        return (
                          <h4 className="breadcrumb" key={idx}>
                            {breadcrumb.to ? (
                              <Link to={breadcrumb.to}>{breadcrumb.label}</Link>
                            ) : (
                                <span>{breadcrumb.label}</span>
                              )}
                            {idx < this.props.breadcrumbs.length - 1 ? (
                              <span className="gt">></span>
                            ) : null}
                          </h4>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={`page-container ${
              this.props.wrapperCls
                ? `leda-wrapper ${this.props.wrapperCls}`
                : ''
              }`}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
