import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import ActivityCardsView from '../CardsView/ActivityCardsView/ActivityCardsView';
import StoryCardsView from '../CardsView/StoryCardsView/StoryCardsView';
import SupplementaryPanel from '../SupplementaryPanel/SupplementaryPanel';
import AssessmentCardsView from '../CardsView/AssessmentCardsView/AssessmentCardsView';
import GameCardsView from '../CardsView/GameCardsView/GameCardsView';
import SkillLinkItem from '../SkillLinkItem/SkillLinkItem';

//data source: this.props.activities, stories, assessments, games, skills
export default class RelatedSidebarPanels extends Component {
  render() {
    return (
      <div className="related-sidebar-panels">
        {Util.array.any(this.props.activities || []) ? (
          <SupplementaryPanel
            title="Related Activities"
            subtitle="Apply what you're learning"
            icon={Util.icon.activity}
          >
            <ActivityCardsView
              activities={this.props.activities.slice(0, 2)}
              isNeverLargeMode={true}
            />
          </SupplementaryPanel>
        ) : null}
        {Util.array.any(this.props.stories || []) ? (
          <SupplementaryPanel
            title="Related Stories"
            subtitle=""
            icon={Util.icon.story}
          >
            <StoryCardsView
              stories={this.props.stories.slice(0, 2)}
              isNeverLargeMode={true}
            />
          </SupplementaryPanel>
        ) : null}
        {Util.array.any(this.props.games || []) ? (
          <SupplementaryPanel
            className="no-bg"
            title="Related Games"
            subtitle=""
            icon={Util.icon.assessment}
          >
            <GameCardsView games={this.props.games} isNeverLargeMode={true} />
          </SupplementaryPanel>
        ) : null}
        {Util.array.any(this.props.assessments || []) ? (
          <SupplementaryPanel
            className="no-bg"
            title="Related Assessments"
            subtitle=""
            icon={Util.icon.assessment}
          >
            <AssessmentCardsView
              assessments={this.props.assessments}
              isNeverLargeMode={true}
            />
          </SupplementaryPanel>
        ) : null}
        {Util.array.any(this.props.skills || []) ? (
          <SupplementaryPanel
            className="no-bg"
            title="Related Skills"
            subtitle=""
            icon={Util.icon.skill}
          >
            {this.props.skills.map(skill => (
              <SkillLinkItem key={skill.skillId} skill={skill} />
            ))}
          </SupplementaryPanel>
        ) : null}
      </div>
    );
  }
}
