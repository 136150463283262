import React, { Component } from 'react';

export default class CardsView extends Component {
  render() {
    return (
      <div
        className={`cards-view ${this.props.className || ''} ${
          this.props.isNeverLargeMode ? 'never-big-card' : ''
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}
