import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '.././../../Util';

// Components
import ContentCardsView from '../CardsView/ContentCardsView/ContentCardsView';
import Card from '../Card/Card';
import ReadingTimeLabel from '../ReadingTimeLabel/ReadingTimeLabel';
import Button from '../Button/Button';

//data source: this.props.contentId
export default class ArticlesBrowseView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      contents: [],

      offset: 0,
      limit: 9,
      isNoMoreData: false
    };

    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.fetchData(this.props.tagId);
  }
  fetchData(tagId) {
    let isFirstRequest = this.state.offset === 0;
    let limit = isFirstRequest ? this.state.limit + 1 : this.state.limit;

    //First request takes 1 extra for featured one
    Util.api.post(
      '/api/public/getPublicContents',
      {
        tagId: this.props.tagId,
        limit,
        offset: this.state.offset
      },
      {
        success: contents => {
          this.setState({
            contents: [...this.state.contents, ...contents],
            offset: this.state.offset + limit,
            isNoMoreData: contents.length < limit
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  render() {
    if (this.state.isLoading) {
      return <div className="loader"></div>;
    } else if (Util.array.any(this.state.contents)) {
      let featuredContent = this.state.contents[0];
      let remainingContent = this.state.contents.slice(
        1,
        this.state.contents.length + 1
      );

      return (
        <div className="articles-browse-view">
          {featuredContent ? (
            <Card
              className="featured-content-card"
              to={Util.route.site.articlesContent(featuredContent.urlId)}
            >
              <div
                className="content-image"
                style={{
                  backgroundImage: `url('${Util.storage.blob(
                    featuredContent.featureImageBlobId
                  )}')`
                }}
              ></div>
              <div className="card-info">
                <div className="card-body">
                  <h4 className="gilroy">{featuredContent.name}</h4>
                </div>
                <div className="card-footer">
                  <ReadingTimeLabel htmlContent={featuredContent.htmlContent} />
                  <div className="flex-spacer"></div>
                  <ReactSVG
                    className="footer-icon svg-button"
                    src={Util.icon.arrow.right}
                  />
                </div>
              </div>
            </Card>
          ) : null}
          {Util.array.any(this.state.contents) ? (
            <ContentCardsView contents={remainingContent} />
          ) : null}
          <div className="more-articles">
            {this.state.isNoMoreData ? null : (
              <Button
                isSecondary={true}
                label="Load more articles"
                onClick={this.fetchData}
              />
            )}
          </div>
        </div>
      );
    } else {
      return <div className="empty-text">No articles to display.</div>;
    }
  }
}
