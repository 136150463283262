import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

//Components
import AssessmentCardsView from '../../../../UI/CardsView/AssessmentCardsView/AssessmentCardsView';

export default class AppAssessmentsPage extends Component {
  render() {
    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Assessments'
      }
    ];

    return (
      <div className="leda-page-app-assessments">
        <AppExploreLayout
          pageTitle="Assessments"
          pageSubtitle="Test your skills with our self-assessments, and pinpoint your potential to become a stronger leader."
          pageIcon={Util.icon.assessment}
          breadcrumbs={breadcrumbs}
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <AssessmentCardsView
                assessments={Util.context.referenceData.getAssessments()}
              />
            </div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
