import React, { Component } from 'react';
import Util from '../../../Util';

// Components
import Survey from './Survey/Survey';

export default class Assessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      assessment: null
    };
  }
  componentDidMount() {
    this.fetchContent(this.props.assessmentId);
  }
  componentWillReceiveProps(props) {
    if (props.assessmentId && props.assessmentId !== this.props.assessmentId)
      this.fetchContent(props.assessmentId);
  }
  fetchContent(assessmentId) {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/assessment/getAssessmentById',
      {
        assessmentId
      },
      {
        success: assessment => {
          this.setState({
            assessment
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (!this.state.assessment) {
      content = <div className="empty-text">No assessment to display.</div>;
    } else {
      switch (this.state.assessment.type) {
        case Util.enum.AssessmentType.Survey:
          content = <Survey assessment={this.state.assessment} />;
          break;
        default:
          content = <div>Invalid assessment.</div>;
          break;
      }
    }

    return <div className="assessment">{content}</div>;
  }
}
