import React, { Component } from 'react';
import { connect } from 'react-redux';

import JourneyStepStatus from '../JourneyStepStatus/JourneyStepStatus';

//journeyId
class JourneyStepStatusSummary extends Component {
  render() {
    let userJourney = this.props.userJourneys.find(
      userJourney => userJourney.journeyId === this.props.journeyId
    );
    if (!userJourney) return null;

    return (
      <div className="journey-step-status-summary">
        {userJourney.steps.map(step => (
          <JourneyStepStatus
            key={step.stepId}
            step={step}
            isLabelHidden={true}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(
  mapStateToProps,
  {}
)(JourneyStepStatusSummary);
