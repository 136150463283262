import React, { Component } from 'react';
import { connect } from 'react-redux';
import { completedGameAction } from '../../../../actions/userActions';
import Util from '../../../../Util';

// Components
import ProgressBar from '../../ProgressBar/ProgressBar';
import SupplementaryPanel from '../../SupplementaryPanel/SupplementaryPanel';
import ActivityCardsView from '../../CardsView/ActivityCardsView/ActivityCardsView';
import Button from '../../Button/Button';

// Images
import img_bad_choice_detail from '../../../../img/game/bad_choice_detail.png';
import img_good_choice_detail from '../../../../img/game/good_choice_detail.png';

class DialogueGame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      game: null,

      // Active state (what is happening in the game right now)
      isLoading: true,
      activeStage: null,
      activeChoices: null,
      activeImageFocus: null,
      activeImageBackground: null,
      activeResults: null,

      // Tracked state (monitors progress through the game)
      stageIdHistory: [],
      skillPoints: {
        //eg. 45: 2 (skill ID 45 with 2 points)
      }
    };

    this.restart = this.restart.bind(this);
    this.quit = this.quit.bind(this);
    this.nextStage = this.nextStage.bind(this);
    this.changeStage = this.changeStage.bind(this);
    this.makeChoice = this.makeChoice.bind(this);
    this.viewResults = this.viewResults.bind(this);
  }
  componentDidMount() {
    this.loadGameAssets(this.props.game);
  }
  componentDidUpdate() {
    if (this.refs.speech) this.animateSpeech(this.refs.speech);
  }
  loadGameAssets(game) {
    // Loads all focus images and background images into the body so there is no flickering during game

    let imgCount = 0;
    let imgLoadedCount = 0;

    let imgLoaded = () => {
      if (imgCount === imgLoadedCount) {
        this.setState({
          game: game,
          isLoading: false
        });
      }
    };

    let imgLoadFn = imgSrc => {
      imgCount++;

      let img = new Image();
      img.style.display = 'none';
      document.body.append(img);
      img.onload = () => {
        imgLoadedCount++;
        imgLoaded();
      };
      img.src = imgSrc;
    };

    game.stages.forEach(stage => {
      if (stage.imageBackground)
        imgLoadFn(Util.storage.gameAsset(stage.imageBackground, game.urlId));
      if (stage.imageFocus)
        imgLoadFn(Util.storage.gameAsset(stage.imageFocus, game.urlId));
    });

    // Call imgLoaded once just in case the game has no images to load at all
    imgLoaded();
  }
  restart() {
    // Reset tracking state
    this.setState({
      stageIdHistory: [],
      skillPoints: {},
      activeResults: null,
      activeStage: null,
      activeChoices: null,
      activeImageFocus: null,
      activeImageBackground: null
    });

    // Go back to start stage
    this.changeStage(this.state.game.startStageId);
  }
  quit() {
    if (this.props.onQuit) this.props.onQuit();
  }
  animateSpeech(speechRef) {
    let fullString = speechRef.textContent;
    let charsVisible = 0;
    speechRef.textContent = '';
    speechRef.style.visibility = 'visible';

    let typeChar = () => {
      // If the speechRef el is still on screen, the chars visible is still less than the full string, and the
      // fullString startsWith the content already in speechRef (to prevent the typing from continuing/overlapping with another animation if the user skips ahead)
      if (
        speechRef &&
        charsVisible < fullString.length &&
        fullString.startsWith(speechRef.textContent)
      ) {
        //Type the next letter
        speechRef.textContent += fullString.charAt(charsVisible);
        charsVisible++;
        setTimeout(typeChar, 20);
      }
    };

    typeChar();
  }
  makeChoice(choice) {
    // If this choice has associated choiceSkills to assign points for, and we haven't been on this stage before (skills only assigned by first choice)
    if (
      Util.array.any(choice.choiceSkills) &&
      !this.state.stageIdHistory.includes(this.state.activeStage.stageId)
    ) {
      // Copy the existing skillPoints object
      let skillPointsCopy = { ...this.state.skillPoints };

      choice.choiceSkills.forEach(choiceSkill => {
        skillPointsCopy[choiceSkill.skillId]
          ? (skillPointsCopy[choiceSkill.skillId] += choiceSkill.value)
          : (skillPointsCopy[choiceSkill.skillId] = choiceSkill.value);
      });

      this.setState({
        skillPoints: skillPointsCopy
      });
    }

    this.changeStage(choice.nextStageId);
  }
  nextStage() {
    if (this.state.activeStage.nextStageId)
      this.changeStage(this.state.activeStage.nextStageId);
  }
  changeStage(stageId) {
    let updatedState = {};

    let newStage = Util.array.firstMatch(
      this.state.game.stages,
      stageId,
      'stageId'
    );
    let newStageImageBg = Util.storage.gameAsset(
      newStage.imageBackground,
      this.state.game.urlId
    );
    let newStageImageFocus = Util.storage.gameAsset(
      newStage.imageFocus,
      this.state.game.urlId
    );

    // If background has changed, add this to the updatedState
    if (
      !!newStageImageBg &&
      this.state.activeImageBackground !== newStageImageBg
    ) {
      updatedState.activeImageBackground = newStageImageBg;
    }

    // If focus image has changed, add this to the updatedState
    if (
      !!newStageImageFocus &&
      this.state.activeImageFocus !== newStageImageFocus
    ) {
      updatedState.activeImageFocus = newStageImageFocus;
    }

    // If choiceShuffle is on and we haven't been on this stage before, shuffle those choices.
    updatedState.activeChoices =
      this.state.game.isChoiceShuffleOn &&
      !this.state.stageIdHistory.includes(stageId)
        ? Util.array.shuffle(newStage.choices)
        : newStage.choices;

    updatedState.activeStage = newStage;

    // Add the active (soon to be previous) stageId to the stageIdHistory array
    if (this.state.activeStage) {
      updatedState.stageIdHistory = this.state.stageIdHistory.concat(
        this.state.activeStage.stageId
      );
    }

    this.setState(updatedState);

    Util.analytics.track('GameProgress', {
      gameId: this.state.game.gameId,
      fromStageId: this.state.activeStage
        ? this.state.activeStage.stageId
        : null,
      toStageId: newStage.stageId
    });
  }
  viewResults() {
    this.setState({
      activeResults: this.getResults(),
      activeStage: null
    });

    Util.analytics.track('GameFinished', {
      gameId: this.state.game.gameId
    });

    this.props.completedGameAction(this.state.game.gameId);
  }
  getResults() {
    let results = {
      activities: [],
      feedbacks: []
    };

    this.state.game.relatedSkills.forEach(skill => {
      let resultForSkill = this.state.skillPoints[skill.skillId] || 0;

      //1. Feedback results
      // Find all matching feedbacks for this result
      let matchedFeedbacks = this.state.game.gameFeedbacks.filter(feedback => {
        if (feedback.skillId !== skill.skillId) return false;
        return Util.operator.evaluate(
          feedback.operator,
          resultForSkill,
          feedback.value
        );
      });
      results.feedbacks = results.feedbacks.concat(
        Util.operator
          .filterToMaxAndMins(matchedFeedbacks)
          .map(fb => fb.htmlContent)
      );

      //2. Activities
      // Find all matching activities for this result
      let matchedActivities = this.state.game.gameActivities.filter(
        activity => {
          if (activity.skillId !== skill.skillId) return false;
          return Util.operator.evaluate(
            activity.operator,
            resultForSkill,
            activity.value
          );
        }
      );
      results.activities = results.activities.concat(
        Util.operator
          .filterToMaxAndMins(matchedActivities)
          .map(act => act.activity)
      );
    });

    return results;
  }
  render() {
    let gameContainerContent = null;

    if (this.state.isLoading) {
      // Loading screen
      gameContainerContent = <div className="loader"></div>;
    } else if (this.state.activeResults) {
      // Results Screen
      gameContainerContent = (
        <div className="ui-container">
          <div className="ui-window-container">
            <div className="ui-window results-window">
              <h1 className="ui-window-title">Your Results</h1>
              <div className="result-skills">
                {this.state.game.relatedSkills.map(skill => (
                  <div key={skill.skillId} className="skill-result">
                    <h4>{skill.name}</h4>
                    <ProgressBar
                      numerator={this.state.skillPoints[skill.skillId] || 0}
                      denominator={
                        Util.array.firstMatch(
                          this.state.game.relatedSkills,
                          skill.skillId,
                          'skillId'
                        ).maxPoints
                      }
                    />
                  </div>
                ))}
              </div>
              {Util.array.any(this.state.activeResults.activities) ? (
                <SupplementaryPanel
                  title="Recommended Activities"
                  subtitle={'Based on your results'}
                  icon={Util.icon.activity}
                >
                  <ActivityCardsView
                    isNeverLargeMode={true}
                    activities={this.state.activeResults.activities}
                  />
                </SupplementaryPanel>
              ) : null}
              <div className="result-feedbacks">
                {this.state.activeResults.feedbacks.map((feedback, idx) => (
                  <div
                    key={idx}
                    className="feedback-item"
                    dangerouslySetInnerHTML={{ __html: feedback }}
                  ></div>
                ))}
              </div>
              <div className="result-buttons">
                <Button label="Finish" onClick={this.quit} />
                <Button label="Restart" onClick={this.restart} />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (!this.state.activeStage) {
      // Start Screen
      gameContainerContent = (
        <div className="ui-container">
          <div className="ui-window-container">
            <div className="ui-window start-window">
              <h1 className="ui-window-title">{this.state.game.name}</h1>
              <div className="game-overview">{this.state.game.overview}</div>
              <Button
                label="Start"
                onClick={() => this.changeStage(this.state.game.startStageId)}
              />
            </div>
          </div>
        </div>
      );
    } else {
      // All this stuff is actual game stuff

      // The div that holds the dialoguecontent
      let getDialogueContent = ref =>
        this.state.activeStage.htmlContent ? (
          <div
            ref={ref || null}
            className="dialogue-content"
            dangerouslySetInnerHTML={{
              __html: this.state.activeStage.htmlContent
            }}
          ></div>
        ) : null;

      // The button that progresss to next stage
      let getNextBtn = (btnText = 'Continue') => {
        if (this.state.activeStage.nextStageId) {
          return <Button onClick={this.nextStage} label={btnText} />;
        } else if (Util.array.none(this.state.activeStage.choices)) {
          //If there is no next stage, and there is no choices, this must be the final stage.
          return <Button onClick={this.viewResults} label="View results" />;
        } else {
          // If there IS choices, don't show a next button (they must select a choice)
          return null;
        }
      };

      let getUi = () => {
        switch (this.state.activeStage.type) {
          case Util.enum.DialogueStageType.SpeechNPC:
            return (
              <div className="ui-container speech-npc" onClick={this.nextStage}>
                <div className="ui-focus">
                  {getDialogueContent('speech')}
                  {getNextBtn()}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.SpeechPlayer:
            return (
              <div
                className="ui-container speech-player"
                onClick={this.nextStage}
              >
                <div className="ui-focus">
                  {getDialogueContent('speech')}
                  {getNextBtn()}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.ThoughtPlayer:
            return (
              <div
                className="ui-container thought-player"
                onClick={this.nextStage}
              >
                <div className="ui-focus">
                  {getDialogueContent('speech')}
                  {getNextBtn()}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.ChoiceGood:
            return (
              <div
                className="ui-container choice-good"
                onClick={this.nextStage}
                style={{ backgroundImage: `url(${img_good_choice_detail})` }}
              >
                <div className="ui-focus">
                  {getDialogueContent()}
                  {getNextBtn()}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.ChoiceBad:
            return (
              <div
                className="ui-container choice-bad"
                onClick={this.nextStage}
                style={{ backgroundImage: `url(${img_bad_choice_detail})` }}
              >
                <div className="ui-focus">
                  {getDialogueContent()}
                  {getNextBtn('Try again')}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.Intro:
            return (
              <div className="ui-container intro" onClick={this.nextStage}>
                <div className="ui-focus">
                  {getDialogueContent()}
                  {getNextBtn()}
                </div>
              </div>
            );

          case Util.enum.DialogueStageType.Choice:
          default:
            return (
              <div className="ui-container decision">
                <div className="ui-focus">
                  {getDialogueContent()}
                  {Util.array.none(this.state.activeChoices) ? null : (
                    <div className="choices-container">
                      {this.state.activeChoices.map(choice => (
                        <div
                          key={choice.choiceId}
                          className="choice-option"
                          onClick={() => this.makeChoice(choice)}
                          dangerouslySetInnerHTML={{
                            __html: choice.htmlContent
                          }}
                        ></div>
                      ))}
                    </div>
                  )}
                  {getNextBtn()}
                </div>
              </div>
            );
        }
      };

      gameContainerContent = (
        <div
          className="game-scene"
          style={{
            backgroundImage: `url(${this.state.activeImageBackground})`
          }}
        >
          <div className="image-focus-container">
            <div
              className="image-focus"
              style={{ backgroundImage: `url(${this.state.activeImageFocus})` }}
            ></div>
          </div>
          {getUi()}
        </div>
      );
    }

    return (
      <div className="dialogue-game-container">{gameContainerContent}</div>
    );
  }
}

export default connect(
  null,
  { completedGameAction }
)(DialogueGame);
