import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

import VideoPlayer from '../VideoPlayer/VideoPlayer';
import JourneyBadge from '../JourneyBadge/JourneyBadge';
import Button from '../Button/Button';

//this.props.url
export default class PostLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      url: this.props.url,
      urlObject: new URL(this.props.url)
    };
  }
  componentWillReceiveProps(props) {
    if (props.url !== this.state.url) this.setLinkData(props.url);
  }
  componentDidMount() {
    this.setLinkData(this.state.url);
  }
  setLinkData(url) {
    //Must be
    let urlObject = new URL(url);

    this.setState({
      isLoading: true,

      urlObject,

      title: '',
      description: '',
      image: null,
      videoUrl: null,

      callToActionLabel: ''
    });

    let isInternal = Util.route.isInternal(url);

    // All internal links will not go well trying to use /getOpenGraphData.
    // Bigger companies and social media sites have their own dedicated crawlers, and our use of 'Prerender' allows them to crawl correctly
    // We don't have a crawler. and the og request below does it on the fly, without the use of Prerender.
    // So we must customize and treat all internal links differently.
    if (isInternal) {
      let possibleUrlId = Util.route.getPossibleUrlId(url);

      switch (true) {
        case Util.route.app.journey(possibleUrlId) === urlObject.pathname:
          // eg. /app/journey/know-yourself-better
          let journey = Util.context.referenceData.getJourneyById(
            possibleUrlId
          );

          if (journey) {
            this.setState({
              isLoading: false,

              title: journey.name,
              description: journey.overview,
              image: <JourneyBadge journey={journey} />,
              callToActionLabel: 'Go to Journey'
            });
          }
          break;
        case Util.route.site.articlesContent(possibleUrlId) ===
          urlObject.pathname:
          // eg. /app/article/what-is-leadership-really
          //One of the few where we have to post a request, despite being internal
          Util.api.post(
            '/api/content/getContentPreviewById',
            {
              contentId: possibleUrlId
            },
            {
              success: contentPreview => {
                this.setState({
                  isLoading: false,

                  title: contentPreview.name,
                  description: contentPreview.overview,
                  image: contentPreview.featureImageBlobId ? (
                    <img
                      src={Util.storage.blob(contentPreview.featureImageBlobId)}
                      alt="Preview Content"
                    />
                  ) : null,
                  callToActionLabel: 'Go to Article'
                });
              }
            }
          );
          break;
        default:
      }
    } else {
      //External link, style ok
      Util.api.post(
        '/api/post/getOpenGraphData',
        { url: url },
        {
          success: ogData => {
            //Image
            if (ogData.ogImage && ogData.ogImage.url) {
              this.setState({
                image: <img src={ogData.ogImage.url} alt="None" />
              });
            }

            //Video
            if (
              ogData.ogVideo &&
              ogData.ogVideo.url &&
              !ogData.ogVideo.url.includes('embed')
            ) {
              //If the videoUrl has the word 'embed' in it, it's probably not going to play nice with our videoplayer
              this.setState({
                videoUrl: ogData.ogVideo.url
              });
            } else if (ogData.twitterPlayer && ogData.twitterPlayer.url) {
              // Wistia/Dailymotion sends back an embedded twitterplayer url, it won't work with our player, but if the ogData
              // contains this object, we can assume the URL is a wistia Url. This will probs break other links.
              this.setState({
                videoUrl: url
              });
            }

            this.setState({
              title: ogData.ogTitle,
              description: ogData.ogDescription
            });
          },
          complete: () => this.setState({ isLoading: false })
        }
      );
    }
  }
  render() {
    if (this.state.isLoading) return null;

    //Lots of ternarys, very conditional

    let content = (
      <div className="post-link-inner">
        {this.state.videoUrl ? (
          <VideoPlayer videoUrl={this.state.videoUrl} />
        ) : null}
        {this.state.image ||
          this.state.title ||
          this.state.description ||
          this.state.callToActionLabel ? (
            <div className="post-link-details">
              {this.state.image ? (
                <div className="post-link-image">{this.state.image}</div>
              ) : null}
              {this.state.title || this.state.description ? (
                <div className="post-link-info">
                  {this.state.title ? (
                    <p className="bold">{this.state.title}</p>
                  ) : null}
                  {this.state.description ? (
                    <h4>{this.state.description}</h4>
                  ) : null}
                </div>
              ) : null}
              {this.state.callToActionLabel &&
                Util.route.isInternal(this.state.url) ? ( //only works with internal links at the moment
                  <Button size="sm" label={this.state.callToActionLabel} /> // no link because the entire strip is a link
                ) : null}
            </div>
          ) : null}
      </div>
    );

    return Util.route.isInternal(this.state.url) ? (
      <Link className="post-link" to={this.state.urlObject.pathname}>
        {content}
      </Link>
    ) : (
        <a
          className="post-link"
          href={this.state.url}
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          {content}
        </a>
      );
  }
}
