import React, { Component } from 'react';
import Util from '../../../../../Util';
import AppHelpLayout from '../../../../Layouts/App/AppHelpLayout/AppHelpLayout';
//Components
import Accordion from '../../../../UI/Accordion/Accordion';
import ArticlePanel from '../../../../UI/ArticlePanel/ArticlePanel';
import Button from '../../../../UI/Button/Button';

export default class AppHelpPage extends Component {
  render() {
    return (
      <div className="leda-page-app-help">
        <AppHelpLayout
          pageTitle="Help"
          pageSubtitle="How-to-Leda, FAQ and support"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <div className="help-accordions">
                <Accordion head="Admins">
                  <ArticlePanel contentId="leda-help-admin" />
                </Accordion>
                <Accordion head="Assessments">
                  <ArticlePanel contentId="leda-help-assessments" />
                </Accordion>
                <Accordion head="Badges">
                  <ArticlePanel contentId="leda-help-badges" />
                </Accordion>
                <Accordion head="Bug reporting">
                  <ArticlePanel contentId="leda-help-bug-reports" />
                </Accordion>
                <Accordion head="Course Plan">
                  <ArticlePanel contentId="leda-help-course-plan" />
                </Accordion>
                <Accordion head="Creating posts for team">
                  <ArticlePanel contentId="leda-help-creating-posts-for-team" />
                </Accordion>
                <Accordion head="Explore">
                  <ArticlePanel contentId="leda-help-explore" />
                </Accordion>
                <Accordion head="Games">
                  <ArticlePanel contentId="leda-help-games" />
                </Accordion>
                {Util.context.feature.getIsFeedbackEnabled() ? (
                  <Accordion head="Feedback">
                    <ArticlePanel contentId="leda-help-user-feedback" />
                  </Accordion>
                ) : null}
                <Accordion head="Journeys">
                  <ArticlePanel contentId="leda-help-journeys" />
                </Accordion>
                <Accordion head="Journey Summary">
                  <ArticlePanel contentId="leda-help-journey-summary" />
                </Accordion>
                <Accordion head="Leadership questions">
                  <ArticlePanel contentId="leda-help-leadership-questions" />
                </Accordion>
                <Accordion head="Leda rewards">
                  <ArticlePanel contentId="leda-help-leda-rewards" />
                </Accordion>
                <Accordion head="Notification settings">
                  <ArticlePanel contentId="leda-help-notification-settings" />
                </Accordion>
                <Accordion head="Profile">
                  <ArticlePanel contentId="leda-help-profile" />
                </Accordion>
                <Accordion head="Stories">
                  <ArticlePanel contentId="leda-help-stories" />
                </Accordion>
                <Accordion head="Suggestions and feedback">
                  <ArticlePanel contentId="leda-help-suggestions-feedback" />
                </Accordion>
                <Accordion head="Teams">
                  <ArticlePanel contentId="leda-help-teams" />
                </Accordion>
              </div>
              <div
                className="button-container"
                style={{ marginBottom: '30px' }}
              >
                <Button
                  onClick={() =>
                    (window.location.href = 'mailto:hello@getleda.com')
                  }
                  label="Contact us"
                />
                {Util.context.user.getIsLedaAdmin() ? (
                  <Button
                    label="Admin"
                    to={Util.route.admin.home()}
                    isAdmin={true}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </AppHelpLayout>
      </div>
    );
  }
}
