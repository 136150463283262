import React, { Component } from 'react';
import Util from '../../../../Util';

//Components
import Card from '../Card';
import ProgressBar from '../../ProgressBar/ProgressBar';
import ActivityTagline from '../../ActivityTagline/ActivityTagline';
import MyActivityTasksLabel from '../../MyActivityTasksLabel/MyActivityTasksLabel';
import Truncate from '../../Truncate/Truncate';

// Data source: this.props.myActivity
export default class MyActivityCard extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    if (this.props.onClick) this.props.onClick(this.props.myActivity);

    Util.analytics.track('MyActivityClicked', {
      myActivityId: this.props.myActivity.myActivityId,
      myActivityName: this.props.myActivity.name
    });
  }
  render() {
    let completedTasksLength = this.props.myActivity.myActivityTasks.filter(
      task => !!task.completedAt
    ).length;

    return (
      <Card
        className={`my-activity-card ${
          this.props.myActivity.completedAt ? 'completed' : ''
        } ${this.props.myActivity.isGenerated ? 'generated' : ''}`}
        isSelected={this.props.isSelected}
        onClick={this.onClick}
      >
        <div className="card-body">
          <ActivityTagline
            journeyStepId={this.props.myActivity.journeyStepId}
            skills={this.props.myActivity.myActivitySkills}
          />
          <div className="my-act-name mini-hidden">
            <Truncate
              component="h2"
              maxLine={2}
              text={this.props.myActivity.name}
            />
          </div>
          <div className="my-act-name mini-only">
            <Truncate
              component="p"
              className="mini-only"
              maxLine={2}
              text={this.props.myActivity.name}
            />
          </div>
          <div className="my-act-lower">
            <MyActivityTasksLabel myActivity={this.props.myActivity} />
          </div>
        </div>
        <ProgressBar
          numerator={completedTasksLength}
          denominator={this.props.myActivity.myActivityTasks}
        />
      </Card>
    );
  }
}
