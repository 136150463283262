import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//this.props.tag and this.props.to OR this.props.getTo (fn)
export default class TagLink extends Component {
  render() {
    return (
      <h5>
        <Link
          to={this.props.to || this.props.getTo(this.props.tag)}
          className="tag-link"
        >
          {this.props.tag.name}
        </Link>
      </h5>
    );
  }
}
