import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';

export default class AuthFoundation extends Component {
  render() {
    return (
      <div className="leda-foundation-auth">
        <Div100vh className="foundation-content">
          {this.props.children}
        </Div100vh>
      </div>
    );
  }
}
