import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

// Components
import StoryChapterView from '../../../../UI/StoryChapterView/StoryChapterView';
import RelatedActivityStrip from '../../../../UI/RelatedActivityStrip/RelatedActivityStrip';
import StoryIcon from '../../../../UI/StoryIcon/StoryIcon';

export default class AppStoryPage extends Component {
  constructor() {
    super();

    this.state = {
      story: null
    };
  }
  componentDidMount() {
    this.fetchData(this.props.storyId);
  }
  fetchData(storyId) {
    Util.api.post(
      '/api/course/getStoryById',
      {
        storyId: storyId
      },
      {
        success: story => {
          this.setState({ story });
        }
      }
    );
  }
  render() {
    let story = Util.array.firstIdOrUrlIdMatch(
      Util.context.referenceData.getStories(),
      this.props.storyId,
      'storyId'
    );
    if (!story) return <Redirect to={Util.route.site.error404()} />;

    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Stories',
        to: Util.route.app.stories()
      },
      {
        label: story.name
      }
    ];

    let content = null;

    if (!this.state.story) {
      content = <div className="loader"></div>;
    } else {
      content = (
        <div>
          <h1 className="story-page-title gilroy">
            <StoryIcon story={story} />
            {story.name}
          </h1>
          <StoryChapterView
            story={this.state.story}
            chapterId={this.props.chapterId}
          />
        </div>
      );
    }

    return (
      <div className="leda-page-app-story">
        <AppExploreLayout
          pageTitle="Stories"
          pageSubtitle="It's just another day in the office. Meet real-life characters and learn how they — and you! — can build better leadership practices."
          pageIcon={Util.icon.story}
          breadcrumbs={breadcrumbs}
        >
          <div className="leda-container-md">
            <div className="leda-row">{content}</div>
          </div>
          {this.state.story &&
          Util.array.any(this.state.story.relatedActivities) ? (
            <div className="leda-wrapper blue-grey-3">
              <div className="leda-container-md">
                <div className="leda-row">
                  <RelatedActivityStrip
                    activities={this.state.story.relatedActivities}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </AppExploreLayout>
      </div>
    );
  }
}
