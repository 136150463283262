import React, { Component } from 'react';
import Util from '../../../Util';
import Textarea from 'react-textarea-autosize';

import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button/Button';

export default class UserFeedbackSkillRater extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFailed: false,
      isSubmitted: false,

      userName: '',
      skills: [],
      skillIdsGood: [],
      skillIdsBad: [],
      formError: null
    };

    this.additionalCommentsFieldRef = React.createRef();

    this.toggleSkill = this.toggleSkill.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
  }
  componentDidMount() {
    Util.api.post(
      '/api/public/getUserFeedbackConfig',
      {
        token: this.props.token
      },
      {
        success: config => {
          this.setState({
            skills: config.skills,
            userName: config.userName
          });
        },
        failure: () => {
          this.setState({
            isFailed: true
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  toggleSkill(skillId, arrayName) {
    let skillIdArray = this.state[arrayName];

    if (skillIdArray.includes(skillId)) {
      this.setState({
        [arrayName]: skillIdArray.filter(
          existingSkillId => existingSkillId !== skillId
        )
      });
    } else {
      let newArray = [skillId];
      if (skillIdArray.length > 0) newArray.push(skillIdArray[0]);

      this.setState({
        [arrayName]: newArray
      });
    }
  }
  submitFeedback() {
    if (
      this.state.skillIdsBad.length < 2 ||
      this.state.skillIdsGood.length < 2
    ) {
      this.setState({
        formError:
          'Sorry, you need to choose 2 skills in both sections before you can send your feedback.'
      });
    } else {
      let answers = [];

      let additionalCommentVal = this.additionalCommentsFieldRef.current._ref
        .value;

      if (additionalCommentVal) {
        answers.push({
          questionIdentifier: 'ADDITIONAL_COMMENT',
          value: additionalCommentVal
        });
      }

      let skillRatings = [
        ...this.state.skillIdsBad.map(skillId => {
          return {
            skillId,
            rating: -1
          };
        }),
        ...this.state.skillIdsGood.map(skillId => {
          return {
            skillId,
            rating: 1
          };
        })
      ];

      Util.api.post('/api/public/submitUserFeedback', {
        token: this.props.token,
        feedback: {
          answers,
          skillRatings
        }
      });

      this.setState({
        isSubmitted: true
      });
    }
  }
  render() {
    let getSkillQuestions = (idArray, arrayName) => {
      return (
        <div className="question-skills">
          {this.state.skills.map(skill => (
            <div key={skill.skillId} className="skill-button-container">
              <button
                className={`skill-button ${
                  idArray.includes(skill.skillId) ? 'selected' : ''
                }`}
                onClick={() => this.toggleSkill(skill.skillId, arrayName)}
              >
                <h2 className="skill-name">{skill.name}</h2>
                <Tooltip
                  tipContent={<div>{skill.overview}</div>}
                  position="bottom"
                />
              </button>
            </div>
          ))}
        </div>
      );
    };

    let content = null;

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else if (this.state.isFailed) {
      content = <div>Sorry, this feedback link has expired.</div>;
    } else if (this.state.isSubmitted) {
      content = <div>Thanks for your feedback!</div>;
    } else {
      content = (
        <div className="feedback-content">
          <h2>
            Thanks for giving feedback to {this.state.userName}. Your anonymous
            feedback will help them focus on training that makes a difference.
          </h2>
          <div className="question-number">1</div>
          <div className="question-name">
            <h3>What's {this.state.userName} good at? Choose 2 skills.</h3>
            <p className={this.state.skillIdsGood.length === 2 ? 'blue' : ''}>
              {this.state.skillIdsGood.length} of 2 skills chosen.
            </p>
          </div>
          {getSkillQuestions(this.state.skillIdsGood, 'skillIdsGood')}
          <div className="question-number">2</div>
          <div className="question-name">
            <h3>
              What could {this.state.userName} be better at? Choose 2 skills.
            </h3>
            <p className={this.state.skillIdsBad.length === 2 ? 'blue' : ''}>
              {this.state.skillIdsBad.length} of 2 skills chosen.
            </p>
          </div>
          {getSkillQuestions(this.state.skillIdsBad, 'skillIdsBad')}
          <div className="question-number">3</div>
          <div className="question-name">
            <h3>
              Is there anything else you’d like to share with{' '}
              {this.state.userName} about their leadership skills or style?
            </h3>
          </div>
          <Textarea
            ref={this.additionalCommentsFieldRef}
            placeholder="This step is optional, but if you have feedback, enter your thoughts here."
          />
          {this.state.formError ? (
            <div className="form-error">{this.state.formError}</div>
          ) : null}
          <Button
            label="Send feedback"
            size="lg"
            onClick={this.submitFeedback}
          />
          <div className="anon-disclaimer">Your feedback is anonymous.</div>
        </div>
      );
    }

    return (
      <div className="user-feedback-skill-rater">
        <div className="feedback-header">
          Feedback {this.state.userName ? `for ${this.state.userName}` : null}
        </div>
        <div className="feedback-container">{content}</div>
      </div>
    );
  }
}
