import React, { Component } from 'react';

//Layout
import AppJourneyLayout from '../../../../Layouts/App/AppJourneyLayout/AppJourneyLayout';
//Component
import JourneyView from '../../../../UI/JourneyView/JourneyView';
import Util from '../../../../../Util';
import { connect } from 'react-redux';
import { startJourneyAction } from '../../../../../actions/journeyActions';

//requires this.props.journeyId
class AppJourneyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      journey: null,
      activeStepId: null,
      isNavOpenDefault: false
    };
  }
  componentDidMount() {
    this.setJourney(this.props.journeyId);
  }
  componentWillReceiveProps(props) {
    if (
      props.journeyId &&
      this.state.journey &&
      !Util.id.urlId.isIdOrUrlIdMatch(
        this.state.journey,
        props.journeyId,
        'journeyId'
      )
    ) {
      //Journey change, big reload
      this.setJourney(props.journeyId);
    } else if (props.stepId && this.state.activeStepId !== props.stepId) {
      //Journey the same, new step. Switch it.
      this.setState({
        activeStepId: props.stepId
      });

      // Util.analytics.track('ViewJourneyStep', {
      // 	journeyId: this.state.journey.journeyId,
      // 	journeyName: this.state.journey.name,
      // 	stepId: activeStep.stepId,
      // 	stepUrlId: activeStep.urlId,
      // 	stepName: activeStep.name
      // });
    }
  }
  setJourney(journeyId) {
    let userJourney = Util.array.firstIdOrUrlIdMatch(
      this.props.userJourneys,
      journeyId,
      'journeyId'
    );
    if (!userJourney) {
      //If no existing userjourney found, start it,
      this.props.startJourneyAction(journeyId, createdUserJourney => {
        //set it,
        this.setState({
          userJourney: createdUserJourney
        });
        //Then fetch journey data
        this.fetchData(journeyId);
      });
    } else {
      //If existing userjourney is found, set it,
      this.setState({
        userJourney
      });
      //Then fetch journey data
      this.fetchData(journeyId);
    }
  }
  fetchData(journeyId) {
    this.setState({
      isLoading: true
    });

    //Ensure journeyId is an int not urlId
    if (isNaN(journeyId))
      journeyId = Util.context.referenceData.getJourneyById(journeyId)
        .journeyId;
    let userJourney = Util.array.firstIdOrUrlIdMatch(
      this.props.userJourneys,
      journeyId,
      'journeyId'
    );

    Util.api.post(
      '/api/journey/getJourneyContent',
      {
        journeyId
      },
      {
        success: journey => {
          //this.props.stepId MIGHT equal 'end' so don't rely on the fact you'll find it in the journey.steps array
          let activeStepId = this.props.stepId;

          //Steps where none of their activities are in progress or completed
          let notStartedSteps = journey.steps.filter(
            step =>
              Util.context.referenceData.getJourneyStepStatus(
                step,
                userJourney,
                this.props.myActivities,
                this.props.userProgressActivity
              ) === 'unstarted'
          );

          if (!activeStepId) {
            //If no stepId passed in, find the most appropriate one to show
            if (Util.array.any(notStartedSteps)) {
              activeStepId = Util.array.first(notStartedSteps).urlId;
            } else {
              let inProgressSteps = journey.steps.filter(
                step =>
                  Util.context.referenceData.getJourneyStepStatus(
                    step,
                    userJourney,
                    this.props.myActivities,
                    this.props.userProgressActivity
                  ) === 'inprogress'
              );

              activeStepId = Util.array.any(inProgressSteps)
                ? Util.array.first(inProgressSteps).urlId
                : 'end';
            }
          }

          this.setState({
            journey,
            activeStepId,
            isNavOpenDefault: notStartedSteps.length === journey.steps.length,
            isLoading: false
          });
        }
      }
    );
  }
  render() {
    if (this.state.isLoading) return <div className="loader"></div>;

    return (
      <div className="leda-page-app-journey">
        <AppJourneyLayout journey={this.state.journey} wrapperCls="grey-6">
          <div className="leda-container-md">
            <div className="leda-row over-xs">
              <JourneyView
                journey={this.state.journey}
                activeStepId={this.state.activeStepId}
                isNavOpenDefault={this.state.isNavOpenDefault}
              />
            </div>
          </div>
        </AppJourneyLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys,
  myActivities: state.activityState.myActivities,
  userProgressActivity: state.userState.userProgress.activity
});

export default connect(mapStateToProps, { startJourneyAction })(AppJourneyPage);
