import React, { Component } from 'react';
import Util from '../../../../Util';

// Components
import CardsView from '../CardsView';
import SkillCard from '../../Card/SkillCard/SkillCard';

//data source: this.props.skills
export default class SkillCardsView extends Component {
  render() {
    return (
      <CardsView
        className="skill-cards-view"
        isNeverLargeMode={this.props.isNeverLargeMode}
      >
        {Util.array.none(this.props.skills)
          ? null
          : this.props.skills.map(skill => (
              <SkillCard
                isNeverLargeMode={this.props.isNeverLargeMode}
                key={skill.skillId}
                skill={skill}
              />
            ))}
      </CardsView>
    );
  }
}
