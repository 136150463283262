import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Util from '../../../../Util';
import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import Button from '../../../UI/Button/Button';

export default class AdminHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = { redirectToNewOrg: false };
  }

  //
  setRedirectToNewOrgToTrue() {
    this.setState({
      redirectToNewOrg: true
    });
  }

  render() {
    return (
      <div className="leda-page-admin-home">
        {this.state.redirectToNewOrg ? (
          <Redirect
            to={{
              pathname: Util.route.admin.teamList(),
              state: { openNewOrgModal: true }
            }}
          />
        ) : null}
        <AdminLayout>
          <h1>1. User Managements</h1>
          <div className="menus">
            <div className="menu">
              <h2>Users</h2>
              <Button
                label="View User List"
                isAdmin={true}
                to={Util.route.admin.userList()}
              />
            </div>
            <div className="menu">
              <h2>Organisations and Teams</h2>
              <Button
                label="View Organisation &amp; Team List"
                isAdmin={true}
                to={Util.route.admin.teamList()}
              />
              <Button
                label="Create Organisation"
                isAdmin={true}
                onClick={() => this.setRedirectToNewOrgToTrue()}
              />
            </div>
          </div>
          <h1>2. Site and Content</h1>
          <div className="menus">
            <div className="menu">
              <h2>Journeys</h2>
              <Button
                label="View Journey List"
                isAdmin={true}
                to={Util.route.admin.journeyList()}
              />
            </div>
            <div className="menu">
              <h2>Activities</h2>
              <Button
                label="View Activity List"
                isAdmin={true}
                to={Util.route.admin.activityList()}
              />
              <Button
                label="Create Activity"
                isAdmin={true}
                to={Util.route.admin.activityEditor()}
              />
            </div>
            <div className="menu">
              <h2>Content</h2>
              <Button
                label="View Content List"
                isAdmin={true}
                to={Util.route.admin.contentList()}
              />
              <Button
                label="Create Content"
                isAdmin={true}
                to={Util.route.admin.contentEditor()}
              />
            </div>
          </div>
          <h1>3. Team Settings</h1>
          <div className="menus">
            <div className="menu">
              <h2>Team</h2>
              <Button
                label="Pre-set team settings"
                isAdmin={true}
                to={Util.route.admin.teamPresets()}
              />
            </div>
          </div>
        </AdminLayout>
      </div>
    );
  }
}
