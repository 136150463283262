import React, { Component } from 'react';
import validator from 'validator';
import withForm from '../withForm';

import Button from '../../Button/Button';

class ResetPasswordForm extends Component {
  render() {
    return (
      <form className="form-reset" onSubmit={this.props.onSubmit}>
        {this.props.getField('currentPassword')}
        {this.props.getField('newPassword')}
        {this.props.getField('confirmNewPassword')}
        <div className="button-container">
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
          <Button label="Submit" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(ResetPasswordForm, {
  getOverallError: formData => {
    if (!validator.equals(formData.newPassword, formData.confirmNewPassword))
      return 'Passwords do not match';
  },
  fields: {
    currentPassword: {
      label: 'Current password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Enter your current password (8 - 64 characters)';
      }
    },
    newPassword: {
      label: 'New password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Enter a new password (8 - 64 characters)';
      }
    },
    confirmNewPassword: {
      label: 'Confirm new password',
      isPassword: true,
      getError: val => {
        if (!validator.isLength(val, { min: 8, max: 64 }))
          return 'Re-enter your new password (8 - 64 characters)';
      }
    }
  }
});
