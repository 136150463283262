import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { openModalAction } from '../../../actions/modalActions';
import Util from '../../../Util';

import CardsView from '../CardsView/CardsView';
import SkillCard from '../Card/SkillCard/SkillCard';

class UserFeedbackSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userFeedbackResults: null,

      isLoading: true
    };

    this.onAnswersButtonClick = this.onAnswersButtonClick.bind(this);
  }
  componentDidMount() {
    Util.api.post(
      '/api/feedback/getUserFeedbackResults',
      {},
      {
        success: userFeedbackResults => {
          this.setState({
            userFeedbackResults,
            isLoading: false
          });
        }
      }
    );
  }
  onAnswersButtonClick() {
    this.props.openModalAction({
      content: (
        <div className="answers-modal">
          <h2>Additional comments</h2>
          <div className="answer-items">
            {this.state.userFeedbackResults.answers.map((answer, idx) => (
              <div key={idx} className="answer-item">
                {answer}
              </div>
            ))}
          </div>
        </div>
      )
    });
  }
  render() {
    let content = null;

    let getSkillCard = skillId => {
      let skill = Util.context.referenceData.getSkillById(skillId);
      return skill ? (
        <SkillCard skill={skill} isNeverLargeMode={true} key={skillId} />
      ) : null;
    };

    if (this.state.isLoading) {
      content = <div className="loader"></div>;
    } else {
      content = (
        <div>
          <div className="feedback-heading">
            <p>
              {this.state.userFeedbackResults.responseCount}{' '}
              {Util.format.pluralise(
                this.state.userFeedbackResults.responseCount,
                'response'
              )}{' '}
              received
            </p>
          </div>
          <div className="summary-result-skills">
            {Util.array.any(this.state.userFeedbackResults.skillIdsGood) ? (
              <div className="skills-result">
                <p className="result-heading">
                  Your responses suggest you are good at these skills:
                </p>
                <CardsView isNeverLargeMode={true}>
                  {this.state.userFeedbackResults.skillIdsGood.map(skillId =>
                    getSkillCard(skillId)
                  )}
                </CardsView>
              </div>
            ) : null}
            {Util.array.any(this.state.userFeedbackResults.skillIdsBad) ? (
              <div className="skills-result">
                <p className="result-heading">
                  Your responses suggest you could improve these skills:
                </p>
                <CardsView isNeverLargeMode={true}>
                  {this.state.userFeedbackResults.skillIdsBad.map(skillId =>
                    getSkillCard(skillId)
                  )}
                </CardsView>
              </div>
            ) : null}
          </div>
          <div
            className="summary-result-answers"
            onClick={this.onAnswersButtonClick}
          >
            <p>
              You have received {this.state.userFeedbackResults.answers.length}{' '}
              additional{' '}
              {Util.format.pluralise(
                this.state.userFeedbackResults.answers,
                'comment',
                'comments'
              )}
            </p>
            <ReactSVG
              src={Util.icon.arrow.right}
              className="arrow-icon svg-button"
            />
          </div>
        </div>
      );
    }

    return <div className="user-feedback-summary">{content}</div>;
  }
}

export default connect(
  null,
  { openModalAction }
)(UserFeedbackSummary);
