import React, { Component } from 'react';

import Button from '../..//Button/Button';
import FeatureBanner from '../FeatureBanner';
import JourneyBadge from '../../JourneyBadge/JourneyBadge';
import JourneyStepStatusSummary from '../../JourneyStepStatusSummary/JourneyStepStatusSummary';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';
import { connect } from 'react-redux';

//this.props.journey || this.props.teamId
class JourneyFeatureBanner extends Component {
  render() {
    if (!this.props.journey && !this.props.teamId) return null;

    let journey = this.props.journey;
    let currentTeamJourney = null;

    if (!journey && this.props.teamId) {
      let team = Util.context.user.getTeamById(this.props.teamId);

      if (team) {
        currentTeamJourney = team.teamJourneys
          .filter(teamJourney => teamJourney.scheduledAt)
          .sort((tj1, tj2) =>
            Util.sort.by('scheduledAt', tj2, tj1, Util.sort.asDate)
          )
          .find(
            teamJourney =>
              teamJourney.scheduledAt &&
              new Date(teamJourney.scheduledAt) <= new Date()
          );

        journey = currentTeamJourney
          ? Util.context.referenceData.getJourneyById(
              currentTeamJourney.journeyId
            )
          : null;

        if (journey) {
          journey.isBreak = false;
          if (currentTeamJourney.breakLabel) {
            journey = {
              ...journey,
              name: currentTeamJourney.breakLabel,
              isBreak: true
            };
          }
        }
      }
    }

    if (!journey || journey.isBreak) return null;

    let isLocked =
      journey.isLocked &&
      (!currentTeamJourney ||
        !currentTeamJourney.unlockedAt ||
        new Date(currentTeamJourney.unlockedAt) <= new Date());
    let userJourney = this.props.userJourneys.find(
      userJourney => userJourney.journeyId === journey.journeyId
    );

    let buttonLabel = userJourney ? 'Continue' : 'Start';

    let journeySkill = Util.array.any(journey.skills)
      ? Util.context.referenceData.getSkillById(journey.skills[0].skillId)
      : null;
    let bgColour = journeySkill
      ? Util.context.referenceData.getSkillGroupById(journeySkill.skillGroupId)
          .primaryColour
      : Util.css.colour.ledaLightBlue;

    return (
      <FeatureBanner
        className="journey-feature-banner"
        featureImage={<JourneyBadge journey={journey} />}
        style={{
          backgroundColor: `#${bgColour}`,
          backgroundImage: `linear-gradient(to right, #FFFFFF33 0%, #FFFFFF00 100%)`
        }}
        bannerIcon={Util.icon.journey}
        bannerTitle="Current Journey"
      >
        <div className="banner-content">
          <h1 className="gilroy">{journey.name}</h1>
          <p className="gilroy">{journey.overview}</p>
          <JourneyStepStatusSummary journeyId={journey.journeyId} />
          <div className="button-container">
            {!isLocked ? (
              <Button
                label={buttonLabel}
                to={Util.route.app.journey(journey.urlId)}
              />
            ) : null}
            <Button
              isSecondary={true}
              label="View all Journeys"
              to={Util.route.app.teamJourneys(this.props.teamId)}
            />
          </div>
        </div>
      </FeatureBanner>
    );
  }
}

const mapStateToProps = state => ({
  userJourneys: state.journeyState.userJourneys
});

export default connect(mapStateToProps, {})(JourneyFeatureBanner);
