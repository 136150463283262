import React, { Component } from 'react';
import Util from '../../../Util';

export default class MyActivityTasksLabel extends Component {
  render() {
    let completedTasksLength = this.props.myActivity.myActivityTasks.filter(
      task => !!task.completedAt
    ).length;
    return (
      <h4 className="my-act-tasks-label">
        {completedTasksLength +
          '/' +
          this.props.myActivity.myActivityTasks.length +
          Util.format.pluralise(this.props.myActivity.myActivityTasks, ' task')}
      </h4>
    );
  }
}
