import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import Util from '../../../Util';

export default class AdminFoundation extends Component {
  render() {
    return (
      <div className="leda-foundation-admin">
        <Div100vh className="foundation-content">
          <div className="admin-header">
            <h2 className="white">Leda Admin</h2>
            <Link to={Util.route.admin.home()}>Back to admin home</Link> |{' '}
            <Link to={Util.route.app.home()}>Back to main site</Link>
            {Util.context.env.getIsStag() || Util.context.env.getIsDev() ? (
              <div className="staging-heading">
                You are currently on staging - NOT production. Our real users
                will NOT receive changes you make here.
              </div>
            ) : null}
          </div>
          {this.props.children}
        </Div100vh>
      </div>
    );
  }
}
