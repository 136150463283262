import bowser from 'bowser';
import humanizeDuration from 'humanize-duration';
import iconActivity from './img/icons/brain-head-1.svg';
//Icon imports for use throughout the site
import iconAdd from './img/icons/add.svg';
import iconArrowDoubleDown from './img/icons/arrow-button-down-1.svg';
import iconArrowDoubleLeft from './img/icons/arrow-button-left-1.svg';
import iconArrowDoubleRight from './img/icons/arrow-button-right-1.svg';
import iconArrowDoubleUp from './img/icons/arrow-button-up-1.svg';
import iconArrowDown from './img/icons/arrow-down.svg';
import iconArrowHeadDown from './img/icons/arrow-down-1.svg';
import iconArrowHeadLeft from './img/icons/arrow-left-1.svg';
import iconArrowHeadRight from './img/icons/arrow-right-1.svg';
import iconArrowHeadUp from './img/icons/arrow-up-1.svg';
import iconArrowLeft from './img/icons/arrow-left.svg';
import iconArrowRight from './img/icons/arrow-right.svg';
import iconArrowUp from './img/icons/arrow-up.svg';
import iconAssessment from './img/icons/paper-write.svg';
import iconAttachment from './img/icons/attachment.svg';
import iconBin from './img/icons/bin-1.svg';
import iconBreakGrey from './img/icons/expand-vertical-left-right-grey.svg';
import iconBreakWhite from './img/icons/expand-vertical-left-right-white.svg';
import iconCamera from './img/icons/camera-1.svg';
import iconClock from './img/icons/time-clock-circle.svg';
import iconClose from './img/icons/close.svg';
import iconComment from './img/icons/messages-bubble.svg';
import iconContextMenu from './img/icons/navigation-menu-vertical.svg';
import iconCopy from './img/icons/common-file-double-1.svg';
import iconEdit from './img/icons/pencil-1.svg';
import iconEmail from './img/icons/email-action-unread.svg';
import iconExplore from './img/icons/space-ship-1.svg';
import iconFeedback from './img/icons/conversation-chat-1.svg';
import iconFilter from './img/icons/filter.svg';
import iconGame from './img/icons/video-game-xbox-controller.svg';
import iconGlobalUser from './img/icons/network-user.svg';
import iconGraph from './img/icons/analytics-graph.svg';
import iconHelp from './img/icons/question-circle.svg';
import iconHome from './img/icons/house-2.svg';
import iconImage from './img/icons/picture-landscape.svg';
import iconJourney from './img/icons/compass-1.svg';
import iconLock from './img/icons/lock-1.svg';
import iconLogin from './img/icons/login.svg';
import iconLogout from './img/icons/logout.svg';
import iconNavigationMenu from './img/icons/navigation-menu.svg';
import iconNotification from './img/icons/alarm-bell.svg';
import iconOrganisation from './img/icons/briefcase.svg';
import iconParty from './img/icons/party-confetti.svg';
import iconPin from './img/icons/pin.svg';
import iconPlay from './img/icons/controls-play.svg';
import iconPost from './img/icons/pencil-write.svg';
import iconRefresh from './img/icons/button-refresh-arrow.svg';
import iconReorderDown from './img/icons/reorder-down.svg';
import iconSearch from './img/icons/search.svg';
import iconSettings from './img/icons/cog.svg';
import iconSkill from './img/icons/shapes.svg';
import iconStar from './img/icons/rating-star.svg';
import iconStory from './img/icons/book-play.svg';
import iconTag from './img/icons/tags.svg';
import iconTeam from './img/icons/multiple-circle.svg';
import iconTeamAdd from './img/icons/multiple-actions-add.svg';
import iconTextField from './img/icons/content-pen-write.svg';
import iconTextarea from './img/icons/content-paper-edit.svg';
import iconThumb from './img/icons/like-2.svg';
import iconTick from './img/icons/leda-tick.svg';
import iconTreasure from './img/icons/treasure-chest-open.svg';
import iconUndo from './img/icons/undo.svg';
import iconUnlock from './img/icons/lock-unlock-1.svg';
import iconUser from './img/icons/single-neutral.svg';
import iconUsers from './img/icons/multiple-neutral-1.svg';
import iconVideo from './img/icons/social-video-youtube-clip.svg';
import iconView from './img/icons/view-1.svg';
import linkifyHtml from 'linkifyjs/html';
import moment from 'moment';
import striptags from 'striptags';

// Holds a large amount of common functions to be used in the project
// Properties prefixed with '_' should not be accessed outside this file
const Util = {
  // Used for accordion component and accordions in content panels
  accordion: {
    open: accordionEl => {
      let accordionBody = accordionEl.lastElementChild;
      accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px'; // Set the max height to the height of the content inside
      accordionEl.classList.add('open');

      setTimeout(() => (accordionBody.style.maxHeight = 'none'), 400); // After the open animation has completed, set maxHeight to none to allow content resizing
    },

    close: accordionEl => {
      let accordionBody = accordionEl.lastElementChild;
      accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px'; // Set the max height to the height of the content inside
      accordionEl.classList.remove('open');

      // A valid use of setTimeout 0. We want the above maxHeight to be applied to the DOM.
      // Going directly from maxHeight 'none' to null will NOT animate, but after setting px above, the 0 waits for a render before applying the new maxheight below.
      setTimeout(() => (accordionBody.style.maxHeight = null), 0);
    },

    isOpen: target => {
      let accordionEl = target.classList.contains('accordion')
        ? target
        : target.closest('.accordion');

      return accordionEl ? accordionEl.classList.contains('open') : false;
    },

    toggle: target => {
      let accordionHeadEl = target.classList.contains('accordion-head')
        ? target
        : target.closest('.accordion-head');

      if (accordionHeadEl) {
        let accordionEl = accordionHeadEl.parentElement;

        Util.accordion.isOpen(accordionEl)
          ? Util.accordion.close(accordionEl)
          : Util.accordion.open(accordionEl);
      }
    }
  },

  // Google ads/Facebook pixel
  ads: {
    _isTesting: false,

    facebook: {
      //enum/lookup
      Event: {
        Contact: 'Contact',
        ViewContent: 'ViewContent'
      },

      track: (event, params = {}) => {
        // if (Util.ads._isTesting || Util.context.env.getIsProd()) {
        //   window.fbq('track', event, {
        //     ...params
        //   });
        // }
      }
    },

    google: {
      _conversionId: '833199972',

      //enum/lookup
      ConversionEvent: {
        WindowFocus: '_nCRCMGS3aYBEOS-po0D',
        SiteView: 'fBQJCKrs-6YBEOS-po0D',
        DemoContactPageView: 'RZCmCPma-KYBEOS-po0D',
        DemoContactSubmitted: '57LaCLjVhKcBEOS-po0D'
      },

      track: (conversionEvent, params = {}) => {
        // if (Util.ads._isTesting || Util.context.env.getIsProd()) {
        // window.gtag('event', 'conversion', {
        //   send_to: `AW-${Util.ads.google._conversionId}/${conversionEvent}`,
        //   ...params //test params
        // });
        // }
      }
    },

    linkedIn: {
      _partnerId: '1367820',

      ConversionEvent: {
        SiteView: '1198610',
        DemoContactPageView: '1194290',
        DemoContactSubmitted: '1198602'
      },

      track: conversionId => {
        // if (Util.ads._isTesting || Util.context.env.getIsProd()) {
        //   let pixel = document.createElement('img');
        //   pixel.src = `https://px.ads.linkedin.com/collect/?pid=${Util.ads.linkedIn._partnerId}&conversionId=${conversionId}&fmt=gif`;
        //   pixel.height = 1;
        //   pixel.width = 1;
        //   document.body.appendChild(pixel);
        // }
      }
    }
  },

  // Util.analytics - analytics/segment information tracking
  analytics: {
    _getCommonEventObject: () => {
      let eventObject = {
        properties: {
          url: window.location.href,
          path: Util.route.getCurrent(),
          cid: Util.route.getParameterByName('cid'), //anonymous link - click Id
          referrer: document.referrer,
          redirectedFrom: Util.route.getParameterByName('redirectedFrom'),
          language:
            navigator.languages && navigator.languages.length
              ? navigator.languages[0]
              : navigator.language
        },
        context: {
          campaign: {
            //UTM campaign
            name: Util.route.getParameterByName('utm_campaign'),
            source: Util.route.getParameterByName('utm_source'),
            medium: Util.route.getParameterByName('utm_medium'),
            term: Util.route.getParameterByName('utm_term'),
            content: Util.route.getParameterByName('utm_content')
          }
        }
      };

      return eventObject;
    },

    page: () => {
      let eventObject = Util.analytics._getCommonEventObject();

      eventObject.name = Util.route.getCurrent(); //TODO(enhancement) change to getCurrentName when ready
      eventObject.category = Util.route.getCurrentCategory();

      Util.api.post('/api/public/p', eventObject);
    },

    track: (event, properties = {}) => {
      let eventObject = Util.analytics._getCommonEventObject();

      eventObject.event = event;
      eventObject.properties = { ...eventObject.properties, ...properties };

      Util.api.post('/api/public/t', eventObject);
    }
  },

  // Util.api - Wrapper for our FETCH and POST requests, with built in error handling
  api: {
    // A common way to handle callbacks for our requests
    _handleCallback: (callbackConfig, resJson) => {
      //If the resJon has used a specific 'errorMsg' property, do the failure callback
      if (resJson && resJson.errorMsg) {
        callbackConfig.failure
          ? callbackConfig.failure(resJson.errorMsg)
          : alert(
              resJson.errorMsg ||
                "We're sorry, something went wrong. Please wait a moment and try again."
            );
      } else {
        //Otherwise, we can now assume the request was successful, do the success callback
        if (callbackConfig.success) callbackConfig.success(resJson);
      }

      // Always do the complete callback, whether error or not
      if (callbackConfig.complete) callbackConfig.complete();
    },
    _handleNotOk: (callbackConfig, res) => {
      if (!res.ok) {
        if (res.status === 403) {
          // If this is a forbidden/access denied error, clear the user token so they have to log in again
          Util.auth.clearToken();
          // Intentional location.href usage - authenticate needs to be called again
          window.location.href = Util.route.auth.login(Util.route.getCurrent());
        } else if (res.status === 500 || res.status === 404) {
          if (callbackConfig.failure) callbackConfig.failure();
          if (callbackConfig.complete) callbackConfig.complete();
        }

        throw new Error(
          '_handleNotOk error: ' + res.url + ' - ' + res.statusText
        );
      }

      return res;
    },
    _post: (endpoint, data, callbackConfig, headers = {}) => {
      //Add authentication tokens onto all post headers
      headers = {
        ...headers,
        [Util.auth._tokenKey]: Util.auth.getToken(),
        [Util.auth._anonymousIdKey]: Util.auth.getAnonUserId()
      };

      fetch(endpoint, {
        method: 'POST',
        headers,
        body: data
      })
        .then(res => Util.api._handleNotOk(callbackConfig, res))
        .then(res => res.json())
        .then(resJson => Util.api._handleCallback(callbackConfig, resJson))
        .catch(err => {
          console.log(err);
          if (callbackConfig.failure) callbackConfig.failure();
        });
    },

    post: (endpoint, data, callbackConfig = {}) => {
      //Regular post JSON stringifys the data before posting
      Util.api._post(endpoint, JSON.stringify(data), callbackConfig, {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      });
    },
    postRaw: (endpoint, data, callbackConfig = {}) => {
      // Raw post does not stringify. Implemented for file upload capabilities.
      Util.api._post(endpoint, data, callbackConfig);
    }
    // fetch: (endpoint, callbackConfig) => {
    // 	fetch(endpoint, {
    // 		headers: {
    // 			[Util.auth._tokenKey]: Util.auth.getToken(),
    // 			[Util.auth._anonymousIdKey]: Util.auth.getAnonUserId()
    // 		}
    // 	})
    // 	.then(res => Util.api._handleNotOk(callbackConfig, res))
    // 	.then(res => res.json())
    // 	.then(resJson => Util.api._handleCallback(callbackConfig, resJson))
    // 	.catch(err => {
    // 		console.log(err);
    // 		if(callbackConfig.failure) callbackConfig.failure();
    // 	});
    // }
  },

  // Util.array - complex or frequently used functions for arrays
  array: {
    any: array => {
      return array && array.length > 0;
    },

    first: array => {
      return Util.array.any(array) ? array[0] : null;
    },

    firstMatch: (array, matchVal, matchProp) => {
      return array.find(item => item[matchProp] === matchVal);
    },

    firstIdMatch: (array, id, idProp) => {
      id = isNaN(id) ? id : parseInt(id, 10);

      return array.find(item => {
        let itemId = item[idProp];
        itemId = isNaN(itemId) ? itemId : parseInt(itemId, 10);

        return itemId === id;
      });
    },

    firstIdOrUrlIdMatch: (array, id, idProp, urlIdProp = 'urlId') => {
      return isNaN(id)
        ? array.find(
            item =>
              String(item[urlIdProp]).toLowerCase() === String(id).toLowerCase()
          )
        : Util.array.firstIdMatch(array, id, idProp);
    },

    //https://stackoverflow.com/questions/14446511/what-is-the-most-efficient-method-to-groupby-on-a-javascript-array-of-objects
    groupBy: (array, groupProp) => {
      return array.reduce((rv, x) => {
        (rv[x[groupProp]] = rv[x[groupProp]] || []).push(x);
        return rv;
      }, {});
    },

    last: array => {
      return Util.array.any(array) ? array[array.length - 1] : null;
    },

    max: (array, maxProp) => {
      return Util.array.any(array)
        ? Math.max(...array.map(item => item[maxProp]))
        : null;
    },

    min: (array, minProp) => {
      return Util.array.any(array)
        ? Math.min(...array.map(item => item[minProp]))
        : null;
    },

    none: array => {
      return !Util.array.any(array);
    },

    // overlaps: (array1, array2) => {
    // 	return Util.array.any(array1.filter(value => array2.includes(value)));
    // },

    //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array/25984542
    shuffle: array => {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle-
      while (0 !== currentIndex) {
        // Pick a remaining element-
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  },

  // Auth - local storage and functions related to authentication
  auth: {
    _tokenKey: 'x-access-token',
    _anonymousIdKey: 'anonymous-id',

    getToken: () => localStorage.getItem(Util.auth._tokenKey),
    setToken: token => localStorage.setItem(Util.auth._tokenKey, token),
    clearToken: () => localStorage.removeItem(Util.auth._tokenKey),

    getAnonUserId: () => localStorage.getItem(Util.auth._anonymousIdKey),
    setAnonUserId: anonId => {
      // Only set the anonId if one isn't already set.
      // This may lead to the situation where, with device sharing, the anon Id is used by two people, but that is ok
      if (!Util.auth.getAnonUserId())
        localStorage.setItem(Util.auth._anonymousIdKey, anonId);
    }
  },

  autoRefresh: {
    _checkInterval: 10000, //Check if we need to refresh every 10 seconds
    _inactivityMinutes: 360, // How many minutes of inactivity until a refresh (currently 6 hours)
    _latestActivity: new Date(),

    init: () => {
      let refreshCheck = () => {
        //Autorefresh will only occur on certain pages (eg. it wouldn't make sense to refresh mid-survey)
        let refreshRoutes = [Util.route.app.home()];

        //If the user's latestActivity time PLUS the allowed inactivity minutes is earlier than NOW, refresh
        if (
          moment(Util.autoRefresh._latestActivity)
            .add(Util.autoRefresh._inactivityMinutes, 'minutes')
            .toDate() < new Date() &&
          !!refreshRoutes.find(route => Util.route.isCurrently(route))
        ) {
          Util.analytics.track('AutoRefresh');
          window.location.reload();
        } else {
          setTimeout(refreshCheck, Util.autoRefresh._checkInterval);
        }
      };

      setTimeout(refreshCheck, Util.autoRefresh._checkInterval);
    },

    reset: () => {
      Util.autoRefresh._latestActivity = new Date();
    }
  },

  clipboard: {
    copy: value => {
      var textArea = document.createElement('textarea');

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      textArea.value = value;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');

      document.body.removeChild(textArea);
    },

    copyEl: el => {
      var body = document.body,
        range,
        sel;
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
      } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
      }

      document.execCommand('copy');
    }
  },

  // Context about the current session
  context: {
    _isReady: false, //true after successful authenticate

    // User context
    user: {
      _current: null,

      setCurrent: user => (Util.context.user._current = user),
      getCurrent: () => Util.context.user._current || null, //You should NEVER use this for serverside request params, it can be changed to anything
      updateCurrent: updatedUser => Util.context.user.setCurrent(updatedUser),
      clearCurrent: () => (Util.context.user._current = null),

      getCurrentUserId: () =>
        Util.context.user._current ? Util.context.user._current.userId : null,

      _hasUserPermissions: () =>
        Util.context.user._current &&
        Util.context.user._current.userPermissions,

      //User permission data
      getIsLedaAdmin: () =>
        Util.context.user._hasUserPermissions()
          ? Util.context.user._current.userPermissions.isLedaAdmin
          : false,
      getOrganisationId: () =>
        Util.context.user._hasUserPermissions()
          ? Util.context.user._current.userPermissions.organisationId
          : null,
      getOrganisationName: () =>
        Util.context.user._hasUserPermissions()
          ? Util.context.user._current.userPermissions.organisationName
          : null,
      getIsOrganisationAdmin: () =>
        Util.context.user._hasUserPermissions() &&
        Util.context.user._current.userPermissions.organisationId &&
        Util.context.user._current.userPermissions.isOrganisationAdmin,

      getTeams: () =>
        Util.context.user._hasUserPermissions()
          ? Util.context.user._current.userPermissions.teams || []
          : [],
      getTeamById: teamId => {
        let parsedTeamId = parseInt(teamId, 10);
        if (isNaN(parsedTeamId)) return null;
        return Util.context.user
          .getTeams()
          .find(team => team.teamId === parsedTeamId);
      },
      getTeamsUserCanAdmin: () =>
        Util.context.user
          .getTeams()
          .filter(
            team =>
              Util.context.user.getIsOrganisationAdmin() || team.isTeamAdmin
          ),
      canAdminTeam: teamId =>
        Util.context.user
          .getTeamsUserCanAdmin()
          .find(team => team.teamId === teamId),

      //License data
      getIsLicensed: () =>
        Util.context.user._current &&
        Util.context.user._current.userPermissions,
      getLicenseExpiry: () =>
        Util.context.user.getIsLicensed() &&
        Util.context.user._current.userPermissions.licenseExpiredAt
          ? new Date(
              Util.context.user._current.userPermissions.licenseExpiredAt
            )
          : null,
      getLicenceHeaderText: (date = new Date()) => {
        date =
          moment(date)
            .fromNow()
            .replace('in ', '') + ' remaining';
        return date.includes('ago') ? 'Licence expired' : date;
      },
      getIsTrialLicense: () =>
        Util.context.user.getIsLicensed() &&
        Util.context.user._current.userPermissions.licenseType ===
          Util.enum.LicenseType.Trial,
      getIsStudentLicense: () =>
        Util.context.user.getIsLicensed() &&
        Util.context.user._current.userPermissions.licenseType ===
          Util.enum.LicenseType.Student
    },

    //
    referenceData: {
      _current: null,

      setCurrent: referenceData => {
        if (referenceData) {
          //while it is useful to have the nested capabilities, we also often want a simple array of skills. separate them here.
          let skillGroups = [];
          let skills = [];

          let skillGroupColours = {
            'self-awareness': {
              primary: '76CECE',
              complementary: 'ABFFFF'
            },
            'self-management': {
              primary: '5BA2B5',
              complementary: '99E9FF'
            },
            'social-awareness': {
              primary: 'F2C577',
              complementary: 'FFD996'
            },
            'relationship-management': {
              primary: 'EF9D78',
              complementary: 'FFB999'
            }
          };

          if (Util.array.any(referenceData.capabilities)) {
            referenceData.capabilities.forEach(capability => {
              capability.skillGroups.forEach(skillGroup => {
                skillGroup.primaryColour =
                  skillGroupColours[skillGroup.urlId].primary;
                skillGroup.complementaryColour =
                  skillGroupColours[skillGroup.urlId].complementary;
                skillGroups.push(skillGroup);
                skillGroup.skills.forEach(skill => {
                  skill.skillGroupUrlId = skillGroup.urlId;
                  skills.push(skill);
                });
              });
            });
          }
          referenceData.skillGroups = skillGroups;
          referenceData.skills = skills;

          //While it is useful to have the nested modules, we also often want a simple array of stories
          let stories = [];
          if (Util.array.any(referenceData.modules)) {
            referenceData.modules.forEach(mod => {
              mod.stories.forEach(story => stories.push(story));
            });
          }
          referenceData.stories = stories;
        }

        Util.context.referenceData._current = referenceData;
      },
      clearCurrent: () => (Util.context.referenceData._current = null),

      getAssessments: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.assessments
          : [],

      getCapabilities: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.capabilities
          : [],
      getSkillGroups: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.skillGroups
          : [],
      getSkills: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.skills
          : [],

      getGames: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.games
          : [],

      getJourneys: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.journeys
          : [],

      getModules: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.modules
          : [],
      getStories: () =>
        Util.context.referenceData._current
          ? Util.context.referenceData._current.stories
          : [],

      getSkillById: skillId => {
        return Util.array.firstIdOrUrlIdMatch(
          Util.context.referenceData.getSkills(),
          skillId,
          'skillId'
        );
      },

      getSkillGroupById: skillGroupId => {
        return Util.array.firstIdOrUrlIdMatch(
          Util.context.referenceData.getSkillGroups(),
          skillGroupId,
          'skillGroupId'
        );
      },

      getJourneyById: journeyId => {
        return Util.array.firstIdOrUrlIdMatch(
          Util.context.referenceData.getJourneys(),
          journeyId,
          'journeyId'
        );
      },
      getJourneyStepStatus: (
        step,
        userJourney,
        myActivities,
        userProgressActivity
      ) => {
        switch (true) {
          case Util.array.none(step.activityIds) ||
            Util.array.any(
              step.activityIds.filter(
                activityId =>
                  userProgressActivity[activityId] &&
                  moment(userProgressActivity[activityId]).isAfter(
                    userJourney.createdAt
                  )
              )
            ):
            return 'completed';
          case !!myActivities.find(
            myAct =>
              step.activityIds.includes(myAct.sourceActivityId) &&
              !myAct.completedAt
          ):
            return 'inprogress';
          default:
            return 'unstarted';
        }
      }
    },

    // environment context (about the platform)
    env: {
      _current: null,

      setCurrent: (env, underConstructionDate) => {
        Util.context.env._current = {
          env,
          underConstructionDate
        };

        Util.route.setPageTitle();
      },

      getIsProd: () =>
        Util.context.env._current &&
        Util.context.env._current.env === 'production',
      getIsStag: () =>
        Util.context.env._current &&
        Util.context.env._current.env === 'staging',
      getIsDev: () =>
        Util.context.env._current &&
        Util.context.env._current.env === 'development',

      getIsUnderConstruction: () =>
        !!Util.context.env._current.underConstructionDate,
      getUnderConstructionDate: () =>
        Util.context.env._current.underConstructionDate
    },

    feature: {
      //Hiding - completely removes functionality from the platform
      getIsJourneyVisible: () => !Util.context.user.getIsStudentLicense(),
      getIsFeedbackVisible: () =>
        false && !Util.context.user.getIsStudentLicense(),

      //Disabling - locks areas of the platform, but does NOT necessarily hide
      //must first inherit from hiding settings (can't have something hidden but not disabled)
      getIsJourneyEnabled: () => Util.context.feature.getIsJourneyVisible(),
      getIsFeedbackEnabled: () =>
        false && Util.context.feature.getIsFeedbackVisible()
    }
  },

  // Util.css - holds variables useful for CSS, which for one reason or another cannot be in the SCSS file itself (often for JS/React dynamic styles) - avoids scattering them throughout the project
  css: {
    breakpoint: {
      xs: 576,
      sm: 768,
      md: 980,
      lg: 1200,

      overxs: () =>
        window.matchMedia(`(min-width: ${Util.css.breakpoint.xs}px)`).matches,
      oversm: () =>
        window.matchMedia(`(min-width: ${Util.css.breakpoint.sm}px)`).matches,
      overmd: () =>
        window.matchMedia(`(min-width: ${Util.css.breakpoint.md}px)`).matches,
      overlg: () =>
        window.matchMedia(`(min-width: ${Util.css.breakpoint.lg}px)`).matches
    },

    colour: {
      ledaBlue: '61C0E0',
      ledaLightBlue: '98D6EB',
      ledaDarkBlue: '30A1C9',
      ledaGreen: '76CECE',

      ledaGrey1: '666767',
      ledaGrey2: '828282',
      ledaGrey3: 'B2B3B3',
      ledaGrey4: 'CACDCD',
      ledaGrey5: 'EFEFF1',
      ledaGrey6: 'F8F8FA',

      ledaBlueGrey1: 'E2E7F7',
      ledaBlueGrey2: 'F1F3FA',
      ledaBlueGrey3: 'F9FAFE'
    }
  },

  document: {
    setMeta: (title, description, image, video) => {
      let prefixedTitle = title ? `Leda | ${title}` : 'Leda';

      document.title = prefixedTitle;
      document.getElementById('meta_og_title').content = prefixedTitle;

      if (description) {
        document.getElementById('meta_description').content = description;
        document.getElementById('meta_og_description').content = description;
      }
      if (image) {
        document.getElementById('meta_og_image').content = image;
      }
      if (video) {
        document.getElementById('meta_og_video').content = video;
      }
    }
  },

  // Util.enum - enums. obviously do NOT change these. adding is fine.
  enum: {
    toString: (enumType, value) => {
      return Object.keys(enumType).find(key => enumType[key] === value);
    },

    toList: enumType => {
      return Object.keys(enumType).map(key => {
        return {
          enumValue: enumType[key],
          enumName: key
        };
      });
    },

    AssessmentType: {
      Survey: 1
    },

    AudienceType: {
      Global: 1,
      Organisation: 2,
      Team: 3
    },

    DialogueStageType: {
      Intro: 1,
      SpeechNarrator: 2,
      SpeechNPC: 3,
      SpeechPlayer: 4,
      Choice: 5,
      ChoiceGood: 6,
      ChoiceBad: 7,
      ThoughtPlayer: 8
    },

    FeedbackType: {
      SkillPageThumbs: 1,
      SkillGroupPageThumbs: 2, //obsolete
      LibraryContentPage: 3, //obsolete
      StoryChapterPageThumbs: 4,
      LibraryHome: 5, //obsolete
      LockedContentInterest: 6,
      ContentPageThumbs: 7,
      ActivityStars: 8,
      ActivityMessage: 9,
      JourneyStars: 10,
      JourneyMessage: 11
    },

    FieldType: {
      Text: 1,
      Textarea: 2,
      CheckboxGroup: 3,
      RadioButtons: 4,
      DateField: 5,
      Dropdown: 6,
      Checkbox: 7,
      TimeField: 8,
      DateTimeField: 9,
      HtmlEditor: 10,
      FileUpload: 11
    },

    Frequency: {
      Hourly: 1,
      Daily: 2,
      Weekly: 3,
      Monthly: 4,
      Yearly: 5
    },

    GameType: {
      Dialogue: 1
    },

    LicenseType: {
      NoLicense: 0,
      Trial: 1,
      Full: 2,
      Student: 3
    },

    ModalType: {
      Alert: 1,
      Confirm: 2,
      VideoModal: 3,
      CompletedActivityModal: 4,
      SkillMatrixModal: 5,
      ActivityTaskEditorModal: 6,
      UserFeedbackRequest: 7,
      MyActivitiesModal: 8,
      GameModal: 9,
      AssessmentModal: 10,
      JourneyStepCongratsModal: 11, //obsolete
      UserEditorModal: 12,
      UserNotificationsModal: 13,
      ImageCarouselModal: 14,
      UserListModal: 15,
      ScheduleAtModal: 16,
      PostEditorModal: 17,
      UrlModal: 18,
      TextInputModal: 19,
      ActivityTaskFieldEditorModal: 20,
      CreateTeamModal: 21,
      EditPresetPost: 22
    },

    NotificationType: {
      General: 1,
      Welcome: 2,
      Journey: 3,
      Post: 4,
      PostComment: 5,
      PostLike: 6
    },

    Operator: {
      EqualTo: 1,
      NotEqualTo: 2,
      LessThan: 3,
      GreaterThan: 4,
      LessThanOrEqualTo: 5,
      GreaterThanOrEqualTo: 6
    }
  },

  event: {
    deferredInstallPrompt: null,

    stopPropagation: e => (e && e.stopPropagation ? e.stopPropagation() : null),

    registerWindowEvents: () => {
      window.addEventListener('beforeunload', () =>
        Util.analytics.track('WindowClosed', { nonInteraction: 1 })
      );
      window.addEventListener('blur', () =>
        Util.analytics.track('WindowBlur', { nonInteraction: 1 })
      );
      window.addEventListener('focus', () =>
        Util.analytics.track('WindowFocus', { nonInteraction: 1 })
      );

      // Autorefresh reset events
      ['click', 'mousemove', 'touchstart', 'keypress', 'scroll'].forEach(
        event => {
          window.addEventListener(event, () => Util.autoRefresh.reset());
        }
      );
      Util.autoRefresh.init();

      //TODO i dont think these are doing anything since we're not trying to be PWA anymore
      window.addEventListener('beforeinstallprompt', e => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        Util.event.deferredInstallPrompt = e;
      });
      window.addEventListener('appinstalled', evt => {
        console.log('App installed');
      });
    }
  },

  // Util.format - used to stringify and format values (like dates)
  format: {
    acronym: words => {
      let acronym = '';

      words
        .replace('-', ' ')
        .split(' ')
        .forEach(word => {
          acronym += String(word[0]).toUpperCase();
        });

      return acronym;
    },

    date: {
      as: (date = new Date(), formatStr = 'dddd, MMMM Do YYYY, h:mm:ss a') => {
        return moment(date).format(formatStr);
      },

      full: (date = new Date()) => {
        return moment(date).format('dddd MMM Do YYYY - h:mma');
      },

      // 4 days
      // 2 weeks
      fromNow: (date = new Date(), suffix = '', prefix = '') => {
        //The (true) below removes the suffix which removes the need to determind if it is past or future
        return prefix + moment(date).fromNow(true) + suffix;
      },

      fromNowReadable: (date = new Date()) => {
        let now = moment();
        let momentDate = moment(date);

        switch (true) {
          case momentDate.isSame(now, 'day'):
            return Util.format.date.fromNow(date, ' ago');
          case now
            .clone()
            .subtract(1, 'days')
            .startOf('day') < momentDate:
            return Util.format.date.calendar(date);
          case now.clone().subtract(3, 'days') < momentDate:
            return Util.format.date.weekdayTime(date);
          default:
            return Util.format.date.dayMonthTime(date);
        }
      },

      preciseFromNow: (date = new Date()) => {
        const diffDuration = moment.duration(moment(date).diff(moment()));
        let prefix = '',
          suffix = '';

        if (moment(date) > moment()) {
          prefix = 'in ';
        } else {
          suffix = ' ago';
        }

        return (
          prefix +
          humanizeDuration(diffDuration, {
            units: ['y', 'mo', 'w', 'd'],
            round: true,
            delimiter: ' and '
          }) +
          suffix
        );
      },

      //Last Monday at 2:30 AM
      //Yesterday at 2:30 AM
      //Today at 2:30 AM
      //Tomorrow at 2:30 AM
      //Sunday at 2:30 AM
      //7/10/2011
      calendar: (date = new Date()) => {
        return moment(date).calendar();
      },

      //03/04/19
      shortDate: (date = new Date()) => {
        return moment(date).format('DD/MM/YY');
      },

      //03/04/19 - 10:10am
      shortDateTime: (date = new Date(), separator = ' ') => {
        return (
          moment(date).format('DD/MM/YY') +
          separator +
          moment(date).format('h:mma')
        );
      },

      //2 Apr at 4:20pm
      dayMonthTime: (date = new Date()) => {
        return (
          moment(date).format('D MMM') + ' at ' + moment(date).format('h:mma')
        );
      },

      dayMonthYear: (date = new Date()) => {
        return moment(date).format('D MMM YYYY');
      },

      //2019-12-25
      inputDate: (date = new Date()) => {
        return moment(date).format('YYYY-MM-DD');
      },

      // 16:20:00 (used for db times)
      inputTime: (date = new Date()) => {
        return moment(date).format('HH:mm:ss');
      },

      // 4:00 PM
      time: (date = new Date()) => {
        return moment(date).format('h:mm a');
      },

      //Monday April 2, 2019
      weekdayMonthDayYear: (date = new Date()) => {
        return moment(date).format('dddd MMMM D, YYYY');
      },

      //Monday at 4:20pm
      weekdayTime: (date = new Date()) => {
        return (
          moment(date).format('dddd') + ' at ' + moment(date).format('h:mma')
        );
      },

      //1554246600
      unix: (date = new Date()) => {
        return moment(date).unix();
      }
    },

    //similar to acronym but max 2 letters
    initials: (firstName = '', lastName = '') => {
      return (
        (firstName.length > 0 ? firstName[0] : '') +
        (lastName.length > 0 ? lastName[0] : '')
      );
    },

    loremIpsum: charCap => {
      let lorem =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eros dui, gravida ut ligula id, congue scelerisque nibh. Aenean leo est, maximus quis dolor id, gravida gravida justo. Curabitur convallis fringilla nunc, vel molestie lectus placerat eget. Aenean non tellus nec orci tincidunt molestie vitae eget elit. Maecenas pharetra, nunc id pulvinar finibus, ligula felis laoreet lectus, id viverra magna nunc in nulla. In hac habitasse platea dictumst. Morbi id libero quis libero laoreet egestas. Sed neque nisi, dapibus in iaculis non, dictum ut dui. Etiam vitae mattis neque. Donec faucibus nibh velit. Vivamus a nibh at est porttitor cursus. Suspendisse potenti. Vivamus faucibus velit leo, placerat finibus massa vehicula vitae. In tincidunt euismod aliquet. Nullam tincidunt tristique eros non imperdiet.';
      return charCap ? lorem.substring(0, charCap) : lorem;
    },

    pluralise: (countOrArray, singular, plural) => {
      let count = Array.isArray(countOrArray)
        ? countOrArray.length
        : countOrArray;
      return count === 1 ? singular : plural || singular + 's';
    },

    readingTime: htmlContent => {
      let tmp = document.createElement('DIV');
      tmp.innerHTML = htmlContent;
      let plainText = tmp.textContent || tmp.innerText || '';

      let words = plainText.split(' ').length;
      let wordsPerMin = 200;

      let mins = Math.ceil(words / wordsPerMin);
      return `${mins} min read`;
    },

    romanize: num => {
      if (typeof num !== 'number') return false;

      let digits = String(+num).split(''),
        key = [
          '',
          'C',
          'CC',
          'CCC',
          'CD',
          'D',
          'DC',
          'DCC',
          'DCCC',
          'CM',
          '',
          'X',
          'XX',
          'XXX',
          'XL',
          'L',
          'LX',
          'LXX',
          'LXXX',
          'XC',
          '',
          'I',
          'II',
          'III',
          'IV',
          'V',
          'VI',
          'VII',
          'VIII',
          'IX'
        ],
        roman_num = '',
        i = 3;

      while (i--) roman_num = (key[+digits.pop() + i * 10] || '') + roman_num;
      return Array(+digits.join('') + 1).join('M') + roman_num;
    },

    secsToDuration: secs => {
      if (!secs) return '0:00';

      secs = Number(secs);
      var h = Math.floor(secs / 3600);
      var m = Math.floor((secs % 3600) / 60);
      var s = Math.floor((secs % 3600) % 60);

      var hDisplay = h > 0 ? h + ':' : '';
      var mDisplay = m > 0 ? m + ':' : '0';
      var sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : '00';
      return hDisplay + mDisplay + sDisplay;
    }
  },

  icon: {
    activity: iconActivity,
    add: iconAdd,
    arrow: {
      down: iconArrowDown,
      left: iconArrowLeft,
      right: iconArrowRight,
      up: iconArrowUp
    },
    arrowDouble: {
      down: iconArrowDoubleDown,
      left: iconArrowDoubleLeft,
      right: iconArrowDoubleRight,
      up: iconArrowDoubleUp
    },
    arrowHead: {
      down: iconArrowHeadDown,
      left: iconArrowHeadLeft,
      right: iconArrowHeadRight,
      up: iconArrowHeadUp
    },
    assessment: iconAssessment,
    attachment: iconAttachment,
    bin: iconBin,
    camera: iconCamera,
    clock: iconClock,
    close: iconClose,
    comment: iconComment,
    contextMenu: iconContextMenu,
    copy: iconCopy,
    explore: iconExplore,
    edit: iconEdit,
    email: iconEmail,
    feedback: iconFeedback,
    filter: iconFilter,
    game: iconGame,
    globalUser: iconGlobalUser,
    graph: iconGraph,
    help: iconHelp,
    home: iconHome,
    breakWhite: iconBreakWhite,
    breakGrey: iconBreakGrey,
    image: iconImage,
    journey: iconJourney,
    lock: iconLock,
    login: iconLogin,
    logout: iconLogout,
    menu: iconNavigationMenu,
    notification: iconNotification,
    organisation: iconOrganisation,
    party: iconParty,
    pin: iconPin,
    play: iconPlay,
    post: iconPost,
    refresh: iconRefresh,
    reorderDown: iconReorderDown,
    settings: iconSettings,
    search: iconSearch,
    skill: iconSkill,
    star: iconStar,
    story: iconStory,
    tag: iconTag,
    team: iconTeam,
    teamAdd: iconTeamAdd,
    textarea: iconTextarea,
    textfield: iconTextField,
    thumb: iconThumb,
    tick: iconTick,
    treasure: iconTreasure,
    undo: iconUndo,
    unlock: iconUnlock,
    user: iconUser,
    users: iconUsers,
    video: iconVideo,
    view: iconView
  },

  id: {
    tempId: {
      _tempIdPrefix: 'tempId_',
      _tempIdCount: 0,

      getTempId: () => {
        return Util.id.tempId._tempIdPrefix + Util.id.tempId._tempIdCount++;
      },

      clearAllTempIds: objectOrArray => {
        //This could hypothetically nullify something incorrectly, but serverside checks prevent any real issues that could arise.
        // We are always prepared for any data at any of our endpoints

        if (!objectOrArray) return;

        if (Array.isArray(objectOrArray)) {
          objectOrArray.forEach(item => Util.id.tempId.clearAllTempIds(item));
        } else if (typeof objectOrArray === 'object') {
          Object.keys(objectOrArray).forEach(key => {
            let val = objectOrArray[key];

            if (typeof val === 'string') {
              if (val.startsWith(Util.id.tempId._tempIdPrefix))
                objectOrArray[key] = null;
            } else {
              Util.id.tempId.clearAllTempIds(val);
            }
          });
        }
      }
    },

    urlId: {
      isIdOrUrlIdMatch: (object, id, idProp, urlIdProp = 'urlId') => {
        return isNaN(id)
          ? String(object[urlIdProp]).toLowerCase() === String(id).toLowerCase()
          : object[idProp] === parseInt(id, 10);
      },

      isUrlIdFormat: urlId => {
        let regex = new RegExp('^[a-z/-]+$');
        return regex.test(urlId);
      }
    }
  },

  leadBooster: {
    _chatHolderId: 'pipedrive-chat-holder',

    show: () => {
      let el = document.getElementById(Util.leadBooster._chatHolderId);
      if (el) el.style.display = 'block';
    },

    hide: () => {
      let el = document.getElementById(Util.leadBooster._chatHolderId);
      if (el) el.style.display = 'none';
    }
  },

  moment: {
    isValid: (dateStr, format = moment.ISO_8601) => {
      let momentDate = moment(dateStr, format);
      return !!momentDate && momentDate.isValid();
    },

    startOfDay: (date = new Date()) => {
      return moment(date)
        .startOf('day')
        .toDate();
    },

    endOfDay: (date = new Date()) => {
      return moment(date)
        .endOf('day')
        .toDate();
    },

    //eg: Util.moment.parse('09:10:00', 'HH:mm:ss')
    parse: (string, format) => {
      return moment(string, format).toDate();
    },

    diffDays: (date1 = new Date(), date2 = new Date()) => {
      return moment(date1).diff(moment(date2), 'days');
    },

    addDays: (date = new Date(), nbDays = 0) =>
      new Date(moment(date).add(nbDays, 'days')).toISOString(),
    substractDays: (date = new Date(), nbDays = 0) =>
      new Date(moment(date).subtract(nbDays, 'days')).toISOString()
  },

  number: {
    isOdd: number => {
      return number % 2 === 1;
    },

    isEven: number => {
      return number % 2 === 0;
    },

    getRandom: (min, max) => Math.floor(Math.random() * (max - min + 1)) + min
  },

  // Util.operator - used to evaluate conditions constructed with Operator enum
  operator: {
    evaluate: (operator, valueA, valueB) => {
      switch (operator) {
        case Util.enum.Operator.EqualTo:
          return valueA === valueB;
        case Util.enum.Operator.NotEqualTo:
          return valueA !== valueB;
        case Util.enum.Operator.LessThan:
          return valueA < valueB;
        case Util.enum.Operator.GreaterThan:
          return valueA > valueB;
        case Util.enum.Operator.LessThanOrEqualTo:
          return valueA <= valueB;
        case Util.enum.Operator.GreaterThanOrEqualTo:
          return valueA >= valueB;
        default:
          return false;
      }
    },

    filterToMaxAndMins: (array, opProp = 'operator', valProp = 'value') => {
      let filteredArray = [];

      // Only push the highest greater than / greater than or equal to
      let maxGtOrGteFeedback = Util.operator.maxGtOrGte(array, opProp, valProp);
      if (maxGtOrGteFeedback) filteredArray.push(maxGtOrGteFeedback);
      // Only push the lowest less than / less than or equal to
      let minLtOrLteFeedback = Util.operator.minLtOrLte(array, opProp, valProp);
      if (minLtOrLteFeedback) filteredArray.push(minLtOrLteFeedback);
      // Push all the equal to / not equal to
      filteredArray.concat(
        array.filter(
          item =>
            item[opProp] === Util.enum.Operator.EqualTo ||
            item[opProp] === Util.enum.Operator.NotEqualTo
        )
      );

      return filteredArray;
    },

    maxGtOrGte: (array, opProp = 'operator', valProp = 'value') => {
      let gtOrGtes = array.filter(
        item =>
          item[opProp] === Util.enum.Operator.GreaterThan ||
          item[opProp] === Util.enum.Operator.GreaterThanOrEqualTo
      );

      if (Util.array.none(gtOrGtes)) return null;

      return gtOrGtes.reduce((a, b) => {
        let aVal =
          a[opProp] === Util.enum.Operator.GreaterThan
            ? a[valProp]
            : a[valProp] - 1;
        let bVal =
          b[opProp] === Util.enum.Operator.GreaterThan
            ? b[valProp]
            : b[valProp] - 1;
        return Math.max(aVal, bVal) === aVal ? a : b;
      });
    },

    minLtOrLte: (array, opProp = 'operator', valProp = 'value') => {
      let ltOrLtes = array.filter(
        item =>
          item[opProp] === Util.enum.Operator.LessThan ||
          item[opProp] === Util.enum.Operator.LessThanOrEqualTo
      );

      if (Util.array.none(ltOrLtes)) return null;

      return ltOrLtes.reduce((a, b) => {
        let aVal =
          a[opProp] === Util.enum.Operator.LessThan
            ? a[valProp]
            : a[valProp] + 1;
        let bVal =
          b[opProp] === Util.enum.Operator.LessThan
            ? b[valProp]
            : b[valProp] + 1;
        return Math.min(aVal, bVal) === aVal ? a : b;
      });
    }
  },

  // Util.route - used to construct routes(urls) from objects. keeps consistency
  route: {
    isInternal: url => {
      let host = window.location.hostname;
      let linkHost = /^https?:\/\//.test(url) // absolute url
        ? new URL(url).hostname
        : window.location.hostname;

      //The link might be to www.getleda while the current window is without www. or vice versa.
      return (
        host === linkHost ||
        host === `www.${linkHost}` ||
        `www.${host}` === linkHost
      );
    },

    isCurrently: route => {
      return Util.route.getCurrent() === route;
    },

    isCurrentlyStartsWith: route => {
      return Util.route.getCurrent().startsWith(route);
    },

    setPageTitle: notifications => {
      let pageName = '';
      if (Util.context.env.getIsDev()) pageName += 'DEV | ';
      if (Util.context.env.getIsStag()) pageName += 'STAGING | ';

      let notificationsCount = '';
      if (notifications && notifications.length) {
        let unseenNotifications = notifications.filter(n => !n.seenAt);
        notificationsCount =
          unseenNotifications && unseenNotifications.length > 0
            ? `(${unseenNotifications.length}) `
            : '';
      }

      document.title = `${notificationsCount}${pageName}Leda`;
    },

    getCurrent: () => {
      let pathname = window.location.pathname;
      if (pathname.endsWith('/'))
        pathname = pathname.substr(0, pathname.length - 1);
      return pathname;
    },

    getCurrentName: () => {},

    getCurrentCategory: () => {
      let currentPath = Util.route.getCurrent();

      if (currentPath === '' || currentPath.startsWith('/site')) {
        return 'Site';
      } else if (currentPath === '/app' || currentPath.startsWith('/app/')) {
        return 'App';
      } else if (currentPath.startsWith('/auth/')) {
        return 'Auth';
      } else if (currentPath.startsWith('/admin/')) {
        return 'Admin';
      }

      return 'Unknown';
    },

    getPossibleUrlId: url => {
      let splitUrl = url.split('/');

      return splitUrl.length > 0 ? splitUrl[splitUrl.length - 1] : '';
    },

    toParameterString: paramObject => {
      if (!paramObject || Util.array.none(Object.keys(paramObject))) return '';

      let str = '';

      Object.keys(paramObject).forEach(key => {
        let val = paramObject[key];
        if (val) str += `${!!str ? '&' : '?'}${key}=${val}`;
      });

      return str;
    },

    getParameterByName: (name, url) => {
      if (!url) url = window.location.href;
      name = name.replace(/[[]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    //All of these MUST begin with /admin/
    admin: {
      activityEditor: (activityId = '') =>
        `/admin/activity-editor/${activityId}`,
      activityList: () => `/admin/activity-list`,
      communicationList: recipient => `/admin/communication-list/${recipient}`,
      contentEditor: (contentId = '') => `/admin/content-editor/${contentId}`,
      contentList: () => `/admin/content-list`,
      notificationEditor: notificationId =>
        `/admin/notification/editor/${notificationId}`,
      notificationList: () => `/admin/notification-list`,
      organisationEditor: (organisationId = '') =>
        `/admin/organisation-editor/${organisationId}`,
      organisationCreator: () => `/admin/organisation-creator`,
      teamList: () => `/admin/team-list`,
      teamEditor: teamId => `/admin/team-editor/${teamId}`,
      teamEditorJourneys: teamId =>
        `/admin/team-editor/${teamId}/settings/journeys`,
      teamPresets: () => '/admin/team-presets',
      teamPresetEditor: presetId => `/admin/team-presets/${presetId}`,
      userList: () => `/admin/user-list`,
      home: () => `/admin`,
      test: () => `/admin/test`,
      journeyList: () => `/admin/journey-list`
    },

    //All of these MUST begin with /auth/
    auth: {
      login: thenTo => `/auth/login${Util.route.toParameterString({ thenTo })}`,
      passwordForgot: () => `/auth/password-forgot`,
      passwordReset: token => `/auth/password-reset/${token}`,
      verifyAccount: token => `/auth/verify-account/${token}`
    },

    //All of these MUST begin with /app/
    app: {
      activities: () => `/app/explore/activities`,
      myActivities: () => `/app/activities`,
      completedActivities: () => `/app/activities/completed-activities`,
      explore: () => `/app/explore`,
      stories: () => `/app/explore/story`,
      story: storyUrlId => `/app/explore/story/${storyUrlId}`,
      storyChapter: (storyUrlId, chapterUrlId) =>
        `/app/explore/story/${storyUrlId}/chapter/${chapterUrlId}`,
      skills: () => `/app/explore/skill`,
      skill: skillUrlId => `/app/explore/skill/${skillUrlId}`,
      skillGroup: skillGroupId => `/app/explore/skill/group/${skillGroupId}`,
      games: () => `/app/explore/games`,
      assessments: () => `/app/explore/assessments`,
      home: () => {
        if (Util.context.user.getIsStudentLicense())
          return Util.route.app.stories();
        return Util.route.app.teams();
      },
      journey: journeyUrlId => `/app/journey/${journeyUrlId}`,
      journeyStep: (journeyUrlId, stepUrlId) =>
        stepUrlId
          ? `/app/journey/${journeyUrlId}/step/${stepUrlId}`
          : Util.route.app.journey(journeyUrlId),
      feedback: () => `/app/feedback`,
      settings: () => `/app/settings`,
      settingsNotification: () => `/app/settings/notification`,
      settingsProfile: () => `/app/settings/profile`,
      help: () => `/app/help`,
      teams: () => `/app/team`,
      team: teamId => `/app/team/${teamId}`,
      teamInsights: teamId => `/app/team/${teamId}/insights`,
      teamJourneys: teamId => `/app/team/${teamId}/journeys`,
      teamMembers: teamId => `/app/team/${teamId}/members`,
      teamPosts: teamId => `/app/team/${teamId}/posts`,
      profile: userId =>
        `/app/profile/${userId || Util.context.user.getCurrentUserId()}`,
      post: postId => `/app/post/${postId}`
    },

    site: {
      contact: prefillFormData =>
        `/contact${Util.route.toParameterString(prefillFormData)}`,
      tryForFree: prefillFormData =>
        `/try-for-free${Util.route.toParameterString(prefillFormData)}`,
      courses: () => `/courses`,
      error404: () => `/404?redirectedFrom=${Util.route.getCurrent()}`,
      home: () => `/`,
      articles: () => `/articles`,
      articlesContent: contentId => `/articles/${contentId}`,
      articlesTag: tagId => `/articles/tag/${tagId}`,
      how: () => `/how`,
      licenseExpired: () => `/license-expired`,
      platform: () => `/platform`,
      pricing: () => `/pricing`,
      privacyPolicy: () => `/privacy-policy`,
      termsConditions: () => `/terms-conditions`,
      userFeedback: token => `/user-feedback/${token}`,
      underConstruction: () => `/under-construction`,
      unsubscribe: () => `/unsubscribe`
    }
  },

  scroll: {
    to: (scrollTop, scrollingElement = null, behavior = 'auto') => {
      if (!scrollingElement)
        scrollingElement = document.getElementsByClassName(
          'foundation-content'
        )[0];

      if (scrollingElement && scrollingElement.scrollTo) {
        scrollingElement.scrollTo({
          top: scrollTop,
          behavior //eg. "smooth"
        });
      } else {
        let stickyHeaderElement = document.getElementsByClassName(
          'sticky-header'
        )[0];
        let headerOffset = stickyHeaderElement
          ? stickyHeaderElement.offsetHeight
          : 0;

        window.scrollTo({
          top: scrollTop - headerOffset,
          behavior //eg. "smooth"
        });
      }
    },

    //Seems to be the best for elements positioned within the document (eg. not in a modal)
    toElement: (element, behavior = 'smooth') => {
      let topOfElement =
        element.getBoundingClientRect().top - element.offsetHeight;
      Util.scroll.to(topOfElement, null, behavior);
    },

    // I've had a lot of trouble with this, should not be expected to scroll anything other than the entire window basically.
    // Instead, i suggest making the parent div position: relative and scroll it to the child's .offsetTop property
    intoView: (element, behavior = 'smooth', block = 'start') => {
      element.scrollIntoView({
        behavior,
        block
      });
    }
  },

  sort: {
    _invalidValSortFn: (val1Valid, val2Valid) => {
      if (!val1Valid && !val2Valid) return 0; //no change
      if (!val1Valid) return -1; //only val2Valid
      return 1; //only val1Valid
    },

    by: (sortProp, item1, item2, sortFn = Util.sort.asString) => {
      return sortFn(item1[sortProp], item2[sortProp]);
    },

    asString: (val1, val2) => {
      let val1Valid = val1 !== undefined && val1 !== null && isNaN(val1);
      let val2Valid = val2 !== undefined && val2 !== null && isNaN(val2);

      if (val1Valid && val2Valid) return ('' + val1).localeCompare('' + val2);

      return Util.sort._invalidValSortFn(val1Valid, val2Valid);
    },

    asNumber: (val1, val2) => {
      let val1Valid = !isNaN(val1);
      let val2Valid = !isNaN(val2);

      if (val1Valid && val2Valid) {
        let num1 = parseFloat(val1);
        let num2 = parseFloat(val2);
        if (num1 < num2) return -1;
        if (num1 > num2) return 1;
        return 0;
      }

      return Util.sort._invalidValSortFn(val1Valid, val2Valid);
    },

    asBoolean: (val1, val2) => {
      let val1Valid = typeof val1 === 'boolean';
      let val2Valid = typeof val2 === 'boolean';

      if (val1Valid && val2Valid) {
        if (val1 && !val2) return -1;
        if (!val1 && val2) return 1;
        return 0;
      }

      return Util.sort._invalidValSortFn(val1Valid, val2Valid);
    },

    asDate: (val1, val2) => {
      let val1Valid = val1 && Util.moment.isValid(val1);
      let val2Valid = val2 && Util.moment.isValid(val2);

      if (val1Valid && val2Valid) return new Date(val1) - new Date(val2);

      return Util.sort._invalidValSortFn(val1Valid, val2Valid);
    }
  },

  //Util.storage - for getting images and other assets/files from blob storage
  storage: {
    _azureAssetPath: 'https://ledastorageaccount.blob.core.windows.net/assets',
    _azureUploadPath:
      'https://ledastorageaccount.blob.core.windows.net/uploads',

    //Assets
    storyIcon: (urlId = 'default') => {
      return urlId
        ? `${Util.storage._azureAssetPath}/story/icon/${urlId}.svg`
        : null;
    },
    storyFeature: (urlId = 'default') => {
      return `${Util.accordion._azureAssetPath}/story/feature/${urlId}.svg`;
    },
    skillIcon: (urlId = 'default') => {
      return `${Util.storage._azureAssetPath}/skill/icon/${urlId}.png`;
    },
    skillCircularIcon: (urlId = 'default') => {
      return `${Util.storage._azureAssetPath}/skill/circular_icon/${urlId}.png`;
    },
    skillAnimatedIcon: (urlId = 'default') => {
      return urlId
        ? `${Util.storage._azureAssetPath}/skill/animated_icon/${urlId}.gif`
        : null;
    },
    skillGroupIcon: (urlId = 'default') => {
      return urlId
        ? `${Util.storage._azureAssetPath}/skill_group/icon/${urlId}.png`
        : null;
    },
    gameAsset: (fileName, urlId) => {
      return fileName
        ? `${Util.storage._azureAssetPath}/game/asset/${urlId}/${fileName}`
        : null;
    },
    gameIcon: (urlId = 'default') => {
      return urlId
        ? `${Util.storage._azureAssetPath}/game/icon/${urlId}.png`
        : null;
    },
    videoThumbnail: (urlId = 'default') => {
      return urlId
        ? `${Util.storage._azureAssetPath}/video/thumbnail/${urlId}.jpg`
        : null;
    },

    //Uploads
    blob: blobId => {
      return blobId ? `${Util.storage._azureUploadPath}/${blobId}` : null;
    }
  },

  text: {
    getLinks: text => {
      let links = [];

      linkifyHtml(text, {
        formatHref: (href, type) => {
          links.push(href);
          return href;
        }
      });

      return links;
    },

    toSafeHtml: text => {
      if (!text) return text;

      //Strip out any existing html tags
      text = striptags(text);

      //Adds our safe HTML to customer text
      // Can be extended to do formatting like **bold** or __italic__

      //Linkify any text. rel="nofollow" to stop google from crawling it
      text = linkifyHtml(text, {
        defaultProtocol: 'https',
        attributes: url =>
          Util.route.isInternal(url) ? {} : { rel: 'nofollow noopener' },
        target: url => (Util.route.isInternal(url) ? '_self' : '_blank')
      });

      //Bold
      text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

      //Italics (should happen after bold)
      text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');

      //Underline
      text = text.replace(/__(.*?)__/g, '<u>$1</u>');

      return text;
    }
  },

  userAgent: {
    getCurrent: () => bowser.getParser(window.navigator.userAgent).parsedResult,

    isIE: () => window.document.documentMode,

    isMobile: () => Util.userAgent.getCurrent().platform.type === 'mobile',

    isDesktop: () => Util.userAgent.getCurrent().platform.type === 'desktop'
  }
};

export default Util;
