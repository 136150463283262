import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../Util';

import Button from '../Button/Button';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import ContextMenu from '../ContextMenu/ContextMenu';
import LedaLogo from '../LedaLogo/LedaLogo';

export default class SiteHeader extends Component {
  render() {
    let user = Util.context.user.getCurrent();

    let links = [
      {
        label: 'How it works',
        to: Util.route.site.how()
      },
      {
        label: 'Articles',
        to: Util.route.site.articles()
      }
    ];

    return (
      <div className="leda-site-header sticky-header">
        <div className="leda-container">
          <div className="leda-row">
            <div className="site-header-inner">
              <div className="header-left sm-only">
                <ContextMenu
                  align="left"
                  icon={Util.icon.menu}
                  items={
                    !user || !user.userId
                      ? [
                        {
                          label: 'Login',
                          icon: Util.icon.user,
                          to: Util.route.auth.login()
                        },
                        ...links
                      ]
                      : links
                  }
                />
              </div>
              <LedaLogo className="header-center" />
              <div className="header-right">
                {links.map((link, idx) => (
                  <Link
                    key={idx}
                    className={`header-link ${
                      Util.route.isCurrentlyStartsWith(link.to) ? 'active' : ''
                      }`}
                    to={link.to}
                  >
                    {link.label}
                  </Link>
                ))}
                {!user || !user.userId ? (
                  <div className="button-container">
                    <Button
                      label="Login"
                      className="lg-only login-button"
                      isSecondary={true}
                      to={Util.route.auth.login()}
                    />
                    <Button
                      label="Try for free"
                      isSecondary={false}
                      to={Util.route.site.tryForFree()}
                    />
                  </div>
                ) : (
                    <div className="button-container">
                      <ProfilePhoto isMe={true} to={Util.route.app.home()} />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
