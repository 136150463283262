import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';
//Redux
import { connect } from 'react-redux';
import { deleteMyActivityAction } from '../../../actions/activityActions';

//Components
import ActivityTagline from '../ActivityTagline/ActivityTagline';
import MyActivityTasksLabel from '../MyActivityTasksLabel/MyActivityTasksLabel';
import MyActivityCardsView from '../CardsView/MyActivityCardsView/MyActivityCardsView';
import MyActivityTasksView from '../MyActivityTasksView/MyActivityTasksView';
import ContextMenu from '../ContextMenu/ContextMenu';

class MyActivitiesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialMyActivityIds: [],
      selectedMyActivityId: null,

      isToggleListVisible: true
    };

    this.setSelectedMyActivityId = this.setSelectedMyActivityId.bind(this);
    this.toggleListVisible = this.toggleListVisible.bind(this);
  }
  componentDidMount() {
    let selectedMyActivityId = this.props.selectedMyActivityId; // This is likely to be null

    if (this.props.selectedActivityId) {
      let selectedMyActivity = this.props.myActivities.find(
        myActivity =>
          myActivity.sourceActivityId === this.props.selectedActivityId
      );
      selectedMyActivityId = selectedMyActivity
        ? selectedMyActivity.myActivityId
        : null;
    }

    this.setState({
      selectedMyActivityId,
      isToggleListVisible: !this.props.selectedActivityId,
      initialMyActivityIds: this.props.myActivities
        .filter(myAct => !myAct.completedAt)
        .map(myAct => myAct.myActivityId)
    });
  }
  componentWillReceiveProps(props) {
    // We want to display any myActivities that were in the list when it is first rendered (in a completed state)
    // But they will not remain when the component is mounted next
    let initialMyActivityIds = this.state.initialMyActivityIds;
    let incomingNewMyActivityIds = props.myActivities
      .filter(
        myAct =>
          !myAct.completedAt &&
          !this.state.initialMyActivityIds.includes(myAct.myActivityId)
      )
      .map(myAct => myAct.myActivityId);

    this.setState({
      initialMyActivityIds: [
        ...initialMyActivityIds,
        ...incomingNewMyActivityIds
      ]
    });
  }
  setSelectedMyActivityId(myActivityId) {
    let incomingMyActivity = this.props.myActivities.find(
      myActivity => myActivity.myActivityId === myActivityId
    );

    if (incomingMyActivity) {
      this.setState({
        selectedMyActivityId: myActivityId,
        isToggleListVisible: false
      });
    }
  }
  toggleListVisible() {
    this.setState({
      isToggleListVisible: !this.state.isToggleListVisible
    });
  }
  render() {
    let filteredMyActivities = this.props.myActivities.filter(
      myAct =>
        !myAct.completedAt ||
        this.state.initialMyActivityIds.includes(myAct.myActivityId)
    );

    //If there are no myActivities, return a real basic alert window
    if (!Util.array.any(filteredMyActivities)) {
      return (
        <div className="my-activities-panel">
          <div className="empty-details">
            <h1 className="my-act-title">My Activities</h1>
            <p>You don't have any activities in progress.</p>
          </div>
        </div>
      );
    }

    //selectedMyActivity is determined by state id or just take the first in the list
    let selectedMyActivity =
      filteredMyActivities.find(
        myAct => myAct.myActivityId === this.state.selectedMyActivityId
      ) || filteredMyActivities[0];

    return (
      <div
        className={`my-activities-panel ${
          this.state.isToggleListVisible ? 'list-visible' : ''
          }`}
      >
        <div className="panel-list">
          <div className="toggle-list-strip" onClick={this.toggleListVisible}>
            <ReactSVG
              src={Util.icon.arrow.left}
              className="arrow-icon svg-button"
            />
            View all activities ({filteredMyActivities.length})
          </div>
          <div className="my-activities-list">
            <MyActivityCardsView
              myActivities={filteredMyActivities}
              selectedMyActivityId={selectedMyActivity.myActivityId}
              onCardClick={myActivity =>
                this.setSelectedMyActivityId(myActivity.myActivityId)
              }
            />
          </div>
        </div>
        <div className="panel-content">
          <div className="my-activities-list">
            <MyActivityCardsView
              myActivities={filteredMyActivities}
              selectedMyActivityId={selectedMyActivity.myActivityId}
              onCardClick={myActivity =>
                this.setSelectedMyActivityId(myActivity.myActivityId)
              }
            />
          </div>
          <div className="selected-activity-panel">
            <div className="selected-activity-header">
              <ActivityTagline
                journeyStepId={selectedMyActivity.journeyStepId}
                skills={selectedMyActivity.myActivitySkills}
              />
              <h2 className="my-act-name">{selectedMyActivity.name}</h2>
              <div className="my-act-header-lower">
                <MyActivityTasksLabel myActivity={selectedMyActivity} />
                <div className="flex-spacer"></div>
                <ContextMenu
                  items={[
                    {
                      icon: Util.icon.bin,
                      label: 'Delete Activity',
                      onClick: () =>
                        this.props.deleteMyActivityAction(
                          selectedMyActivity.myActivityId
                        )
                    }
                  ]}
                />
              </div>
            </div>
            <MyActivityTasksView myActivity={selectedMyActivity} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myActivities: state.activityState.myActivities,
  userJourneys: state.journeyState.userJourneys
});

export default connect(
  mapStateToProps,
  { deleteMyActivityAction }
)(MyActivitiesView);
