import {
  MOD_MODAL_OPEN,
  MOD_MODAL_CLOSE,
  MOD_MODAL_CLOSE_ALL
} from '../actions/types';

const initialState = {
  // Array of modal objects - the last modal in the array will be the one displayed on top
  modals: []
};

export default function(state = initialState, action) {
  let newState = state;

  switch (action.type) {
    case MOD_MODAL_OPEN:
      newState = {
        ...state,
        modals: [...state.modals, action.config]
      };
      break;

    case MOD_MODAL_CLOSE:
      newState = {
        ...state,
        modals: state.modals.filter(modal => modal.id !== action.modalId)
      };
      break;

    case MOD_MODAL_CLOSE_ALL:
      newState = initialState;
      break;

    default:
      break;
  }

  return newState;
}
