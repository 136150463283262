import React, { Component } from 'react';
import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import TeamJourneyProgressChart from '../../../UI/Chart/TeamJourneyProgressChart';
import TeamRetentionChart from '../../../UI/Chart/TeamRetentionChart';

export default class AdminTeamEditorInsights extends Component {
  render() {
    const teamId = this.props.teamId;
    return (
      <AdminLayout>
        <div className="leda-container-md">
          <div className="leda-row">
            <TeamRetentionChart teamId={teamId} />
            <div style={{ height: '50px' }}></div>
            <TeamJourneyProgressChart teamId={teamId} />
          </div>
        </div>
      </AdminLayout>
    );
  }
}
