import React, { Component } from 'react';
import validator from 'validator';
import Util from '../../../../Util';
import withForm from '../withForm';

import Button from '../../Button/Button';

class UserProfileEditorForm extends Component {
  render() {
    return (
      <form className="form-register" onSubmit={this.props.onSubmit}>
        <div className="form-row">
          {this.props.getField('firstName')}
          {this.props.getField('lastName')}
        </div>
        {this.props.getField('jobTitle')}
        {this.props.getField('phone')}
        {this.props.getField('bio')}
        <div className="button-container">
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
          <Button label="Save" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(UserProfileEditorForm, {
  getOverallError: formData => {
    if (formData.firstName === 'Luke' && formData.lastName === 'Hedin')
      return "You're not Luke Hedin! I am!";
  },
  fields: {
    firstName: {
      label: 'Given name',
      getError: val => {
        if (!validator.isLength(val, { max: 250 })) return 'Enter a first name';
      }
    },
    lastName: {
      label: 'Family name',
      getError: val => {
        if (!validator.isLength(val, { max: 250 })) return 'Enter a last name';
      }
    },
    jobTitle: {
      label: 'Job title',
      isOptional: true,
      getError: val => {
        if (!validator.isLength(val, { max: 250 }))
          return 'Job title too long (max 250 characters)';
      }
    },
    phone: {
      label: 'Phone',
      isOptional: true,
      getError: val => {
        if (val && !validator.isMobilePhone(val))
          return 'Phone number is not valid';
      }
    },
    bio: {
      type: Util.enum.FieldType.Textarea,
      label: 'Bio',
      isOptional: true,
      getError: val => {
        if (!validator.isLength(val, { max: 10000 }))
          return 'Bio is too long (max 10,000 characters)';
      }
    }
  }
});
