import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

export default class FeatureBanner extends Component {
  render() {
    return (
      <div
        className={`feature-banner ${this.props.className || ''}`}
        style={this.props.style || {}}
      >
        {this.props.bannerTitle && this.props.bannerIcon ? (
          <div className="banner-title">
            <ReactSVG className="banner-icon" src={Util.icon.journey} />
            <h3 className="white">{this.props.bannerTitle}</h3>
          </div>
        ) : null}
        <div className="banner-inner">
          <div className="feature-image">
            {this.props.featureImage ? this.props.featureImage : null}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
