import React, { Component } from 'react';
import validator from 'validator';

import Button from '../../../Button/Button';
import withForm from '../../withForm';

//this.props.userId to edit
class AdminUserEditorForm extends Component {
  render() {
    return (
      <form className="form-admin-user-editor" onSubmit={this.props.onSubmit}>
        {this.props.getField('email')}
        {this.props.getField('firstName')}
        {this.props.getField('lastName')}
        {this.props.getField('jobTitle')}
        <div className="button-container">
          {this.props.onCancel ? (
            <Button
              label="Cancel"
              isSecondary={true}
              onClick={this.props.onCancel}
            />
          ) : null}
          <Button label="Save" isSubmit={true} />
        </div>
      </form>
    );
  }
}

export default withForm(AdminUserEditorForm, {
  fields: {
    email: {
      label: 'Email',
      getError: val => {
        if (!validator.isEmail(val)) return 'Enter a valid email address';
      }
    },
    firstName: {
      label: 'First name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a first name';
      }
    },
    lastName: {
      label: 'Last name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a last name';
      }
    },
    jobTitle: {
      label: 'Job title',
      isOptional: true
    }
  }
});
