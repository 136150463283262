import React, { Component } from 'react';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Util from '../../../../Util';
import withForm from '../withForm';

import Button from '../../Button/Button';

class ContactForm extends Component {
  render() {
    return (
      <form className="form-register" onSubmit={this.props.onSubmit}>
        <div className="form-row">
          {this.props.getField('firstName')}
          {this.props.getField('lastName')}
        </div>
        {this.props.getField('email')}
        {this.props.getField('organisation')}
        {this.props.getField('jobTitle')}
        {this.props.variation === 'contact us'
          ? this.props.getField('message')
          : this.props.getField('phoneNumber')}
        {this.props.getField('consentToNews')}

        <div className="button-container">
          <Button
            label={
              this.props.variation === 'contact us' ? 'Get in touch' : 'Sign up'
            }
            isSecondary={true}
            isSubmit={true}
          />
        </div>
        <h5 className="disclaimer grey-3">
          By clicking "Get in touch", you agree to Leda's{' '}
          <Link to={Util.route.site.termsConditions()}>
            Terms and conditions
          </Link>{' '}
          and that you have read our{' '}
          <Link to={Util.route.site.privacyPolicy()}>Privacy Policy</Link>.
        </h5>
      </form>
    );
  }
}

export default withForm(ContactForm, {
  fields: {
    firstName: {
      label: 'Given name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a first name';
      }
    },
    lastName: {
      label: 'Family name',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter a last name';
      }
    },
    email: {
      label: 'Email',
      getError: val => {
        if (!validator.isEmail(val)) return 'Enter a valid email address';
      }
    },
    organisation: {
      label: 'Organisation',
      getError: val => {
        if (!validator.isLength(val, { min: 1, max: 250 }))
          return 'Enter an organisation';
      }
    },
    jobTitle: {
      label: 'Job Title',
      isOptional: true
    },
    message: {
      label: 'How can we help?',
      placeholder: 'Please let us know what your inquiry is about',
      type: Util.enum.FieldType.Textarea,
      isOptional: true
    },
    phoneNumber: {
      label: 'Phone number',
      isOptional: true
    },
    consentToNews: {
      label: `I'd like to receive news, insights and promotions from Leda`,
      type: Util.enum.FieldType.Checkbox
    }
  }
});
