import React, { Component } from 'react';
import moment from 'moment';
import Util from '../../../../../Util';
//redux
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';

import AppSettingsLayout from '../../../../Layouts/App/AppSettingsLayout/AppSettingsLayout';

// Components
import Switch from '../../../../UI/Switch/Switch';
import TimeField from '../../../../UI/DateTimeField/TimeField/TimeField';
import Button from '../../../../UI/Button/Button';
import Checkbox from '../../../../UI/Checkbox/Checkbox';

class AppSettingsNotificationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalUserSettings: null,
      userSettings: null,
      isLoading: true,
      hasUnsavedChanges: false
    };

    this.toggleWeekdayForKey = this.toggleWeekdayForKey.bind(this);

    this.changeSetting = this.changeSetting.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.resetDefaults = this.resetDefaults.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }
  resetDefaults() {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content:
          'Are you sure you wish to reset all your notification settings to their defaults?',
        yesLabel: 'Reset all settings',
        noLabel: 'Cancel',
        yesFn: () => {
          this.setState({
            isLoading: true
          });

          Util.api.post(
            '/api/user/resetUserSettings',
            {},
            {
              success: userSettings => {
                this.setState({
                  userSettings,
                  originalUserSettings: userSettings
                });
              },
              complete: () => this.setState({ isLoading: false })
            }
          );
        }
      }
    });
  }
  fetchData() {
    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/user/getUserSettings',
      {},
      {
        success: userSettings => {
          this.setState({
            userSettings,
            originalUserSettings: userSettings
          });
        },
        complete: () => this.setState({ isLoading: false })
      }
    );
  }
  cancelChanges() {
    this.setState({
      userSettings: this.state.originalUserSettings
    });
  }
  saveChanges() {
    let userSettings = this.state.userSettings;

    this.setState({
      isLoading: true
    });

    Util.api.post(
      '/api/user/saveUserSettings',
      { userSettings },
      {
        success: () => {},
        failure: () => {},
        complete: () => {
          this.setState({
            isLoading: false,
            hasUnsavedChanges: false
          });
        }
      }
    );
  }
  toggleWeekdayForKey(weekdayId, key) {
    let id = weekdayId.toString();

    let newWeekdayString = this.state.userSettings[key].includes(id)
      ? this.state.userSettings[key].replace(id, '')
      : this.state.userSettings[key] + id;

    this.changeSetting(key, newWeekdayString);
  }
  changeSetting(key, value) {
    let newSettings = { ...this.state.userSettings };
    newSettings[key] = value.toString ? value.toString() : value;

    this.setState({
      userSettings: newSettings,
      hasUnsavedChanges: true
    });

    Util.analytics.track('ChangeSetting', {
      setting: key,
      value
    });
  }
  render() {
    let getBooleanSettingSwitch = key => {
      return (
        <Switch
          checked={this.state.userSettings[key] === 'true'}
          onChange={newVal => this.changeSetting(key, newVal)}
        />
      );
    };

    let getTimeSettingField = key => {
      return (
        <TimeField
          label="Send email at:"
          value={this.state.userSettings[key]}
          hideSeconds={true}
          minutesModulo={15}
          use12Hours={true}
          onChange={newVal => this.changeSetting(key, newVal)}
        />
      );
    };

    return (
      <div className="leda-page-app-settings-notification">
        <AppSettingsLayout
          pageTitle="Notification settings"
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              {this.state.isLoading ? (
                <div className="loader"></div>
              ) : (
                <div>
                  <h2>Email</h2>
                  <div className="settings-panel">
                    {getBooleanSettingSwitch(
                      'EMAIL_ACTIVITIES_REMINDER_ENABLED'
                    )}
                    <div className="setting-details">
                      <h3>My Activities reminders</h3>
                      <p>
                        If there are Activities in the My Activities list, we'll
                        remind you about them every Monday at 7am. You can add
                        extra reminders, change the time, or turn Activity
                        notifications off here.
                      </p>
                      <div
                        className={`settings-panel-options ${
                          this.state.userSettings
                            .EMAIL_ACTIVITIES_REMINDER_ENABLED === 'true'
                            ? ''
                            : 'disabled'
                        }`}
                      >
                        {[1, 2, 3, 4, 5, 6, 0].map((weekdayId, idx) => (
                          <Checkbox
                            key={idx}
                            checked={this.state.userSettings.EMAIL_ACTIVITIES_REMINDER_DAYS.includes(
                              weekdayId.toString()
                            )}
                            onChange={() =>
                              this.toggleWeekdayForKey(
                                weekdayId,
                                'EMAIL_ACTIVITIES_REMINDER_DAYS'
                              )
                            }
                            label={moment.weekdays(weekdayId)}
                          />
                        ))}
                        {getTimeSettingField('EMAIL_ACTIVITIES_REMINDER_TIME')}
                      </div>
                    </div>
                  </div>
                  {Util.context.feature.getIsJourneyEnabled() ? (
                    <div className="settings-panel">
                      {getBooleanSettingSwitch(
                        'EMAIL_JOURNEY_REMINDER_ENABLED'
                      )}
                      <div className="setting-details">
                        <h3>Journey reminders</h3>
                        <p>
                          If you start a journey, but don't come back for a
                          while, we'll send you an email or two to follow up.
                          You can change the time you get them, or turn Journey
                          notifications off here.
                        </p>
                        <div
                          className={`settings-panel-options ${
                            this.state.userSettings
                              .EMAIL_JOURNEY_REMINDER_ENABLED === 'true'
                              ? ''
                              : 'disabled'
                          }`}
                        >
                          {getTimeSettingField('EMAIL_JOURNEY_REMINDER_TIME')}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="settings-panel">
                    {getBooleanSettingSwitch('EMAIL_LEDA_UPDATES_ENABLED')}
                    <div className="setting-details">
                      <h3>Leda news and updates</h3>
                      <p>
                        We send occasional news and updates no more than once a
                        month.
                      </p>
                    </div>
                  </div>
                  <div className="settings-confirm">
                    <Button
                      label="Reset all settings"
                      isSecondary={true}
                      onClick={this.resetDefaults}
                    />
                    <Button
                      label="Cancel"
                      isSecondary={true}
                      onClick={this.cancelChanges}
                    />
                    <Button
                      label="Save changes"
                      onClick={this.saveChanges}
                      isDisabled={!this.state.hasUnsavedChanges}
                    />
                  </div>
                  {/* <h2>SMS</h2> */}
                </div>
              )}
            </div>
          </div>
        </AppSettingsLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction }
)(AppSettingsNotificationPage);
