import React, { Component } from 'react';
import Util from '../../../Util';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    //This is an array of valid file extensions that can be uploaded. this.props.allowedExtensions can narrow this array, but not add to it
    let allowedExtensions = ['.pdf', '.png', '.jpg', '.jpeg', '.txt', '.csv'];

    this.state = {
      error: null,
      isUploadingFiles: false,
      allowedExtensions: this.props.allowedExtensions
        ? allowedExtensions.filter(ext =>
            this.props.allowedExtensions.includes(ext)
          )
        : allowedExtensions
    };

    this.fileInputRef = React.createRef();

    this.onFileChange = this.onFileChange.bind(this);
  }
  onFileChange(e) {
    let selectedFiles = e.target.files;

    this.setState({
      isUploadingFiles: true,
      error: null
    });

    if (this.props.onUploading) this.props.onUploading();

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    Util.api.postRaw(
      this.props.uploadUrl || '/api/file/uploadFiles',
      formData,
      {
        success: result => {
          this.setState({
            isUploadingFiles: false
          });

          if (this.props.onUploadSuccess) {
            //result.file is only used by profile photo upload at the moment, it should probably just never use it and expect files[0], otherwise code is doubling up
            if (result.file) this.props.onUploadSuccess(result.file);
            else if (result.files) this.props.onUploadSuccess(result.files);
          }
        },
        failure: error => {
          this.setState({
            error
          });
        },
        complete: () => {
          this.setState({
            isUploadingFiles: false
          });

          this.fileInputRef.current.value = '';
        }
      }
    );
  }
  render() {
    return (
      <div className="file-upload">
        <div
          className={`file-upload-button ${
            this.state.isUploadingFiles ? 'disabled' : ''
          }`}
        >
          {this.props.children}
          <input
            ref={this.fileInputRef}
            type="file"
            name="files"
            multiple={this.props.isMultiple}
            accept={this.state.allowedExtensions.join(',')}
            onChange={this.onFileChange}
          />
        </div>
        {this.props.isAllowedTypesVisible ? (
          <div className="allowed-extensions">
            Allowed file types: {this.state.allowedExtensions.map(ext => ext)}
          </div>
        ) : null}
        <div className="form-error">{this.state.error}</div>
      </div>
    );
  }
}
