import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import Util from '../../../Util';

import SkillIcon from '../SkillIcon/SkillIcon';

// Data source: this.props.skill
export default class SkillLinkItem extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    Util.analytics.track('SkillClicked', {
      skillId: this.props.skill.skillId,
      skillUrlId: this.props.skill.urlId,
      skillName: this.props.skill.name
    });

    if (this.props.onClick) this.props.onClick();
  }
  render() {
    return (
      <div
        className={`skill-link-item ${
          this.props.skill.isLocked ? 'disabled' : ''
        }`}
      >
        <Link
          to={Util.route.app.skill(this.props.skill.urlId)}
          onClick={this.onClick}
          className="link-no-style link-cover"
        />
        <div className="skill-link-item-inner">
          <SkillIcon skillId={this.props.skill.skillId} />
          <h4 className="skill-name">{this.props.skill.name}</h4>
          <ReactSVG
            className="arrow-icon svg-button"
            src={Util.icon.arrow.right}
          />
        </div>
      </div>
    );
  }
}
