import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { openModalAction } from '../../../../actions/modalActions';
import Util from '../../../../Util';
import Button from '../../../UI/Button/Button';
import ContextMenu from '../../../UI/ContextMenu/ContextMenu';
import Grid from '../../../UI/Grid/Grid';
import Switch from '../../../UI/Switch/Switch';

class AdminTeamEditorMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team: props.team
    };
  }

  openUserEditorModal = user => {
    this.props.openModalAction({
      type: Util.enum.ModalType.AdminUserEditorModal,
      data: {
        user,
        organisation: this.props.organisation,
        onUserSaved: savedUser => {
          if (user) {
            //if this was an update
            this.setState({
              team: {
                ...this.state.team,
                teamUsers: this.state.team.teamUsers.map(teamUser => {
                  return teamUser.userId !== user.userId
                    ? teamUser
                    : {
                        ...teamUser,
                        ...savedUser
                      };
                })
              }
            });
          } else {
            //If this was a create
            this.addUserToTeam(savedUser.email);
          }
        }
      }
    });
  };

  submitExistingUserForm = e => {
    e.preventDefault();

    this.setState({
      formError: null
    });

    let email = this.refs.existingUserEmail.value;

    if (validator.isEmail(email)) {
      this.addUserToTeam(email);
      this.refs.existingUserEmail.value = null;
    } else {
      this.setState({
        formError: 'Email is invalid'
      });
    }
  };
  addUserToTeam = (email, isAdmin) => {
    Util.api.post(
      '/api/admin/addUserToTeam',
      {
        email,
        teamId: this.state.team.teamId,
        isAdmin
      },
      {
        success: teamUser => {
          this.setState({
            team: {
              ...this.state.team,
              teamUsers: [...this.state.team.teamUsers, teamUser]
            }
          });
        },
        failure: errorMsg => {
          this.setState({
            formError: errorMsg
          });
        }
      }
    );
  };
  sendWelcomeEmails = records => {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: (
          <div>
            <div>
              Are you sure you wish to send{' '}
              <b>
                {records.length === 1
                  ? records[0].fullName
                  : records.length + ' users'}
              </b>{' '}
              a welcome email?
            </div>
          </div>
        ),
        yesLabel: 'Send',
        yesFn: () => {
          Util.api.post(
            '/api/admin/sendWelcomeEmails',
            {
              emails: records.map(rec => rec.email)
            },
            {
              failure: () =>
                alert('An error occurred. Your changes may not be saved.')
            }
          );
        }
      }
    });
  };
  toggleTeamUserAdmin = record => {
    //Client side
    record.isTeamAdmin = !record.isTeamAdmin; //Instant change

    this.setState({
      team: {
        ...this.state.team,
        teamUsers: this.state.team.teamUsers.map(teamUser =>
          teamUser.teamUserId === record.teamUserId ? record : teamUser
        )
      }
    });

    //Server side
    Util.api.post(
      '/api/admin/toggleTeamUserAdmin',
      {
        teamUserId: record.teamUserId
      },
      {
        failure: () => {
          alert('An error occurred. Your changes may not be saved.');
        }
      }
    );
  };
  removeTeamUser = record => {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: (
          <div>
            Are you sure you wish to remove <b>{record.fullName}</b> from this
            team?
          </div>
        ),
        yesFn: () => {
          //Client side
          this.setState({
            team: {
              ...this.state.team,
              teamUsers: this.state.team.teamUsers.filter(
                teamUser => teamUser.teamUserId !== record.teamUserId
              )
            }
          });

          //Server side
          Util.api.post('/api/admin/removeTeamUser', {
            teamUserId: record.teamUserId
          });
        }
      }
    });
  };

  onFileUploadClick = () => {
    document.getElementById('fileInput').click();
  };
  onFileUpload = event => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      let csvContent = fileReader.result.split('\n');

      const headers = csvContent[0]
        .split(',')
        .map(header => header.trim().toLowerCase());
      const headersToFind = [
        'first name',
        'last name',
        'email',
        'job title',
        'is this person the admin?'
      ];

      const headersWithOrder = {};
      headers.forEach((header, i) => {
        if (headersToFind.includes(header)) headersWithOrder[header] = i;
      });

      for (let i = 1; i < csvContent.length; i++) {
        const rowFields = csvContent[i].split(',');
        const isAdminField =
          rowFields[headersWithOrder['is this person the admin?']];

        const formData = {
          firstName: rowFields[headersWithOrder['first name']],
          lastName: rowFields[headersWithOrder['last name']],
          email: rowFields[headersWithOrder['email']],
          jobTitle: rowFields[headersWithOrder['job title']],
          isAdmin: isAdminField.toLowerCase().trim() === 'yes' ? 1 : 0
        };

        Util.api.post(
          '/api/admin/saveUser',
          {
            user: {
              ...formData,
              userId: null
            },
            organisationLicenseType: this.props.organisation.licenseType,
            organisationName: this.props.organisation.name
          },
          {
            success: ({ email }) => {
              this.addUserToTeam(email, formData.isAdmin);
            },
            failure: errorMsg => {
              if (
                errorMsg === 'Email address already in use by another user.'
              ) {
                this.addUserToTeam(formData.email, formData.isAdmin);
              }
            }
          }
        );
      }
    };

    fileReader.readAsText(event.target.files[0]);
  };

  toggleIsOrganisationAdmin(toggleForUser) {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: (
          <p>
            {toggleForUser.isOrganisationAdmin
              ? `Revoke this user's organisation admin privelages?`
              : `Grant this user organisation admin privelages?`}
          </p>
        ),
        yesFn: () => {
          Util.api.post(
            '/api/admin/setIsOrganisationAdmin',
            {
              userId: toggleForUser.userId,
              isOrganisationAdmin: !toggleForUser.isOrganisationAdmin
            },
            {
              success: () => {
                this.setState({
                  users: this.state.users.map(user => {
                    return user.userId !== toggleForUser.userId
                      ? user
                      : {
                          ...user,
                          isOrganisationAdmin: !user.isOrganisationAdmin
                        };
                  })
                });
              }
            }
          );
        }
      }
    });
  }

  render() {
    return (
      <div>
        <Grid
          data={this.state.team.teamUsers}
          toolbarItems={[
            <input
              key={10}
              type="file"
              onChange={this.onFileUpload}
              id="fileInput"
              style={{ display: 'none' }}
            />,
            <Button
              key={0}
              isBlack={true}
              label="Import contacts (.CSV)"
              size="sm"
              onClick={() => this.onFileUploadClick()}
            />,
            <Button
              key={1}
              label="Create new user"
              size="sm"
              onClick={() => this.openUserEditorModal()}
            />,
            <span key={2}>or</span>,
            <form key={3} onSubmit={this.submitExistingUserForm}>
              <input
                ref="existingUserEmail"
                placeholder="Existing user email"
              ></input>
              <Button label="Add" isSubmit={true} isBlack={true} size="sm" />
              <span className="form-error">{this.state.formError}</span>
            </form>,
            <div key={4} className="flex-spacer"></div>,
            <Button
              key={5}
              label="Send Welcome Email to all team members"
              onClick={() => this.sendWelcomeEmails(this.state.team.teamUsers)}
              isBlack={true}
              size="sm"
            />
          ]}
          columns={[
            {
              name: '',
              key: 'isPasswordNonNull',
              sortFn: Util.sort.asBoolean,
              render: val => {
                return (
                  <span className={'circle-' + (val ? 'green' : 'red')}></span>
                );
              }
            },
            {
              name: 'Id',
              key: 'userId',
              sortFn: Util.sort.asNumber,
              render: (val, { isPasswordNonNull }) => {
                return (
                  <span className={isPasswordNonNull ? '' : 'grey'}>{val}</span>
                );
              }
            },
            {
              name: 'First Name',
              key: 'userId',
              isRenderSort: true,
              render: (val, { isPasswordNonNull, firstName }) => {
                return (
                  <span className={isPasswordNonNull ? '' : 'grey'}>
                    {firstName}
                  </span>
                );
              }
            },
            {
              name: 'Last Name',
              key: 'userId',
              isRenderSort: true,
              render: (val, { lastName, isPasswordNonNull }) => {
                return (
                  <span className={isPasswordNonNull ? '' : 'grey'}>
                    {lastName}
                  </span>
                );
              }
            },
            {
              name: 'Email',
              key: 'userId',
              width: '50%',
              isRenderSort: true,
              render: (val, { email, isPasswordNonNull }) => {
                return (
                  <span className={isPasswordNonNull ? '' : 'grey'}>
                    {email}
                  </span>
                );
              }
            },
            {
              name: 'Admin',
              key: 'isTeamAdmin',
              sortFn: Util.sort.asBoolean,
              render: (val, record) => {
                return (
                  <Switch
                    checked={val ? true : false}
                    onChange={() => this.toggleTeamUserAdmin(record)}
                    hideText={true}
                  ></Switch>
                );
              }
            },
            {
              name: 'Actions',
              key: 'teamUserId',
              isSortDisabled: true,
              render: (val, record) => {
                return (
                  <ContextMenu
                    items={[
                      {
                        icon: Util.icon.edit,
                        label: 'Edit user',
                        onClick: () => this.openUserEditorModal(record)
                      },
                      {
                        icon: Util.icon.email,
                        label: 'View emails',
                        to: Util.route.admin.communicationList(record.email)
                      },
                      {
                        icon: Util.icon.party,
                        label: 'Send welcome email',
                        onClick: () => this.sendWelcomeEmails([record])
                      },
                      {
                        label: 'Remove from team',
                        icon: Util.icon.bin,
                        onClick: () => this.removeTeamUser(record)
                      },
                      {
                        onClick: () => this.toggleIsOrganisationAdmin(record),
                        icon: record.isOrganisationAdmin
                          ? Util.icon.lock
                          : Util.icon.unlock,
                        label: `${
                          record.isOrganisationAdmin ? 'Revoke' : 'Grant'
                        } org admin`
                      }
                    ]}
                  />
                );
              }
            }
          ]}
        />
        <div style={{ height: '175px' }}></div>
      </div>
    );
  }
}

export default connect(null, { openModalAction })(AdminTeamEditorMembers);
