import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  openModalAction,
  closeModalAction
} from '../../../actions/modalActions';
import Util from '../../../Util';

import Carousel from '../Carousel/Carousel';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';

//Data source: this.props.videos
class VideoCarousel extends Component {
  constructor(props) {
    super(props);

    this.onVideoClick = this.onVideoClick.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
    this.playNextVideoIfExists = this.playNextVideoIfExists.bind(this);
  }
  onVideoClick(video) {
    this.openVideoModal(video);
    if (this.props.onVideoClick) this.props.onVideoClick();
  }
  openVideoModal(video) {
    let modalId = this.props.openModalAction({
      type: Util.enum.ModalType.VideoModal,
      data: {
        video,
        onVideoFinished: () => this.playNextVideoIfExists(video, modalId)
      }
    });
  }
  playNextVideoIfExists(currentVideo, currentModalId) {
    let currentVideoIdx = this.props.videos.indexOf(currentVideo);
    let nextVideo = this.props.videos[currentVideoIdx + 1];

    if (nextVideo) {
      this.props.closeModalAction(currentModalId);
      this.openVideoModal(nextVideo);
    }
  }
  render() {
    let settings = {
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 30000,
      speed: 1000,
      infinite: true,
      responsive: [
        {
          breakpoint: Util.css.breakpoint.lg, //Below lg
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: Util.css.breakpoint.md, //Below md
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: Util.css.breakpoint.sm, //Below sm
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: Util.css.breakpoint.xs, //Below xs
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Carousel settings={settings}>
        {this.props.videos.map(vid => (
          <VideoThumbnail
            key={vid.videoId}
            video={vid}
            onClick={this.onVideoClick}
          />
        ))}
      </Carousel>
    );
  }
}

export default connect(
  null,
  { openModalAction, closeModalAction }
)(VideoCarousel);
