import React, { Component } from 'react';

export default class BadgeCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: this.props.count,
      animateCls: ''
    };
  }
  componentWillReceiveProps(props) {
    if (props.count !== this.props.count) {
      this.setState({
        count: props.count,
        animateCls: 'animate-throbbing'
      });
    }
  }
  render() {
    if (!this.state.count) return null;

    //key=count to trigger the animation everytime the count changes
    return (
      <h4
        className={`white badge-count ${this.props.className || ''} ${
          this.state.animateCls
        }`}
        key={this.state.count}
      >
        {this.state.count}
      </h4>
    );
  }
}
