import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Util from '../../../../Util';

//Components
import Card from '../Card';
import Truncate from '../../Truncate/Truncate';
import ReadingTimeLabel from '../../ReadingTimeLabel/ReadingTimeLabel';

// Data source: this.props.content
export default class ContentCard extends Component {
  render() {
    return (
      <Card
        className="content-card"
        isNeverLargeMode={this.props.isNeverLargeMode}
        onClick={() =>
          Util.analytics.track('ContentCardClicked', {
            contentId: this.props.content.contentId,
            contentName: this.props.content.name
          })
        }
        to={Util.route.site.articlesContent(this.props.content.urlId)}
      >
        <div
          className="content-image"
          style={{
            backgroundImage: `url('${Util.storage.blob(
              this.props.content.featureImageBlobId
            )}')`
          }}
        ></div>
        <div className="card-body">
          <div className="content-name">
            <Truncate
              component="h2"
              maxLine={2}
              className="grey-1"
              text={this.props.content.name}
            />
          </div>
          <div className="flex-spacer"></div>
        </div>
        <div className="card-footer">
          <ReadingTimeLabel htmlContent={this.props.content.htmlContent} />
          <div className="flex-spacer"></div>
          <ReactSVG
            className="footer-icon svg-button"
            src={Util.icon.arrow.right}
          />
        </div>
      </Card>
    );
  }
}
