import React, { Component } from 'react';
import Util from '../../../../../Util';

import AppExploreLayout from '../../../../Layouts/App/AppExploreLayout/AppExploreLayout';

// Components
import StoryFeatureBanner from '../../../../UI/FeatureBanner/StoryFeatureBanner/StoryFeatureBanner';
import CourseView from '../../../../UI/CourseView/CourseView';

export default class AppCoursePage extends Component {
  render() {
    let userTeams = Util.context.user.getTeams();
    let teamId = Util.array.any(userTeams) ? userTeams[0].teamId : null;

    let breadcrumbs = [
      {
        label: 'Explore',
        to: Util.route.app.explore()
      },
      {
        label: 'Stories'
      }
    ];

    return (
      <div className="leda-page-app-course">
        <AppExploreLayout
          pageTitle="Stories"
          pageSubtitle="It's just another day in the office. Meet real-life characters and learn how they — and you! — can build better leadership practices."
          pageIcon={Util.icon.story}
          breadcrumbs={breadcrumbs}
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <StoryFeatureBanner teamId={teamId} />
              <CourseView />
            </div>
          </div>
        </AppExploreLayout>
      </div>
    );
  }
}
