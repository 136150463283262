import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModalAction } from '../../../../../actions/modalActions';
import Util from '../../../../../Util';
import LinesEllipsis from 'react-lines-ellipsis';

//Layout
import AppTeamLayout from '../../../../Layouts/App/AppTeamLayout/AppTeamLayout';

import Grid from '../../../../UI/Grid/Grid';
import Button from '../../../../UI/Button/Button';

class AppTeamPostsPage extends Component {
  constructor(props) {
    super(props);

    this.teamPostsGridRef = React.createRef();

    this.editPost = this.editPost.bind(this);
    this.deletePost = this.deletePost.bind(this);
  }
  editPost(postId) {
    this.props.openModalAction({
      type: Util.enum.ModalType.PostEditorModal,
      data: {
        postId,
        onPostSaved: () => {
          this.teamPostsGridRef.current.refresh();
        }
      }
    });
  }
  deletePost(postId) {
    this.props.openModalAction({
      type: Util.enum.ModalType.Confirm,
      data: {
        content: 'Are you sure you wish to delete this post?',
        yesLabel: 'Delete',
        noLabel: 'Cancel',
        yesFn: () => {
          Util.api.post(
            '/api/post/deletePost',
            {
              postId
            },
            {
              success: () => {
                this.teamPostsGridRef.current.refresh();
              }
            }
          );
        }
      }
    });
  }
  render() {
    let team = Util.context.user.getTeamById(this.props.teamId);
    if (!team) return <Redirect to={Util.route.app.home()} />;

    let breadcrumbs = [
      {
        label: team.name,
        to: Util.route.app.team(team.teamId)
      },
      {
        label: 'Team Posts'
      }
    ];

    return (
      <div className="leda-page-app-team-posts">
        <AppTeamLayout
          pageTitle={`Posts for ${team.name}`}
          breadcrumbs={breadcrumbs}
          wrapperCls="grey-6"
        >
          <div className="leda-container-md">
            <div className="leda-row">
              <Grid
                ref={this.teamPostsGridRef}
                dataUrl="/api/post/getAllPostsByTeamId/"
                dataParams={{ teamId: team.teamId }}
                columns={[
                  {
                    name: 'Scheduled at',
                    key: 'scheduledAt',
                    sortFn: Util.sort.asDate,
                    render: val =>
                      val ? Util.format.date.shortDateTime(val) : null
                  },
                  {
                    name: 'Posted at',
                    key: 'postedAt',
                    sortFn: Util.sort.asDate,
                    render: val =>
                      val ? Util.format.date.shortDateTime(val) : null
                  },
                  {
                    name: 'Content',
                    key: 'text',
                    isSortDisabled: true,
                    render: val => <LinesEllipsis maxLine={3} text={val} />
                  },
                  {
                    name: '',
                    key: 'postId',
                    isSortDisabled: true,
                    render: (val, record) => {
                      return (
                        <div className="button-container">
                          <Button
                            icon={Util.icon.edit}
                            size="sm"
                            isSecondary={true}
                            onClick={() => this.editPost(val)}
                          />
                          <Button
                            icon={Util.icon.bin}
                            size="sm"
                            isSecondary={true}
                            onClick={() => this.deletePost(val)}
                          />
                        </div>
                      );
                    }
                  }
                ]}
              />
            </div>
          </div>
        </AppTeamLayout>
      </div>
    );
  }
}

export default connect(
  null,
  { openModalAction }
)(AppTeamPostsPage);
