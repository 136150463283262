import React, { Component } from 'react';
import Util from '../../../Util';

export default class DropDown extends Component {
  constructor(props) {
    super(props);

    let initialValue = this.props.value || this.props.defaultValue;
    this.state = {
      value: initialValue,
      options: this.props.options || [],
      isLoading: !this.props.options && this.props.optionsUrl
    };

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    if (this.props.optionsUrl) {
      Util.api.post(this.props.optionsUrl, this.props.optionsParams || {}, {
        success: options => {
          this.setState({
            options,
            isLoading: false
          });
        }
      });
    }
  }
  onClick(e) {
    if (this.props.onClick) this.props.onClick(e);
  }
  onChange(value) {
    if (!isNaN(value)) value = parseInt(value, 10); //TODO(enhancement) no handle decimals yet

    this.setState({
      value: this.props.isEmptyOnChange ? '' : value
    });

    //Value can be null, causing no selectedOption and onChange should handle that
    let selectedOption = this.state.options.find(
      option => option[this.props.valueProp] === value
    );
    if (this.props.onChange) this.props.onChange(value, selectedOption);
  }
  render() {
    if (this.state.isLoading) {
      return (
        <div className="dropdown custom-input">
          <div className="loader"></div>
        </div>
      );
    } else {
      let value = this.state.value || '';
      return (
        <div className="dropdown custom-input">
          <select
            className={`${this.props.className || ''} ${
              this.props.isEmptyAllowed && !value ? 'placeholder' : ''
            } ${this.props.isDisabled ? 'disabled' : ''}`}
            onChange={e => this.onChange(e.target.value)}
            value={value}
            onClick={this.onClick}
          >
            {this.props.isEmptyAllowed ? (
              <option disabled hidden value={''}>
                {this.props.placeholder || ''}
              </option>
            ) : null}
            {(this.state.options || []).map(option => {
              let display = option[this.props.displayProp];
              let optionValue = option[this.props.valueProp];
              return (
                <option
                  defaultValue={'1-5'}
                  key={optionValue}
                  value={optionValue}
                >
                  {this.props.render
                    ? this.props.render(display, option)
                    : display}
                </option>
              );
            })}
          </select>
          {/* {this.props.isEmptyAllowed && !this.props.isEmptyOnChange ? <ReactSVG className="clear-value" src={Util.icon.close} onClick={() => this.onChange(null)} /> : null} */}
          <div className="flex-spacer"></div>
        </div>
      );
    }
  }
}
