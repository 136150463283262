import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

//Avoiding using a service worker for now
unregister();

// This must occur at the root of the site or everything will die
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
