// app
export const APP_USER_AUTHENTICATED = 'APP_USER_AUTHENTICATED';
export const APP_USER_LOGGED_OUT = 'APP_USER_LOGGED_OUT';
export const APP_COMPLETED_ACTIVITY = 'APP_COMPLETED_ACTIVITY';
export const APP_COMPLETED_VIDEO = 'APP_COMPLETED_VIDEO';
export const APP_COMPLETED_GAME = 'APP_COMPLETED_GAME';
export const APP_COMPLETED_ASSESSMENT = 'APP_COMPLETED_ASSESSMENT';
export const APP_COMPLETED_CONTENT = 'APP_COMPLETED_CONTENT';

// activities
export const ACT_ADDED_MY_ACTIVITY = 'ACT_ADDED_MY_ACTIVITY';
export const ACT_COMPLETED_MY_ACTIVITY = 'ACT_COMPLETED_MY_ACTIVITY';
export const ACT_DELETED_MY_ACTIVITY = 'ACT_DELETED_MY_ACTIVITY';

export const ACT_COMPLETED_MY_ACTIVITY_TASK = 'ACT_COMPLETED_MY_ACTIVITY_TASK';
export const ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS =
  'ACT_UPDATED_MY_ACTIVITY_TASK_FIELDS';

// journeys
export const JRN_STARTED_JOURNEY = 'JRN_STARTED_JOURNEY';
export const JRN_COMPLETED_JOURNEY = 'JRN_COMPLETED_JOURNEY';

// modals
export const MOD_MODAL_OPEN = 'MOD_MODAL_OPEN';
export const MOD_MODAL_CLOSE = 'MOD_MODAL_CLOSE';
export const MOD_MODAL_CLOSE_ALL = 'MOD_MODAL_CLOSE_ALL';

//notification
export const NTF_REFRESHED_NOTIFICATIONS = 'NTF_REFRESHED_NOTIFICATIONS';
export const NTF_SEEN_NOTIFICATIONS = 'NTF_SEEN_NOTIFICATIONS';
export const NTF_ACTIONED_NOTIFICATION = 'NTF_ACTIONED_NOTIFICATION';
