import { Component } from 'react';
import { connect } from 'react-redux';
import { getRecentUserNotificationsAction } from '../../../actions/notificationActions';


//TODO(enhancement) should be HOC?
class AppUserNotificationsManager extends Component {
  componentDidMount() {
    //Note: 'getrecentusernotificatins' only goes back 10 minutes

    let fetchFrequencyActive = 30000; // check for notifications this often
    let fetchFrequencyInactive = fetchFrequencyActive * 5; // 5 times less often if the window is blurred

    let fetchFrequency = fetchFrequencyActive;
    let fetchTimeout = null;

    let refreshUserNotifications = () => {
      this.props.getRecentUserNotificationsAction();
      setTimeout(refreshUserNotifications, fetchFrequency);
    };

    fetchTimeout = setTimeout(refreshUserNotifications, fetchFrequency);

    window.addEventListener(
      'blur',
      () => (fetchFrequency = fetchFrequencyInactive)
    );
    window.addEventListener('focus', () => {
      fetchFrequency = fetchFrequencyActive;

      //Immediately check on window focus
      clearTimeout(fetchTimeout);
      refreshUserNotifications();
    });
  }
  render() {
    return null;
  }
}

export default connect(
  null,
  { getRecentUserNotificationsAction }
)(AppUserNotificationsManager);
