import React, { Component } from 'react';

import Carousel from '../Carousel/Carousel';

export default class ImageCarousel extends Component {
  render() {
    let defaultSettings = {
      slidesToShow: 1,
      initialSlide: 0
    };

    let settings = { ...defaultSettings, ...this.props.settings };

    return (
      <div className="image-carousel">
        <Carousel settings={settings}>
          {this.props.images.map((image, idx) => (
            <div key={idx} className="image-slide">
              {image}
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
