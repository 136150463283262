import { Link, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';

import AdminLayout from '../../../Layouts/Admin/AdminLayout';
import AdminTeamEditorGeneral from '../AdminTeamEditorGeneral/AdminTeamEditorGeneral';
import AdminTeamEditorJourneys from '../AdminTeamEditorJourneys/AdminTeamEditorJourneys';
import AdminTeamEditorPosts from '../AdminTeamEditorPosts/AdminTeamEditorPosts';
import Util from '../../../../Util';

export default class AdminTeamEditorSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: this.getInitialTab(),
      presets: [],
      team: this.props.team,
      areJourneysLoading: false,
      arePostsLoading: false
    };
  }

  componentDidMount() {
    Util.api.post(
      '/api/admin/getAllPresets',
      {},
      {
        success: presets => {
          this.setState({ presets });
        }
      }
    );
  }

  computeUnlockedAtAndScheduledAt = (journeys, startDate) => {
    journeys[0].scheduledAt = startDate;

    journeys.map((journey, i) => {
      // replaces presetJourneyId by teamJourney
      journey.teamJourneyId = journey.presetJourneyId;
      delete journey.presetJourneyId;

      //ScheduledAt
      if (i > 0) {
        journey.scheduledAt = Util.moment.addDays(
          journeys[i - 1].scheduledAt,
          journeys[i - 1].length
        );
      }
      //UnlockedAt

      journey.unlockedAt = Util.moment.substractDays(
        journey.scheduledAt,
        journey.nbWeeksBeforeUnlock * 7
      );

      return journey;
    });

    return journeys;
  };

  getInitialTab = () => {
    const currentUrl = window.location.href.split('/');
    const lastPath = currentUrl[currentUrl.length - 1] || 'settings'; // handles the case where the url ends with a '/'

    return lastPath === 'settings' ? 'general' : lastPath;
  };

  render() {
    const teamId = this.props.team.teamId;
    const rootPath = `/admin/team-editor/${teamId}/settings`;
    return (
      <AdminLayout overflowVisible={true} bottomSpacerHeigth={200}>
        <div className="select-panel-container">
          <div
            className={
              'select-panel ' +
              (this.state.selectedTab === 'general' ? 'selected' : '')
            }
          >
            <Link
              className="select-title"
              to={`${rootPath}`}
              onClick={() => this.setState({ selectedTab: 'general' })}
            >
              General
            </Link>
          </div>
          <div
            className={
              'select-panel ' +
              (this.state.selectedTab === 'journeys' ? 'selected' : '')
            }
          >
            <Link
              className="select-title"
              to={`${rootPath}/journeys`}
              onClick={() => this.setState({ selectedTab: 'journeys' })}
            >
              Journeys
            </Link>
          </div>
          <div
            className={
              'select-panel ' +
              (this.state.selectedTab === 'posts' ? 'selected' : '')
            }
          >
            <Link
              className="select-title"
              to={`${rootPath}/posts`}
              onClick={() => this.setState({ selectedTab: 'posts' })}
            >
              Posts
            </Link>
          </div>
        </div>

        <div>
          <Switch>
            <Route
              exact
              path={`${rootPath}`}
              render={() =>
                this.state.areJourneysLoading || this.state.arePostsLoading ? (
                  <div className="loader" style={{ height: '500px' }}></div>
                ) : (
                  <AdminTeamEditorGeneral
                    key={JSON.stringify(this.state.presets)}
                    dropDownOptions={this.state.presets}
                    onSubmit={(form, { preset, startDate }) => {
                      startDate = new Date(startDate);
                      startDate = new Date(startDate + 'Z').toISOString();
                      this.setState({ areJourneysLoading: true });
                      if (
                        window.confirm(
                          'WARNING: This will replace the existing journeys and posts for this team'
                        )
                      ) {
                        Util.api.post(
                          '/api/admin/getPresetById',
                          {
                            presetId: preset
                          },
                          {
                            success: preset => {
                              const newTeam = {
                                ...this.state.team
                              };

                              // Journeys and description
                              newTeam.teamJourneys = this.computeUnlockedAtAndScheduledAt(
                                [...Object.values(preset.journeys)],
                                startDate
                              );

                              newTeam.description = preset.description;
                              this.props.changeTeamDescription(
                                preset.description
                              );

                              console.log(newTeam);

                              Util.api.post(
                                '/api/admin/updateTeam',
                                { team: newTeam },
                                {
                                  success: team => {
                                    this.setState({
                                      areJourneysLoading: false,
                                      team,
                                      journeysStartDate: Util.format.date.as(
                                        startDate,
                                        'YYYY-MM-DDTHH:mm:ss'
                                      )
                                    });
                                  }
                                }
                              );

                              if (preset.posts.length)
                                this.setState({
                                  arePostsLoading: true
                                });
                              Util.api.post(
                                '/api/post/deletePostsFromTeam/',
                                { teamId: this.state.team.teamId },
                                {
                                  success: () => {
                                    // Posts
                                    for (const {
                                      text,
                                      isLedaOfficial,
                                      scheduledIn,
                                      time,
                                      postFiles
                                    } of preset.posts) {
                                      let scheduledAt = new Date(
                                        Util.moment.addDays(
                                          this.state.journeysStartDate,
                                          scheduledIn
                                        )
                                      );

                                      scheduledAt.setHours(0, 0, 0, 0);

                                      scheduledAt =
                                        scheduledAt.getTime() +
                                        Number.parseInt(time.split(':')[0]) *
                                          3600000 +
                                        Number.parseInt(time.split(':')[1]) *
                                          60000;
                                      Util.api.post(
                                        '/api/post/savePost',
                                        {
                                          post: {
                                            text,
                                            postFiles: [],
                                            scheduledAt,
                                            isLedaOfficial,
                                            teamId: this.state.team.teamId,
                                            postFiles
                                          }
                                        },
                                        {
                                          success: () => {
                                            console.log(preset.posts.length);
                                            this.setState({
                                              arePostsLoading: false
                                            });
                                          },
                                          failure: () => {
                                            alert('Bug while saving preset');
                                          }
                                        }
                                      );
                                    }
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path={`${rootPath}/journeys`}
              render={() => (
                <AdminTeamEditorJourneys
                  key={JSON.stringify(this.state.team)}
                  journeys={this.state.team}
                  team={{ ...this.state.team }}
                />
              )}
            />
            <Route
              exact
              path={`${rootPath}/posts`}
              render={() => (
                <AdminTeamEditorPosts
                  teamId={this.state.team.teamId}
                  organisationId={this.state.team.organisation.organisationId}
                />
              )}
            />
          </Switch>
        </div>
      </AdminLayout>
    );
  }
}
